import React from 'react';
import { DefaultTheme, DarkTheme } from 'react-native-paper';

export const themes = {
    light: {
        ...DefaultTheme,
        roundness: 2,
        colors: {
          ...DefaultTheme.colors,
          primary: '#438eb9',
          accent: '#4186c3',
        },
        backgroundColorReader: '#e9e9ef',
        backgroundColorCal: '#ffffff',
        backgroundColorLogin: '#438eb9',
        textBlack: '#000000',
        fontSizeDia: 28,
        fontSizeMes: 18,
        fontSizeDiaSemana: 10,
        fontSize9: 9, 
        fontSize10: 10, 
        fontSize12: 12,
        fontSize14: 14,
        fontSize16: 16,
        fontSize17: 17,
        fontSize18: 18,
        fontSize20: 20,
        fontSize24: 24,
    },
    dark: {
        ...DarkTheme,
        roundness: 2,
        backgroundColor: '#000000',
        backgroundColorCal: '#000000',
        backgroundColorLogin: '#000000',
        textBlack: '#000000',
        textWhite: '#ffffff',
        textBlue: '#38b8f2',
        textPink: '#ffc0cb',
        colors: {
          ...DarkTheme.colors,
        },
        backgroundColorReader: '#000000',
        backgroundColorDoc: '#c5bdbd',
        borderWidthDoc: 1,
        borderLeftColorDoc: '#ffffff',
        fontSizeDia: 28,
        fontSizeMes: 18,
        fontSizeDiaSemana: 10,
        fontSize9: 9, 
        fontSize10: 10,
        fontSize12: 12, 
        fontSize14: 14,
        fontSize16: 16,
        fontSize17: 17,
        fontSize18: 18,
        fontSize20: 20,
        fontSize24: 24,
    },
    smallLight: {
        ...DefaultTheme,
        roundness: 2,
        colors: {
          ...DefaultTheme.colors,
          primary: '#438eb9',
          accent: '#4186c3',
        },
        backgroundColorReader: '#e9e9ef',
        backgroundColorCal: '#ffffff',
        backgroundColorLogin: '#438eb9',
        fontSizeDia: 26,
        fontSizeMes: 16,
        fontSizeDiaSemana: 8,
        fontSize9: 7, 
        fontSize10: 8, 
        fontSize12: 10,
        fontSize14: 12, 
        fontSize16: 14,
        fontSize17: 15,
        fontSize18: 16,
        fontSize20: 18,
        fontSize24: 22,
    },
    largerLight: {
        ...DefaultTheme,
        roundness: 2,
        colors: {
          ...DefaultTheme.colors,
          primary: '#438eb9',
          accent: '#4186c3',
        },
        backgroundColorReader: '#e9e9ef',
        backgroundColorCal: '#ffffff',
        backgroundColorLogin: '#438eb9',
        fontSizeDia: 30,
        fontSizeMes: 20,
        fontSizeDiaSemana: 12,
        fontSize9: 11, 
        fontSize10: 12,
        fontSize12: 14, 
        fontSize14: 16,
        fontSize16: 18,
        fontSize17: 19,
        fontSize18: 20,
        fontSize20: 22,
        fontSize24: 26,
    },
  };

export const ThemeContext = React.createContext({
    theme: themes.light
});