import React, { useEffect } from 'react';
import { View, Image, StyleSheet, Platform, NativeModules } from 'react-native';
import { Card, Paragraph, ActivityIndicator } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import TouchID from './TouchID';
import { Api, Util } from '../services';
import { useStateValue } from '../state';

export default function Loading(props) {

  const [{ usuario }, dispatch] = useStateValue();

  const api = Api.create('https://adm.educarweb.net.br/');

  const listaPessoas = [];

  const optionalConfigObject = {
    title: 'Touch-Id',
    imageColor: '#e00606',
    imageErrorColor: '#ff0000',
    sensorDescription: 'Sensor de touch',
    sensorErrorDescription: 'Falhou',
    cancelText: 'Cancelar',
  };

  const erro = {
    name: "Nenhuma biometria disponível",
    message: "Nenhuma biometria disponível",
    code: "NOT_SUPPORTED"
  }

  async function carregarQtdeNotificacoes(pes_codigo, tokenAuth, urlApi) {
    const apiNotificacoes = Api.create(urlApi, tokenAuth);
    return await apiNotificacoes.get("/ApiMobileNotificacoes/listarNotificacoes?_pes_codigo=" + pes_codigo + "&_ativo=1&_lido=&datainformacao=&_limit=1&_offset=0");
  }

  async function carregaDadosPessoa(usuario) {
    let dadosUsuario = JSON.parse(await AsyncStorage.getItem('EducarWebEstudanteApp:user'));
    let apiEducar = Api.create(usuario.ApiEducar, usuario.tokenAuth);
    let alterouEstudante = false;

    apiEducar.get("/ApiMobileMatricula/buscarInformacoesPessoa?_pes_codigo=" + usuario.pes_codigo).then((retorno) => {

      if (retorno.status === 200 && retorno.data.informacoes.length > 0) {

        if (retorno.data.informacoes.length === 1) {
          let dadosMatriculaFilho = listaPessoas.find((m) => m.matricula == retorno.data.informacoes[0].matricula);
          retorno.data.informacoes[0].parentesco = dadosMatriculaFilho?.parentesco || null;

          dispatch({
            type: 'alterarEstudante',
            newEstudante: retorno.data.informacoes[0]
          });
        }
        else {
          let lista = [];

          for (let i = 0; i < retorno.data.informacoes.length; i++) {

            let dadosMatriculaFilho = listaPessoas.find((m) => m.matricula == retorno.data.informacoes[i].matricula);
            retorno.data.informacoes[i].parentesco = dadosMatriculaFilho?.parentesco || null;

            if (parseInt(retorno.data.informacoes[i].matricula) === dadosUsuario.matricula) {
              dispatch({
                type: 'alterarEstudante',
                newEstudante: retorno.data.informacoes[i]
              });
              alterouEstudante = true;
            } else if (parseInt(retorno.data.informacoes[i].codPessoa) === dadosUsuario.pes_codigo && !alterouEstudante) {
              dispatch({
                type: 'alterarEstudante',
                newEstudante: retorno.data.informacoes[i]
              });
              alterouEstudante = true;
            }

            if (!!!listaPessoas.find((m) => m.matricula == retorno.data.informacoes[i].matricula))
              lista.push({
                pes_nome: retorno.data.informacoes[i].nome,
                parentesco: retorno.data.informacoes[i].parentesco,
                turma: retorno.data.informacoes[i].turma,
                escola: retorno.data.informacoes[i].escola,
                matricula: retorno.data.informacoes[i].matricula,
                pes_codigo: retorno.data.informacoes[i].codPessoa,
                codturma: retorno.data.informacoes[i].codTurma,
                periodo: retorno.data.informacoes[i].periodo,
                nperiodo: retorno.data.informacoes[i].nperiodo,
                ano: retorno.data.informacoes[i].ano,
                turno: retorno.data.informacoes[i].turno,
                turnocodigo: retorno.data.informacoes[i].turnocodigo
              })
          }

          if (!!!alterouEstudante)
            dispatch({
              type: 'alterarEstudante',
              newEstudante: retorno.data.informacoes[0]
            });

          listaPessoas.push(...lista);
          dispatch({
            type: 'alterarFilhos',
            newFilhos: listaPessoas
          });
        }
      }
    }, (erro) => {
      console.error(erro);
    });
  }

  async function carregarFilhos(usuario) {
    let dadosUsuario = JSON.parse(await AsyncStorage.getItem('EducarWebEstudanteApp:user'));
    let apiEducar = Api.create(usuario.ApiEducar, usuario.tokenAuth);
    apiEducar.get("/ApiMobileResponsaveis/listarFilhos?_pes_codigo=" + usuario.pes_codigoLogin).then((retorno) => {
      if (retorno.status === 200 && !!retorno.data.success) {
        let alterouEstudante = false;
        let pesLogado = usuario.pes_codigoLogin;

        for (let i = 0; i < retorno.data.filhos.length; i++) {
          if (!!!listaPessoas.find((m) => m.matricula == retorno.data.filhos[i].matricula))
            listaPessoas.push({
              pes_nome: retorno.data.filhos[i].pes_nome,
              parentesco: retorno.data.filhos[i].pgp_codigo,
              turma: retorno.data.filhos[i].turma,
              escola: retorno.data.filhos[i].escola,
              matricula: retorno.data.filhos[i].matricula,
              pes_codigo: retorno.data.filhos[i].pes_codigo,
              pgp_codigo: retorno.data.filhos[i].pgp_codigo,
              codturma: retorno.data.filhos[i].codTurma,
              periodo: retorno.data.filhos[i].periodo,
              nperiodo: retorno.data.filhos[i].nperiodo
            })

          if (parseInt(retorno.data.filhos[i].matricula) === dadosUsuario.matricula) {
            dispatch({
              type: 'alterarEstudante',
              newEstudante: {
                nome: retorno.data.filhos[i].pes_nome,
                parentesco: retorno.data.filhos[i].pgp_codigo,
                turma: retorno.data.filhos[i].turma,
                escola: retorno.data.filhos[i].escola,
                periodo: retorno.data.filhos[i].periodo,
                nperiodo: retorno.data.filhos[i].nperiodo,
                matricula: retorno.data.filhos[i].matricula,
                ano: retorno.data.filhos[i].ano,
                turno: retorno.data.filhos[i].turno,
                turnocodigo: retorno.data.filhos[i].turnocodigo
              }
            });
            alterouEstudante = true;
            pesLogado = retorno.data.filhos[i].pes_codigo;

          } else if (parseInt(retorno.data.filhos[i].pes_codigo) === dadosUsuario.pes_codigo && !alterouEstudante) {
            dispatch({
              type: 'alterarEstudante',
              newEstudante: {
                nome: retorno.data.filhos[i].pes_nome,
                parentesco: retorno.data.filhos[i].pgp_codigo,
                turma: retorno.data.filhos[i].turma,
                escola: retorno.data.filhos[i].escola,
                periodo: retorno.data.filhos[i].periodo,
                nperiodo: retorno.data.filhos[i].nperiodo,
                matricula: retorno.data.filhos[i].matricula,
                ano: retorno.data.filhos[i].ano,
                turno: retorno.data.filhos[i].turno,
                turnocodigo: retorno.data.filhos[i].turnocodigo
              }
            });
            alterouEstudante = true;
            pesLogado = retorno.data.filhos[i].pes_codigo;
          }
        }

        dispatch({
          type: 'alterarFilhos',
          newFilhos: listaPessoas
        });

        let infoUsuario = usuario;
        if (!!retorno.data.filhos && !!retorno.data.filhos.length && retorno.data.filhos.length > 0 && !alterouEstudante) {
          infoUsuario.tipo = (retorno.data.filhos.length > 0 ? 2 : 1);
          infoUsuario.pes_codigo = retorno.data.filhos[0].pes_codigo;
          pesLogado = retorno.data.filhos[0].pes_codigo;

          dispatch({
            type: 'alterarEstudante',
            newEstudante: {
              nome: retorno.data.filhos[0].pes_nome,
              parentesco: retorno.data.filhos[0].pgp_codigo,
              turma: retorno.data.filhos[0].turma,
              escola: retorno.data.filhos[0].escola,
              periodo: retorno.data.filhos[0].periodo,
              nperiodo: retorno.data.filhos[0].nperiodo,
              matricula: retorno.data.filhos[0].matricula,
              ano: retorno.data.filhos[0].ano,
              turno: retorno.data.filhos[0].turno,
              turnocodigo: retorno.data.filhos[0].turnocodigo
            }
          });

        }
        else infoUsuario.tipo = 1;

        dadosUsuario.pes_codigo = pesLogado;

        AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(dadosUsuario));

        dispatch({
          type: 'alterarUsuario',
          newUsuario: infoUsuario
        });
      } else {
        let infoUsuario = usuario;
        infoUsuario.tipo = 1;
        dispatch({
          type: 'alterarUsuario',
          newUsuario: infoUsuario
        });
        AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(infoUsuario));
      }
    }, (erro) => {
      console.error(erro);
    });
  }

  function getTokenPayload(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(Util.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  async function processarLoginEducar(token, urlEducar) {
    let userTokenPayload = getTokenPayload(token);
    let dadosUsuario = {
      tokenAuth: token,
      pes_codigo: userTokenPayload.pes_codigo,
      pes_codigoLogin: userTokenPayload.pes_codigo,
      nome: userTokenPayload.nome,
      tipo: null,
      ApiEducar: urlEducar
    };
    await AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(dadosUsuario));

    dispatch({
      type: 'alterarUsuario',
      newUsuario: dadosUsuario
    });

    dispatch({
      type: 'alterarMainNavigator',
      newMainNavigator: 'AppHome'
    });

    await carregarFilhos(dadosUsuario);
    await carregaDadosPessoa(dadosUsuario);

    if (Platform.OS === "web") window.location = '/';
    else {

      const autenticarBiometria = await AsyncStorage.getItem('EducarWebEstudanteApp:AutenticarBiometria');
      if ((autenticarBiometria === 'true') ? true : false) {
        return TouchID.authenticate('Coloque sua digital para entrar no aplicativo', optionalConfigObject)
          .then(success => {
            props.navigation.navigate('AppHome');
          })
          .catch(error => {
            props.navigation.navigate('Login')
          })
      }
      else {
        props.navigation.navigate('AppHome');
      }

    }
  }

  useEffect(() => {
    (async function verificarUsuarioLogado() {
      global.logAcesso = false;
      if (Platform.OS === 'web') {
        let userData = await AsyncStorage.getItem('EducarWebEstudanteApp:user');
        userData = JSON.parse(userData);
        if (!!userData?.tokenAuth && !!userData?.ApiEducar) {
          dispatch({
            type: 'alterarUsuario',
            newUsuario: userData
          });

          await carregarFilhos(userData);
          await carregaDadosPessoa(userData);

          props.navigation.navigate('AppHome');
        }
        else props.navigation.navigate('Login');
      }
      else {

        const emailUsuario = await AsyncStorage.getItem('EducarWebEstudanteApp:email');
        const senhaUsuario = await AsyncStorage.getItem('EducarWebEstudanteApp:senha');
        const municipioUsuario = await AsyncStorage.getItem('EducarWebEstudanteApp:municipio');
        const expopushtokenUsuario = await AsyncStorage.getItem('EducarWebEstudanteApp:expopushtoken');



        if (!!emailUsuario === false && !!senhaUsuario === false) props.navigation.navigate('Login');

        if (!!municipioUsuario) {
          if (municipioUsuario != "https://bentogoncalves.educarweb.net.br") {
            console.log('não tem patrocinio de dados!', municipioUsuario);
            NativeModules.SmiSdkReactModule.stopSponsoredData();
          }


          const apiLoginEducar = Api.create(municipioUsuario);
          apiLoginEducar.post('/ApiMobileAcesso/LoginApp', {
            _usuario: emailUsuario,
            _senha: senhaUsuario,
            _soacessoapp: 1,
            _token: expopushtokenUsuario,
            tipo: Util.emailValido(emailUsuario) ? 1 : (Util.verificaCPF(emailUsuario) !== null ? 2 : 3)
          }).then(async (retorno) => {
            if (retorno.status === 200 && retorno.data.success === true) {
              await processarLoginEducar(retorno.data.auth.token, municipioUsuario);
            }
            else {
              props.navigation.navigate('Login');
            }
          }, (erro) => {
            console.log("ERRO AO EFETUAR LOGIN", erro);
            AsyncStorage.removeItem('EducarWebEstudanteApp:email');
            AsyncStorage.removeItem('EducarWebEstudanteApp:senha');
            AsyncStorage.removeItem('EducarWebEstudanteApp:user');
            AsyncStorage.removeItem('EducarWebEstudanteApp:idcidadeselecionada');
            AsyncStorage.removeItem('EducarWebEstudanteApp:expopushtoken');
            AsyncStorage.removeItem('EducarWebEstudanteApp:municipio');
            props.navigation.navigate('Login');
          });
        } else {
          api.post("AbsUsuario/Login", { User: emailUsuario, Password: senhaUsuario }).then(async (response) => {
            if (response.data.success) {
              let notificacoes = await carregarQtdeNotificacoes(response.data.result.identity.pes_codigo, response.data.result.token, response.data.result.identity.url);

              global.QtdeNotificacoes = notificacoes.data.naoLidas;

              let dadosUsuario = {
                tokenAuth: response.data.result.token,
                pes_codigo: response.data.result.identity.pes_codigo,
                pes_codigoLogin: response.data.result.identity.pes_codigo,
                nome: response.data.result.identity.nome,
                tipo: null,
                ApiEducar: response.data.result.identity.url
              };

              await AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(dadosUsuario));

              dispatch({
                type: 'alterarUsuario',
                newUsuario: dadosUsuario
              });

              await carregarFilhos(dadosUsuario);
              await carregaDadosPessoa(dadosUsuario);

              const autenticarBiometria = await AsyncStorage.getItem('EducarWebEstudanteApp:AutenticarBiometria');
              if ((autenticarBiometria === 'true') ? true : false) {
                return TouchID.authenticate('Coloque sua digital para entrar no aplicativo', optionalConfigObject)
                  .then(success => {
                    props.navigation.navigate('AppHome');
                  })
                  .catch(error => {
                    props.navigation.navigate('Login')
                  })
              }
              else {
                props.navigation.navigate('AppHome');
              }
              // props.navigation.navigate('AppHome');
            }
            else props.navigation.navigate('Login');
          }, (erro) => {
            props.navigation.navigate('Login');
          });
        }
      }
    })();
  }, []);

  return (
    <View style={styles.container}>
      <Card style={styles.card}>
        <Card.Content>
          <View style={styles.logoContainer}>
            <Image style={styles.logo} source={require('../../assets/educar_cor.png')} />
          </View>
          <Paragraph style={styles.texto}>Inicializando app, aguarde...</Paragraph>
          <ActivityIndicator animating={true} size="small" />
        </Card.Content>
      </Card>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#438eb9',
    alignItems: "center",
    justifyContent: "center"
  },
  card: {
    height: 170
  },
  logo: {
    height: 38,
    width: 110,
  },
  logoContainer: {
    margin: 10,
    alignSelf: "center"
  },
  texto: {
    margin: 15,
    paddingBottom: 15,
    fontSize: 14,
    fontWeight: '600'
  }
});