import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';
import _ from 'lodash';

import ItemListaDocumentos from './ItemListaDocumentos';
import {ThemeContext} from '../../../theme-context';

export default function ListaDocumentos({dados, responderDocumento, visualizarDocumento, visualizarPaginaDocumento, downloadDocumento, abrirMensagensDocumento, abrirChatGrupo, filtrarApenasNovos, filtrarApenasSemResposta, visualizarAnexo, cor}) {

  function renderLista(){
    return (
      <View style={{ backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }}>
        {_.filter(dados, function(doc) {
              let retornar = true;
            
              if(!!filtrarApenasNovos)
                retornar = (!filtrarApenasNovos) ? true : doc.novoDocumento == filtrarApenasNovos;
              if(!!filtrarApenasSemResposta)
                retornar = (!filtrarApenasSemResposta) ? true : (doc.qtde_respostas == 0 && (!!doc.dpf_respaposdatalimite ||(!!doc.dpf_datalimite && new Date(doc.dpf_datalimite).getTime() > Date.now())))

              return retornar;
          }).map((documento, indice) => (
             <View key={indice}>
               <ItemListaDocumentos 
                  dados={documento} 
                  responderDocumento={responderDocumento} 
                  visualizarDocumento={visualizarDocumento} 
                  visualizarPaginaDocumento={visualizarPaginaDocumento}
                  downloadDocumento={downloadDocumento} 
                  abrirMensagensDocumento={abrirMensagensDocumento}
                  abrirChatGrupo={abrirChatGrupo}
                  visualizarAnexo={visualizarAnexo}
                  cor={cor} />
               <Divider />
             </View>
        ))}
      </View>
    )
  }

  function renderSemDados(){
    return (
      <View style={styles.center}>
        <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>Nenhum documento disponível no momento...</Text>
      </View>
    )
  }

  return (
      <View style={styles.content}>
        {!!dados && dados.length > 0 ? renderLista() : renderSemDados()}
      </View>
  );
}

const styles = StyleSheet.create({
  
  content: {
    backgroundColor: "#FFF",
    flex: 1,
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
});