import React, { useEffect, useState } from 'react';
import { Platform, View, Image, StyleSheet, SafeAreaView } from 'react-native';
import { IconButton, Badge } from 'react-native-paper'
import { createDrawerNavigator, createStackNavigator } from 'react-navigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MenuApp from '../components/MenuApp';
import { useStateValue } from '../state';
import { Api } from '../services';

import Home from './Home';
import Notas from './Notas';
import Horarios from './Horarios';
import Frequencia from './Frequencia';
import Configuracoes from './Configuracoes';
import MinhaConta from './MinhaConta';
import Notificacoes from './Notificacoes';
import AdicionarEvento from './AdicionarEvento';
import EnviarMensagem from './EnviarMensagem';
import Mensagens from './Mensagens';
import VisualizarMensagem from './VisualizarMensagem';
import Documentos from './Documentos';
import MeusBoletins from './MeusBoletins';
import Rematricula from './Rematricula';
import ChatGrupo from '../screens/ChatGrupo';
import Observacoes from './Observacoes';
import Biblioteca from './Biblioteca';
import Cardapio from './Cardapio';
import MinhasReservas from './MinhasReservas';
import { Agendas } from '.';
import Atestados from './Atestados';

export default function AppHome(props) {

  const [{ mainNavigator, usuario, estudante }, dispatch] = useStateValue();

  const [ QtdeNotificacoes, setQtdeNotificacoes ] = useState(0);

  async function logOff() {
    await AsyncStorage.removeItem('EducarWebEstudanteApp:email');
    await AsyncStorage.removeItem('EducarWebEstudanteApp:senha');
    await AsyncStorage.removeItem('EducarWebEstudanteApp:user');
    await AsyncStorage.removeItem('EducarWebEstudanteApp:expopushtoken');
    await AsyncStorage.removeItem('EducarWebEstudanteApp:municipio');
    dispatch({
      type: 'alterarMainNavigator',
      newMainNavigator: 'Login'
    });
  }
  
  useEffect(() => {
      if (mainNavigator === 'Login') props.navigation.navigate('Login')
  }, [mainNavigator])

  useEffect(() => {
    const apiNotificacoes = Api.create(usuario.ApiEducar, usuario.tokenAuth);
    apiNotificacoes.get("/ApiMobileNotificacoes/listarNotificacoes?_pes_codigo=" + usuario.pes_codigo + "&_mtr_codigo=null&_ativo=1&_lido=&datainformacao=&_limit=9999&_offset=0").then(res => {
      setQtdeNotificacoes(res.data.naoLidas);
    });

    apiNotificacoes.get('apimobileacesso/listaMenusAcesso').then(res => {
      if (res.status === 200 && !!res.data.listaMenusAcesso && res.data.listaMenusAcesso.length > 0) {
        dispatch({
          type: 'alterarMenuApp',
          newMenuApp: res.data.listaMenusAcesso
        });
      }
    })
    .catch(erro=>{
      console.error('Erro ao efetuar requisição de dados do menu da app', erro);
      if (erro.response.status === 401) logOff();
    });
  }, [])  

  const HomeNavigator = createDrawerNavigator({
    Home: {screen: Home},
    Horarios: {screen: Horarios},
    Notas: {screen: Notas},
    Frequencia: {screen: Frequencia},
    Configuracoes: {screen: Configuracoes},
    MinhaConta: {screen: MinhaConta},
    Notificacoes: {screen: Notificacoes},
    AdicionarEvento: {screen: AdicionarEvento},
    EnviarMensagem: {screen: EnviarMensagem},
    Mensagens: {screen: Mensagens},
    VisualizarMensagem: {screen: VisualizarMensagem},
    Documentos: {screen: Documentos},
    MeusBoletins: {screen: MeusBoletins},
    Rematricula: {screen: Rematricula},
    ChatGrupo: {screen: ChatGrupo},
    Observacoes: {screen: Observacoes},
    Biblioteca: {screen: Biblioteca},
    Cardapio: {screen:Cardapio},
    MinhasReservas: {screen: MinhasReservas},
    Agendas: {screen: Agendas},
    Atestados: {screen: Atestados},
  }, {
    contentComponent: MenuApp,
    initialRouteName: 'Home',
    drawerType: "slide",
    contentOptions: {
      activeTintColor: '#438eb9',
    },   
  });

  const HomeStack = createStackNavigator({ RootStack : { screen: HomeNavigator }  },  {
    headerMode: 'float',
    navigationOptions: ({navigation}) => ({
      headerStyle: {
        backgroundColor: '#438eb9',
      },
      headerBackground: (
        <Image
          style={styles.logo}
          source={require('../../assets/educar.png')}
        />
      ),
      headerTintColor: 'white',
      headerLeft: <IconButton icon="menu" color="#FFF" size={26} onPress={ () => navigation.toggleDrawer() } />,
      headerRight: (<View style={{flexDirection: 'row'}}>
          <IconButton icon="notifications" color="#FFF" size={26} onPress={ () => navigation.navigate('Notificacoes') } />
          {QtdeNotificacoes > 0 && <Badge style={styles.badge}>{QtdeNotificacoes}</Badge>}
        </View>)
    })
  });
  
  return (
    <SafeAreaView style={styles.header}>
      <HomeStack />
    </SafeAreaView>
  );

}

 const styles = StyleSheet.create({
  logo: {
    height: 42,
    width: 128,
    marginTop: Platform.OS === 'web' ? 6 : (Platform.OS === "android" ? 5 : 12),
    alignSelf: "center"
  },
  header: {
    flex:1, 
    flexDirection: 'row', 
    marginTop: Platform.OS === 'web' ? null : 24,
  },
  badge: {
    position: "absolute",
    alignSelf: 'flex-start',
    marginTop: 5,
    marginLeft: 5
  },
 });