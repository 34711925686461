import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, StyleSheet, StatusBar, Platform, Alert, Linking } from 'react-native';
import { Button, IconButton, Avatar, Divider, Switch } from 'react-native-paper';
import { NavigationActions } from 'react-navigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';
import TouchID from '../screens/TouchID';
import { useStateValue } from '../state';
import { Api, Util } from '../services'
import ApiCalendar from 'react-google-calendar-api';
import { ThemeContext } from '../../theme-context';

export default function MenuApp(props) {

  const [{ usuario, mainNavigator, menuApp }, dispatch] = useStateValue();
  //const [ menuApp, setMenuApp ] = useState([]);
  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
  const [AutenticarBiometria, SetAutenticarBiometria] = React.useState(false);
  const [VincularGoogle, SetVincularGoogle] = React.useState(false);
  const optionalConfigObject = {
        unifiedErrors: false // use unified error messages (default false)
 }
  const [existeBiometria, SetExisteBiometria] = React.useState(false);

  async function AlternaAutenticarBiometria() {
    let biometria = !AutenticarBiometria;
    SetAutenticarBiometria(biometria);

    await AsyncStorage.setItem('EducarWebEstudanteApp:AutenticarBiometria', biometria.toString());
  }

  function VerificaBiometria() {
    TouchID.isSupported()
    .then(biometryType => {
     
         SetExisteBiometria(biometryType)
      
      })
      .catch(e => {
        SetExisteBiometria(false)
      })

  }



  useEffect(() => {
    const statusBiometria = async () => {
      try {
      if(Platform.OS !== 'web'){
       VerificaBiometria();
      }
        let verificaStatusBiometria = await AsyncStorage.getItem('EducarWebEstudanteApp:AutenticarBiometria');
        SetAutenticarBiometria((verificaStatusBiometria === 'true') ? true : false);
      
      } catch (err) {
        console.log(err);
      }
    };

    statusBiometria();

 
  }, []);

    function Login() {
      if (!ApiCalendar.sign) {
        ApiCalendar.handleAuthClick()
          .then(() => {
            console.log('sign in succesful!');
          })
          .catch((e) => {
            console.error(`sign in failed ${e}`);
          })
      }
    }


  /*useEffect(() => {
    api.get('apimobileacesso/listaMenusAcesso').then(res => {
      if (res.status === 200 && !!res.data.listaMenusAcesso && res.data.listaMenusAcesso.length > 0) {
        setMenuApp(res.data.listaMenusAcesso);
      }
    }, erro => {
      console.error('Erro ao efetuar requisição de dados do menu da app', erro);
      if (erro.response.status === 401) logOff();
    });
  }, []);*/

  function exibirConfirmacao(titulo, texto, descricaoBotaoOK = 'OK', descricaoBotaoCancela = 'Cancelar', funcaoOK = () => { }, funcaoCancela = () => { }) {
    if (Platform.OS === "web") {
      if (confirm(titulo + '\n\n' + texto)) funcaoOK();
    }
    else {
      Alert.alert(
        titulo,
        texto,
        [
          {
            text: descricaoBotaoCancela,
            onPress: funcaoCancela,
            style: 'cancel',
          },
          { text: descricaoBotaoOK, onPress: funcaoOK },
        ],
        { cancelable: true },
      );
    }
  }

  function navigateToScreen(route) {
    const navigateAction = NavigationActions.navigate({
      routeName: route
    });
    props.navigation.dispatch(navigateAction);
  }

  async function sair() {
    exibirConfirmacao('Sair', 'Deseja realmente efetuar o logoff?', 'SIM', 'NÃO', () => logOff());
  }

  async function logOff() {
    await AsyncStorage.removeItem('EducarWebEstudanteApp:email');
    await AsyncStorage.removeItem('EducarWebEstudanteApp:senha');
    await AsyncStorage.removeItem('EducarWebEstudanteApp:user');
    //await AsyncStorage.removeItem('EducarWebEstudanteApp:idcidadeselecionada');
    await AsyncStorage.removeItem('EducarWebEstudanteApp:expopushtoken');
    await AsyncStorage.removeItem('EducarWebEstudanteApp:municipio');

    dispatch({
      type: 'alterarMainNavigator',
      newMainNavigator: 'Login'
    });
  }

  function renderMenuDefault() {
    return (
      <React.Fragment>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Home') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela home" icon="home" onPress={() => navigateToScreen('Home')}>Home</Button>
        </View>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Horarios') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de horários" icon="timer" onPress={() => navigateToScreen('Horarios')}>Horários</Button>
        </View>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Cardapio') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de cardápio" icon="restaurant-menu" onPress={() => navigateToScreen('Cardapio')}>Cardápio</Button>
        </View>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Agendas') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de agenda" icon="account-circle" onPress={() => navigateToScreen('Agendas')}>Agenda</Button>
        </View>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Notas') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de consulta de notas" icon="show-chart" onPress={() => navigateToScreen('Notas')}>Notas</Button>
        </View>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Frequencia') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de consulta de frequencia" icon="assignment" onPress={() => navigateToScreen('Frequencia')}>Frequência</Button>
        </View>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Documentos') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de documentos" icon="attachment" onPress={() => navigateToScreen('Documentos')}>Documentos</Button>
        </View>
        {usuario.tipo === 2 &&
          <View style={[[styles.screenStyle, (props.activeItemKey == 'Mensagens') ? styles.activeBackgroundColor : null],
          { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
            <Button accessibilityLabel="Botão acesso a tela de Mensagens" icon="mail" onPress={() => navigateToScreen('Mensagens')}>Mensagens</Button>
          </View>
        }
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Biblioteca') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela da biblioteca" icon="library-books" onPress={() => navigateToScreen('Biblioteca')}>Biblioteca</Button>
        </View>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'MeusBoletins') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de boletins" icon="show-chart" onPress={() => navigateToScreen('MeusBoletins')}>Meus Boletins</Button>
        </View>
        <View style={[[styles.screenStyle, (props.activeItemKey == 'Observacoes') ? styles.activeBackgroundColor : null],
        { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de observações" icon="insert-comment" onPress={() => navigateToScreen('Observacoes')}>Observações</Button>
        </View>
        {/*<View style={[styles.screenStyle, (props.activeItemKey == 'Rematrícula') ? styles.activeBackgroundColor : null]}>
          <Button accessibilityLabel="Botão acesso a tela de rematrícula" icon="near-me" onPress={() => navigateToScreen('Rematricula')}>Rematricula</Button>
      </View>*/}
        <View style={[styles.screenStyle, (props.activeItemKey == 'Atestados') ? styles.activeBackgroundColor : null,
          { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <Button accessibilityLabel="Botão acesso a tela de observações" icon="file-download" onPress={() => navigateToScreen('Atestados')}>Atestados</Button>
        </View>
      </React.Fragment>
    )
  }

  function acessarMenu(link) {
    if (Util.urlValida(link)) {
      Linking.openURL(link);
    }
    else {
      navigateToScreen(link);
    }
  }

  return (
    <ScrollView>
      {/* <ScrollView contentContainerStyle={[styles.container, { flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}> */}
      <StatusBar backgroundColor="#438eb9" translucent={true} />

      <View style={[styles.headerContainer]}>
        <View style={{ alignItems: "center" }}>
          <Avatar.Image size={120} source={{
            uri: usuario.ApiEducar + '/Imagem/RetornaFotoPessoa?codPessoa=' + usuario.pes_codigoLogin,
            method: 'POST',
            headers: {
              Pragma: 'no-cache',
              Authorization: 'Bearer ' + usuario.tokenAuth
            }
          }} />

          <IconButton icon="edit" onPress={() => acessarMenu('MinhaConta')} size={24} style={{ position: 'absolute', right: 0, color: ThemeContext._currentValue.theme?.textWhite }} />
   
          <Text style={[styles.headerText, { color: ThemeContext._currentValue.theme?.textBlack}]}>{usuario.nome}</Text>
        </View>
      </View>
      <ScrollView>
        <View style={styles.screenContainer}>
          {menuApp.length === 0 && renderMenuDefault()}
          {menuApp.length > 0 &&
            <React.Fragment>
              {_.orderBy(menuApp, 'men_codigo').map((menu, indice) => (
                <View key={indice} style={[[styles.screenStyle, (props.activeItemKey == menu.men_link) ? styles.activeBackgroundColor : null],
                { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
                  <Button accessibilityLabel={menu.men_acessibilidade} icon={menu.men_icone} onPress={() => acessarMenu(menu.men_link)}>{menu.men_descricao}</Button>
                </View>
              ))}
            </React.Fragment>
          }
          <Divider />
          {Platform.OS == 'web' &&
        <View style={[styles.screenStyle, { backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal } ]}>
          <Button accessibilityLabel="Botão acesso a tela de configurações" icon="settings" onPress={() => navigateToScreen('Configuracoes')}>Configurações</Button>
        </View>}
          {/* <View style={[styles.screenStyle, (props.activeItemKey == 'MinhaConta') ? styles.activeBackgroundColor : null]}>
          <Button accessibilityLabel="Botão acesso a tela de dados de sua conta de usuário" icon="account-box" onPress={() => navigateToScreen('MinhaConta')}>Minha Conta</Button>
        </View> */}


          {Platform.OS !== 'web' && existeBiometria == true  ?
            <View style={[styles.screenStyle, { flexDirection: 'row', marginTop: 5, backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
              <Button accessibilityLabel="Botão para solicitar biometria" icon="touch-app">Solicitar biometria</Button>
              <Switch style={{ alignSelf: 'center' }} value={AutenticarBiometria} onValueChange={() => AlternaAutenticarBiometria()} />
            </View>
            : <View></View>}

          <View style={[styles.screenStyle, { height: 40, marginTop: 2, width: '100%', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
            <Button accessibilityLabel="Botão para sair de sua conta realizando logoff" icon="exit-to-app" onPress={() => sair()}>Sair</Button>
          </View>
          <View style={[styles.screenStyle, { height: 20, marginTop: 2, width: '100%', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
            <Text style={{ color: '#808080ad', marginRight: 10, fontSize: 12 }}>v.: 1.0.14</Text>
          </View>
        </View>
      </ScrollView>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  headerContainer: {
    margin: 12,
    width: "100%"
  },
  headerText: {
    marginTop: 10,
    color: '#000',
  },
  screenContainer: {
    paddingTop: 10,
    width: '100%',
  },
  screenStyle: {
    height: 40,
    marginTop: 2,
    width: '100%',
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap'
  },
  screenTextStyle: {
    fontSize: 20,
    marginLeft: 20,
    textAlign: 'center'
  },
  selectedTextStyle: {
    fontWeight: 'bold',
    color: '#FFF'
  },
  activeBackgroundColor: {
    backgroundColor: '#ede8e8',
  }
});