import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';

import ItemListaEvento from './ItemListaEvento'
import {ThemeContext} from '../../../theme-context';

export default function ListaEventos(props) {

  function renderLista(){
    return (
      <View>
        {props.dados.map((evento, indice) => (
          <View key={indice}>
            <ItemListaEvento evento={evento} key={indice} />
            <Divider />
          </View>
        ))}
      </View>
    )
  }

  function renderSemDados(){
    return (
      <View style={styles.center}>
        <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>Você não possui eventos para esta data</Text>
      </View>
    )
  }

  return (
    <View style={styles.content}>
      {!!props.dados && props.dados.length > 0 ? renderLista() : renderSemDados()}
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: "#FFF",
    flex: 1,
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
});