import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Platform, Dimensions } from 'react-native';
import { DataTable, IconButton, Divider, Card, Paragraph, Title, } from 'react-native-paper';
import { useStateValue } from '../state';
import { ThemeContext } from '../../theme-context';
import { Api } from '../services';
import { ListaReservas } from '../components';

export default function MinhasReservas(props) {

  const [{ usuario }, dispatch] = useStateValue();
  const [reserva, setReservas] = useState([]);
  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    api.get("/ApiMobileBiblioteca/MinhasReservas?pes_codigo=" + usuario.pes_codigo).then((retorno) => {
      if (retorno.status === 200) {
        setReservas(retorno.data.reservas);
        setCarregando(false);
      }
    }, (erro) => {
      console.error(erro);
      setCarregando(false);
    });
  }, [usuario.pes_codigo]);

  function MinhasReservasWeb() {
    return (
      <View style={[styles.container,  {backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
        <IconButton icon="navigate-before" size={30} onPress={() => props.navigation.navigate('Biblioteca')}></IconButton>
        <Divider style={{ height: 3 }} />
        <ListaReservas dados={reserva} />
      </View>
    );
  }

  function MinhasReservasMobile() {
    return (
      <View style={styles.container}>
        <IconButton icon="navigate-before" size={30} onPress={() => props.navigation.navigate('Biblioteca')} />
        {reserva?.length > 0 && reserva.map((dadosReserva, indice) => (
          <View key={indice}>
            <Card>
              <Card.Content>
                <Title style={{ fontSize: 18 }}>{dadosReserva.obr_titulo}</Title>
                <Paragraph>
                  {dadosReserva.bib_descricao} {"\n"}
                  Retirado: {dadosReserva.retirada} {"\n"}
                  Devolver: {dadosReserva.devolucao}{"\n"}
                  Status: {dadosReserva.status} 
                </Paragraph>
              </Card.Content>
              <View>
                <Divider />
              </View>
            </Card>
          </View>
        ))}
        {reserva.length === 0 && carregando === false && (
          <View style={styles.centralizar}>
            <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }}>Você não possui livros emprestados</Text>
          </View>
        )}
      </View>
    )
  }

  return (
    (Platform.OS === "web" && Dimensions.get("screen").width >= 1024) ? MinhasReservasWeb() : MinhasReservasMobile())
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white"
  },
  botaoMobile: {
    height: 50,
    borderWidth: 1,
    color: '#ffffff',
    tintColor: 'white',
    fontSize: 10,
    fontWeight: "bold",
    width: "100%",
    justifyContent: "center",
  },
  centralizar: {
    flex: 1,
    justifyContent: 'center',
    alignContent: "center",
  },
  emdia: {
    marginTop: 3,
    width: 18,
    height: 18,
    borderRadius: 18 / 2,
    backgroundColor: 'green',
  },
  atrasado: {
    width: 18,
    height: 18,
    borderRadius: 18 / 2,
    backgroundColor: 'red',
  }

})