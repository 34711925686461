import React, { useState } from 'react';
import { View, Text, StyleSheet, Platform, Alert} from 'react-native';
import { Button, Modal, IconButton, Divider, Card, Dialog, Paragraph } from 'react-native-paper';
import { ThemeContext } from '../../theme-context';
import ApiCalendar from 'react-google-calendar-api';
import { Api } from '../services';
import { set } from 'lodash';


export default function Configuracoes() {

  const [disabled, setDisabled] = useState(false);


  function Login() {

    if(Platform.OS == 'web')
  {
    if (!ApiCalendar.sign) {
      ApiCalendar.handleAuthClick()
       
        .catch((e) => {
          console.error(`sign in failed ${e}`);
        })
    }

  }
  }

  function SairLogin() {
    if (ApiCalendar.sign) {
      ApiCalendar.handleSignoutClick()
     
    }
  }

  return (

    <View style={styles.container}>
      <Card>
        {!ApiCalendar.sign && Platform.OS ==  'web' &&
          <Card.Content style={{paddingBottom: 15, marginTop: 10}}>
            <Text style={{ fontSize: 15,  textAlign: 'center', fontWeight: 'bold' }}>Vincule o aplicativo com a sua conta do Google para usufruir dos benefícios do Google Agenda </Text>
            <Button disabled={disabled} mode="contained"  icon='link' style={{ alignSelf: 'center', marginTop: 20, marginLeft: 10 }} onPress={() => Login(setDisabled(true))}>Vincular</Button>
          </Card.Content>}
        {ApiCalendar.sign && Platform.OS ==  'web' &&
          <Card.Content style={{paddingBottom: 15, marginTop: 10}}>
            <Text style={{ fontSize: 15, textAlign: 'center', fontWeight: 'bold' }}>O aplicativo já está vinculado com a conta do Google</Text>
            <Button  disabled={disabled} mode="contained" icon='link' style={{ alignSelf: 'center', marginTop: 20,  marginLeft: 10 }} onPress={() => SairLogin(setDisabled(true)) } >Sair</Button>
          </Card.Content>}    
      </Card>

    </View>
  );
}

const styles = StyleSheet.create({


  container: {
    flex: 1,
    backgroundColor: "#FFF",

  },
  linha: {
    flexDirection: 'row',
    alignItems: "center",
  },
  containerTitulo: {
    alignSelf: "center",
    alignItems: 'center',
  },
  titulo: {
    fontSize: 16,
    fontWeight: "bold",
  },
  conteudo: {
    marginTop: 20,
    marginHorizontal: (Platform.OS === "web" ? 40 : 5),

  },
  item: {
    margin: 5,
    padding: 8,
    borderColor: "#2d2d2d",
    borderWidth: 1,
    minWidth: 36,
    alignItems: "center",
  },
  texto: {
    marginTop: 8,
    fontSize: 8,
  },


});