import React, { useState, useEffect } from 'react';
import { TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function BotaoModalPicker(props) {
  return (
    <TouchableWithoutFeedback onPress={() => props.onPress()}>
      <View style={[styles.botao, props.style || null]}>
        <MaterialCommunityIcons name="arrow-down-drop-circle" size={22} color="#926a6a" style={{marginTop: -6, marginLeft: 12}} />
        {!!props.texto ? <Text style={styles.texto}>{props.texto}</Text> : <Text style={styles.label}>{props.label || 'Selecione'}</Text>}
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  botao: {
    paddingTop: 24,
    paddingBottom: 6,
    minHeight: 58,
    backgroundColor: 'rgba(231, 231, 231, 1.0)',
    borderBottomWidth: 2,
    borderBottomColor: 'rgb(201,201,201)',
    flexDirection: 'row',
    flexGrow: 1,
    flex: 1
  },
  label: {
    fontFamily: "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif",
    color: '#926a6a',
    marginLeft: 12,
    fontSize: 16
  },
  texto: {
    fontFamily: "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: 16,
    marginLeft: 12,
    color: "#000000"
  },
})