import { isValid, isBefore, isAfter, parse } from 'date-fns';

var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

const Util = {
  retornaCorStatus: function(status) {
    switch (status.trim().toUpperCase()) {
      case 'P':
        return '#088226';
      case 'F':
        return '#f2290a';
      case 'FJ':
        return '#c7a81c';
      default:
        return '#cecece';
    }
  },
  horaValida: function(horario) {
    let hora = parseInt(horario.split(':')[0]);
    let minutos = parseInt(horario.split(':')[1]);
    return (hora >= 0 && hora <= 23 && minutos >= 0 && minutos <= 59);
  },
  dataValida: function(data, mascara) {
    let dataValidar = parse(data, mascara, new Date());
    return isValid(dataValidar);
  },
  dataFormatada: function(data, br) {
    let _data = new Date(data);
    let _dia = _data.getDate();
    let _mes = _data.getMonth()+1;
    if (_dia.toString().length == 1) { _dia = "0" + _dia; }
    if (_mes.toString().length == 1) { _mes = "0" + _mes; }
    let _ano = _data.getFullYear();
    if(!!br) return  _dia+"/"+_mes+"/"+_ano;
    return _ano +"-"+_mes+"-"+_dia;
  },
  dataInferior: function(data, dataComparacao, mascara) {
    let dataValidar = parse(data, mascara, new Date());
    let dataBase = !!dataComparacao ? parse(dataComparacao, mascara, new Date()) : Date.now();
    return isBefore(dataValidar, dataBase);
  },
  dataPosterior: function(data, dataComparacao, mascara) {
    let dataValidar = parse(data, mascara, new Date());
    let dataBase = !!dataComparacao ? parse(dataComparacao, mascara, new Date()) : Date.now();
    return isAfter(dataValidar, dataBase);
  },
  emailValido: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  urlValida: function(url) {
    let re = /http[s]?:\/\/?[^\s(["<,>]*\.[^\s[",><]*/ig;
    return re.test(String(url));
  },
  verificaCPF: function(numeroCPF) {//retorna true se cpf válido, false se inválido ou NULL caso nao seja um cpf (< ou > que 11 caracteres numericos)
    let cpf = numeroCPF.split(".").join("").split("-").join("");
    if (cpf.length !== 11) return null;
    if (cpf == "00000000000" || 
      cpf == "11111111111" || 
      cpf == "22222222222" || 
      cpf == "33333333333" || 
      cpf == "44444444444" || 
      cpf == "55555555555" || 
      cpf == "66666666666" || 
      cpf == "77777777777" || 
      cpf == "88888888888" || 
      cpf == "99999999999")
        return false;
    // Valida 1o digito	
    let add = 0;	
    for (let i=0; i < 9; i ++) add += parseInt(cpf.charAt(i)) * (10 - i);	
    let rev = 11 - (add % 11);	
    if (rev == 10 || rev == 11) rev = 0;	
    if (rev != parseInt(cpf.charAt(9))) return false;		
    // Valida 2o digito	
    add = 0;	
    for (let i = 0; i < 10; i ++) add += parseInt(cpf.charAt(i)) * (11 - i);	
    rev = 11 - (add % 11);	
    if (rev == 10 || rev == 11)	rev = 0;	
    if (rev != parseInt(cpf.charAt(10))) return false;		
    return true; 
  },
  b64toBlob: function(b64, onsuccess, onerror) {
    var img = new Image();

    img.onerror = onerror;

    img.onload = function onload() {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(onsuccess);
    };

    img.src = b64;
  },
  retornaApenasLetras: function(texto) {
    return texto.replace(/[^a-zA-Z]+/g, '');
  },
  retornaApenasNumeros: function(texto) {
    return texto.replace(/[^0-9]/g, '');
  },
  atob: function(input) {
    var str = (String (input)).replace (/[=]+$/, ''); // #31: ExtendScript bad parse of /=
    if (str.length % 4 === 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (
      // initialize result and counters
      var bc = 0, bs, buffer, idx = 0, output = '';
      // get next character
      buffer = str.charAt (idx++); // eslint-disable-line no-cond-assign
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        // and if not first of each 4 characters,
        // convert the first 8 bits to one ascii character
        bc++ % 4) ? output += String.fromCharCode (255 & bs >> (-2 * bc & 6)) : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf (buffer);
    }
    return output;
  },
  btoa: function(input) {
    var str = String (input);
    for (
      // initialize result and counter
      var block, charCode, idx = 0, map = chars, output = '';
      // if the next str index does not exist:
      //   change the mapping table to "="
      //   check if d has no fractional digits
      str.charAt (idx | 0) || (map = '=', idx % 1);
      // "8 - idx % 1 * 8" generates the sequence 2, 4, 6, 8
      output += map.charAt (63 & block >> 8 - idx % 1 * 8)
    ) {
      charCode = str.charCodeAt (idx += 3 / 4);
      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }
      block = block << 8 | charCode;
    }
    return output;
  },
  getJWTPayload: function(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(Util.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  },
  retira_acentos: function(palavra) { 
    let com_acento = 'áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ'; 
    let sem_acento = 'aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC'; 
    let nova=''; 
    for(let i=0;i<palavra.length;i++) { 
        if (com_acento.indexOf(palavra.substr(i,1))>=0) { 
            nova+=sem_acento.substr(com_acento.indexOf(palavra.substr(i,1)),1); 
        } 
        else { 
            nova+=palavra.substr(i,1); 
        } 
    } 
    return nova; 
  },
  formataCep: function(v){
    let max = 10;
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{2})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1-$2");
    v = v.substring(0, max);
    
    return v;
  },
  defineExtensao: function(extensao) {
    if (extensao == "pdf")
    {
        return "application/pdf";
    }
    else if (extensao == "doc" || extensao == "odt")
    {
        return "application/msword";
    }
    else if (extensao == "docx")
    {
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
    else if (extensao == "ppt" || extensao == "odp")
    {
        return "application/vnd.ms-powerpoint";
    }
    else if (extensao == "pptx")
    {
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    }
    else if (extensao == "xls" || extensao == "odx")
    {
        return "application/vnd.ms-excel";
    }
    else if (extensao == "xlsx")
    {
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    else if (extensao == "zip" || extensao == "rar")
    {
        return "application/zip";
    }
    else if (extensao == "mp3")
    {
        return "mp3";
    }
    else if (extensao == "txt")
    {
        return "text/plain";
    }
    else
    {
        return "application/octet-stream";
    }
  }
}

export default Util;