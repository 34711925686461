
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform, Dimensions, Text, Button, Image } from 'react-native';
import { Agenda } from 'react-native-calendars';
import { Card, Title, Divider, Paragraph, Modal, ActivityIndicator, Colors } from 'react-native-paper';
import { Api } from '../services';
import { useStateValue } from '../state';
import { ThemeContext } from '../../theme-context';
import ApiCalendar from 'react-google-calendar-api';

const Data = (time) => {
  const date = new Date(time);
  return date.toISOString().split('T')[0];
};

export default function Agendas() {

  const [{ usuario }, dispatch] = useStateValue();
  const [agenda, setAgenda] = useState([]);
  const [carregandoAgenda, setCarregandoAgenda] = useState(false)
  const [itens, setItens] = useState([]);
  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
  const [agendaExterna, setAgendaExterna] = useState([])

  function SelecionaDia() {
    var data = new Date().getDate();
  }



  function CarregarEventosAgendaExterna() {
    if (ApiCalendar.sign) {
      ApiCalendar.listUpcomingEvents(10).then(({ result }) => {
        let listaAgendaExterna = []
        result.items.map((a) => {
          listaAgendaExterna.push({
            data: (a.start?.date) ? Data(a.start.date) : Data(a.start.dateTime),
            descricao: a.summary,
            tipo: 1,
            cor: '#ffc0ca6b',
          })
        })

        setAgendaExterna(listaAgendaExterna)

      });
    }
  }

  useEffect(() => {
    CarregarEventosAgendaExterna()
    carregarAgenda();
  }, [])

  async function carregarAgenda() {
    setCarregandoAgenda(true);
    await api.get("/ApiMobileAgenda/listarAvaliacoes?pes_codigo=" + usuario.pes_codigo).then((retorno) => {

      if (retorno.status === 200)
        setAgenda(retorno.data.listaAvaliacoesAgenda);
      setCarregandoAgenda(false);
    }, (erro) => {
      console.error(erro);
      setCarregandoAgenda(false);
    });

  }

  function CarregarItens(dia) {

    for (let i = -15; i < 85; i++) {
      const data = Data(dia.timestamp + i * 24 * 60 * 60 * 1000);   //horas / minutos / segudos / milissegundos 

      itens[data] = [];

      agenda.filter((a) => a.data == data).map((ag) => {

        itens[data].push({
          descricao: ag.descricao,
          componente: ag.componente,
          professor: ag.professor,
          cor: ag.cor,
          datainicio: ag.datainicio,
          horaentrega: ag.horaentrega,
          tipo: 0
        });
      })

      agendaExterna.filter((a) => a.data == data)
        .map((ag) => {

          itens[data].push({
            descricao: ag.descricao,
            tipo: 1,
            cor: '#ffc0ca6b',
          });
        }
        )



    }

  };

  function AgendaWeb() {
    const renderItem = (item) => {

      return (
        <View >
          {item.tipo == 0 &&
            <Card style={{ backgroundColor: (item.cor), marginRight: 10, marginTop: 17, height: ((item?.horaentrega) ? 125 : 100) }}>
              <Card.Content style={{ flex: 1, justifyContent: 'center' }}>
                <View
                  style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Card.Content >
                    
                    {item.componente.length > 0 &&
                      <View>
                        <Title style={{ fontSize: 13, fontWeight: 'bold' }}>{item.descricao}</Title>
                        <Paragraph style={{ fontSize: 11 }}>{item.componente} - {item.professor}</Paragraph>
         
                      </View>
                    }
                    {item.componente.length == 0 &&
                      <Title style={{ fontSize: 13, fontWeight: 'bold' }}>{item.descricao}</Title>
                      
                    }
                    
                    {item?.datainicio &&
                      <Paragraph style={{ fontSize: 10 }}>Criado em: {item?.datainicio} </Paragraph>
                    }

                    {item?.horaentrega &&
                      <Paragraph style={{ fontSize: 10 }}>Entregar até as {item?.horaentrega} </Paragraph>
                    }

                  </Card.Content>
                </View>
              </Card.Content>
            </Card>}


          {item.tipo == 1 &&
            <Card style={{ backgroundColor: (item.cor), marginRight: 10, marginTop: 17, height: 100 }}>
              <Image
                style={styles.agenda}
                source={require('../../assets/agenda.png')} />
              <Card.Content>
                <View
                  style={{ flexDirection: 'row', alignItems: 'center' }}>

                  <Card.Content >

                    <Title style={{ fontSize: 13, fontWeight: 'bold' }}>{item.descricao}</Title>
                    {/* <Paragraph style={{ fontSize: 11 }}>{item.componente} - {item.professor}</Paragraph> */}
                  </Card.Content>
                </View>
              </Card.Content>
            </Card>
          }
          <Divider style={styles.container} />
        </View>
      );
    }
    return (
      <View style={{ flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }}>
        {agenda.length > 0 &&
          <Agenda
            items={itens}
            loadItemsForMonth={(dia) => CarregarItens(dia)}
            selected={SelecionaDia()}
            renderItem={renderItem}
          />
        }
        <Modal visible={carregandoAgenda}>
          <ActivityIndicator color={(!!!ThemeContext._currentValue.theme.dark) ? Colors.blue700 : Colors.white} size="large" />
        </Modal>
      </View>
    );
  }

  function AgendaMobile() {
    const renderItem = (item) => {

      return (
        <View>
          {item.tipo == 0 &&
            <Card style={{ backgroundColor: (item.cor), marginRight: 10, marginTop: 17, height: ((item?.horaentrega) ? 125 : 100) }}>
              <Card.Content style={{ flex: 1, justifyContent: 'center' }}>
                <View
                  style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Card.Content >

                    {item.componente.length > 0 &&
                      <View>
                        <Title style={{ fontSize: 13, fontWeight: 'bold' }}>{item.descricao}</Title>
                        <Paragraph style={{ fontSize: 11 }}>{item.componente} - {item.professor}</Paragraph>
                      </View>
                    }
                    {item.componente.length == 0 &&
                      <Title style={{ fontSize: 13, fontWeight: 'bold' }}>{item.descricao}</Title>
                    }
                    {(!!item?.datainicio) &&
                      <Paragraph style={{ fontSize: 11 }}>Criado em: {item?.datainicio} </Paragraph>
                    }
                    {(!!item?.horaentrega) &&
                      <Paragraph style={{ fontSize: 11 }}>Entregar até as {item?.horaentrega} </Paragraph>
                    }
                  </Card.Content>
                </View>
              </Card.Content>
            </Card>}


          {item.tipo == 1 &&
            <Card style={{ backgroundColor: (item.cor), marginRight: 10, marginTop: 17, height: 100 }}>
              <Image
                style={styles.agenda}
                source={require('../../assets/agenda.png')} />
              <Card.Content>
                <View
                  style={{ flexDirection: 'row', alignItems: 'center' }}>

                  <Card.Content >

                    <Title style={{ fontSize: 13, fontWeight: 'bold' }}>{item.descricao}</Title>

                  </Card.Content>
                </View>
              </Card.Content>
            </Card>
          }

          <Divider style={styles.container} />
        </View>
      )
    }
    return (
      <View style={{ flex: 1 }}>
        {agenda.length > 0 &&
          <Agenda
            items={itens}
            loadItemsForMonth={(dia) => CarregarItens(dia)}
            selected={SelecionaDia()}
            renderItem={renderItem}
          />
        }
        <Modal visible={carregandoAgenda}>
          <ActivityIndicator size="large" />
        </Modal>
      </View>
    );
  }
  return (
    <View style={styles.container}>
      {(Platform.OS === "web" && Dimensions.get("screen").width >= 1024) ? AgendaWeb() : AgendaMobile()}
    </View>
  )

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  divider: {
    height: 1,
    backgroundColor: '#438eb9'
  },
  agenda: {
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    width: 30,
    height: 30,
    marginRight: 10,
    marginTop: 5
  }
})