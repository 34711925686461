import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, RefreshControl, ScrollView } from 'react-native';
import { Badge, Text, Title, Divider, List, Modal, FAB, ActivityIndicator  } from 'react-native-paper';

import { useStateValue } from '../state';
import { Api, Util } from '../services';

import {themes, ThemeContext} from '../../theme-context';

export default function Mensagens(props) {

  const [{ usuario }, dispatch] = useStateValue();
  const [ mensagens, setMensagens ] = useState([]);
  const [ refreshing, setRefreshing] = useState(false);
  const [ carregando, setCarregando] = useState(true);

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  useEffect(() => {
    api.get("/ApiMobileMensagens/listarMensagens?_pes_codigo=" + usuario.pes_codigoLogin).then((retorno) => {
      setCarregando(false);
      setMensagens(retorno.data.mensagens);
    }, (error) => {
      setCarregando(false);
      console.error(error);
    })
  }, []);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, [refreshing]);

  return (
    <View style={[styles.container,{flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor}]}>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />} contentContainerStyle={{flex:1}}>
        <View style={{margin: 10}}>
          {mensagens.length === 0 &&
            <View style={{marginTop: 50, flex: 1, justifyContent: "center", alignItems: "center"}}>
              <Title>Nenhuma mensagem...</Title>
            </View>
          }
          {mensagens.length > 0 && mensagens.map((msg, indice) => (
            <React.Fragment key={indice}>
              <List.Item title={(<React.Fragment><Text style={{marginRight: 5}}>{msg.contato}</Text><Badge size={26}>{msg.mensagensAnteriores.length + 1}</Badge></React.Fragment>)} description={msg.assunto} onPress={() => props.navigation.navigate('VisualizarMensagem', { Mensagem: msg })}
                left={props => <List.Icon {...props} icon={`chat-bubble${msg.lida === true ? '-outline' : ''}`} />}
                right={props => <View>
                    <Text style={styles.textoDataHora}>{msg.data}</Text>
                    <Text style={styles.textoDataHora}>{msg.hora}</Text>
                  </View>}
              />
              <Divider />
            </React.Fragment>
          ))}
        </View>
      </ScrollView>
      <FAB
        style={styles.fab}
        icon="add"
        onPress={() => props.navigation.navigate('EnviarMensagem')}
      />
      <Modal visible={carregando}>
        <ActivityIndicator color="#FFF" size="large" />
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
  },
  textoDataHora: {
    fontSize: 12,
    marginTop: 3,
  },
})