import React from 'react';
import { View, Text, StyleSheet, Platform, Dimensions } from 'react-native';
import { Divider } from 'react-native-paper';

export default function ItemListaReservas(props) {


  return (
    <View >

      <View style={{ flexDirection: 'row', height: 40, marginTop: 15 }}>

        <Text style={styles.biblioteca}>{props.dados.bib_descricao}</Text>
        <Text style={styles.titulo}> {props.dados.obr_titulo}</Text>
        <Text style={styles.retirada}> {props.dados.retirada}</Text>
        <Text style={styles.devolucao}> {props.dados.devolucao}</Text>
        {props.dados.status === 'Está em dia!' ? <View style={styles.emdia} /> : <View style={styles.atrasado} />}
        <Text style={styles.status}> {props.dados.status}</Text>
      </View>
      <Divider style={styles.divider} />
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
  },
  botao: {
    backgroundColor: "white"
  },
  biblioteca: {
    width: 380,
    marginLeft: 10,
  },
  titulo: {
    width: 385,
  },
  retirada: {
    width: 100
  },
  devolucao: {
    width: 100
  },
  status: {
    width: 100
  },
  divider: {
    width: 2000
  },
  emdia: {
    marginTop: 3,
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    backgroundColor: 'green'
  },
  atrasado: {
    marginTop: 3,
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    backgroundColor: 'red'
  }

})