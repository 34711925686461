import React, { useState, useEffect } from 'react';
import { View, Picker, StyleSheet, Alert, Platform } from 'react-native';
import { List, TextInput, Button } from 'react-native-paper';

import { useStateValue } from '../state';
import { Api, Util } from '../services';
import {ThemeContext} from '../../theme-context';

export default function EnviarMensagem(props) {

  const [{ usuario }, dispatch] = useStateValue();
  const [ destinatarios, setDestinatarios ] = useState([]);
  const [ destinatario, setDestinatario ] = useState({codigo: null, nome: null});
  const [ titulo, setTitulo ] = useState('');
  const [ mensagem, setMensagem ] = useState('');
  const [ professor, setProfessor ] = useState([]);

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  useEffect(() => {
    api.get("/ApiMobileMensagens/listarDestinatarios?_pes_codigo=" + usuario.pes_codigoLogin).then((retorno) => {
      setDestinatarios(retorno.data.destinatarios);
    }, (error) => {
      console.error(erro);
    });
  }, []);

  function exibirAlerta(titulo, descricao) {
    if (Platform.OS === "web") alert(descricao);
    else Alert.alert(titulo, descricao);
  }

  function enviarMensagem() {
    if (!!destinatario === false || !!destinatario.codigo === false) exibirAlerta('Atenção!', 'Você deve selecionar o destinatário para esta mensagem.');
    else if (titulo === '') exibirAlerta('Atenção!', 'Você deve informar o titulo para a mensagem');
    else if (mensagem === '') exibirAlerta('Atenção!', 'Você deve informar a mensagem que deseja enviar');
    else {
      let url = "/ApiMobileMensagens/enviarMensagem?_pes_codigo=" + usuario.pes_codigoLogin;
      url += "&_pes_destinatario=" + destinatario.codigo + "&_mensagem=" + mensagem + "&_assunto=" + titulo + "&_msg_anterior=0";
      
      api.get(url).then((retorno) => {
        exibirAlerta('Atenção!', 'Sua mensagem foi enviada com suceso!');
        props.navigation.navigate('Home');
      }, (erro) => {
        console.error(erro);
        exibirAlerta("Erro", "Não foi possível enviar sua mensagem. Tente novamente mais tarde...");
      });
    }
  }

  return (
    <View style={[styles.container,{flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
      <View style={styles.conteudo}>
        <View style={styles.linhaFormulario}>
          
              <Picker
                style={styles.componentePicker}
                selectedValue={destinatario.codigo}    
                onValueChange={(itemValue, itemIndex) => {
                    if (!!destinatarios[itemIndex].professor === true && destinatarios[itemIndex].professor.length > 0) setProfessor(destinatarios[itemIndex].professor);
                    setDestinatario({codigo: itemValue, nome: destinatarios[itemIndex].nome});
                  }
                }>
                <Picker.Item label="Selecione o Destinatário..." value={''} />
                {destinatarios.map((destinatario) => <Picker.Item key={destinatario.codigo} label={destinatario.nome} value={destinatario.codigo} />)}
              </Picker>
          
        </View>

        <View style={styles.linhaFormulario}>
          {professor.map((prof, indice) => (
            <List.Item title={prof} key={indice} style={styles.listItem} />
          ))}
        </View>
        
        <View style={styles.linhaFormulario}>
          <TextInput
            label='Título'
            value={titulo}
            autoCapitalize="sentences"
            autoCorrect={true}
            onChangeText={setTitulo}
            style={styles.input}
            />
        </View>
        
        <View style={styles.linhaFormulario}>
        <TextInput
          label='Mensagem'
          value={mensagem}
          autoCapitalize="sentences"
          autoCorrect={true}
          onChangeText={setMensagem}
          multiline={true}
          numberOfLines={8}
          style={styles.input}
          />
        </View>
        
        <View style={styles.linhaCentralizado}>
          <Button icon="send" mode="contained" onPress={() => enviarMensagem()}>Enviar Mensagem</Button>
        </View>

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  conteudo: {
    flex: 1,
    margin: 10,
  },
  componentePicker: {
    marginVertical: 10,
    height: 44
  },
  linhaFormulario: {
    marginBottom: 10,
  },
  linhaCentralizado: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItem: {
    backgroundColor: '#FFF',
    borderColor: '#202021',
    borderWidth: 1,
  },
  input: {
    backgroundColor: '#FFF'
  },
});