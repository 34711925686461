import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Platform, Dimensions } from 'react-native';
import { ActivityIndicator, Modal, Divider, Colors } from 'react-native-paper';

import { SelecionaDiaSemana, AlterarAluno, ModalSelecionaFilho } from '../components';

import { useStateValue } from '../state';
import { Api } from '../services';
import {ThemeContext} from '../../theme-context';
import { block } from 'react-native-reanimated';

//const flexDir = (Platform.OS === "web" && Dimensions.get("screen").width >= 1024) ? 'row' : 'column';
const flexDir = Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? 'row' : 'column';
const justify = Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? 'center' : 'flex-start';

export default function Horarios() {

  const diasSemana = [ "Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado" ];

  const [{ usuario }, dispatch] = useStateValue();
  const [ carregando, setCarregando ] = useState(true);
  const [ alterarAluno, setAlterarAluno ] = useState(false);
  const [ diaSemana, setDiaSemana ] = useState(null);
  const [ listaHorarios, setListaHorarios ] = useState([]);

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  useEffect(() => {
    api.get("/ApiMobileHorarios/listarHorariosSemana?_pes_codigo=" + usuario.pes_codigo).then((retorno) => {
      if (retorno.status === 200) {
        setListaHorarios(retorno.data.horarios);
        setCarregando(false);
      }
    }, (erro) => {
      console.error(erro);
      setCarregando(false);
    });
  }, [usuario.pes_codigo]);

  function renderItemHorarioMobile(horarios) {
    return (
    <View style={{flex: 1}}>
      {/* <View style={[styles.containerConteudo,{flex: 1, marginTop: 5, flexWrap: 'wrap', flexDirection: flexDir, justifyContent: justify, alignContent: justify, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}> */}
        {horarios.length > 0 && horarios.map((horario, indice) => (
          <React.Fragment key={indice}>
            <View style={styles.containerItemMobile}>
              <View style={{ height: 48, width: 5, backgroundColor: (!!horario.cor ? horario.cor.trim() : '#FFF'), marginRight: 5 }} />
              <View style={{ flexDirection: "column" }}>
                <View style={{ maxWidth: (Dimensions.get("screen").width - 10) }}>
                  <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}} ellipsizeMode="clip" numberOfLines={2}>{horario.periodo} - <Text style={[styles.materia, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>{horario.componente}</Text></Text>
                  <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}} ellipsizeMode="clip" numberOfLines={2}>{horario.horario} - {horario.professor}</Text>
                </View>
              </View>
            </View>
            <Divider />
          </React.Fragment>
        ))}
        {horarios.length === 0 && carregando === false && (
         <View style={{flex: 1, justifyContent: 'center'}}>
        <Text style={[{ fontSize: 16, fontWeight: "bold", textAlign: 'center' }]}>Você não possui horários neste dia</Text>
       </View>
        )}
      </View>
      // </View>
    )
  }

  function renderItemHorarioWeb(horarios) {
    return (
      <View style={[styles.containerConteudo,{flex: 1,marginTop: 5, flexWrap: 'wrap', flexDirection: flexDir, justifyContent: justify, alignContent: justify, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
        {horarios.length > 0 && horarios.map((horario, indice) => (
          <View key={indice} style={styles.containerItemWeb}>
            <View style={{ height: 118, width: 8, backgroundColor: (!!horario.cor ? horario.cor.trim() : '#FFF'), marginRight: 5 }} />
            <View style={{justifyContent: "center", maxWidth: 284 }}>
              <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>
                {horario.periodo} - <Text style={[styles.materia, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>{horario.componente}</Text>
              </Text>
              <Text ellipsizeMode="clip" numberOfLines={2}>
                <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>{horario.horario} - {horario.professor}</Text>
              </Text>
            </View>
          </View>
        ))}
      {horarios.length === 0 && carregando === false && (
              <View style={styles.containerSemDados}>
                <Text style={[styles.textoSemDados, {fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }]}>Você não possui horários neste dia</Text>
              </View>
      )}
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <AlterarAluno onPress={() => setAlterarAluno(true)}/>
      <SelecionaDiaSemana onChange={ (dia) => setDiaSemana(dia) } />
      {(Platform.OS === "web" && Dimensions.get("screen").width >= 1024) ? renderItemHorarioWeb(listaHorarios.filter(horarios => horarios.diaSemana === diasSemana[diaSemana])) : renderItemHorarioMobile(listaHorarios.filter(horarios => horarios.diaSemana === diasSemana[diaSemana]))}
      <Modal visible={carregando}>
        <ActivityIndicator color={(!!!ThemeContext._currentValue.theme.dark) ? Colors.blue700 : Colors.white} size="large" />
      </Modal>
      <ModalSelecionaFilho visible={alterarAluno} onDismiss={() => setAlterarAluno(false)} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerConteudo: {
    flex: 1,
    marginTop: 5,
    flexWrap: 'wrap',
    flexDirection: flexDir,
    justifyContent: justify,
    alignContent: justify,
    
  },
  containerItemWeb: {
    backgroundColor: "#FFF",
    borderColor: "#202021",
    borderWidth: 1,
    height: 120,
    minWidth: 300,
    maxWidth: 300,
    margin: 5,
    flexDirection: "row",
    flex: 1,
  },
  containerItemMobile: {
    flexDirection: "row",
    backgroundColor: "#FFF",
    flex: 1,
    minHeight: 48,
    maxHeight: 48,
    width: "100%",
    alignItems: "center",
  },
  materia: {
    fontSize: 14,
    fontWeight: "bold",
  },
  containerSemDados: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    
  },
  textoSemDados: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: 'center',
  },
  containerSemDadosMobile: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignContent: 'center'
  },
  containerSemDadosMobile: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignContent: 'center'
  },

});