import React, { useEffect, useState, useRef }  from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import { NavigationActions } from 'react-navigation';

import { ModalChat } from '../components';
import { Api, Util } from '../services'
import { useStateValue } from '../state';

console.log('ChatGrupo')
export default function ChatGrupo({navigation}) {
  const [ modalChat, setModalChat ] = useState(false);
  const [ dadosChat, setDadosChat ] = useState(false);
  const [ dados, setDados ] = useState({chg_codigo: 1, chn_codigo:1, chp_codigo: 1});

  return (
    <React.Fragment>
      <View style={styles}>
        <Text>Chat Grupo</Text>
        <Button mode="contained" onPress={() => setDadosChat(dados)}>Abrir chat</Button>
        
      </View>

      <ModalChat dadosChat={dadosChat} fecharModal={() => {
        setDadosChat(null);
      } } />
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});