import React from 'react';
import { View, Text, StyleSheet,} from 'react-native';
import {ThemeContext} from '../../theme-context';
import { ItemListaLivros } from '../components';


export default function Biblioteca(props) {

  return (
    <View style={[styles,{flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>

    <ItemListaLivros props ={props} />
    {/* <Button icon="people" style={styles.botao} mode="contained" onPress={() =>props.navigation.navigate('MinhasReservas')}>Minhas Reservas</Button>  */}

    </View>
  );
  }

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  botao: {
    height: 50,
    borderWidth: 1,
    margin: 5, 
    backgroundColor: '#438eb9',
    color: 'blue',
    borderColor: '#438eb9',
    fontSize: 10,
    fontWeight: "bold",
    width: "20%",
    justifyContent: "center"
  },
 
});
