import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView, Text, Platform, Linking, Alert, StyleSheet, Dimensions, Switch, PermissionsAndroid, } from 'react-native';
import { Title, Caption, Button, IconButton, Modal, TextInput, List, ActivityIndicator, Divider, Menu, Dialog, Portal , Avatar, Card, Paragraph, Colors, Chip } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WebView } from 'react-native-webview';
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { format } from 'date-fns';
import _, { trimStart, trim } from 'lodash';

///import RNFetchBlob from 'rn-fetch-blob';
import RNFetchBlob from './RNFetchBlob';

import { Api, Util } from '../services';
import { ListaDocumentos, ModalMensagensDocumento, ModalChat, AlterarAluno } from '../components';
import { useStateValue } from '../state';
import { trimEnd } from 'lodash';
import {themes, ThemeContext} from '../../theme-context';

var temporizador = null;

export default function Documentos({ navigation }) {

  const [{ usuario, estudante }, dispatch] = useStateValue();

  const [ menuAcoes, setMenuAcoes ] = useState(false);
  const [ modalDocumentos, setModalDocumentos ] = useState(false);
  const [ modalRespostasDocumento, setModalRespostasDocumento ] = useState(false);
  const [ mensagensDocumento, setMensagensDocumento ] = useState(false);
  const [ chatGrupo, setChatGrupo ] = useState(false);
  const [ listaLinks, setListaLinks ] = useState({dpf_codigo: null, links: []});
  const [ documento, setDocumento ] = useState(null);
  const [ DPE_CODIGO, setDPE_CODIGO ] = useState(null);
  const [ DPE_RESPOSTA, setDPE_RESPOSTA ] = useState(null);
  const [ DPE_OBSERVACAO, setDPE_OBSERVACAO ] = useState(null);
  const [ listaDocumentos, setListaDocumentos ] = useState([]);
  const [ listaRespostasDocumento, setListaRespostasDocumento ] = useState([]);
  const [ arquivoAnexo, setArquivoAnexo ] = useState(null);
  const [ requisicaoCompleta, setRequisicaoCompleta ] = useState(false);
  const [ enviando, setEnviando ] = useState(false);
  const [ filtroComponente, setFiltroComponente ] = useState(0);
  const [ filtroPeriodoLetivo, setFiltroPeriodoLetivo ] = useState({});
  const [ filtroApenasNovos, setFiltroApenasNovos ] = useState(false);
  const [ filtroApenasSemResposta, setFiltroApenasSemResposta ] = useState(false);
  const [ modalComponentesFiltro, setModalComponentesFiltro ] = useState(false);
  const [ modalVisualizarAnexo, setModalVisualizarAnexo ] = useState(false);
  const [ linkPreVisualizarAnexo, setLinkPreVisualizarAnexo ] = useState({});
  const [ carregandoPreVisualizacao, setCarregandoPreVisualizacao ] = useState(false);
  const [ carregandoPreVisualizacaoErro, setCarregandoPreVisualizacaoErro ] = useState(true);
  const [ isExpandedComp, setIsExpandedComp ] = useState(false);
  const [ modalVisualizarLink, setModalVisualizarLink] = useState(false);
  const [ visualizarLink, setVisualizarLink] = useState({}); 

  const [ modalDownloadLink, setModalDownloadLink] = useState(false);
  const [ downloadLink, setDownloadLink] = useState({}); 
  
  let iframeEl = useRef(null);

  let webViewRef = useRef();
  let webViewRefDownload = useRef();

  const atualizaLinkIframe = () => {
    if(!!iframeEl)
      if(!!iframeEl.current) 
        iframeEl.current.src = linkPreVisualizarAnexo.src;
  }

  useEffect(() => {
    if (modalVisualizarAnexo) {
      temporizador = setInterval(atualizaLinkIframe, 5000);
    }
  }, [modalVisualizarAnexo])

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);//usuario.ApiEducar, usuario.tokenAuth);

  function expandedComp(id, isExpanded) {
    listaDocumentos[0].componentes.forEach(comp => {
      comp.isExpanded = false;
      if(comp.cpc_codigo == id)
        comp.isExpanded = isExpanded;
    });
    setIsExpandedComp(isExpanded);
  }

  function carregarListaDocumentos() {
    (async () => {
        setRequisicaoCompleta(false);
        let dadosUsuario = JSON.parse(await AsyncStorage.getItem('EducarWebEstudanteApp:user'));
        let _matricula = (!!dadosUsuario.matricula) ? dadosUsuario.matricula : estudante.matricula;
        let _pes_codigo = (!!dadosUsuario.pes_codigo) ? dadosUsuario.pes_codigo : usuario.pes_codigo;

        let dataInicial = (!!filtroPeriodoLetivo.prl_datainicial) ? Util.dataFormatada(filtroPeriodoLetivo.prl_datainicial) : null;
        let dataFinal = (!!filtroPeriodoLetivo.prl_datafinal) ? Util.dataFormatada(filtroPeriodoLetivo.prl_datafinal) : null;
        let filtroDatas = "";
        if(!!dataInicial && !!dataFinal)
          filtroDatas = "&dataInicial=" + dataInicial + "&dataFinal=" + dataFinal;

        api.get("/ApiMobileDocumentos/Listar?pes_codigo=" +_pes_codigo+ "&mtr_codigo=" + _matricula + ""+filtroDatas).then((retorno) => {
          if (retorno.status === 200) {
            let listaDocs = [];
            if(!!retorno.data.length)
              retorno.data.forEach(doc => {
                let objDoc = {};
                objDoc.periodosLetivos = doc.periodosLetivos;
                objDoc.pes_codigo = doc.pes_codigo;
                objDoc.pes_nome = doc.pes_nome;
                objDoc.trm_codigo = doc.trm_codigo;
                objDoc.trm_nometurma = doc.trm_nometurma;
                objDoc.componentes = [];
                doc.componentes.forEach(comp => {
                  comp.grupos.forEach(grup => {
                    let possuiGrupos = comp.grupos.filter((g) => g.tcg_codigo != null)
                    let objComp = {};
                    objComp.isExpanded = false;
                    if(grup.tcg_codigo == null) {
                      objComp.cpc_codigo = comp.cpc_codigo;
                      objComp.cpc_descricao = comp.cpc_descricao;
                      objComp.crp_cor = comp.crp_cor;
                      objComp.quantidadeDocumentosNaoRespondidos = comp.quantidadeDocumentosNaoRespondidos;
                      objComp.quantidadeDocumentosNovos = comp.quantidadeDocumentosNovos;
                      objComp.quantidadeMensagensNaoLidas = comp.quantidadeMensagensNaoLidas;
                      objComp.resumoAtividades = (!!possuiGrupos.length) ? '' : comp.resumoAtividades;
                      objComp.grupos = [{
                        documentos: grup.documentos,
                        grp_descricao: grup.grp_descricao,
                        tcg_codigo: grup.tcg_codigo
                      }]
                    } else {
                      objComp.cpc_codigo = comp.cpc_codigo+''+grup.tcg_codigo;
                      objComp.cpc_descricao = grup.grp_descricao;
                      objComp.crp_cor = comp.crp_cor;
                      objComp.quantidadeDocumentosNaoRespondidos = 0;
                      objComp.quantidadeDocumentosNovos = 0;
                      objComp.quantidadeMensagensNaoLidas = 0;
                      objComp.resumoAtividades = comp.cpc_descricao;
                      objComp.grupos = [{
                        documentos: grup.documentos,
                        grp_descricao: null,
                        tcg_codigo: null
                      }]
                    }
                    objDoc.componentes.push(objComp);
                  });
                });
                if(objDoc.componentes.length == 1)
                  objDoc.componentes[0].isExpanded = true;
                listaDocs.push(objDoc);
              });
            setListaDocumentos(listaDocs);
          }
          else setListaDocumentos([]);

          setRequisicaoCompleta(true);
        }, (erro) => {
          setRequisicaoCompleta(false);
          exibirAlerta("ERRO", "Não foi possível carregar os documentos. tente novamente mais tarde.")
          console.log(erro);
        });
    })().catch(err => {
        console.error(err);
    });
  }

  function verificarPermissoes() {
    (async () => {
      if (Constants.platform.ios) {
        const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
        if (status !== 'granted') {
          alert('Você precisa permitir o acesso do app à galeria.');
        }
        const permCamera = await Permissions.askAsync(Permissions.CAMERA);
        if (permCamera.status !== 'granted') {
          alert('Você precisa permitir o acesso do app à camera do dispositivo.');
        }
      }
    })().catch(err => {
        console.error(err);
    });
  }

  useEffect(() => {
    verificarPermissoes();
    carregarListaDocumentos();
  }, []);

  useEffect(() => {
    if (modalDocumentos === false) {
      setDPE_CODIGO(null);
      setDPE_RESPOSTA(null);
      setDPE_OBSERVACAO(null);
      setArquivoAnexo(null);
    }
  }, [modalDocumentos]);

  function exibirAlerta(titulo, texto) {
    if (Platform.OS === "web") {
      alert(titulo+'\n\n'+texto);
    }
    else {
      Alert.alert(
        titulo,
        texto
      );
    }
  }

  function exibirConfirmacao(titulo, texto, descricaoBotaoOK = 'OK', descricaoBotaoCancela = 'Cancelar', funcaoOK = ()=>{}, funcaoCancela = ()=>{}) {
    if (Platform.OS === "web") {
      if (confirm(titulo+'\n\n'+texto)) funcaoOK();
    }
    else {
      Alert.alert(
        titulo,
        texto,
        [
          {
            text: descricaoBotaoCancela,
            onPress: funcaoCancela,
            style: 'cancel',
          },
          {text: descricaoBotaoOK, onPress: funcaoOK},
        ],
        {cancelable: true},
      );
    }
  }

  function selecionarArquivo(){
    if (Platform.OS === "web") {
      var input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => {
        var file = e.target.files[0];
        setArquivoAnexo(file);
      }
      input.click();
    }
    else {
      DocumentPicker.getDocumentAsync({type: '*/*'}).then((retorno) =>{
        if (Platform.OS === "web") setArquivoAnexo(retorno.file);
        else {
          if(retorno.type === "cancel") {
            setArquivoAnexo(null);
          } else {
            setArquivoAnexo(retorno);
          }
        }
      }, (erro) => {
        console.log('erro',erro);
      });
    }
  }

  async function selecionarImagemCamera() {
    setTimeout(async () => {
      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: false,
        allowsEditing: true,
        quality: 1
      });
      if (!result.cancelled) {
        setArquivoAnexo({
          name: "Imagem da Camera.jpg",
          size: result.size,
          type: "success",
          uri: result.uri
        });
      }
    }, 1200);
  }

  function carregarListaRespostasDocumento(documento) {
    api.get('/ApiMobileDocumentos/ListarRetornosEstudante?pes_codigo=' + usuario.pes_codigo + '&dpf_codigo=' + documento.dpf_codigo).then(res => {
      if (res.status === 200) {
        setListaRespostasDocumento(res.data);
        if (res.data.length === 0 && modalRespostasDocumento === true) setModalRespostasDocumento(false);
      }
    }, err => {
      console.error('Erro ao carregar as respostas já enviadas para esta atividade.', err);
    }).finally(() => setModalRespostasDocumento(true));
  }

  function visualizarModalRespostaDocumento(documento) {
      setDocumento(documento);
      if (documento.qtde_respostas > 0){
        carregarListaRespostasDocumento(documento);
      }
      else {
        if ((!!!documento.dpf_respaposdatalimite) && (!!documento.dpf_datalimite && new Date(documento.dpf_datalimite).getTime() < Date.now())) {
          exibirAlerta("ATENÇÃO!", "Não é possível realizar o envio da resposta, o prazo máximo do envio era até: " + format(new Date(documento.dpf_datalimite), 'dd/LL/yyyy') + " às " + format(new Date(documento.dpf_datalimite), 'hh:mm') + 'hs')
        }
        else{
          setModalDocumentos(true);
        }
    }
  }

  function visualizarAnexoDocumento(documento) {

    setDocumento(documento);
    let codigoArquivo = documento?.codigoVisualizar || documento?.arq_codigo;
    if(!!codigoArquivo) {
        setEnviando(true);
        api.post('Abs_arquivos/getUrlDownloadArquivo', {"arq_codigo":codigoArquivo}).then((retorno) => {
          if (retorno.status === 200){
            let formatos_nao_suportados = [".dot", ".mht", ".mhtml", ".xml", ".wps", ".wpd", ".xltx", ".xltm", ".xlam", ".xlm", ".xla", ".xlt", ".xll", ".xlw", ".prn", ".csv",
              ".mdb", ".mde", ".accdb", ".accde", ".dbc", ".igy", ".dqy", ".rqy", ".oqy", ".cub", ".uxdc", ".dbf", ".slk", ".dif", ".xlk", ".bak", ".xlb", ".pot", ".ppa", ".odp", ".thmx"];
            let mimetype = retorno.data.mimetype;
            let src = '';
            let height = (Dimensions.get('window').height - (Dimensions.get('window').height * 0.30));
            let arquivoZip = (!!retorno.data.zip) ? retorno.data.zip : false;
            let nomeArquivo = retorno.data.name;
            let extencao = `.${retorno.data.extencao || nomeArquivo.split('.')[1]}`;
            let suportado = (formatos_nao_suportados.indexOf(extencao) == -1);

            if (mimetype.match(`image\/.*`)|| extencao.toLowerCase().match('(.*\.png)|(.*\.jpeg)|(.*\.jpg)|(.*\.gif)')) {//frame de imagens
              src = `data:text/html;charset=utf-8,` +
                   encodeURI(`<div style="text-align: center; position: absolute; top: 50%; left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);">
                          <img src="${retorno.data.url}" style="justify-content: center;align-items: center;height: auto; max-height: 100vh;width:auto; max-width:100vw;"></div>`);
            } else if (mimetype.match(`video\/.*`)  || extencao.match('(.*\.mp4)')) {//player de video
              src = `data:text/html;charset=utf-8,` +
                      encodeURI(`<div style="text-align: center; position: absolute; top: 50%; left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);">
                          <video style="justify-content: center;align-items: center;height:376px; max-height: 100vh;width:652px; max-width:100vw;" controls autoplay><source src="${retorno.data.url}" type="${(extencao.match('(.*\.mp4)') ? 'video/mp4':mimetype)}"></video></div>`);
              height = 396;
            } else if (mimetype.match(`audio\/.*`)) {//player de audio
              src = `data:text/html;charset=utf-8,` +
                      encodeURI(`<div style="text-align: center; position: absolute; top: 50%; left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);height: 50;width: 600;">
                          <audio style="justify-content: center;align-items: center;height: 100%; max-height: 100%;width:100%; max-width:100%;" controls><source src="${retorno.data.url}" type="${mimetype}"></audio></div>`);
              height = 75;
            } else if (mimetype.match(`.*\/(msword.*)|(octet-stream.*)|(vnd.*)`) && suportado && !(mimetype.match(`(.*\/zip.*)|(.*\/x-zip.*)|(.*\/rar.*)`) || nomeArquivo.match(`(.*\.rar)|(.*\.zip)|(.*\.exe)`)) || (nomeArquivo.match(`(.*\.rar)|(.*\.zip)`) && arquivoZip)) {
              var url = encodeURIComponent(retorno.data.url);//arquivos do pacote office
              src = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
            } else if (mimetype.match(`.*\/html`)) {
              src = (retorno.data.url);
            } else if ((!(mimetype.match(`(.*\/zip.*)|(.*\/x-zip.*)|(.*\/rar.*)`) || nomeArquivo.match(`(.*\.rar)|(.*\.zip)|(.*\.exe)`)) || (nomeArquivo.match(`(.*\.rar)|(.*\.zip)`) && arquivoZip)) && suportado) {//google docs pra abrir pdf
              var url = encodeURIComponent(retorno.data.url);
              src = `https://docs.google.com/viewer?url=${url}&embedded=true`;
            } else {
              src = null;
            }
            
            setLinkPreVisualizarAnexo({"src":src, "height":height, url: encodeURIComponent(retorno.data.url), "visualizandoAnexo":true});
            setEnviando(false);

            if(src == null) {
              exibirAlerta("", "Visualização indisponível para este arquivo.");
            } else {
              //{Platform.OS == "web" && setCarregandoPreVisualizacao(true);}
              setCarregandoPreVisualizacao(false);
              setModalVisualizarAnexo(true);
            }
          }
          if (retorno.status !== 200) {
            setEnviando(false);
          }
        }, (erro) => {
          setEnviando(false);
        });
    } else {
      exibirAlerta("", "Visualização indisponível para este arquivo.");
    }
  }

  function visualizarPaginaDocumento(documento) {
    setDocumento(documento);
    setEnviando(true);
    
    api.get("ApiMobileDocumentos/BuscaPaginaDocumento?dpf_codigo="+documento.dpf_codigo).then((retorno) => {
      if (retorno.status === 200){
        let height = (Dimensions.get('window').height - (Dimensions.get('window').height * 0.30));
        let conteudo = retorno.data.pagina;

        if(!!conteudo) {
          if(Platform.OS === 'web'){
            conteudo = `data:text/html;charset=utf-8,` +
            encodeURIComponent(conteudo);
          }else{
            conteudo = conteudo;
          }

          setLinkPreVisualizarAnexo({"src":conteudo, "height":height, "visualizandoAnexo":false});
          setEnviando(false);

          setCarregandoPreVisualizacao(false);
          setModalVisualizarAnexo(true);
        }else {
          exibirAlerta("", "Visualização indisponível.");
        }
      }

      if (retorno.status !== 200) {
        setEnviando(false);
      }
    }, (erro) => {
      setEnviando(false);
    });
  }

  function LoadingIndicatorView() {
    return <ActivityIndicator size="large" color="#0a83bf"
              style={{ position: "absolute",
                      top: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.70)),
                      left:(Dimensions.get('window').width / 2.5)
                    }} />
  }

  function converteURLYoutube(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return false;
    }
  }

  async function abrirLink(link, dpf_codigo){
    if (!!dpf_codigo) {
      let dados = {
        dpf_codigo: dpf_codigo,
        pes_codigo: usuario.pes_codigo,
        dcl_tipo: 1,
        dcl_especificacao: link
      }
      api.post('ApiMobileDocumentos/AtualizaDocumentoLog?data=' + JSON.stringify(dados));
    }
    if (Platform.OS === 'web') window.open(link, '_blank');
    else {
      link = trimStart(link);
      if(Platform.OS === 'ios'){
        Linking.canOpenURL(link).then(supported => {
          if (!supported) {
            console.log('NÃO TEM SUPORTE: ' + link);
          } else {
            return Linking.openURL(link);
          }
        }).catch(err => console.error('Ocorreu um erro:', err));
      } else {
        setModalVisualizarLink(true);
        if(link.indexOf('youtube.com') != -1 || link.indexOf('youtu.be') != -1) {
          let urlLink = converteURLYoutube(link);
          if(urlLink) {
            setVisualizarLink({"url": true, "link": `https://www.youtube.com/embed/${urlLink}?rel=0&autoplay=0&showinfo=0&controls=2&fullscreen=0&fs=1`});
          } else {
            if(link.indexOf('playlist') != 1) {
              urlLink = link.split('=')[1];
              setVisualizarLink({"url": true, "link": `https://www.youtube.com/embed?listType=playlist&list=${urlLink}&rel=0&autoplay=0&showinfo=0&controls=2&fullscreen=0&fs=1`});
            } else {
              setModalVisualizarLink(false);
              Linking.openURL(link);
            }
          }
        } else {
          setVisualizarLink({"url": true, "link": link});
        }
      }
    }
  }

  function acessarDocumento(dados) {
    if (!!dados.dpf_link && dados.dpf_link.slice(0,1) === "[") {

        let link = JSON.parse(dados.dpf_link);
        if (link.length === 1) abrirLink(link[0], dados.dpf_codigo);
        else setListaLinks({dpf_codigo: dados.dpf_codigo, links: link});
    }
    else if (!!dados.dpf_link === false) exibirAlerta("Link inexistente", "O professor não forneceu nenhum link junto a este documento.");
    else {
      abrirLink(dados.dpf_link, dados.dpf_codigo);
    }
  }

  async function downloadDocumento(dados) {
    let dadosLog = {
      dpf_codigo: dados.dpf_codigo,
      pes_codigo: usuario.pes_codigo,
      dcl_tipo: 0,
      dcl_especificacao: null
    }
    api.post('ApiMobileDocumentos/AtualizaDocumentoLog?data=' + JSON.stringify(dadosLog));
    if (Platform.OS === 'web') window.open(`${usuario.ApiEducar}/ServicoExterno/DownloadArquivoProfessor?dpf_codigo=${dados.dpf_codigo}&nomedoc=${dados.dpf_descricao}`, '_blank');
    else if(Platform.OS === 'ios') Linking.openURL(`${usuario.ApiEducar}/ServicoExterno/DownloadArquivoProfessor?dpf_codigo=${dados.dpf_codigo}&nomedoc=${dados.dpf_descricao}`);
    else {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          {
            title: 'Permissão para armazenamento',
            message:'O aplicativo precisa de permissão para baixar o arquivo',
            buttonPositive: 'OK',
          }
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          let date = new Date();
          let nomeArquivoDownload = `educarweb_${Math.floor(date.getTime() + date.getSeconds() / 2)}.`+dados.dpf_extencaoanexo;

          const { dirs } = RNFetchBlob.fs;
          RNFetchBlob.config({
            fileCache: true,
            addAndroidDownloads: {
              overwrite: true,
              useDownloadManager: true,
              notification: true,
              mediaScannable: true,
              description: `App - EducarWeb`,
              title: nomeArquivoDownload,
              path: `${dirs.DownloadDir}/${nomeArquivoDownload}`,
            },
            appendExt: dados.dpf_extencaoanexo
          })
            .fetch('GET', `${usuario.ApiEducar}/ServicoExterno/DownloadArquivoProfessor?dpf_codigo=${dados.dpf_codigo}&nomedoc=${dados.dpf_descricao}`)
            .then((res) => {
              exibirAlerta("Download concluído.", `${dados.dpf_descricao}`);
            })
        } else {
          alert('Permissão para armazenamento negada pelo usuário');
        }
      } catch (err) {
        console.log("ERROR com o RNFetchBlob: ", err);
        Linking.openURL(`${usuario.ApiEducar}/ServicoExterno/DownloadArquivoProfessor?dpf_codigo=${dados.dpf_codigo}&nomedoc=${dados.dpf_descricao}`)
      }
    }
  }

  function atualizaDocLog(dados){
    let dadosLog = {
      dpf_codigo: dados.dpf_codigo,
      pes_codigo: usuario.pes_codigo,
      dcl_tipo: dados.tipolog,
      dcl_especificacao: "Visualização do arquivo"
    }
    api.post('ApiMobileDocumentos/AtualizaDocumentoLog?data=' + JSON.stringify(dadosLog));
  }

  function abrirMensagensDocumento(dados) {

    setMensagensDocumento(dados);
  }

  function abrirChatGrupo(dados) {
    setChatGrupo(dados);
  }

  function enviarResposta() {
    let dadosObrigatorios = '';
    let tipoResposta = (documento.dpf_tiporesposta === 2 ? 2 : null);
    if (tipoResposta === null) tipoResposta = (documento.dpf_tiporesposta === 3 ? 4 : null);
    if (tipoResposta === null) tipoResposta = (!!DPE_RESPOSTA ? (!!arquivoAnexo) ? 0 : 1 : 0);

    if (tipoResposta === 2 && !!DPE_OBSERVACAO === false) dadosObrigatorios += '* Observação\n';
    if ((tipoResposta < 2 && tipoResposta != 3 && tipoResposta != 4) && (arquivoAnexo === null && !!DPE_RESPOSTA === false)) dadosObrigatorios += '* Inclua um arquivo anexo ou o link para acesso a seu arquivo\n';
    if ((tipoResposta < 2 && tipoResposta != 3 && tipoResposta != 4) && (!!DPE_RESPOSTA === true && Util.urlValida(DPE_RESPOSTA) === false)) dadosObrigatorios += '* Informe uma URL válida no link para acesso a seu arquivo\n';
    
    if ((tipoResposta === 4) && (!!trim(DPE_OBSERVACAO) === false && arquivoAnexo === null && !!DPE_RESPOSTA === false)) dadosObrigatorios += '* Inclua um arquivo ou um link ou um texto para sua atividade\n';
    if ((tipoResposta === 4) && (!!trim(DPE_OBSERVACAO) === false && !!DPE_RESPOSTA === true && Util.urlValida(DPE_RESPOSTA) === false)) dadosObrigatorios += '* Informe uma URL válida no link para sua atividade\n';
    
    if (dadosObrigatorios === '') {

      setEnviando(true);

      let formData = new FormData();
      formData.append('dpf_codigo', documento.dpf_codigo);
      formData.append('pes_codigo', usuario.pes_codigo);
      formData.append('dpe_resposta', DPE_RESPOSTA || '');
      formData.append('dpe_tipo', tipoResposta);
      formData.append('dpe_observacao', DPE_OBSERVACAO || '');

      if (DPE_CODIGO !== null) formData.append('dpe_codigo', DPE_CODIGO);

      if (arquivoAnexo !== null) {
        if (Platform.OS === "web") formData.append('documento', arquivoAnexo);
        else formData.append('documento', { uri: arquivoAnexo.uri, name: arquivoAnexo.name, type: 'application/octet-stream'});
      }

      let api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
      api.defaults.headers["Content-Type"] = "multipart/form-data";
      api.post('ApiMobileDocumentos/EnvioResposta', formData).then((retorno) => {
        setModalDocumentos(false);
        if (retorno.status === 200){
          if (retorno.data.success === true) {
            carregarListaDocumentos();
            setEnviando(false);
            exibirAlerta("Resposta Enviada", "As informações foram enviadas com sucesso ao professor.");
            if (modalRespostasDocumento === true) carregarListaRespostasDocumento(documento);
          }
          else {
            setEnviando(false);
            exibirAlerta("ERRO", retorno.data.mensagem);
          }
        }
        if (retorno.status !== 200) {
          setEnviando(false);
          exibirAlerta("ERRO", "Não foi possível enviar a resposta no momento. Tente novamente mais tarde...");
        }
      }, (erro) => {
        setEnviando(false);
        exibirAlerta("ERRO!", "Erro ao enviar a resposta ao professor");
        console.log('erro', erro.response);
      });
    }
    else {
      exibirAlerta('Informe os seguintes dados:', dadosObrigatorios);
    }
  }

  function excluirResposta(dpe_codigo) {
    api.get('/ApiMobileDocumentos/ExcluirRetornoEstudante?pes_codigo=' + usuario.pes_codigo + '&dpe_codigo=' + dpe_codigo).then(res => {
      if (res.data?.success === true){
        carregarListaRespostasDocumento(documento);
        exibirAlerta("Resposta Excluída", "A resposta foi excluída do sistema.");
        carregarListaDocumentos();
      }
      else exibirAlerta("Erro", res.data.mensagem);
    }, err => {
      console.error("Erro ao exluir a resposta", err);
    })
  }

  function editarResposta(dados) {
    setDPE_CODIGO(dados.dpe_codigo);
    setDPE_RESPOSTA((dados.dpe_resposta||''));
    setDPE_OBSERVACAO(dados.dpe_observacao);
    setModalDocumentos(true);
  }

  function renderItemResposta(doc, documento) {
    return (
      <View style={styles.itemResposta}>
        <Text numberOfLines={2} style={{color: ThemeContext._currentValue.theme?.textWhite, fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>Tipo de resposta: <Text style={[styles.texto, {color: ThemeContext._currentValue.theme?.textWhite}]}>{
            ( (doc.dpe_tipo === 0 && !!doc?.arq_codigo) && (!!doc.dpe_resposta && doc.dpe_resposta != 'null' && trim(doc.dpe_resposta) != '')) ? 'Arquivo e Link'
            : (doc.dpe_tipo === 0 && !!doc?.arq_codigo) ? 'Arquivo'
            : (doc.dpe_tipo === 1 && !!doc?.arq_codigo) ? 'Arquivo e Link'
            : (doc.dpe_tipo === 4 && !!doc?.arq_codigo && (!!doc.dpe_resposta && doc.dpe_resposta != 'null' && trim(doc.dpe_resposta) != '') && trim(doc.dpe_observacao) != '') ? 'Arquivo,Link e Texto'
            : (doc.dpe_tipo === 4 && !!doc?.arq_codigo && (!!doc.dpe_resposta && doc.dpe_resposta != 'null' && trim(doc.dpe_resposta) != '')) ? 'Arquivo e Link'
            : (doc.dpe_tipo === 4 && (!!doc.dpe_resposta && doc.dpe_resposta != 'null' && trim(doc.dpe_resposta) != '') && doc.dpe_observacao != '') ? 'Link e Texto'
            : (doc.dpe_tipo === 4 && !!doc?.arq_codigo && doc.dpe_observacao != '') ? 'Arquivo e Texto'
            : (doc.dpe_tipo === 4 && !!doc?.arq_codigo) ? 'Arquivo'
            : (doc.dpe_tipo === 4 && (!!doc.dpe_resposta && doc.dpe_resposta != 'null' && trim(doc.dpe_resposta) != '')) ? 'Link'
            : (doc.dpe_tipo === 4 && doc.dpe_observacao != '') ? 'Texto'
            : (doc.dpe_tipo === 1 ? 'Link'
            : 'Texto')
          }
          </Text>
        </Text>
        <View style={{flex: 1, flexDirection:'row'}}>
          {(!!doc?.arq_codigo) &&
            <>
            <IconButton icon="cloud-download" color={Colors.blue500} size={25} onPress={() => {
              if (Platform.OS === 'web') window.open(`${usuario.ApiEducar}/abs_arquivos/downloadArquivoPorId?_arq_codigo=${doc?.arq_codigo}`, '_self');
              else Linking.openURL(`${usuario.ApiEducar}/abs_arquivos/downloadArquivoPorId?_arq_codigo=${doc?.arq_codigo}`);
            }}/>

            <IconButton color={Colors.blue500} size={25} icon="pageview" onPress={() => {
              documento.atualizaLog = false;
              documento.tituloModalVisualizar = `Resposta enviada: ${format(new Date(doc.dpe_data), 'dd/LL/yyyy')}, da atividade: ${documento?.dpf_descricao}`;
              documento.codigoVisualizar = doc.arq_codigo;
              visualizarAnexoDocumento(documento);
              }
              }/> 
            </>
          }
          
          {((doc.dpe_tipo === 1 || (doc.dpe_tipo === 0 || !!doc?.arq_codigo) || doc.dpe_tipo === 4) && (!!doc.dpe_resposta && (doc.dpe_resposta != 'null' || trim(doc.dpe_resposta) != ''))) &&
            <IconButton icon="link" color={Colors.blue500} size={25} onPress={() => {
              if (Platform.OS === 'web') window.open(doc.dpe_resposta, '_blank');
              else Linking.openURL(trimStart(doc.dpe_resposta));
            }}/>
          }
        </View>
        <Text numberOfLines={2} style={{color: ThemeContext._currentValue.theme?.textWhite, fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>Enviada em: <Text style={[styles.texto, {fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite}]}>{format(new Date(doc.dpe_data), 'dd/LL/yyyy')}</Text></Text>
    <Text numberOfLines={10} style={{color: ThemeContext._currentValue.theme?.textWhite, fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>Observações: <Text style={[styles.texto, {fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite}]}>{doc.dpe_observacao}</Text></Text>
        {!!doc.dtc_codigo === false && ((documento.dpf_respaposdatalimite === true) || (!!documento.dpf_datalimite && new Date(documento.dpf_datalimite).getTime() > Date.now())) &&
          <View style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'row', margin: 5}}>
          {Dimensions.get('screen').width <= 1024 &&
            <React.Fragment>
              <Button mode="contained" style={styles.botaoAcaoResposta} onPress={() => editarResposta(doc)}>Editar</Button>
              <Button mode="contained" style={styles.botaoAcaoResposta} onPress={() => exibirConfirmacao("Excluir?", "Deseja ralmente excluir esta resposa?", "Sim", "Não", () => excluirResposta(doc.dpe_codigo))}>Excluir</Button>
            </React.Fragment>
          }
          {Dimensions.get('screen').width > 1024 &&
            <React.Fragment>
              <Button mode="contained" icon="edit" style={styles.botaoAcaoResposta} onPress={() => editarResposta(doc)}>Editar</Button>
              <Button mode="contained" icon="remove" style={styles.botaoAcaoResposta} onPress={() => exibirConfirmacao("Excluir?", "Deseja realmente excluir esta resposta?", "Sim", "Não", () => excluirResposta(doc.dpe_codigo))}>Excluir</Button>
            </React.Fragment>
          }
          </View>
        }
        {!!doc.dtc_descricao && <Text style={{color: ThemeContext._currentValue.theme?.textWhite}}>Sua resposta <Text style={styles.texto}>{doc.dtc_descricao}</Text>
        {!!doc.dpe_obscorrecao && <Text numberOfLines={10} style={{color: ThemeContext._currentValue.theme?.textWhite}}>, <Text style={styles.texto}>{doc.dpe_obscorrecao}</Text></Text>}
        </Text>}
      </View>
    )
  }

  return (
    <React.Fragment>
      <ScrollView style={[styles.container,{ backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
        {requisicaoCompleta === true && listaDocumentos.length > 0 &&
          listaDocumentos.map((item, indice) => (
            <React.Fragment key={indice}>

              <Card.Title
                title={item.trm_nometurma}
                titleStyle={{fontSize:ThemeContext._currentValue.theme?.fontSize17}}
                titleNumberOfLines="3"
                subtitle={<Caption>Clique no componente para ver suas atividades!</Caption>}
                left={(props) => <Avatar.Icon {...props} icon="class" />}
                right={(props) => <IconButton {...props} icon="search" color={((!!filtroApenasSemResposta) || (!!filtroApenasNovos) || (!!filtroComponente)) ? Colors.red500 : ThemeContext._currentValue.theme?.textWhite } onPress={() => {setModalComponentesFiltro(true)}}/>}
              />


              <Portal>
                <Dialog visible={modalComponentesFiltro} onDismiss={() => {setModalComponentesFiltro(false)}}>
                  <Dialog.Content style={{paddingStart: 10, paddingBottom:0, paddingTop: 10}}>
                    <Paragraph style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>Pesquisar por</Paragraph>

                    <View style={styles.chipRow}>
                      {(!!item?.periodosLetivos) && item?.periodosLetivos.map((prl, indice) => (
                          <React.Fragment key={indice}>
                            <Chip style={styles.chip}
                              selected={((filtroPeriodoLetivo.prl_codigo == prl.prl_codigo) || (!filtroPeriodoLetivo.prl_codigo && !!prl.andamento)) ? true : false}
                              onPress={() => setFiltroPeriodoLetivo(prl)}>
                                <Text style={styles.chipText}>{prl.prl_sigla}</Text>
                            </Chip>
                          </React.Fragment>
                      ))}
                     </View>

                      <List.Item
                        title="Atividades não respondidas"
                        description=""
                        titleStyle={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}
                        right={(props) => <Switch value={filtroApenasSemResposta} onValueChange={setFiltroApenasSemResposta} onTintColor={'grey'} tintColor={'grey'}/>}
                      />
                      <List.Item
                        title="Atividades novas"
                        description=""
                        titleStyle={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}
                        right={(props) => <Switch value={filtroApenasNovos} onValueChange={setFiltroApenasNovos} onTintColor={'grey'} tintColor={'grey'}/>}
                      />
                  </Dialog.Content>
                  <Dialog.ScrollArea>
                    <Paragraph style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>Componentes</Paragraph>
                    <ScrollView style={{maxHeight: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.55))}}>
                        {item.componentes.map((componente, indice) => (
                          <React.Fragment key={indice}>
                              <List.Item onPress={() => {setFiltroComponente(componente.cpc_codigo)}}
                                title={`${componente.cpc_descricao}`}
                                description=""
                                titleStyle={{fontSize: ThemeContext._currentValue.theme?.fontSize12}}
                                style={(componente.cpc_codigo == filtroComponente) ? {backgroundColor: "lightgray"} : {backgroundColor:""}}
                              />
                          </React.Fragment>
                        ))}
                    </ScrollView>
                  </Dialog.ScrollArea>
                  <Dialog.Actions>
                    <Button onPress={() => {setFiltroApenasSemResposta(false); setFiltroApenasNovos(false); setFiltroComponente(0); setModalComponentesFiltro(false)}}>Redefinir Pesquisa</Button>
                    <Button compact mode="contained" onPress={() => {carregarListaDocumentos(); setModalComponentesFiltro(false)}}>Pesquisar</Button>
                  </Dialog.Actions>
                </Dialog>
              </Portal>

              {_.filter(item.componentes, function(comp) {
                let retorno = true;
                if(!!filtroApenasNovos || !!filtroApenasSemResposta) {
                  let possuiDocNovo = false;
                  if(filtroComponente != 0){
                    if(comp.cpc_codigo == filtroComponente){
                      comp.grupos.forEach(grupo => {
                        grupo.documentos.forEach(doc => {
                          if(!!filtroApenasNovos && !!!filtroApenasSemResposta)
                            if(!!doc.novoDocumento) possuiDocNovo = true;
                          if(!!filtroApenasSemResposta)
                            if(doc.qtde_respostas == 0 && (!!doc.dpf_respaposdatalimite || (!!doc.dpf_datalimite && new Date(doc.dpf_datalimite).getTime() > Date.now()))) possuiDocNovo = true;
                        });
                      });
                    }
                  } else {
                    comp.grupos.forEach(grupo => {
                      grupo.documentos.forEach(doc => {
                        if(!!filtroApenasNovos && !!!filtroApenasSemResposta)
                          if(!!doc.novoDocumento) possuiDocNovo = true;
                        if(!!filtroApenasSemResposta)
                          if(doc.qtde_respostas == 0 && (!!doc.dpf_respaposdatalimite || (!!doc.dpf_datalimite && new Date(doc.dpf_datalimite).getTime() > Date.now()))) possuiDocNovo = true;
                      });
                    });
                  }
                  retorno = possuiDocNovo;
                } else {
                   retorno = (filtroComponente == 0) ? true : comp.cpc_codigo == filtroComponente;
                }
                if(comp.cpc_codigo == filtroComponente)
                  comp.isExpanded = true;

                return retorno;
              }).map((componente, indice) => (
                <View key={indice}>

                  <List.Accordion
                      key={indice}
                      title={componente.cpc_descricao}
                      titleStyle={{
                        color: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.textWhite : '',
                        fontWeight: "bold",
                        fontSize: ThemeContext._currentValue.theme?.fontSize16
                      }}
                      description={<Caption style={{fontSize: ThemeContext._currentValue.theme?.fontSize12, color: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.textWhite : ''}}>{componente.resumoAtividades}</Caption>}
                      style={{backgroundColor: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.backgroundColor : componente.crp_cor, borderBottomWidth: 1, borderColor:'#ffffff', borderStyle:'solid' }}
                      // expanded={(!!componente.isExpanded) ? true : false}
                      // onPress={() => {
                      //     setIsExpandedComp(false);
                      //     setTimeout(() => {
                      //       expandedComp(componente.cpc_codigo, (!!componente.isExpanded) ? false : true);
                      //     },500);
                      // }}
                      >
                        {componente.grupos.map((grupo, indice) => (
                                  <React.Fragment key={indice}>
                                    {/* {!!grupo.grp_descricao && <Paragraph> - {grupo.grp_descricao}</Paragraph>} */}
                                    {(!!!grupo.tcg_codigo) && <ListaDocumentos dados={grupo.documentos}
                                        responderDocumento={visualizarModalRespostaDocumento}
                                        visualizarDocumento={acessarDocumento}
                                        downloadDocumento={downloadDocumento}
                                        abrirMensagensDocumento={abrirMensagensDocumento}
                                        abrirChatGrupo={abrirChatGrupo}
                                        filtrarApenasNovos={filtroApenasNovos}
                                        filtrarApenasSemResposta={filtroApenasSemResposta}
                                        visualizarAnexo={visualizarAnexoDocumento}
                                        visualizarPaginaDocumento={visualizarPaginaDocumento}
                                        cor={componente.crp_cor} />}
                                  </React.Fragment>
                                ))}
                  </List.Accordion>

                </View>
              ))}
            </React.Fragment>
          ))
        }
        <Title></Title>
        {requisicaoCompleta === false &&
          <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginTop: 50}}>
            <ActivityIndicator size="large" color="#0a83bf" />
          </View>
        }
        {requisicaoCompleta === true && listaDocumentos.length === 0 &&
          <View style={{flex: 1, justifyContent: "center", alignItems: "center", marginTop: 50}}>
            <Title>Nenhum documento disponível no momento</Title>
          </View>
        }
      </ScrollView>

      <ModalMensagensDocumento mensagensDocumento={mensagensDocumento} fecharModal={() => {
        setMensagensDocumento(null);
        carregarListaDocumentos();
      } } />

      <ModalChat dadosChat={chatGrupo} fecharModal={() => {
        setChatGrupo(null);
        carregarListaDocumentos();
      } } />


      <Modal contentContainerStyle={styles.containerModal} visible={listaLinks.links.length > 0} onDismiss={() => setListaLinks({dpf_codigo: null, links: []})}>
        <Text style={[styles.tituloModal, {marginBottom: 10, fontSize: ThemeContext._currentValue.theme?.fontSize18, color: ThemeContext._currentValue.theme?.textWhite }]}>Selecione o link que deseja abrir</Text>
        <ScrollView>
        {listaLinks.links.map((link, indice) => (
          <List.Item key={indice}
            description={link} title={`Link ${indice+1}`}
            left={props => <List.Icon {...props} icon="link" />}
            onPress={() => abrirLink(link, listaLinks.dpf_codigo)}
          />
        ))}
        <View style={{marginVertical: 10}}>
          <Button mode="contained" onPress={() => setListaLinks({dpf_codigo: null, links: []})}>Fechar</Button>
        </View>
        </ScrollView>
      </Modal>

      <Modal contentContainerStyle={[styles.containerModal, {padding: 20, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]} visible={modalRespostasDocumento} onDismiss={() => setModalRespostasDocumento(false)}>
      <ScrollView style={{maxHeight: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.35))}}>
        <Text style={[styles.tituloModal, {marginBottom: 10, fontSize: ThemeContext._currentValue.theme?.fontSize18, color: ThemeContext._currentValue.theme?.textWhite}]}>Respostas já enviadas para: <Text style={[styles.tituloModalNegrito, {marginBottom: 10, fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize18}]}>{documento?.dpf_descricao}</Text></Text>
        {!!documento?.correcao &&
          <View>
            <Text style={{color: ThemeContext._currentValue.theme?.textWhite}}> Sua atividade <Text style={[styles.texto, { fontWeight: '700', fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite }]}>{documento?.correcao.dtc_descricao}</Text>
            , <Text style={[styles.texto, { fontWeight: '700', fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite }]}> {documento?.correcao.obsCorrecao} </Text>
            </Text>
          </View>
        }
        <View style={{marginBottom: 10}}>
          {((documento?.dpf_respaposdatalimite === true) || (!!documento?.dpf_datalimite && new Date(documento?.dpf_datalimite).getTime() > Date.now())) &&
            <Button mode="contained" onPress={() => {
              setModalRespostasDocumento(false);
              setModalDocumentos(true);
            }} icon="add">Nova resposta</Button>
          }
        </View>
        <ScrollView style={{maxHeight: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.55))}}>
          {listaRespostasDocumento.map((doc, indice) => (
            <View key={indice.toString()}>
              {renderItemResposta(doc, documento)}
              <Divider />
            </View>
          ))}
        </ScrollView>
        </ScrollView>
        <View style={{marginTop: 10}}>
          <Button mode="contained" onPress={() => setModalRespostasDocumento(false)}>Fechar</Button>
        </View>
      </Modal>

      <Modal contentContainerStyle={[styles.containerModal,{padding: 20, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]} visible={modalDocumentos} onDismiss={() => setModalDocumentos(false)}>
      <ScrollView style={{maxHeight: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.35))}}>
        <Text style={[styles.tituloModal, {marginBottom: 10, color: ThemeContext._currentValue.theme?.textWhite, fontSize: ThemeContext._currentValue.theme?.fontSize18 } ]}>Enviar resposta para: <Text style={[styles.nomeDocumento, {fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize18 }]}>{documento?.dpf_descricao}</Text></Text>
        <View style={{marginTop: 10}}>
          {!!documento && (documento.dpf_tiporesposta === 1 || documento.dpf_tiporesposta === 3) &&
            <React.Fragment>
              <TextInput label='Link de acesso ao documento de resposta' value={DPE_RESPOSTA} onChangeText={setDPE_RESPOSTA} autoCapitalize="none" autoCorrect={false} />
              <View style={{marginVertical: 10, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontSize: 12, color: ThemeContext._currentValue.theme?.textWhite,  fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>OU</Text>
              </View>
              {Platform.OS === "web" && <Button accessibilityLabel="Enviar arquivo de resposta" mode="contained" onPress={() => selecionarArquivo()}>{arquivoAnexo !== null ? `Arquivo selecionado: ${arquivoAnexo.name}` : "Clique aqui para selecionar um Arquivo"}</Button>}
              {Platform.OS !== "web" &&
                <Menu
                  visible={menuAcoes}
                  onDismiss={() => setMenuAcoes(false)}
                  anchor={<React.Fragment>
                      <Button accessibilityLabel="Enviar arquivo de resposta" mode="contained" onPress={() => setMenuAcoes(true)}>{arquivoAnexo !== null ? `Arquivo selecionado: ${arquivoAnexo.name}` : "Clique aqui para selecionar um Arquivo"}</Button>
                    </React.Fragment>}>
                  <Menu.Item icon="attach-file" onPress={() => {
                    setMenuAcoes(false);
                    selecionarArquivo();
                  }} title="Selecionar arquivo" />
                  <Menu.Item icon="camera" onPress={() => {
                    setMenuAcoes(false);
                    selecionarImagemCamera();
                  }} title="Utilizar camera" />
                </Menu>
              }
            </React.Fragment>
          }
          {!!documento && documento.dpf_tiporesposta == 3 && 
              <View style={{marginVertical: 10, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{fontSize: 12, color: ThemeContext._currentValue.theme?.textWhite,  fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>OU</Text>
              </View>
          }
          <View style={(!!documento && documento.dpf_tiporesposta == 3) ? {marginVertical: 0} : {marginVertical: 10}}>
            <TextInput label='Coloque aqui suas respostas' value={DPE_OBSERVACAO} onChangeText={setDPE_OBSERVACAO} autoCapitalize="none" autoCorrect={true} multiline={true} numberOfLines={5} />
          </View>
          <View style={{flexDirection: 'row', justifyContent: "flex-end", marginVertical: 5, marginBottom: 5}}>
            <Button loading={enviando} disabled={enviando} style={{marginRight: 15}} mode="contained" onPress={() => enviarResposta()}>Enviar</Button>
            <Button style={{marginRight: 15}} mode="outlined" onPress={() => setModalDocumentos(false)}>Cancelar</Button>
          </View>
        </View>
        </ScrollView>
      </Modal>

      <Modal contentContainerStyle={[styles.containerModalVisualizar,{padding: 5, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 10), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]} visible={modalVisualizarAnexo}
        onDismiss={() => {
          clearInterval(temporizador);
          setModalVisualizarAnexo(false)}
        }>
        <Paragraph style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>{documento?.tituloModalVisualizar}</Paragraph>
        <ScrollView>
            {(!!linkPreVisualizarAnexo.src) && Platform.OS != "web" &&
            <View style={{ flex: 1 }}>
                <WebView
                  originWhitelist={['*']}
                  height={linkPreVisualizarAnexo.height}
                  source={(linkPreVisualizarAnexo.visualizandoAnexo)? {uri: linkPreVisualizarAnexo.src} : {html: linkPreVisualizarAnexo.src}}
                  //renderLoading={LoadingIndicatorView}
                  onLoadStart={() => {
                    setCarregandoPreVisualizacaoErro(false);
                  }}
                  onLoad={() => {
                    debugger;
                    if(carregandoPreVisualizacaoErro == false) {
                      clearInterval(temporizador);
                      if(documento.atualizaLog){
                        atualizaDocLog(documento);
                      }
                      setCarregandoPreVisualizacao(false);
                    } else {
                      setCarregandoPreVisualizacao(true);
                      webViewRef.current.reload();
                    }
                  }}
                  onLoadEnd={(e) => {
                    setCarregandoPreVisualizacaoErro(true);
                  }}
                  startInLoadingState={true}
                  ref={(ref) => (webViewRef.current = ref)}
                  saveFormDataDisabled={true}
                  />

                    {carregandoPreVisualizacao && (
                      <ActivityIndicator size="small" color="#0a83bf"
                        style={{ position: "absolute",
                                top: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.70)),
                                left:(Dimensions.get('window').width / 2.5)
                              }} />
                    )}
                </View>
            }

            {(!!linkPreVisualizarAnexo.src) && Platform.OS == "web" &&
              <View style={{ flex: 1 }}>
                <iframe ref={iframeEl}
                    id="idPreVisualizarAnexo"
                    onLoad={() => {
                      clearInterval(temporizador);
                      setCarregandoPreVisualizacao(false);
                      if(documento.atualizaLog){
                        atualizaDocLog(documento);
                      }
                    }}
                    frameBorder="0"
                    title={documento?.dpf_descricao}
                    height={linkPreVisualizarAnexo.height}
                    src={linkPreVisualizarAnexo.src}>
                </iframe>
                {carregandoPreVisualizacao && (
                  <ActivityIndicator
                    style={{
                      top: (linkPreVisualizarAnexo.height - (linkPreVisualizarAnexo.height * 2))
                    }}
                    size="large"
                  />
                )}
              </View>
            }

          <View>
            <Button mode="contained" onPress={() => {clearInterval(temporizador); setModalVisualizarAnexo(false)}}>Fechar</Button>
          </View>
        </ScrollView>
      </Modal>

      <Modal contentContainerStyle={{padding: 5, margin: 10, backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }} visible={modalVisualizarLink}
        onDismiss={() => {setModalVisualizarLink(false)}}>
        <ScrollView>
            <View style={{ flex: 1 }}>
              <WebView
                originWhitelist={['*']}
                source={(!!visualizarLink.url) ? {uri: visualizarLink.link} : {html: visualizarLink.link}}
                renderLoading={LoadingIndicatorView}
                style={{flex:1, height: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.30)) }}
                bounces={false}     
                dataDetectorTypes='link'
                scalesPageToFit={false}
                scrollEnabled={false}
                automaticallyAdjustContentInsets={false}
                mediaPlaybackRequiresUserAction={true}
                startInLoadingState={true}
                saveFormDataDisabled={true}
                javaScriptEnabled={true}
                allowsFullscreenVideo={true}
              />
            </View>
        </ScrollView>
        <View>
            <Button mode="contained" onPress={() => {setModalVisualizarLink(false)}}>Fechar</Button>
          </View>
      </Modal>

      <Modal visible={modalDownloadLink} onDismiss={() => {setModalDownloadLink(false)}}>
              <WebView
                source={{ 
                  uri: "file://"+ downloadLink.url,
                  method: 'GET',
                  headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/pdf',
                  },
                }}
                ref={webViewRefDownload}
                javaScriptEnabled={true}
                domStorageEnabled={true}
                allowFileAccess={true}
                allowUniversalAccessFromFileURLs={true}
                allowingReadAccessToURL={true}
                mixedContentMode={'always'}
                renderLoading={LoadingIndicatorView}
                startInLoadingState={true}
                originWhitelist={['*']}
                onLoadEnd={(e) => {
                  console.log('e',e);
                  setModalDownloadLink(false);
                }}
              />
      </Modal>

    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10
  },
  containerModal: {
    padding: 20,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30),
    backgroundColor: "#FFF",
  },
  containerModalVisualizar: {
    padding: 5,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 10),
    backgroundColor: "#FFF"
  },
  tituloModal: {
    fontSize: 18,
    marginBottom: 10
  },
  tituloModalNegrito: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: 'bold'
  },
  itemResposta: {
    marginVertical: 10
  },
  botaoAcaoResposta: {
    marginHorizontal: 4,
  },
  botaoDownloadArquivoResposta: {
    position: "absolute",
    right: 0
  },
  texto: {
    fontSize: 14,
    fontWeight: '700'
  },
  nomeDocumento: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  filtro: {
    flexDirection: Dimensions.get('window').width >= 1024 ? 'row' : 'column',
    fontSize:12
  },
  filtroDireita: {
    flexDirection: Dimensions.get('window').width >= 1024 ? 'row-reverse' : 'column-reverse',
    flex: 1,
  },
  filtroEsquerda: {
    flexDirection: Dimensions.get('window').width >= 1024 ? 'row' : 'column',
    flex: 1,
  },
  chipRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 12
  },
  chip: {
    backgroundColor: "#438eb9",
    margin: 4
  },
  chipText: {
    color: "#ffffff"
  }
});