import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView, Text, Platform, Linking, Alert, StyleSheet, Dimensions, Switch, ViewPropTypes } from 'react-native';
import { Title, Caption, Button, IconButton, Modal, TextInput, List, ActivityIndicator, Divider, Menu, Dialog, Portal , Avatar, Card, Paragraph, Colors, Chip } from 'react-native-paper';
import { WebView } from 'react-native-webview';

import { TextInputMask } from 'react-native-masked-text';
import DateTimePicker from "react-native-modal-datetime-picker";
import { format } from 'date-fns';
import { useStateValue } from '../state';
import _, { trimStart } from 'lodash';
import { Api, Util } from '../services';
import { WebViewEducar } from '../components';

import {ThemeContext} from '../../theme-context';

const flexDir = Platform.OS === 'web' && Dimensions.get("window").width >= 768 ? 'row' : 'column';

export default function Observacoes({ navigation }) {
    const [{ usuario, estudante }, dispatch] = useStateValue();
    const [ carregando, setCarregando ] = useState(true);
    const [ listaObservacoes, setListaObservacoes ] = useState([]);
    const [ listaComponentes, setListaComponentes ] = useState([]);
    const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
    const [ modalComponentesFiltro, setModalComponentesFiltro ] = useState(false);
    const [ filtroComponente, setFiltroComponente ] = useState(0);
    const dataAtual = new Date();
    const [ dataInicial, setDataInicial ] = useState(null);
    const [ dataFinal, setDataFinal ] = useState(null);
    const [ modalDataIni, setModalDataIni ] = useState(false);
    const [ modalDataFim, setModalDataFim ] = useState(false);
    
    const [expanded, setExpanded] = React.useState(false);

    const handlePressExpanded = () => setExpanded(!expanded);


    useEffect(() => {
        carregarObsDiarias(dataInicial, dataFinal);
      }, []);

      function redifinirFiltro() {
        setFiltroComponente(0); 
        setModalComponentesFiltro(false); 
        if (Platform.OS === "web") {
          setDataInicial("01/01/"+dataAtual.getFullYear());
          setDataFinal("01/01/"+(dataAtual.getFullYear()+1));
        } else {
          setDataInicial(null);
          setDataFinal(null);
        }
        carregarObsDiarias("01/01/"+dataAtual.getFullYear(),"01/01/"+(dataAtual.getFullYear()+1), true);
      }

      function carregarObsDiarias(_dataInicial, _dataFinal, listarTodos) {
        setCarregando(true);
        setExpanded(false);
        
        let _componente = filtroComponente;
        if(!!listarTodos) _componente = 0;
        
        if(!!!_dataInicial) {
          _dataInicial = "01/01/"+dataAtual.getFullYear();
          if (Platform.OS === "web") setDataInicial(_dataInicial);
        }

        if(!!!_dataFinal) {
          _dataFinal = "01/01/"+(dataAtual.getFullYear()+1);
          if (Platform.OS === "web") setDataFinal(_dataFinal);
        }

        _dataInicial = Util.dataFormatada(new Date(_dataInicial));
        _dataFinal = Util.dataFormatada(new Date(_dataFinal));        

        api.get("/ApiMobileObsDiariaEstudante/listar?_mtr_codigo=" + estudante.matricula + "&dataInicial='"+_dataInicial+"'&dataFinal='"+_dataFinal+"'&_componente="+_componente).then((retorno) => {
            setCarregando(false);
            if (retorno.status === 200) {
              setListaObservacoes(retorno.data.lista);
              if(listaComponentes.length === 0) setListaComponentes(retorno.data.lista);
            }
          }, (erro) => {
            setCarregando(false);
          });
      }

      function renderFiltroDataInicial() {
        if (Platform.OS === "web") {
          return (
            <React.Fragment>
              <Text style={[styles.label, {alignSelf: 'center', marginRight: 10, color: (!!ThemeContext._currentValue.theme.dark) ? "#fff" : null}]}>Data Inicial </Text>
              <TextInputMask
                type={'custom'}
                style={[styles.webTextInput,{marginBottom: 10, height: 36, borderColor: (!!ThemeContext._currentValue.theme.dark) ? '#fff' : '#202021', borderWidth: 1, fontSize: ThemeContext._currentValue.theme?.fontSize14, fontWeight: 'bold', color: ThemeContext._currentValue.theme?.textBlue }]}
                options={{
                  mask: '99/99/9999'
                }}
                value={dataInicial}
                onChangeText={text => {
                  setDataInicial(text);
                }}
              />
            </React.Fragment>
          )
        }
        else {
          return (
            <Button mode="outlined" icon="date-range" uppercase={false} onPress={()=>setModalDataIni(true)}>{!!dataInicial === false ? 'Data Inicial' : format(dataInicial, "dd/LL/yyyy")}</Button>
          )
        }
      }
    
      function renderFiltroDataFinal() {
        if (Platform.OS === "web") {
          return (
            <React.Fragment>
              <Text style={[styles.label, {marginLeft: 10, alignSelf: 'center', marginRight: 10, color: (!!ThemeContext._currentValue.theme.dark) ? "#fff" : null}]}>Data Final </Text>
              <TextInputMask
                type={'custom'}
                style={[styles.webTextInput,{marginBottom: 10, height: 36, borderColor: (!!ThemeContext._currentValue.theme.dark) ? '#fff' : '#202021', borderWidth: 1, fontSize: ThemeContext._currentValue.theme?.fontSize14, fontWeight: 'bold', color: ThemeContext._currentValue.theme?.textBlue }]}
                options={{
                  mask: '99/99/9999'
                }}
                value={dataFinal}
                onChangeText={text => {
                  setDataFinal(text);
                }}
              />
            </React.Fragment>
          )
        }
        else {
          return (
            <Button mode="outlined" icon="date-range" uppercase={false} onPress={()=>setModalDataFim(true)}>{!!dataFinal === false ? 'Data Final' : format(dataFinal, "dd/LL/yyyy")}</Button>
          )
        }
      }

      return (
        <View style={[styles.container,{flex:1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }] }>
            
            {listaObservacoes.length === 0 &&
              <View style={styles.centralizar}>
                <Text style={{textAlign:'center',fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }}>Você não possui observações disponibilizadas para sua consulta.</Text>
              </View>
            }
            
          {listaObservacoes.length > 0  &&
          <>
          <Card.Title
          title={estudante.turma}
          titleStyle={{fontSize:ThemeContext._currentValue.theme?.fontSize17}}
          titleNumberOfLines="3"
          subtitle={<Caption>Clique no componente para ver suas observações!</Caption>}
          left={(props) => <Avatar.Icon {...props} icon="class" />}
          right={(props) => <IconButton {...props} icon="search" color={(!!filtroComponente) ? Colors.red500 : ThemeContext._currentValue.theme?.textWhite } onPress={() => {setModalComponentesFiltro(true)}}/>}
      />
      <Divider />

      <Portal>
          <Dialog visible={modalComponentesFiltro} dismissable={false} onDismiss={() => {setModalComponentesFiltro(false)}}>
          <Dialog.Content style={{paddingStart: 10, paddingBottom:0, paddingTop: 10}}>
              <Paragraph style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>Pesquisar por</Paragraph>
              <View style={styles.linhaModal}>
                  <View style={styles.linhaHorizontal}>{renderFiltroDataInicial()}</View>
                  <View style={styles.linhaHorizontal}>{renderFiltroDataFinal()}</View>
              </View>
          </Dialog.Content>
          <Dialog.ScrollArea>
              <Paragraph style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>Componentes</Paragraph>
              <ScrollView style={{maxHeight: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.55))}}>
                  {listaComponentes.map((componente, indice) => (
                  <React.Fragment key={indice}>
                      <List.Item onPress={() => {setFiltroComponente(componente.cct_codigo)}}
                          title={`${componente.cpc_descricao}`}
                          description=""
                          titleStyle={(componente.cct_codigo == filtroComponente) 
                              ? {fontSize: ThemeContext._currentValue.theme?.fontSize14, fontWeight: "bold"}
                              : {fontSize: ThemeContext._currentValue.theme?.fontSize12}
                          }
                          style={
                              (componente.cct_codigo == filtroComponente) 
                              ? {backgroundColor: "#b7b7b7", borderWidth: 1, borderColor:'#000000', borderStyle:'solid'} 
                              : {backgroundColor: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.backgroundColor : componente.crp_cor, borderBottomWidth: 1, borderColor:'#ffffff', borderStyle:'solid'}
                          }
                      />
                  </React.Fragment>
                  ))}
              </ScrollView>
          </Dialog.ScrollArea>
          <Dialog.Actions>
              <Button onPress={() => {redifinirFiltro()}}>Redefinir Pesquisa</Button>
              <Button compact mode="contained" onPress={() => {setModalComponentesFiltro(false); carregarObsDiarias(dataInicial, dataFinal)}}>Pesquisar</Button>
          </Dialog.Actions>
          </Dialog>
      </Portal>

            <React.Fragment>
              <ScrollView>
              <View style={{margin: 10}}>
                  <ScrollView>
                  {_.filter(listaObservacoes, function(comp) {
                        return (filtroComponente == 0) ? true : comp.cct_codigo == filtroComponente;
                    }).map((ode, indice) => (
                        <View key={indice}>
                            <List.Accordion
                                key={indice}
                                title={ode.cpc_descricao}
                                expanded={expanded}
                                onPress={handlePressExpanded}
                                titleStyle={{
                                    color: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.textWhite : '',
                                    fontWeight: "bold",
                                    fontSize: ThemeContext._currentValue.theme?.fontSize16
                                }}
                                style={{backgroundColor: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.backgroundColor : ode.crp_cor, borderBottomWidth: 1, borderColor:'#ffffff', borderStyle:'solid' }}
                            >

                            {ode.datas.map((data, indice) => (
                                 <React.Fragment key={indice}>
                                    <View>
                                        <Card.Title
                                            title={Util.dataFormatada(data.ode_data, true)}
                                            titleStyle={{fontSize:ThemeContext._currentValue.theme?.fontSize17}}
                                            titleNumberOfLines="2"
                                            subtitle={<Caption style={{fontSize: ThemeContext._currentValue.theme?.fontSize12, color: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.textWhite : ''}}>{data.pes_nome}</Caption>}
                                        />

                                        {data.modelos.length > 0 &&
                                            <Text style={{marginLeft: 20, fontSize: ThemeContext._currentValue.theme?.fontSize10, color: ThemeContext._currentValue.theme?.textWhite }}>Ocorrências:</Text>
                                        }
                                        <View style={{marginTop: (Platform.OS != "web") ? 0 : -10}}>
                                          {data.modelos.map((obm, indice) => (
                                                      <React.Fragment key={indice}>
                                                          <View>
                                                              { Platform.OS != "web" &&
                                                                  <WebView
                                                                    originWhitelist={['*']}
                                                                    height={20}
                                                                    style={{backgroundColor: '#e9e9ef'}}
                                                                    source={{ html: `<html>
                                                                      <head>
                                                                        <style>
                                                                          body { font-size: ${ThemeContext._currentValue.theme?.fontSize12}px; text-align: justify; margin: 2 0 0 25; padding: 0}
                                                                        </style>
                                                                        <meta http-equiv="content-type" content="text/html; charset=utf-8">
                                                                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                                                      </head>
                                                                      <body> 
                                                                          <div style="height: 10px;">
                                                                            ${obm.obm_descricao}
                                                                          </div>
                                                                      </body>
                                                                    </html>` }}
                                                                  />
                                                              }
                                                              { Platform.OS == "web" && 
                                                                  <Text style={{height:20}}>
                                                                      <div style={{
                                                                              fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                                                                              fontSize: ThemeContext._currentValue.theme?.fontSize14,
                                                                              marginLeft: 25, 
                                                                              color: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.textWhite : ''
                                                                      }} dangerouslySetInnerHTML={{__html: obm.obm_descricao}}></div>
                                                                  </Text>
                                                              }
                                                          </View>
                                                      </React.Fragment>
                                          ))}
                                        </View>
                                        <View style={{marginTop: (!!data.modelos.length) ? (Platform.OS != "web") ? 5 : 20 : 0}}>
                                            { Platform.OS != "web" &&
                                              <WebViewEducar conteudoHTML={data.ode_descricao}/>
                                            }
                                            { Platform.OS == "web" && 
                                                <Text>
                                                    <div style={{
                                                            fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                                                            fontSize: ThemeContext._currentValue.theme?.fontSize16,
                                                            marginLeft: 25, 
                                                            color: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.textWhite : ''
                                                    }} dangerouslySetInnerHTML={{__html: data.ode_descricao}}></div>
                                                </Text>
                                            }
                                        </View>
                                        <Divider style={{marginTop: 10, color: (!!ThemeContext._currentValue.theme.dark) ? "#ffffff" : null}} />
                                    </View>
                                </React.Fragment>
                            ))}
                            </List.Accordion>
                        </View>
                    ))}
                  </ScrollView>
              </View>
              </ScrollView>
            </React.Fragment>
            </>
          }
          <Modal visible={carregando}>
            <ActivityIndicator color="#FFF" size="large" />
          </Modal>

          {Platform.OS !== "web" &&
            <React.Fragment>
            <DateTimePicker
                isVisible={modalDataIni}
                onConfirm={(date) => {
                setDataInicial(date);
                setModalDataIni(false);
                }}
                onCancel={() => setModalDataIni(false)}
            />
            <DateTimePicker
                isVisible={modalDataFim}
                onConfirm={(date) => {
                setDataFinal(date);
                setModalDataFim(false);
                }}
                onCancel={() => setModalDataFim(false)}
            />
            </React.Fragment>
        }
        </View>
      );
}

const styles = StyleSheet.create({
    container: {
      flex: 1
    },
    linhaHorizontal: {
        flexDirection: 'row',
        justifyContent: "center"
    },
    label: {
        alignSelf: 'center',
        marginRight: 10,
    },
    linhaModal: {
        flexDirection: flexDir,
        alignItems: "center",
        justifyContent: "center"
    },
    webTextInput: {
        height: 36,
        borderColor: '#202021',
        borderWidth: 1,
        fontSize: 14,
        fontWeight: 'bold',
    },
    containerCentralizado: {
      flex: 1,
      alignContent: "center",
      justifyContent: "center",
    },
    headTurma:{
        fontSize: 8,
        fontWeight: "bold",
        marginLeft: 10
    },
    texto: {
      fontSize: 15,
      flexDirection: "row",
     
    },
    conteudoAcoes: { 
        flexDirection: "row", 
        flex: 1, 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10
    },
    botaoAcao: {
        marginLeft: 10
    },
    centralizar: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    }  
    
  });