import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

let sizeAllRequest = 0;

const Api = {
  create: function (baseUrl, JWT = null) {
    
    let config = {
      baseURL: baseUrl,
      timeout: 30000
    }
    
    function formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}

    let api = axios.create(config);

    api.interceptors.request.use(async (config) => {
      if (JWT) config.headers["Authorization"] = 'Bearer ' + JWT;

      return config;
    });
      
    api.interceptors.response.use((response) => {
      //sizeAllRequest += parseInt(response.headers['content-length']);
      //console.log('Size: ', formatBytes(parseInt(response.headers['content-length'])), '  all: ', formatBytes(parseInt(sizeAllRequest)));
      
      return response;
    },(error) => {
      if (error.response.status === 401) {
        AsyncStorage.removeItem('EducarWebEstudanteApp:email');
        AsyncStorage.removeItem('EducarWebEstudanteApp:senha');
        AsyncStorage.removeItem('EducarWebEstudanteApp:user');
      }
      return Promise.reject(error);
    });

    return api;
  },
  urlAdminEducar: "https://adm.educarweb.net.br/",
}

export default Api;