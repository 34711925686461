import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, Platform, Alert, StyleSheet, PermissionsAndroid, Linking } from 'react-native';
import { Caption, IconButton, Modal, ActivityIndicator, Divider, Avatar, Card } from 'react-native-paper';
import { useStateValue } from '../state';

import RNFetchBlob from './RNFetchBlob';
import { Api } from '../services';
import {ThemeContext} from '../../theme-context';

export default function Atestados() {
  const [{ usuario, estudante }, dispatch] = useStateValue();
  const [ listaAtestados, setListaAtestados ] = useState([]);
  const [ carregando, setCarregando ] = useState(true);
  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  useEffect(() => {
    api.get("/ApiMobile/BuscarRelatoriosLiberadosAPP?_mtr_codigo=" + estudante.matricula).then((retorno) => {
        setCarregando(false);
        if (retorno.status === 200) {
          setListaAtestados(retorno.data.listaRelatorio);
        }
      }, (erro) => {
        setCarregando(false);
      });
    }, []);

  function exibirAlerta(titulo, texto) {
    if (Platform.OS === "web") {
      alert(titulo+'\n\n'+texto);
    }
    else {
      Alert.alert(
        titulo,
        texto
      );
    }
  }

  async function downloadAtestado(dados) {
    if (Platform.OS === 'web') 
    window.open(`${usuario.ApiEducar}/servicoexterno/downloadRelatorio?codPes=${usuario.pes_codigoLogin}&codMtr=${estudante.matricula}&idRelatorio=${dados.rlt_codigo}`, '_blank');
    else if(Platform.OS === 'ios') Linking.openURL(`${usuario.ApiEducar}/servicoexterno/downloadRelatorio?codPes=${usuario.pes_codigoLogin}&codMtr=${estudante.matricula}&idRelatorio=${dados.rlt_codigo}`);
    else {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          {
            title: 'Permissão para armazenamento',
            message:'O aplicativo precisa de permissão para baixar seu atestado',
            buttonPositive: 'OK',
          }
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          let nomeArquivoDownload = `educarweb_atestado_${dados.rlt_descricao}.pdf`;

          const { dirs } = RNFetchBlob.fs;
          RNFetchBlob.config({
            fileCache: true,
            addAndroidDownloads: {
              overwrite: true,
              useDownloadManager: true,
              notification: true,
              mediaScannable: true,
              description: `App - EducarWeb`,
              title: nomeArquivoDownload,
              path: `${dirs.DownloadDir}/${nomeArquivoDownload}`,
            },
            appendExt: 'PDF'
          })
            .fetch('GET', `${usuario.ApiEducar}/servicoexterno/downloadRelatorio?codPes=${usuario.pes_codigoLogin}&codMtr=${estudante.matricula}&idRelatorio=${dados.rlt_codigo}`)
            .then((res) => {
              exibirAlerta("Download concluído.", `Seu atestado ${dados.rlt_descricao} foi concluído.`);
            })
        } else {
          alert('Permissão para armazenamento negada pelo usuário');
        }
      } catch (err) {
        console.log("ERROR com o RNFetchBlob: ", err);
        Linking.openURL(`${usuario.ApiEducar}/servicoexterno/downloadRelatorio?codPes=${usuario.pes_codigoLogin}&codMtr=${estudante.matricula}&idRelatorio=${dados.rlt_codigo}`);
      }
    }
  }

  return (
    <View style={[{flex:1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor } ]}>
     

      {listaAtestados.length === 0 &&
        <View style={{flex: 1, justifyContent: "center" }}>
          <Text style={[{textAlign: 'center', fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }]}>Você não possui opções de atestados disponibilizados para emissão</Text>
        </View>
      }

      {listaAtestados.length > 0  &&
      <View>
         <Card.Title
          title={estudante.turma}
          titleStyle={{fontSize:ThemeContext._currentValue.theme?.fontSize17}}
          titleNumberOfLines="3"
          subtitle={<Caption>{estudante.escola} - {estudante.matricula}</Caption>}
          left={(props) => <Avatar.Icon {...props} icon="class" />}
      />
      <Divider />
        <React.Fragment>
          <ScrollView>
            <View style={{margin: 10}}>
              <ScrollView>
                {listaAtestados.map((atestado, indice) => (
                  <React.Fragment key={indice}>
                     <Card.Title
                        title={atestado.rlt_descricao}
                       //subtitle="Card Subtitle"
                        right={(props) => <IconButton {...props} icon="file-download" onPress={() => downloadAtestado(atestado)} />}
                      />
                    <Divider />
                  </React.Fragment>
                ))}
              </ScrollView>
            </View>
          </ScrollView>
        </React.Fragment>
        </View>
      }
  
    <Modal visible={carregando}>
      <ActivityIndicator color="#FFF" size="large" />
    </Modal>
      
    </View>
   
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  containerCentralizado: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
  },
  headTurma:{
      fontSize: 8,
      fontWeight: "bold",
      marginLeft: 10
  },
  texto: {
    fontSize: 15,
    flexDirection: "row"
  },
});