import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Divider } from 'react-native-paper';
import {ThemeContext} from '../../../theme-context';

export default function ItemListaEvento(props) {
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={{ margin: 15 }}>
          <Ionicons name="md-calendar" size={26} color="#202021" />
        </View>
        <View style={{ flexDirection: "column" }}>
          <Text numberOfLines={2} style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>{props.evento.pessoa}</Text>
          <Text numberOfLines={2} style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>{props.evento.nome}</Text>
          {!!props.evento.horario && !!props.evento.local && <Text numberOfLines={2} style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>{`${props.evento.horario} - ${props.evento.local}`}</Text>}
        </View>
      </View>
      <Divider />
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    minHeight: 35,
  }
});