import React, { useState, useEffect } from 'react';
import { View, ScrollView, Alert, StyleSheet, Dimensions, Platform } from 'react-native';
import { ActivityIndicator, Title, Avatar, Card, Button, TextInput, Subheading, Caption, Snackbar } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';
//import { BarPasswordStrengthDisplay } from 'react-native-password-strength-meter';
import { useStateValue } from '../state';
import { Api, Util } from '../services';
import {ThemeContext} from '../../theme-context';
import { functionsIn } from 'lodash';

export default function MinhaConta({navigation}) {

  const passwordLevels = [
    {
      label: 'Senha insegura',
      labelColor: '#ff2900',
      activeBarColor: '#ff2900',
    },
    {
      label: 'Senha insegura',
      labelColor: '#ff3e00',
      activeBarColor: '#ff3e00',
    },
    {
      label: 'Senha muito fraca',
      labelColor: '#ff5400',
      activeBarColor: '#ff5400',
    },
    {
      label: 'Senha fraca',
      labelColor: '#ff6900',
      activeBarColor: '#ff6900',
    },
    {
      label: 'Senha muito simples',
      labelColor: '#f4d744',
      activeBarColor: '#f4d744',
    },
    {
      label: 'Senha mediana',
      labelColor: '#f3d331',
      activeBarColor: '#f3d331',
    },
    {
      label: 'Senha regular',
      labelColor: '#f2cf1f',
      activeBarColor: '#f2cf1f',
    },
    {
      label: 'Senha forte',
      labelColor: '#14eb6e',
      activeBarColor: '#14eb6e',
    },
    {
      label: 'Senha muito forte',
      labelColor: '#0af56d',
      activeBarColor: '#0af56d',
    },
    {
      label: 'Senha alienígena :)',
      labelColor: '#05a84b',
      activeBarColor: '#05a84b',
    }
  ];

  const [{ usuario, mainNavigator }, dispatch] = useStateValue();
  
  const [ dadosUsuario, setDadosUsuario ] = useState(null);
  const [ carregando, setCarregando ] = useState(true);
  const [ efetuandoRequest, setEfetuandoRequest ] = useState(false);
  const [ senhaAtual, setSenhaAtual ] = useState('');
  const [ novaSenha, setNovaSenha ] = useState('');
  const [ confirmaNovaSenha, setConfirmaNovaSenha ] = useState('');
  const [dadosAlerta, setDadosAlerta] = useState({ exibir: false, texto: '' });

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  useEffect(() => {
    (async function inicializar() {
      api.get('/ApiMobile/retornaDadosPessoa?pes_codigo=' + usuario.pes_codigoLogin).then(dados => {
        setDadosUsuario(dados.data);
      }).finally(() => setCarregando(false));
    })();
  }, []);

  function exibirAlerta(titulo, texto) {
    if (Platform.OS === "web") {
      alert(titulo+'\n\n'+texto);
    }
    else {
      Alert.alert(
        titulo,
        texto
      );
    }
  }

  async function alterarSenhaUsuario() {
    setEfetuandoRequest(true);
    let dadosObrigatorios = '';
    if (!!senhaAtual === false) dadosObrigatorios += '* Informe sua senha atual;\n';
    if (!!novaSenha === false) dadosObrigatorios += '* Informe a nova senha;\n';
    else if (novaSenha.length < 6) dadosObrigatorios += '* Sua nova senha deve possuir no mínimo 6 caracteres;\n';
    else if (novaSenha !== confirmaNovaSenha) dadosObrigatorios += '* Erro na confirmação da nova senha. Verifique as senahs digitadas;\n';
    
    if(dadosObrigatorios !== '') {
      setEfetuandoRequest(false);
      exibirAlerta('Informe os seguintes dados:', dadosObrigatorios);
    }
    else {
      let payloadJWT = Util.getJWTPayload(usuario.tokenAuth);
      if (payloadJWT?.sub) {
        try {
          let municipiosSenhaAlterada = []
          const apiAdminEducar = Api.create(Api.urlAdminEducar);
          let retornoMunicipiosAcesso = await apiAdminEducar.get('/AbsUsuario/ListarMunicipiosAcessoUsuario?id=' + payloadJWT?.sub);
          if (retornoMunicipiosAcesso?.data) {
            retornoMunicipiosAcesso.data.result.forEach(async (org) => {
              try {
                let apiOrg = Api.create(org.url, usuario.tokenAuth);
                let retorno = await apiOrg.get(`/ApiMobile/alterarSenha?_pes_codigo=${usuario.pes_codigoLogin}&_senhaAtual=${senhaAtual}&_novaSenha=${novaSenha}`);
                if (retorno?.status !== 200 ) setDadosAlerta({ exibir: true, texto: 'Não foi possivel alterar sua senha no município: ' + org.nome });
                else municipiosSenhaAlterada.push(org.nome);
              }
              catch(erro) {
                setDadosAlerta({ exibir: true, texto: 'Não foi possivel alterar sua senha no município: ' + org.nome });
              }
            });
          }
          setEfetuandoRequest(false);
          if (municipiosSenhaAlterada.length > 1) {
            let msg = "Sua senha de acesso foi alterada nos municípios:\n";
            municipiosSenhaAlterada.forEach(mun => {
              msg += "  - " + mun + "\n";
            });
            exibirAlerta('Senha de acesso', msg);
          }
          else exibirAlerta('Senha de acesso', 'Sua senha de acesso ao sistema foi alterada com sucesso!');

          navigation.navigate('Home');
        }
        catch(err) {
          console.error('Erro ao verificar municipios de acesso do usuário', err);
          setDadosAlerta({ exibir: true, texto: 'Não foi possivel verificar os municipios de acesso do set usuário.' });
          setEfetuandoRequest(false);
        }
      }
      else {
        api.get(`/ApiMobile/alterarSenha?_pes_codigo=${usuario.pes_codigoLogin}&_senhaAtual=${senhaAtual}&_novaSenha=${novaSenha}`).then(retorno => {
          exibirAlerta('Senha de acesso', retorno.data.mensagem);
          if (retorno.data.success === true) navigation.navigate('Home');
        }).finally(() => setEfetuandoRequest(false));
      }
    }
  }

  function MinhaContaMobile(){
    return(
    <React.Fragment>
    {carregando === true &&
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" />
      </View>
    }
    {carregando === false &&
      <ScrollView style={[styles ,{flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
        <Avatar.Image size={40} source={{
          uri: usuario.ApiEducar + '/Imagem/RetornaFotoPessoa?codPessoa=' + usuario.pes_codigoLogin,
          method: 'POST',
          headers: {
            Pragma: 'no-cache',
            Authorization: 'Bearer ' + usuario.tokenAuth
          }
        }} style={styles.avatar} />
        <View style={styles.conteudo}>
          <View style={{marginBottom: 15}}>
            <Title style={{fontSize: ThemeContext._currentValue.theme?.fontSize20}}>Meu Perfil de Usuário</Title>
          </View>
          {dadosUsuario !== null &&
            <Card style={styles.card}>
              <Card.Title style={{fontSize: ThemeContext._currentValue.theme?.fontSize20}} title="Dados Pessoais" />
              <Card.Content>
                <Caption style={{fontSize: ThemeContext._currentValue.theme?.fontSize12}}>Nome: <Subheading style={{fontSize: ThemeContext._currentValue.theme?.fontSize16}}>{dadosUsuario?.pes_nome}</Subheading></Caption>
                <Caption style={{fontSize: ThemeContext._currentValue.theme?.fontSize12}}>CPF: <Subheading style={{fontSize: ThemeContext._currentValue.theme?.fontSize16}}>{dadosUsuario?.pef_cpf}</Subheading></Caption>
                <Caption style={{fontSize: ThemeContext._currentValue.theme?.fontSize12}}>Data de Nascimento: <Subheading style={{fontSize: ThemeContext._currentValue.theme?.fontSize16}}>{dadosUsuario?.pef_datanascimento}</Subheading></Caption>
              </Card.Content>
            </Card>
          }
          <Card style={styles.card}>
            <Card.Title style={{ fontSize: ThemeContext._currentValue.theme?.fontSize20}} title="Alterar senha de acesso"/>
            <Card.Content>
              <View style={{justifyContent: "center"}}>
                <TextInput label="Senha atual" value={senhaAtual} onChangeText={text => setSenhaAtual(text)} secureTextEntry autoCompleteType="password" style={styles.input} />
                <TextInput label="Nova senha" value={novaSenha} onChangeText={text => setNovaSenha(text)} secureTextEntry autoCompleteType="password" style={styles.input} />
                <TextInput label="Repita a nova senha" value={confirmaNovaSenha} onChangeText={text => setConfirmaNovaSenha(text)} secureTextEntry autoCompleteType="password" style={styles.input} />
              </View>
            </Card.Content>
            <Card.Actions style={{justifyContent: "flex-end", marginRight:7, marginBottom: 20}}>
              {/* <BarPasswordStrengthDisplay width={120} levels={passwordLevels}
                password={novaSenha}
              /> */}
              <Button compact mode="contained" icon="lock" onPress={() => alterarSenhaUsuario()} disabled={efetuandoRequest} loading={efetuandoRequest} >Alterar Senha</Button>
            </Card.Actions>
          </Card>
          <Snackbar
            visible={dadosAlerta.exibir}
            style={{backgroundColor: '#B00020'}}
            onDismiss={() => setDadosAlerta({ exibir: false, texto: '' })}>
            {dadosAlerta.texto}
          </Snackbar>
        </View>
      </ScrollView>
    }
    </React.Fragment>
    )
  }

  function MinhaContaWeb(){
    return(
    <React.Fragment>
    {carregando === true &&
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" />
      </View>
    }
    {carregando === false &&
      <ScrollView style={[styles ,{flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
        <Avatar.Image size={40} source={{
          uri: usuario.ApiEducar + '/Imagem/RetornaFotoPessoa?codPessoa=' + usuario.pes_codigoLogin,
          method: 'POST',
          headers: {
            Pragma: 'no-cache',
            Authorization: 'Bearer ' + usuario.tokenAuth
          }
        }} style={styles.avatar} />
        <View style={styles.conteudo}>
          <View style={{marginBottom: 15}}>
            <Title style={{fontSize: ThemeContext._currentValue.theme?.fontSize20}}>Meu Perfil de Usuário</Title>
          </View>
          {dadosUsuario !== null &&
            <Card style={styles.card}>
              <Card.Title style={{fontSize: ThemeContext._currentValue.theme?.fontSize20}} title="Dados Pessoais" />
              <Card.Content>
                <Caption style={{fontSize: ThemeContext._currentValue.theme?.fontSize12}}>Nome: <Subheading style={{fontSize: ThemeContext._currentValue.theme?.fontSize16}}>{dadosUsuario?.pes_nome}</Subheading></Caption>
                <Caption style={{fontSize: ThemeContext._currentValue.theme?.fontSize12}}>CPF: <Subheading style={{fontSize: ThemeContext._currentValue.theme?.fontSize16}}>{dadosUsuario?.pef_cpf}</Subheading></Caption>
                <Caption style={{fontSize: ThemeContext._currentValue.theme?.fontSize12}}>Data de Nascimento: <Subheading style={{fontSize: ThemeContext._currentValue.theme?.fontSize16}}>{dadosUsuario?.pef_datanascimento}</Subheading></Caption>
              </Card.Content>
            </Card>
          }
          <Card style={[styles.card, { alignItems: 'center'}]}>
            <Card.Title style={{fontSize: ThemeContext._currentValue.theme?.fontSize20}} title="Alterar senha de acesso"/>
            <Card.Content>
              <View style={{justifyContent: "center"}}>
                <TextInput label="Senha atual" value={senhaAtual} onChangeText={text => setSenhaAtual(text)} secureTextEntry autoCompleteType="password" style={styles.input} />
                <TextInput label="Nova senha" value={novaSenha} onChangeText={text => setNovaSenha(text)} secureTextEntry autoCompleteType="password" style={styles.input} />
                <TextInput label="Repita a nova senha" value={confirmaNovaSenha} onChangeText={text => setConfirmaNovaSenha(text)} secureTextEntry autoCompleteType="password" style={styles.input} />
              </View>
            </Card.Content>
            <Card.Actions style={{justifyContent: 'center', marginLeft: 7, marginBottom: 20}}>
              {/* <BarPasswordStrengthDisplay width={120} levels={passwordLevels}
                password={novaSenha}
              /> */}
              <Button compact mode="contained" icon="lock" onPress={() => alterarSenhaUsuario()} disabled={efetuandoRequest} loading={efetuandoRequest} >Alterar Senha</Button>
            </Card.Actions>
          </Card>
          <Snackbar
            visible={dadosAlerta.exibir}
            style={{backgroundColor: '#B00020'}}
            onDismiss={() => setDadosAlerta({ exibir: false, texto: '' })}>
            {dadosAlerta.texto}
          </Snackbar>
        </View>
      </ScrollView>
    }
    </React.Fragment>
    )
  }

  return (
    (Platform.OS === "web" && Dimensions.get("screen").width >= 1024) ? MinhaContaWeb() : MinhaContaMobile()
    )

    
  }

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1, 
    justifyContent: "center", 
    alignItems: "center"
  },
  container: {
    flex: 1
  },
  avatar: {
    position: "absolute",
    right: 10,
    marginTop: 10
  },
  conteudo: {
    padding: 10
  },
  card: {
    marginBottom: 20,
  },
  input: {
    marginBottom: 8,
    borderWidth: 1,
    width: (Dimensions.get('screen').width >= 1024 ? 380 : '100%')
  }
});