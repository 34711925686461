import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function DataCardHeader(props) {
  return (
    <TouchableOpacity onPress={() => props.onPress()}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{props.titulo}</Text>
        <MaterialCommunityIcons style={styles.headerRefreshIcon} name={props.icone} size={18} color="#212020" />
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  header: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#dedede",
    height: 30,
  },
  headerTitle: {
    fontSize: 16
  },
  headerRefreshIcon: {
    position: "absolute",
    alignSelf: "flex-end",
    paddingRight: 5
  },
});