import React from 'react';
import { View, Text, StyleSheet} from 'react-native';
import { Divider } from 'react-native-paper';
import ItemListaCardapio from './ItemListaCardapio';
import {ThemeContext} from '../../../theme-context';

export default function ListaCardapio(props) {
  function renderLista(){
    return (
      <View>
        {props.dados.map((dadosCardapio, indice) => (
          <View key={indice}>
          <View style={{flexDirection:'row',}}>
            <View style={{ height: 25, width: 5,  backgroundColor: (!!dadosCardapio.listacardapio[0].tpo_cor ? '#' + dadosCardapio.listacardapio[0].tpo_cor.trim() : '#FFF')}}/>
            <Text style={{fontWeight: "bold", marginLeft: 5, marginTop:3.5, width: 300}}> {dadosCardapio.tpo_descricao} </Text>
          </View>
          {dadosCardapio.listacardapio.map((cardapio, indice) =>(
                <ItemListaCardapio dados={cardapio} indice={indice}/>
            ))}
            <Divider />
         </View>
        ))}
      </View>
    )
  }

  function renderSemDados(){
    return (
      <View style={styles.center}>
        <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>O cardápio de hoje não está disponível</Text>
      </View>
    )
  }
  return (
      <View style={styles.content}>
        {!!props.dados && props.dados.length > 0 ? renderLista() : renderSemDados()}
      </View>
  );
}

const styles = StyleSheet.create({
  
  content: {
    backgroundColor: "#FFF",
    flex: 1,
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    alignContent: 'center',
    flex: 1
  },
});