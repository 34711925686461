import React, { useState, useEffect } from 'react';
import { View, ScrollView, Picker, TouchableOpacity, StyleSheet, Dimensions, Platform } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import { Button, Text, List, ActivityIndicator, Modal, Divider, Switch, Colors } from 'react-native-paper';
import { format, parse } from 'date-fns';
import { TextInputMask } from 'react-native-masked-text';
import DateTimePicker from "react-native-modal-datetime-picker";
import _ from 'lodash';

import { useStateValue } from '../state';
import { Api, Util } from '../services';
import { ModalInformacoesFrequencia, ModalLegendaFrequencia, AlterarAluno, ModalSelecionaFilho } from '../components';
import {ThemeContext} from '../../theme-context';

const width = Dimensions.get('window').width - (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 350 : 20);
const height = (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 350 : 300);

const flexDir = Platform.OS === 'web' && Dimensions.get("window").width >= 768 ? 'row' : 'column';

export default function Frequencia(props) {

  const MODO_VISUALIZACAO = { GraficoResumo:1, Detalhamento:2 };

  const [{ usuario }, dispatch] = useStateValue();
  const [ carregando, setCarregando ] = useState(true);
  const [ alterarAluno, setAlterarAluno ] = useState(false);
  const [ modalDetalhesFrequencia, setModalDetalhesFrequencia ] = useState(false);
  const [ modalLegenda, setModalLegenda ] = useState(false);
  const [ modalFiltros, setModalFiltros ] = useState(false);
  const [ modalDataIni, setModalDataIni ] = useState(false);
  const [ modalDataFim, setModalDataFim ] = useState(false);

  const [ modoVisualizacao, setModoVisualizacao ] = useState(MODO_VISUALIZACAO.GraficoResumo)

  const [ listaComponentes, setListaComponentes ] = useState([]);
  const [ componente, setComponente ] = useState('');
  const [ informacoesComponente, setInformacoesComponente ] = useState({});
  const [ dadosFrequencia, setDadosFrequencia ] = useState({});
  const [ dataInicial, setDataInicial ] = useState(null);
  const [ dataFinal, setDataFinal ] = useState(null);
  const [ somenteFaltas, setSomenteFaltas ] = useState(false);
  const [ detalhamentoFrequencia, setDetalhamentoFrequencia ] = useState([]);

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  async function carregarListaComponenetes(){
    api.get("/ApiMobileMatricula/buscarComponentes?_pes_codigo=" + usuario.pes_codigo).then((retorno) => {
      if (retorno.status === 200) {
        setListaComponentes(retorno.data.componentes);
      }
    }, (erro) => {
      console.error(erro);
    });
  }

  async function carregarDadosFrequencia(){
    setCarregando(true);
    let url = "/ApiMobileFrequencia/buscarInfoFrequencia?_pes_codigo=" + usuario.pes_codigo;
    if (!!componente) url += "&_componente=" + componente;
    api.get(url).then((retorno) => {
      if (retorno.status === 200) {
        setDadosFrequencia(retorno.data.dadosFrequencia);
        setCarregando(false);
      }
    }, (erro) => {
      console.error(erro);
      setCarregando(false);
    });
  }

  function carregarDetalhamentoFrequencia(ignorarFiltros = false){
    setCarregando(true);
    let url = "/ApiMobileFrequencia/buscarDatasFrequencia?_pes_codigo=" + usuario.pes_codigo;
    if (!!componente) url += "&_componente=" + componente;
    if (!ignorarFiltros) {
      if (dataInicial != null) url += "&_datainicial=" + format((Platform.OS === "web" ? parse(dataInicial, 'dd/MM/yyyy', new Date()) : dataInicial), "dd/LL/yyyy");
      if (dataFinal != null) url += "&_datafinal=" + format((Platform.OS === "web" ? parse(dataFinal, 'dd/MM/yyyy', new Date()) : dataFinal), "dd/LL/yyyy");
      if (somenteFaltas != null) url += "&_somenteFaltas=" + somenteFaltas;
    }
    api.get(url).then((retorno) => {
      if (retorno.status === 200) {
        setDetalhamentoFrequencia(retorno.data.listaDataFrequencia);
        setCarregando(false);
        setModalFiltros(false);
      }
    }, (erro) => {
      console.error(erro);
      setCarregando(false);
      setModalFiltros(false);
    });
  }

  async function carregarDados() {
    await carregarListaComponenetes();
    await carregarDadosFrequencia();
  }

  function limparFiltros() {
    setDataInicial(null);
    setDataFinal(null);
    setSomenteFaltas(false);
    setModalFiltros(false);
    carregarDetalhamentoFrequencia(true);
  }

  useEffect(() => {
    carregarDados();
  }, [usuario.pes_codigo]);

  useEffect(() => {
    if (modoVisualizacao === MODO_VISUALIZACAO.GraficoResumo) carregarDadosFrequencia();
    if (modoVisualizacao === MODO_VISUALIZACAO.Detalhamento) carregarDetalhamentoFrequencia(false);
  }, [componente]);

  useEffect(() => {
    if (modoVisualizacao === MODO_VISUALIZACAO.Detalhamento) carregarDetalhamentoFrequencia(false);
  },[modoVisualizacao])

  function renderGraficoResumo() {
    return (
      <View>
        {!!dadosFrequencia && 
          <View>
            <LineChart
            data={{
              labels: [
                `P (${dadosFrequencia.porcPresenca||0}%)`, 
                `FNJ (${dadosFrequencia.porcFaltas}%)`, 
                `FJ (${dadosFrequencia.porcFaltasJustificadas}%)`, 
              ],
              datasets: [
                {
                  data: [dadosFrequencia.qtdePresencas||0, dadosFrequencia.qtdeFaltas||0, dadosFrequencia.qtdeFaltasJustificadas||0]
                }
              ]
            }}
            width={width} 
            height={height}
            
            chartConfig={{
              backgroundColor: (dadosFrequencia.qtdePresencas||0 > dadosFrequencia.qtdeFaltasJustificadas||0 + dadosFrequencia.qtdeFaltas||0) ? "#2f9404" : "#e26a00",//backgroundColor: "#e26a00",
              backgroundGradientFrom: (dadosFrequencia.qtdePresencas||0 > dadosFrequencia.qtdeFaltasJustificadas||0 + dadosFrequencia.qtdeFaltas||0) ? "#40c706" : "#fb8c00",//backgroundGradientFrom: "#fb8c00",
              backgroundGradientTo: (dadosFrequencia.qtdePresencas||0 > dadosFrequencia.qtdeFaltasJustificadas||0 + dadosFrequencia.qtdeFaltas||0) ? "#55ed13" : "#ffd399",//backgroundGradientTo: "#ffd399",
              decimalPlaces: 0, // optional, defaults to 2dp
              color: (opacity = 0.8) => `rgba(0, 0, 0, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
              style: {
                borderRadius: 16
              }
            }}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }} />
            <View>
              <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize12}}>Legenda:</Text>
              <Text>[ <Text style={{fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize12}}>P</Text> ] Presença   [ <Text style={{fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize12}}>FNJ</Text> ] Falta Não Justificada   [ <Text style={{fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize12}}>FJ</Text> ] Falta Justificada</Text>
            </View>
          </View>
        }

        {!!dadosFrequencia === false &&
          <View style={styles.containerAlertaGrafico}>
            <Text style={[styles.textoAlertaGrafico, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>Não foi possível localizar os dados de frequência do estudante para o componente curricular selecionado.</Text>
          </View>
        }

        {alterarAluno === false &&
          <View style={{marginTop: 15}}>
            <Button mode="contained" onPress={() => {
              setModoVisualizacao(MODO_VISUALIZACAO.Detalhamento);
            }}>Ver Detalhes</Button>
          </View>
        }
      </View>
    )
  }

  function renderDetalhamento() {
    return (
      <React.Fragment>
        <View style={[styles.linhaHorizontal, styles.containerModalTitulo]}>
          <Button style={styles.botao} mode="outlined" icon="filter-list" onPress={() => setModalFiltros(true)}>Mais Filtros</Button>
          <Button style={styles.botao} mode="outlined" icon="details" onPress={() => setModalLegenda(true)}>Legenda</Button>
        </View>
        <ScrollView>
          {_.orderBy(detalhamentoFrequencia, 'data').map((detalhes, indice) => (
            <View key={indice}>
              <List.Accordion style={{width: Dimensions.get("window").width - 5}}
                title={<Text style={{marginLeft: 60, fontSize: ThemeContext._currentValue.theme?.fontSize16}}>{detalhes.data}</Text>} left={() => <View style={styles.accordion}>
                  <View style={{flex: 1, flexDirection: "row", marginLeft: 5, alignSelf: "flex-end"}}>
                    {!!detalhes.frequencia && detalhes.frequencia.map((frequencia, indice) => (
                      <View style={[styles.itemFrequencia, {backgroundColor: Util.retornaCorStatus(frequencia.status)}]} key={indice}>
                        <Text style={[styles.textoStatus, {fontWeight: "bold", color: "#202021",fontSize: ThemeContext._currentValue.theme?.fontSize18}]}>{frequencia.status}</Text>
                      </View>
                    ))}
                  </View>
                </View>}>
                {!!detalhes.frequencia && detalhes.frequencia.map((frequencia, indice) => (
                  <List.Item key={indice} 
                  title={<Text style={{fontWeight: "bold", marginLeft: 20,  fontSize: ThemeContext._currentValue.theme?.fontSize16}}>{`${frequencia.periodo}  ${frequencia.componente}`}</Text>}
                  right={(props) => <TouchableOpacity style={[styles.itemFrequencia, {backgroundColor: Util.retornaCorStatus(frequencia.status)}]} onPress={() => {
                    setInformacoesComponente(frequencia);
                    setModalDetalhesFrequencia(true);
                  }}>
                    <Text style={[styles.textoStatus, {fontWeight: "bold", color: "#202021",fontSize: ThemeContext._currentValue.theme?.fontSize18}]}>{frequencia.status}</Text>
                  </TouchableOpacity>}/>
                ))}
              </List.Accordion>
              <Divider />
            </View>
          ))}
        </ScrollView>
      </React.Fragment>
    )
  }

  function renderFiltroDataInicial() {
    if (Platform.OS === "web") {
      return (
        <React.Fragment>
          <Text style={styles.label}>Data Inicial: </Text>
          <TextInputMask
            type={'custom'}
            style={[styles.webTextInput,{ height: 36, borderColor: '#202021', borderWidth: 1, fontSize: ThemeContext._currentValue.theme?.fontSize14, fontWeight: 'bold', color: ThemeContext._currentValue.theme?.textBlue }]}
            options={{
              mask: '99/99/9999'
            }}
            value={dataInicial}
            onChangeText={text => {
              setDataInicial(text);
            }} 
          /> 
        </React.Fragment>
        
      )
    }
    else {
      return (
        <Button mode="outlined" icon="date-range" uppercase={false} onPress={()=>setModalDataIni(true)}>{!!dataInicial === false ? 'Selecione a Data Inicial' : format(dataInicial, "dd/LL/yyyy")}</Button>
      )
    }
  }

  function renderFiltroDataFinal() {
    if (Platform.OS === "web") {
      return (
        <React.Fragment>
          <Text style={styles.label}>Data Final: </Text>
          <TextInputMask
            type={'custom'}
            style={[styles.webTextInput,{ height: 36, marginLeft:5, borderColor: '#202021', borderWidth: 1, fontSize: ThemeContext._currentValue.theme?.fontSize14, fontWeight: 'bold', color: ThemeContext._currentValue.theme?.textBlue }]}
            options={{
              mask: '99/99/9999'
            }}
            value={dataFinal}
            onChangeText={text => {
              setDataFinal(text);
            }}
          />
        </React.Fragment>
      )
    }
    else {
      return (
        <Button mode="outlined" icon="date-range" uppercase={false} onPress={()=>setModalDataFim(true)}>{!!dataFinal === false ? 'Selecione a Data Final' : format(dataFinal, "dd/LL/yyyy")}</Button>
      )
    }
  }

  return (
    <View style={[styles.container,{flex:1,alignItems: "center", backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
      <View style={[styles.conteudo, {alignSelf: 'center', alignItems: 'center'}]}>
        <AlterarAluno onPress={() => setAlterarAluno(true)}/>
        <Picker
          style={styles.componentePicker}
          selectedValue={componente}
        
          onValueChange={(itemValue, itemIndex) =>
            setComponente(itemValue)
          }>
          <Picker.Item label="Todos os componentes" value={''} />
          {listaComponentes.map((componente) => <Picker.Item key={componente.codigo} label={componente.descricao} value={componente.codigo} />)}
        </Picker>
        
        {modoVisualizacao === MODO_VISUALIZACAO.GraficoResumo ? renderGraficoResumo() : renderDetalhamento()}

      </View>
      
      <ModalInformacoesFrequencia visible={modalDetalhesFrequencia} dados={informacoesComponente} onDismiss={() => setModalDetalhesFrequencia(false)} />
      <ModalLegendaFrequencia visible={modalLegenda} onDismiss={() => setModalLegenda(false)} />

      <Modal visible={modalFiltros} onDismiss={() => setModalFiltros(false)} >
        <View style={[styles.containerModal,{padding: 20, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 400 : 20), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
          <View style={styles.containerTitulo}>
            <Text style={[styles.titulo, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16 }]}>Filtros</Text>
          </View>
          <View style={styles.conteudoModal}>
            <View style={styles.linhaModal}>
              <View style={{flexDirection:"column"}}>
              <View style={styles.linhaHorizontal}>{renderFiltroDataInicial()}  </View> 
              <View style={styles.linhaHorizontal}>{renderFiltroDataFinal()}</View> 
              </View>
            </View>
            <View style={styles.linhaModal}>
              <View style={styles.linhaHorizontal}>
                <Text style={[styles.label, {alignSelf: 'center', marginRight: 10, fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>Exibir apenas datas com faltas</Text>
                <Switch value={somenteFaltas} onValueChange={() => setSomenteFaltas(!somenteFaltas)}/>
              </View>
            </View>
            <View style={styles.linhaModal}>
              <View style={styles.linhaBotoesFiltro}>
                <Button mode="outlined" style={{margin: 15}} onPress={() => limparFiltros()}>Limpar Filtros</Button>
                <Button mode="contained" style={{margin: 15}} onPress={() => carregarDetalhamentoFrequencia()}>Filtrar</Button>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      {Platform.OS !== "web" &&
        <React.Fragment>
          <DateTimePicker
            isVisible={modalDataIni}
            onConfirm={(date) => {
              setDataInicial(date);
              setModalDataIni(false);
            }}
            onCancel={() => setModalDataIni(false)}
          />
          <DateTimePicker
            isVisible={modalDataFim}
            onConfirm={(date) => {
              setDataFinal(date);
              setModalDataFim(false);
            }}
            onCancel={() => setModalDataFim(false)}
          />
        </React.Fragment>
      }

      <Modal visible={carregando} style={styles.spinnerView}>
        <ActivityIndicator color={(!!!ThemeContext._currentValue.theme.dark) ? Colors.blue700 : Colors.white} size="large" />
      </Modal>
      <ModalSelecionaFilho visible={alterarAluno} onDismiss={() => setAlterarAluno(false)} />
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F5FCFF88",
  },
  container: {
    flex: 1,
    alignItems: "center"
  },
  conteudo: {
    flex: 1,
    margin: 10,
  },
  containerAlertaGrafico: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  containerTitulo: {
    alignSelf: "center",
  },
  titulo: {
    fontSize: 16,
    fontWeight: "bold",
  },
  textoAlertaGrafico: {
    fontSize: 15,
    fontWeight: "bold"
  },
  componentePicker: {
    marginVertical: 10,
    height: 44,
    marginLeft: 10,
    width: 250,
   
  },
  linhaHorizontal: {
    flexDirection: 'row',
    justifyContent: "center"
  },
  botao: { margin: 5, backgroundColor: "#FFF" },
  itemFrequencia: {
    marginRight: 2, 
    borderColor: "#2d2d2d",
    borderWidth: 1,
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  textoStatus: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#202021",
  },
  accordion: {
    flex: 1, 
    flexDirection: "row", 
    width: Dimensions.get("window").width - 70,
    alignItems: "center",
  },
  containerModal: {
    padding: 20,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 350 : 20),
    backgroundColor: "#FFF",
  },
  containerModalTitulo: {
    alignSelf: "center",
  },
  conteudoModal: {
    marginTop: 20,
  },
  linhaModal: {
    flexDirection: flexDir,
    alignItems: "center",
    justifyContent: "center"
  },
  linhaHorizontal: {
    flexDirection: 'row',
    margin: 5,
  },
  webTextInput: {
    height: 36,
    borderColor: '#202021',
    borderWidth: 1,
    fontSize: 14,
    fontWeight: 'bold',
  },
  label: {
    alignSelf: 'center',
    marginRight: 10,
  },
  linhaBotoesFiltro: {
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "row"
  }
});