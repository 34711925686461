import React, { useEffect, useRef  } from 'react';
import { View, Platform, StyleSheet, Dimensions, Linking, ScrollView } from 'react-native';
import { Modal, Text, Caption, Subheading, Button, Divider } from 'react-native-paper';
import { WebView } from 'react-native-webview';

import { Api } from '../../services';
import { useStateValue } from '../../state';
import {ThemeContext} from '../../../theme-context';

export default function ModalAviso({onDismiss, visible, dados}) {

  const [{ usuario }, dispatch] = useStateValue();
  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  let webview = useRef(null);

  useEffect(() => {
    if (visible && !!dados?.codigo) {
        api.get(`/ApiMobileNotificacoes/marcarLidaNotificacao?_codigo=${dados.codigo}&_lido=true&tipo=1`).then((retorno) => {
      }, (erro) => {
        console.log(erro);
      });
    }
  }, [visible]);

  function downloadArquivo(avs_codigo) {
    if (!!avs_codigo) {
      if (Platform.OS === 'web') window.open(`${usuario.ApiEducar}/servicoexterno/downloadAnexoAviso?avs_codigo=${avs_codigo}`, '_blank');
      else Linking.openURL(`${usuario.ApiEducar}/servicoexterno/downloadAnexoAviso?avs_codigo=${avs_codigo}`);
    }
  }

  return (
    <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={[styles.container, {padding: 20,margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30), height: '80%', backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal}]}>
      <View style={styles.containerTitulo}>
        <Text style={[styles.titulo, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{dados?.titulo}</Text>
      </View>      
        <Divider/>
        {/* <Subheading>{dados?.descricao}</Subheading> */}

        { (Platform.OS != 'web') &&
          <WebView
            originWhitelist={['*']}
            automaticallyAdjustContentInsets={false}
            ref={(ref) => { webview = ref; }}
            onShouldStartLoadWithRequest={event => {
              Linking.openURL(event.url)
              return false
            }}
            source={{ html: `<html>
              <head>
                <style>
                  body { font-size: ${ThemeContext._currentValue.theme?.fontSize14}px; text-align: justify; margin: 2 0 0 0; }
                </style>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></head>
                <body> 
                  <div style="overflow:auto;">
                    ${dados?.descricaoHTML}
                  </div>
                </body>
            </html>` }}
          />
        }
        { (Platform.OS === 'web') &&
        <ScrollView style={styles.conteudo}>
            <Text>
              <div style={{
                    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontSize: ThemeContext._currentValue.theme?.fontSize16,
                    marginBottom: 2,
                    marginTop: 2,
              }} dangerouslySetInnerHTML={{__html: dados?.descricaoHTML}}></div>
            </Text>
          </ScrollView>
        }

      <View style={styles.conteudo}>
        { !!dados?.possuiAnexo &&
          <Button mode="contained" compact icon="file-download" style={{marginVertical: 10}} onPress={() => downloadArquivo(dados?.codigoAviso)}>Baixar arquivo anexo</Button>
        }
        <Caption style={{fontSize:ThemeContext._currentValue.theme?.fontSize10}}>{`Enviado em: ${dados?.data} por: ${dados?.enviadoPor}`}</Caption>
    
        <Button mode="outlined" onPress={() => onDismiss()}>OK</Button>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30),
    height: '80%',
    backgroundColor: "#FFF"
  },
  containerTitulo: {
    alignSelf: "center",
  },
  titulo: {
    fontSize: 16,
    fontWeight: "bold"
  },
  conteudo: {
    marginTop: 10,
    marginHorizontal: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 40 : 5),
    alignContent: "center",
  },
});