import React, { useState } from 'react';
import { View, ScrollView, Text, StyleSheet, Platform, Dimensions, FlatList, Linking } from 'react-native';
import { Appbar, TextInput, Button, Modal, Divider, Searchbar, List } from 'react-native-paper';
import { Api, Util } from '../services';

import {ThemeContext} from '../../theme-context';

export default function RecuperarSenha(props) {

  const api = Api.create('https://adm.educarweb.net.br/');

  const [email, setEmail] = useState("");
  const [ modalSelecionarMunicipio, setModalSelecionarMunicipio ] = useState(false);
  const [ filtroCidade, setFiltroCidade ] = useState('');
  const [ listaCidades, setListaCidades ] = useState([]);

  function exibirAlerta(titulo, texto = "") {
    if (Platform.OS === "web") {
      alert(titulo+'\n\n'+texto);
    }
    else {
      Alert.alert(
        titulo,
        texto
      );
    }
  }

  function selecionaMunicipioRecuperacaoSenha() {
    api.get("AdmOrgaoGovernamental/ListarDisponibilizamAplicativo").then((retorno) => {
      setListaCidades(retorno.data.result);
      setModalSelecionarMunicipio(true);
    }, (erro) => {
      console.error(erro);
    });
  }

  function solicitarRecuperacaoSenha() {
    if (!!email && Util.emailValido(email)) {
      api.get('AbsUsuario/EnviarLinkRedefinirSenha/' + email).then((retorno) => {
        if (retorno.data.success) exibirAlerta('Atenção', 'Verifique sua caixa de entrada para definição de uma nova senha de acesso.');
        else selecionaMunicipioRecuperacaoSenha();
      }, (erro) => {
        exibirAlerta('Erro ao enviar o link de redefinição de senha para o e-mail ' + email);
      });
    }
    else exibirAlerta('Você deve informar seu e-mail para recuperar sua senha.');
  }

  return (
    <React.Fragment>
      <Appbar.Header>
        <Appbar.BackAction
          onPress={ () => {
            props.navigation.navigate('Login');
          } }
        />
        <Appbar.Content title="Recuperar Senha" />
      </Appbar.Header>
      <View style={[styles.container, {flex: 1, justifyContent: "center", backgroundColor: ThemeContext._currentValue.theme?.backgroundColorLogin}]}>
        <View style={Platform.OS === 'web' ? {maxWidth: 600, alignSelf: "center"} : null}>
          <View style={[styles.cardContainer, {margin: 15, padding: 10, backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal , elevation: 4, }]}>
            <View style={styles.containerInstrucoes}>
              <Text style={{textAlign: "justify", color: ThemeContext._currentValue.theme?.textWhite, fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>Preencha no campo abaixo seu e-mail de acesso ao sistema e clique em recuperar senha. Dentro de alguns instantes você receberá um e-mail com as instruções para definição de uma nova senha de acesso.</Text>
              <Text style={{textAlign: "justify", color: ThemeContext._currentValue.theme?.textWhite, fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>Caso não estiver encontrando este e-mail de redefinição de senha em sua caixa de entrada, verifique também nos spams pois infelizmente às vezes nossos e-mails automatizados podem cair nesta classificação.</Text>
            </View>
            <TextInput label='E-mail' style={styles.input} value={email} onChangeText={setEmail} autoCapitalize="none" autoCompleteType="email" returnKeyType="next" autoCorrect={false} keyboardType="email-address" />
            <Button style={{margin: 15}} accessibilityLabel="Botão Recuperar Senha" icon="mail" mode="contained" onPress={() => solicitarRecuperacaoSenha()}>
              Recuperar Senha
            </Button>
            <View style={{margin: 20, textAlign: "center"}}>
              <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite}}>OU</Text>
            </View>
            <Button style={{margin: 15}} accessibilityLabel="Botão Recuperar Senha por CPF ou Login Educar" icon="verified-user" mode="contained" onPress={() => selecionaMunicipioRecuperacaoSenha()}>
              Recuperar Senha com CPF ou Login Educar
            </Button>
          </View>
        </View>
      </View>
      <Modal contentContainerStyle={[styles.containerModal, {padding: 15, height: Dimensions.get('window').height - 80, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 160 : 30), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal}]} visible={modalSelecionarMunicipio} onDismiss={() => {
        setModalSelecionarMunicipio(false);
      }}>
        <View style={[styles.containerTitulo, {alignSelf: "center", backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal}]}>
          <Text style={styles.titulo}>Selecione o seu Município</Text>
        </View>
        <Searchbar style={styles.searchBar} placeholder="Localizar Cidade" autoCapitalize="none" autoCorrect={false} returnKeyLabel="search" value={filtroCidade} onChangeText={setFiltroCidade} />
        <ScrollView>
          <FlatList data={_.filter(listaCidades, function(o) {
            return o.nome.toLowerCase().indexOf(filtroCidade.toLowerCase()) > -1;
          })} renderItem={({item, index}) => (
            <React.Fragment>
              <List.Item
                style={styles.searchBar}
                title={item.nome}
                right={props => <List.Icon {...props} icon="arrow-forward" />}
                onPress={() => {
                  Linking.openURL(`${item.url}/Login/RecuperarSenha?email=${email}`);
                }}
              />
              <Divider />
            </React.Fragment>
          )} keyExtractor={item => item.id}/>
        </ScrollView>
      </Modal>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#438eb9',
    justifyContent: "center",
  },
  cardContainer: {
    margin: 15,
    padding: 10,
    backgroundColor: '#FFF',
    elevation: 4
  },
  containerModal: {
    padding: 15,
    height: Dimensions.get('window').height - 80,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 160 : 30),
    backgroundColor: "#FFF",
  },
  containerTitulo: {
    alignSelf: "center",
  },
  containerInstrucoes: {
    margin: 15,
  }

})