import React, { useEffect, useState, useRef } from 'react';
import { TextInput, Animated,FlatList, View, ScrollView, Text, StyleSheet, Platform, Dimensions, Alert } from 'react-native';
import { Modal, IconButton, Title, ActivityIndicator, Button, Paragraph, Divider } from 'react-native-paper';
import { WebView } from 'react-native-webview';
import { format } from 'date-fns';
import MensagemChat from './MensagemChat';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';

import { useInterval } from '../../hooks'
import { useStateValue } from '../../state';
import { Api } from '../../services';
import {ThemeContext} from '../../../theme-context';

export default function ModalChat({ dadosChat, fecharModal }) {

  const [{ usuario, estudante }, dispatch] = useStateValue();

  const NOTIFICACOES_POR_PAGINA = (Platform.OS === "web" ? parseInt(Math.round(Dimensions.get("window").height / 100)) : 10);
  const [ numeroPagina, setNumeroPagina ] = useState(0);

  const inputMsg = useRef(null);
  const areaMsgs = useRef(null);
  const [ carregando, setCarregando ] = useState(false);
  const [ enviando, setEnviando ] = useState(false);
  const [ mensagens, setMensagens ] = useState([]);
  const [ mensagem, setMensagem ] = useState('');
  const [ tempoAtualizacaoMensagens, setTempoAtualizacaoMensagens ] = useState(5000);
  const [ horaBuscaMensagemApagada, setHoraBuscaMensagemApagada ] = useState('');
  const [ coresRemetente, setCoresRemetente ] = useState([]);
  const [ ultimaMensagemVisualizada, setUltimaMensagemVisualizada ] = useState(0);
  
  const [ modalVisualizarAnexo, setModalVisualizarAnexo ] = useState(false);
  const [ carregandoPreVisualizacao, setCarregandoPreVisualizacao ] = useState(false);
  const [ carregandoPreVisualizacaoErro, setCarregandoPreVisualizacaoErro ] = useState(true);
  const [ linkPreVisualizarAnexo, setLinkPreVisualizarAnexo ] = useState({});
  const [ mensagemArquivoVisualizar, setMensagemArquivoVisualizar ] = useState('');

  const [ textInputMessageHeight, setTextInputMessageHeight ] = useState(0);
  
  const [ arquivoAnexo, setArquivoAnexo ] = useState(null);
  
  let webViewRef = useRef();

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
  
  let iframeEl = useRef(null);
  var temporizador = null;

  useEffect(() => {
    setCoresRemetente(['#0000FF', '#FF0000', '#008000', '#FFA500', '#000000', '#FFFF00', '#00FFFF', '#8B008B', '#B87333', '#2F4F4F', '#FF007F', '#738678', '#008080']);
    setUltimaMensagemVisualizada(0);
    setMensagens([]);
    setNumeroPagina(0);
    if (!!dadosChat) {
      setMensagens([]);
      carregarMensagens(true);
      setTempoAtualizacaoMensagens(25000);
    }
    else {
      setTempoAtualizacaoMensagens(null);
    };
  }, [dadosChat]);

  useEffect(() => {
    if (!!arquivoAnexo) {
      enviarMensagem();
    }
  }, [arquivoAnexo]);

  useInterval(()=>carregarMensagens(false), tempoAtualizacaoMensagens);

  function exibirAlerta(titulo, texto) {
    if (Platform.OS === "web") {
      alert(titulo+'\n\n'+texto);
    }
    else {
      Alert.alert(
        titulo,
        texto
      );
    }
  }

  function carregarMensagens(paginar) {
   
    if (!!dadosChat?.chg_codigo) {
      if (mensagens.length === 0) setCarregando(true);
      
      let url = `/ApiMobileMensagens/listarMensagensGrupo?chg_codigo=${dadosChat.chg_codigo}
      &codigoUltimaMensagemVisualizada=${(paginar)? 0 : (ultimaMensagemVisualizada||0)}
        &horaMensagemApagada=${(horaBuscaMensagemApagada == '')? '':format(new Date(horaBuscaMensagemApagada), 'dd/LL/yyyy H:mm:ss')}&limit=${(paginar)?NOTIFICACOES_POR_PAGINA:null}
        &offset=${(paginar)?(NOTIFICACOES_POR_PAGINA * numeroPagina):null}`;

      api.get(url).then(res => {
        if (res.status === 200 && res.data.success === true) {
          setHoraBuscaMensagemApagada(Date.now());

          if (res.data.mensagens.length > 0){
            if(paginar && res.data.mensagens.length >= NOTIFICACOES_POR_PAGINA)
              setNumeroPagina(numeroPagina+1);

            res.data.mensagens
            .sort((a, b) => a.chn_codigo > b.chn_codigo ? -1 : 1)
            .map(msg => {
              
              if(msg.codigoremetente !== usuario.pes_codigoLogin){
                let cor = res.data.mensagens.find((m) => {
                  return m.codigoremetente === msg.codigoremetente && m.cor !== undefined;
                });

                if(cor === undefined){
                  cor = mensagens.find((m) => {
                    return m.codigoremetente === msg.codigoremetente;
                  });
                }

                if(cor === undefined){
                  msg.cor = coresRemetente[0]; 
                  coresRemetente.splice(0,1); 
                  setCoresRemetente(coresRemetente);
                }else{
                  msg.cor = cor.cor;
                }
              }
            });

            var ids = new Set(mensagens.map(d => d.chn_codigo));
            var merged = [...mensagens, ...res.data.mensagens.filter(d => !ids.has(d.chn_codigo))];
            merged = merged.sort((a, b) => a.chn_codigo > b.chn_codigo ? -1 : 1);

            setMensagens(merged);
           
            var ultimaMensagemVisualizada = merged.reduce(function(prev, current) {
              if (+current.chn_codigo > +prev.chn_codigo) {
                  return current;
              } else {
                  return prev;
              }
          });
 
          setUltimaMensagemVisualizada(ultimaMensagemVisualizada.chn_codigo);

            api.post(`/ApiMobileMensagens/marcarMensagemComoLida`, {
              chn_codigo: ultimaMensagemVisualizada.chn_codigo,
              pgp_remetente: estudante.parentesco
            });
            
          }

          if (res.data.mensagensApagadas.length > 0){
            res.data.mensagensApagadas.map(msg => {
              let msgApagada = mensagens.find((m) => {
                return m.chn_codigo === msg.chn_codigo;
              });

              if(msgApagada !== undefined){
                msgApagada.chn_mensagem = '';
                msgApagada.mensagemapagada = true;
              }
            });
          }
        }
      }, erro => {
        console.log('ERRO ao carregar mensagens', erro);
      }).finally(() => {
        setCarregando(false);
      });
    }
  }

  function enviarMensagem() {
    if (!!mensagem || arquivoAnexo !== null) {
      setEnviando(true);
      
      let dadosEnviarMensagem = new FormData();
      dadosEnviarMensagem.append('mensagem', mensagem);
      dadosEnviarMensagem.append('chg_codigo', dadosChat.chg_codigo||0);
      dadosEnviarMensagem.append('dpf_codigo', dadosChat.dpf_codigo||0,);
      dadosEnviarMensagem.append('pgp_remetente', estudante.parentesco);

      if (arquivoAnexo !== null) {
        if (Platform.OS === "web") dadosEnviarMensagem.append('documento', arquivoAnexo);
        else dadosEnviarMensagem.append('documento', { uri: arquivoAnexo.uri, name: arquivoAnexo.name, type: 'application/octet-stream'});
      }

      let api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
      api.defaults.headers["Content-Type"] = "multipart/form-data";

      api.post('/ApiMobileMensagens/enviarMensagemGrupo', dadosEnviarMensagem).then(res => {
        setMensagem('');
        if (!res.data.success) exibirAlerta("Erro", res.data.mensagem);
        else {
         if(res.data.grupo > 0) dadosChat.chg_codigo = res.data.grupo;

          carregarMensagens(false);
        }
      }, erro => {
        exibirAlerta('ERRO', 'Não foi possível realizar o envio da mensagem, tente novamente mais tarde.');
        console.error('ERRO ao enviar a mensagem', erro);
      }).finally(() => {
        setEnviando(false);
        setTextInputMessageHeight(0);
        inputMsg.current.focus();
      });
    }
  }

  function anexarArquivo(){
    if (Platform.OS === "web") {
      var input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => {
        debugger;
        var file = e.target.files[0];
        setArquivoAnexo(file);
      }
      input.click();
    }
    else {
      DocumentPicker.getDocumentAsync({type: '*/*'}).then((retorno) =>{
        if (Platform.OS === "web") setArquivoAnexo(retorno.file);
        else setArquivoAnexo(retorno);
      }, (erro) => {
        console.log('erro',erro);
      });
    }
  }

  
  function visualizarAnexo(mensagem) {
    if(!!mensagem.arq_codigo) {
        setEnviando(true);
        api.post('Abs_arquivos/getUrlDownloadArquivo', {"arq_codigo":mensagem.arq_codigo}).then((retorno) => {
          if (retorno.status === 200){
            let formatos_nao_suportados = [".dot", ".mht", ".mhtml", ".xml", ".wps", ".wpd", ".xltx", ".xltm", ".xlam", ".xlm", ".xla", ".xlt", ".xll", ".xlw", ".prn", ".csv",
              ".mdb", ".mde", ".accdb", ".accde", ".dbc", ".igy", ".dqy", ".rqy", ".oqy", ".cub", ".uxdc", ".dbf", ".slk", ".dif", ".xlk", ".bak", ".xlb", ".pot", ".ppa", ".odp", ".thmx"];
            let mimetype = retorno.data.mimetype;
            let src = '';
            let height = (Dimensions.get('window').height - (Dimensions.get('window').height * 0.30));
            let arquivoZip = (!!retorno.data.zip) ? retorno.data.zip : false;
            let nomeArquivo = retorno.data.name;
            let extencao = `.${retorno.data.extencao || nomeArquivo.split('.')[1]}`;
            let suportado = (formatos_nao_suportados.indexOf(extencao) == -1);

            if (mimetype.match(`image\/.*`)|| extencao.toLowerCase().match('(.*\.png)|(.*\.jpeg)|(.*\.jpg)|(.*\.gif)')) {//frame de imagens
              src = `data:text/html;charset=utf-8,` +
                   encodeURI(`<div style="text-align: center; position: absolute; top: 50%; left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);">
                          <img src="${retorno.data.url}" style="justify-content: center;align-items: center;height: auto; max-height: 100vh;width:auto; max-width:100vw;"></div>`);
            } else if (mimetype.match(`video\/.*`)  || extencao.match('(.*\.mp4)')) {//player de video
              src = `data:text/html;charset=utf-8,` +
                      encodeURI(`<div style="text-align: center; position: absolute; top: 50%; left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);">
                          <video style="justify-content: center;align-items: center;height:376px; max-height: 100vh;width:652px; max-width:100vw;" controls ><source src="${retorno.data.url}" type="${(extencao.match('(.*\.mp4)') ? 'video/mp4':mimetype)}"></video></div>`);
              height = 396;
            } else if (mimetype.match(`audio\/.*`)) {//player de audio
              src = `data:text/html;charset=utf-8,` +
                      encodeURI(`<div style="text-align: center; position: absolute; top: 50%; left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);height: 50;width: 600;">
                          <audio style="justify-content: center;align-items: center;height: 100%; max-height: 100%;width:100%; max-width:100%;" controls><source src="${retorno.data.url}" type="${mimetype}"></audio></div>`);
              height = 75;
            } else if (mimetype.match(`.*\/(msword.*)|(octet-stream.*)|(vnd.*)`) && suportado && !(mimetype.match(`(.*\/zip.*)|(.*\/x-zip.*)|(.*\/rar.*)`) || nomeArquivo.match(`(.*\.rar)|(.*\.zip)|(.*\.exe)`)) || (nomeArquivo.match(`(.*\.rar)|(.*\.zip)`) && arquivoZip)) {
              var url = encodeURIComponent(retorno.data.url);//arquivos do pacote office
              src = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;
            } else if (mimetype.match(`.*\/html`)) {
              src = (retorno.data.url);
            } else if ((!(mimetype.match(`(.*\/zip.*)|(.*\/x-zip.*)|(.*\/rar.*)`) || nomeArquivo.match(`(.*\.rar)|(.*\.zip)|(.*\.exe)`)) || (nomeArquivo.match(`(.*\.rar)|(.*\.zip)`) && arquivoZip)) && suportado) {//google docs pra abrir pdf
              var url = encodeURIComponent(retorno.data.url);
              src = `https://docs.google.com/viewer?url=${url}&embedded=true`;
            } else {
              src = null;
            }
            
            setLinkPreVisualizarAnexo({"src":src, "height":height, url: encodeURIComponent(retorno.data.url), "visualizandoAnexo":true});
            setEnviando(false);

            if(src == null) {
              exibirAlerta("", "Visualização indisponível para este arquivo.");
            } else {
              //{Platform.OS == "web" && setCarregandoPreVisualizacao(true);}
              setCarregandoPreVisualizacao(false);
              setModalVisualizarAnexo(true);
            }
          }
          if (retorno.status !== 200) {
            setEnviando(false);
          }
        }, (erro) => {
          setEnviando(false);
        });
    } else {
      exibirAlerta("", "Visualização indisponível para este arquivo.");
    }
  }

  return (
    <>
    <Divider />

    <Modal contentContainerStyle={/*styles.containerModalMensagens,*/ {flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }} visible={!!dadosChat} onDismiss={fecharModal}>
      
      {/* <View style={styles.containerTitleChat}>
        <View style={{flex: 1}}>
          <Title style={styles.titleChat} numberOfLines={3}>Grupo: {dadosChat?.dpf_descricao}</Title>
        </View>
        <View style={{width: 36, alignItems: 'flex-end'}}>
          <IconButton style={{margin: 0, padding: 0, backgroundColor: '#ccc', borderRadius: 20}} icon="close" size={18} onPress={fecharModal} />
        </View>
      </View> */}

      <View style={{ backgroundColor: "#438eb9", height: 42, flexDirection: 'row', alignItems: 'center'}}>
        <View style={{width: 36, alignItems: 'flex-start'}}>
          <IconButton color="#FFF" icon="arrow-back" size={26} onPress={fecharModal} />
        </View>
        <View style={{flex: 1, marginLeft: 5}}>
          <Title style={{
            fontSize:  Platform.OS != "web" ? 11 : 
              Dimensions.get('window').width >= 1024 ? 20 
              : Dimensions.get('window').width >= 800 ? 15 
              : 11,  
            lineHeight:  Platform.OS == "web" ? "normal" : 11,
            alignItems: 'center' ,
            color: "#FFF" }} numberOfLines={3}>Grupo: {dadosChat?.dpf_descricao}</Title>
        </View>
      </View>

      <Divider />

     

      <FlatList data={mensagens} style={{marginLeft: 5, marginRight: 5}}
      renderItem={({item, indice}) => (
          <MensagemChat key={indice} mensagem={item} visualizarAnexo={visualizarAnexo} />
        )}
        keyExtractor={item => item.chn_codigo.toString()}
        inverted
        onEndReachedThreshold={0.5} 
        onEndReached={() => carregarMensagens(true)}/>

        {/*<ScrollView ref={areaMsgs} onContentSizeChange={(contentWidth, contentHeight)=>{        
          areaMsgs.current.scrollToEnd({animated: true});
        }}>
          {carregando === true &&
            <View style={{marginTop: 20, alignItems: 'center', justifyContent: 'center'}}>
              <ActivityIndicator size="large" color="#0a83bf" />
            </View>
          }


          {mensagens.map((mensagem, indice) => <MensagemChat key={indice.toString()} mensagem={mensagem} />)}
          
          
          {carregando === false && mensagens.length === 0 &&
            <View style={{marginTop: 20, alignItems: 'center', justifyContent: 'center'}}>
              <View style={{borderColor: '#ccc', borderWidth: 1, minWidth: 120, backgroundColor: '#fefefe', padding: 10, borderRadius: 6}}>
                <Text style={{fontWeight: '500'}}>Inicie uma conversa em grupo, sobre: {dadosChat?.dpf_descricao}</Text>
              </View>
            </View>
          }

        </ScrollView>*/}
      
      {/*<View style={{height: 65, flexDirection: 'row', marginLeft: 5, marginBottom: 10}}>*/}

      <Animated.View style={{ marginBottom: 0 }}>
      <View style={{
        flexDirection: 'row',
        //paddingHorizontal: 10,
        //paddingVertical: 5,
        paddingLeft: 10,
        paddingRight: 5,
        paddingBottom: 5,
        //borderTopWidth: 1,
        //borderTopColor: "#f5f5f5"
      }}>
        <View style={styles.textInputView}>
          <TextInput
              ref={inputMsg} 
              editable={true}
              multiline={true}
              placeholder="Digite uma mensagem"
              placeholderTextColor={"grey"}
              //placeholderStyle={styles.placeholderStyle}
              //underlineColorAndroid='transparent'
              keyboardType='default'
              value={mensagem}
              onChangeText={text => {
                setMensagem(text);
                setTextInputMessageHeight(text.split('\n').length);
              }}
              style={[styles.textInputStyle, {
                  height: Math.min(120, Math.max(35, (textInputMessageHeight*20))),
                  backgroundColor: "#ccc",
                  color: "#000"
              }]}
          />
        </View>
        {/*<View style={{flex:1}}>
          <TextInput selectionColor="#438eb9" 
            style={{borderRadius:20}}
            ref={inputMsg} 
            value={mensagem} 
            autoFocus 
            autoCorrect 
            returnKeyType="send" 
            onSubmitEditing={() => enviarMensagem()} 
            placeholder="Escreva sua mensagem aqui..." 
            onChangeText={setMensagem} 
            mode="outlined"
          />
        </View>
         <View style={{flexDirection: 'column', marginRight: 5}}>
          <View style={{width: 38, justifyContent: 'flex-end', flex: 1}}>
            <IconButton style={{justifyContent: 'center'}} disabled={enviando} icon="attach-file" size={32} onPress={() => anexarArquivo()} />
          </View>
        </View>*/}
        <View style={{marginRight: 5, flexDirection: 'column'}}>
          <View style={{width: 36, 
            height: 36, 
            flexDirection: 'column', 
            justifyContent: 'flex-end', 
            flex: 1}}>
            
            <View style={{backgroundColor: "#4186c3", 
                borderRadius: 20, 
                height: 36, 
                flexDirection: 'row', 
                justifyContent: 'center'
                }}>
              
              <IconButton style={{margin: 0, 
              paddingTop: 5, 
              justifyContent: 'center'}} 
                color="#fff" disabled={enviando} icon="attach-file" size={26} onPress={() => anexarArquivo()} />
            </View>
          </View>
        </View>
        <View style={{marginRight: 5, flexDirection: 'column'}}>
          <View style={{width: 36, 
            height: 36, 
            flexDirection: 'column', 
            justifyContent: 'flex-end', 
            flex: 1}}>
            
            <View style={{backgroundColor: "#4186c3", 
                borderRadius: 20, 
                height: 36, 
                flexDirection: 'row', 
                justifyContent: 'center'
                }}>
              <IconButton style={{margin: 0, paddingTop: 5, justifyContent: 'center'}} 
                color="#fff" disabled={enviando} icon="send" size={26} onPress={() => enviarMensagem()} />
            </View>
          </View>
        </View>
      </View>
      </Animated.View>
    </Modal>
  
    <Modal contentContainerStyle={[styles.containerModalVisualizar, {backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal}]} visible={modalVisualizarAnexo}
          onDismiss={() => {
            clearInterval(temporizador);
            setModalVisualizarAnexo(false)}
          }>
          <Paragraph>{mensagem.arq_nome}</Paragraph>
          <ScrollView>
              {(!!linkPreVisualizarAnexo.src) && Platform.OS != "web" &&
              <View style={{ flex: 1 }}>
                  <WebView
                    originWhitelist={['*']}
                    height={linkPreVisualizarAnexo.height}
                    source={(linkPreVisualizarAnexo.visualizandoAnexo)? {uri: linkPreVisualizarAnexo.src} : {html: linkPreVisualizarAnexo.src}}
                    onLoadStart={() => {
                      setCarregandoPreVisualizacaoErro(false);
                    }}
                    onLoad={() => {
                      debugger;
                      if(carregandoPreVisualizacaoErro == false) {
                        clearInterval(temporizador);
                        
                        setCarregandoPreVisualizacao(false);
                      } else {
                        setCarregandoPreVisualizacao(true);
                        webViewRef.current.reload();
                      }
                    }}
                    onLoadEnd={(e) => {
                      setCarregandoPreVisualizacaoErro(true);
                    }}
                    startInLoadingState={true}
                    ref={(ref) => (webViewRef.current = ref)}
                    saveFormDataDisabled={true}
                    />

                      {carregandoPreVisualizacao && (
                        <ActivityIndicator size="small" color="#0a83bf"
                          style={{ position: "absolute",
                                  top: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.70)),
                                  left:(Dimensions.get('window').width / 2.5)
                                }} />
                      )}
                  </View>
              }

              {(!!linkPreVisualizarAnexo.src) && Platform.OS == "web" &&
                <View style={{ flex: 1 }}>
                  <iframe ref={iframeEl}
                      id="idPreVisualizarAnexo"
                      onLoad={() => {
                        clearInterval(temporizador);
                        setCarregandoPreVisualizacao(false);
                        
                      }}
                      frameBorder="0"
                      title={mensagem?.arq_nome}
                      height={linkPreVisualizarAnexo.height}
                      src={linkPreVisualizarAnexo.src}>
                  </iframe>
                  {carregandoPreVisualizacao && (
                    <ActivityIndicator
                      style={{
                        top: (linkPreVisualizarAnexo.height - (linkPreVisualizarAnexo.height * 2))
                      }}
                      size="large"
                    />
                  )}
                </View>
              }

            <View>
              <Button mode="contained" onPress={() => {clearInterval(temporizador); setModalVisualizarAnexo(false)}}>Fechar</Button>
            </View>
          </ScrollView>
        </Modal>
      </>
  );
}

const styles = StyleSheet.create({
  containerModalMensagens: {
    padding: 10,
    margin: 10,
    flex: 1,
    backgroundColor: "#FFF",
  },
  containerTitleChat:{
    height: 42, 
    flexDirection: 'row'
  },
  titleChat: {
    fontSize:  Platform.OS != "web" ? 11 : Dimensions.get('window').width >= 1024 ? 20 : 11, 
    lineHeight: 12
  },
  textInputView: {
    flex: 1,
    minHeight: 40,
    marginRight: 15,
    justifyContent: 'flex-end',
  },
  textInputStyle: {
    fontSize: 14,
    //overflow: 'hidden',
    //justifyContent: 'center',
    //alignItems: 'center',
    //flexWrap: 'wrap',
    paddingLeft: 10,
    paddingTop: 8,
    textAlign: 'left',
    borderRadius: 20,
  },
  placeholderStyle: {
    fontSize: 12,
    textAlignVertical: 'center'
  }
});