import React, { useState, useEffect, useRef } from 'react';
import { View } from 'react-native';
import { WebView } from 'react-native-webview';

import {ThemeContext} from '../../../theme-context';

export default function WebViewEducar({conteudoHTML}) {
    const [ webViewHeight, setWebViewHeight] = useState(0);
    let webview = useRef(null);
  
    return (
      <View style={{flex: 0}}>
            <WebView
              originWhitelist={['*']}
              ref={(ref) => { webview = ref; }}
              automaticallyAdjustContentInsets={false}
              style={{ height: webViewHeight, backgroundColor: '#e9e9ef' }}
              onMessage={(e) => {
                let height = Number(e.nativeEvent.data);
                setWebViewHeight(height);
              }}
              injectedJavaScript='window.ReactNativeWebView.postMessage(document.body.scrollHeight)'
              source={{ html: `<html>
                <head>
                  <style>
                    body {  font-size: ${ThemeContext._currentValue.theme?.fontSize14}px; 
                            text-align: justify; 
                            margin: 2 0 0 25; 
                            padding: 0;
                          }
                  </style>
                  <meta http-equiv="content-type" content="text/html; charset=utf-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                </head>
                <body> 
                    <div>
                      ${conteudoHTML}
                    </div>
                </body>
              </html>` }}
            />
      </View>
    );
  }