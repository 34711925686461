import React from 'react';
import { View, Platform, StyleSheet, Dimensions } from 'react-native';
import { Modal, Text, Button } from 'react-native-paper';
import {themes, ThemeContext} from '../../../theme-context';

export default function ModalInformacoesFrequencia(props) {
  return (
    <Modal visible={props.visible} onDismiss={props.onDismiss} contentContainerStyle={[styles.container,{ padding: 20, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
      <View style={styles.containerTitulo}>
        <Text style={[styles.titulo, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>Registro de Frequência</Text>
      </View>
      <View style={styles.conteudo}>
        <Text style={[styles.texto, {marginTop: 8, fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>Componente: <Text style={[styles.textoDestaque,{fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{props.dados.componente}</Text></Text>
        <Text style={[styles.texto, {marginTop: 8, fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>Professor: <Text style={[styles.textoDestaque,{fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{props.dados.professor}</Text></Text>
        <Text style={[styles.texto, {marginTop: 8, fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>Período: <Text style={[styles.textoDestaque,{fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{props.dados.periodo}</Text></Text>
        <Text style={[styles.texto, {marginTop: 8, fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>Turno: <Text style={[styles.textoDestaque,{fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{props.dados.turno}</Text></Text>
        <Text style={[styles.texto, {marginTop: 8, fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>Status: <Text style={[styles.textoDestaque,{fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{(props.dados.status == "P " || props.dados.status == "P") ? "Presença" : (props.dados.status == "F " || props.dados.status == "F") ? "Falta" : "Não informado"}</Text></Text>
      </View>
      <View style={styles.conteudo}>
        <Button mode="outlined" onPress={() => props.onDismiss()}>Fechar</Button>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30),
    backgroundColor: "#FFF",
  },
  containerTitulo: {
    alignSelf: "center",
  },
  titulo: {
    fontSize: 16,
    fontWeight: "bold",
  },
  conteudo: {
    marginTop: 20,
    marginHorizontal: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 40 : 5),
  },
  texto: {
    marginTop: 8,
    fontSize: 15,
  },
  textoDestaque: {
    fontSize: 16,
    fontWeight: "bold",
  },
});