import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, StyleSheet, Platform, Alert, Linking, PermissionsAndroid } from 'react-native';
import { IconButton, Button, Divider, List, ActivityIndicator, Modal, Headline } from 'react-native-paper';

import * as Permissions from 'expo-permissions';
import { format } from 'date-fns';
import { useStateValue } from '../state';
import _, { trimStart } from 'lodash';
import { Api } from '../services';
import RNFetchBlob from './RNFetchBlob';

import {ThemeContext} from '../../theme-context';
import { block } from 'react-native-reanimated';

export default function MeusBoletins({ navigation }) {
    const [{ usuario, estudante }, dispatch] = useStateValue();
    const [ carregando, setCarregando ] = useState(true);
    const [ listaBoletins, setListaBoletins ] = useState([]);
    const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

    useEffect(() => {
      api.get("/ApiMobileNotas/listaBoletinsEstudante?_pes_codigo=" + usuario.pes_codigo + "&_mtr_codigo=" + estudante.matricula + "&sistemaAtualizado=true").then((retorno) => {
          setCarregando(false);
          if (retorno.status === 200) {
            setListaBoletins(retorno.data.listaMeusBoletins);
          }
        }, (erro) => {
          setCarregando(false);
        });
      }, []);

    function exibirAlerta(titulo, texto) {
      if (Platform.OS === "web") {
        alert(titulo+'\n\n'+texto);
      }
      else {
        Alert.alert(
          titulo,
          texto
        );
      }
    }
      
    async function downloadDocumento(dados) {
    if (Platform.OS === 'web') 
        window.open(`${usuario.ApiEducar}/servicoexterno/downloadArquivo?_arq_codigo=${dados.arq_codigo}`, '_blank');
    else if(Platform.OS === 'ios') Linking.openURL(`${usuario.ApiEducar}/servicoexterno/downloadArquivo?_arq_codigo=${dados.arq_codigo}`);
    else {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
          {
            title: 'Permissão para armazenamento',
            message:'O aplicativo precisa de permissão para baixar seu boletim',
            buttonPositive: 'OK',
          }
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          let nomeArquivoDownload = `educarweb_boletim_${dados.prl_descricao}.pdf`;

          const { dirs } = RNFetchBlob.fs;
          RNFetchBlob.config({
            fileCache: true,
            addAndroidDownloads: {
              overwrite: true,
              useDownloadManager: true,
              notification: true,
              mediaScannable: true,
              description: `App - EducarWeb`,
              title: nomeArquivoDownload,
              path: `${dirs.DownloadDir}/${nomeArquivoDownload}`,
            },
            appendExt: dados.dpf_extencaoanexo
          })
            .fetch('GET', `${usuario.ApiEducar}/servicoexterno/downloadArquivo?_arq_codigo=${dados.arq_codigo}`)
            .then((res) => {
              exibirAlerta("Download concluído.", `Seu boletim do ${dados.prl_descricao} foi concluído.`);
            })
        } else {
          alert('Permissão para armazenamento negada pelo usuário');
        }
      } catch (err) {
        console.log("ERROR com o RNFetchBlob: ", err);
        Linking.openURL(`${usuario.ApiEducar}/servicoexterno/downloadArquivo?_arq_codigo=${dados.arq_codigo}`);
      }
    }
  }

    return (
        <View style={[styles.container,{flex:1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor } ]}>
          
            {listaBoletins.length === 0 &&
              <View style={styles.centralizar}>
                <Text style={{fontSize: 15,textAlign: 'center',  fontWeight: 'bold', fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }}>Você ainda não possui boletins disponibilizados para sua consulta.</Text>
              </View>
            }
          {listaBoletins.length > 0  &&
            <React.Fragment>
                <View style={styles.headTurma}>
                <Headline style={{fontSize: ThemeContext._currentValue.theme?.fontSize24}} >{estudante.turma}</Headline>
            </View>
            <Divider />
              <ScrollView>
              <View style={{margin: 10}}>
                  <ScrollView>
                    {listaBoletins.map((periodo, indice) => (
                      <React.Fragment key={indice}>
                        <View style={styles.conteudoAcoes}>
                            <Text style={[styles.texto,{fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }]}>{periodo.prl_descricao}</Text>                            
                            {(periodo?.pode_baixar) &&
                                <Button style={styles.botaoAcao} icon="cloud-download" compact mode="contained" onPress={() => downloadDocumento(periodo)}>Download</Button>
                            }
                            {(!periodo?.pode_baixar) &&
                                <Text style={[styles.texto,{fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }]}> (Estará disponível a partir de {format(new Date(periodo?.dst_datadisponivelapp), 'dd/LL/yyyy')}) </Text>
                            }
                        </View>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </ScrollView>
              </View>
              </ScrollView>
            </React.Fragment>
          }
          <Modal visible={carregando}>
            <ActivityIndicator color="#FFF" size="large" />
          </Modal>
        </View>
      );
}

const styles = StyleSheet.create({
    container: {
      flex: 1
    },
    containerCentralizado: {
      flex: 1,
      alignContent: "center",
      justifyContent: "center",
    },
    headTurma:{
        fontSize: 8,
        fontWeight: "bold",
        marginLeft: 10
    },
    texto: {
      fontSize: 15,
      flexDirection: "row",
      justifyContent: 'flex-start', 
      alignItems: 'center',
      textAlign: 'center', 
      fontWeight: 'bold'
    },
    conteudoAcoes: { 
        flexDirection: "row", 
        flex: 1, 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        marginBottom: 10,
        marginTop: 10
    },
    botaoAcao: {
        marginLeft: 10
    },
    centralizar: {
      flex: 1, 
      justifyContent: 'center',
      alignContent: "center",
    }
  });