import React, { useEffect, useState } from 'react';
import { StyleSheet, View, NativeModules, DeviceEventEmitter } from 'react-native';
import { DefaultTheme, DarkTheme, Provider as PaperProvider, Button } from 'react-native-paper';
import {Loading, Login, SolicitarAcesso, FormularioSolicitacaoAcesso, RecuperarSenha, AppHome, Configuracoes } from './src/screens';
import { createSwitchNavigator } from 'react-navigation';
import { LocaleConfig } from 'react-native-calendars';
import { Platform } from 'react-native';

import { StateProvider } from './src/state';

import {ThemeContext, themes} from './theme-context';
import ThemedButton from './themed-button';

LocaleConfig.locales['ptBR'] = {
  monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
  monthNamesShort: ['JAN','FEV','MAR','ABR','MAI','JUN','JUL','AGO','SET','OUT','NOV','DEZ'],
  dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
  dayNamesShort: ['DOM','SEG','TER','QUA','QUI','SEX','SAB'],
  today: 'HOJE'
};
LocaleConfig.defaultLocale = 'ptBR';

const initialState = {
  usuario: { },
  filhos: [],
  estudante : { nome: '', turma: '', escola: '' },
  mainNavigator: null,
  menuApp: [],
  theme : themes.dark
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'alterarUsuario':
      return {
        ...state,
        usuario: action.newUsuario
      };  
    case 'alterarFilhos':
      return {
        ...state,
        filhos: action.newFilhos
      };  
    case 'alterarEstudante':
      return {
        ...state,
        estudante: action.newEstudante
      };
    case 'alterarMainNavigator':
      return {
        ...state,
        mainNavigator: action.newMainNavigator
      };  
    case 'alterarMenuApp':
      return {
        ...state,
        menuApp: action.newMenuApp
      };  
    default:
      return state;
  }
};

const onSdStateChange = (event) => {
  // console.log('---- Internet Patrocinada ----');
  // console.log('state: ' + event.sd_state);
  // console.log('reason: ' +event.sd_reason);
  // console.log('carrier: ' +event.carrier_name);
  // console.log('client ip: ' +event.client_ip);
  };

export default function App() {

  if (Platform.OS != 'web') {
    DeviceEventEmitter.addListener('onSdStateChange',onSdStateChange);
    NativeModules.SmiSdkReactModule.registerSdStateChangeListner();

    NativeModules.SmiSdkReactModule.getCurrentSdState( (message) =>{
      var st_message = "Current state : "+message.sd_state;
      console.log('getCurrentSdState :> ', st_message);
    });
    
    NativeModules.SmiSdkReactModule.isVpnPermissionAccepted((status) => {
      console.log('is-VPN-PermissionAccepted ',status);
    });   
  }

  const MainNavigator = createSwitchNavigator({
    Loading: { screen: Loading },
    AppHome: { screen: AppHome },
    Login: { screen: Login },
    SolicitarAcesso: { screen: SolicitarAcesso },
    FormularioSolicitacaoAcesso: { screen: FormularioSolicitacaoAcesso },
    RecuperarSenha: { screen: RecuperarSenha },
  }, {
    initialRouteName: 'Loading'
  });


  function Toolbar(props) {
    return (
      <ThemedButton  style={{border: 0, backgroundColor: theme?.backgroundColorReader, height: 25 }} theme={theme}>
        <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Button mode={themeButton} style={[styles.btnAccessibility, {marginTop: -5, backgroundColor: (themeButton == "contained" ? '#438eb9' : null)}]} onPress={props.changeTheme}>A</Button>
          <Button mode={themeButtonSmall} style={[styles.btnAccessibility, {marginTop: -5, backgroundColor: (themeButtonSmall == "contained" ? '#438eb9' : null)}]} onPress={props.toggleThemeSmall}>A - </Button>
          <Button mode={themeButtonLarger} style={[styles.btnAccessibility, {marginTop: -5, backgroundColor: (themeButtonLarger == "contained" ? '#438eb9' : null)}]} onPress={props.toggleThemeLarger}>A + </Button>
        </View>
      </ThemedButton>
    );
  }

  const [ theme, setTheme ] = useState(themes.light);
  const [ themeButton, setThemeButton ] = useState('text');
  const [ themeButtonSmall, setThemeButtonSmall ] = useState('text');
  const [ themeButtonLarger, setThemeButtonLarger ] = useState('text');

  async function setAccessibility(classe){
    await AsyncStorage.setItem('EducarWebEstudanteApp:accessibility', classe);
  }

  function toggleTheme() {
    setTheme(theme === themes.dark ? themes.light : themes.dark);
    setThemeButton(theme === themes.dark ? 'text' : 'contained');
    setThemeButtonSmall('text');
    setThemeButtonLarger('text');
  };

  function toggleThemeSmall() {
    setTheme(theme === themes.smallLight ? themes.light : themes.smallLight);
    setThemeButton('text');
    setThemeButtonSmall(theme === themes.smallLight ? 'text' : 'contained');
    setThemeButtonLarger('text');
  };

  function toggleThemeLarger() {
    setTheme(theme === themes.largerLight ? themes.light : themes.largerLight);
    setThemeButton('text');
    setThemeButtonSmall('text');
    setThemeButtonLarger(theme === themes.largerLight ? 'text' : 'contained');
  };
    
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <PaperProvider theme={theme} value={theme}>
        { (Platform.OS === 'web') && 
            <Toolbar changeTheme={toggleTheme} toggleThemeLarger={toggleThemeLarger} toggleThemeSmall={toggleThemeSmall} context={theme}/>
        }
        <MainNavigator />
      </PaperProvider>
    </StateProvider>
  );
  }

  
 const styles = StyleSheet.create({
  btnAccessibility: {
    marginTop: -5,
  }
 });