import React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';

import {ThemeContext} from '../../../theme-context';

export default function ItemListaHoraiosAula(props) {
  return (
    <View style={styles.container}>
      <View style={{ height: 35, width: 5, backgroundColor: (!!props.dados.cor ? props.dados.cor.trim() : '#FFF'), marginRight: 5 }} />
      <View style={{ flexDirection: "column" }}>
        <View style={{ flexDirection: "row", maxWidth: (Dimensions.get("screen").width - 10) }}>
          <Text ellipsizeMode="clip" numberOfLines={2} style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}><Text style={[styles.materia, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize14}]}>{props.dados.componente}</Text> - {props.dados.horario} - {props.dados.professor}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    minHeight: 35,
    alignItems: "center",
  },
  materia: {
    fontSize: 14,
    fontWeight: "bold",
  }
})