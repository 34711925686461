import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, StyleSheet, Dimensions, Platform, Alert, FlatList, TouchableOpacity, Linking, Picker } from 'react-native';
import { Checkbox, IconButton, Button, Divider, List, ActivityIndicator, Modal, Colors, Paragraph, TextInput, Searchbar, Title, Snackbar, HelperText, Portal, Dialog } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';

import { useStateValue } from '../state';
import { Api, Util } from '../services';
import { ThemeContext } from '../../theme-context';

import axios from 'axios';
import _ from 'lodash';

var temporizador = null;

export default function Rematricula({ navigation }) {
    const [{ usuario, estudante }, dispatch] = useStateValue();
    const [dadosAlerta, setDadosAlerta] = useState({ exibir: false, texto: '' });
    const [carregando, setCarregando] = useState(true);
    const [listaEscolasRematricula, setListaEscolasRematricula] = useState([]);
    const [possuiMatricula, setPossuiMatricula] = useState(true);
    const [dadosRematricula, setDadosRematricula] = useState(true);
    const [salvandoMatricula, setSalvandoMatricula] = useState(false);
    const [periodoRematriculaAberto, setPeriodoRematriculaAberto] = useState(false);
    const [modalConfirmarRematricula, setModalConfirmarRematricula] = useState(false);
    const [buscandoDadosCep, setBuscandoDadosCep] = useState(false);
    const [ibgeValue, setIbgeValue] = useState("");
    const [cepValue, setCepValue] = useState("");
    const [bairroValue, setBairroValue] = useState("");
    const [bairroApi, setBairroApi] = useState(false);
    const [enderecoValue, setEnderecoValue] = useState("");
    const [enderecoApi, setEnderecoApi] = useState(false);
    const [numeroValue, setNumeroValue] = useState("");
    const [complementoValue, setComplementoValue] = useState("");
    const [complementoApi, setComplementoApi] = useState(false);
    const [celularValue, setCelularValue] = useState("");
    const [telefoneValue, setTelefoneValue] = useState("");
    const [aceitoTermosValue, setAceitoTermosValue] = useState(false);

    const [modalSelecionarBairro, setModalSelecionarBairro] = useState(false);
    const [filtroBairro, setFiltroBairro] = useState("");
    const [listaBairros, setListaBairros] = useState([]);
    const [carregandoModalBairro, setCarregandoModalBairro] = useState(false);

    const [codOrgLogado, setCodOrgLogado] = useState(0);
    const [mensagemRematricula, setMensagemRematricula] = useState('');

    const [listaRacas, setListaRacas] = useState([]);
    const [racaSelecionada, setRacaSelecionada] = useState('');

    const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
console.log(codOrgLogado)
    useEffect(() => {
        buscarDadosRematricula();
        buscarRacas();
    }, []);

    function exibirAlerta(titulo, texto) {
        if (Platform.OS === "web") {
            alert(titulo + '\n\n' + texto);
        }
        else {
            Alert.alert(
                titulo,
                texto
            );
        }
    }
    function exibirConfirmacao(titulo, texto, descricaoBotaoOK = 'OK', descricaoBotaoCancela = 'Cancelar', funcaoOK = () => { }, funcaoCancela = () => { }) {
        if (Platform.OS === "web") {
            if (confirm(titulo + '\n\n' + texto)) funcaoOK();
        }
        else {
            Alert.alert(
                titulo,
                texto,
                [
                    {
                        text: descricaoBotaoCancela,
                        onPress: funcaoCancela,
                        style: 'cancel',
                    },
                    { text: descricaoBotaoOK, onPress: funcaoOK },
                ],
                { cancelable: true },
            );
        }
    }

    function buscarDadosRematricula() {
        api.get("/ApiMobileMatricula/buscarDadosRematricula?_mtr_codigo=" + estudante.matricula).then((retorno) => {
            setCarregando(false);

            if (retorno.status === 200) {
                if (!!retorno.data.informacoes) {                    
                    if (retorno.data.informacoes.length > 0) {
                        setPeriodoRematriculaAberto(true);
                        setPossuiMatricula(retorno.data.matriculadoAnoSeguinte);
                        setListaEscolasRematricula(retorno.data.informacoes);
                        setCodOrgLogado(retorno.data.codOrgLogado);
                        setMensagemRematricula(retorno.data.mensagemRematricula);

                        if (retorno.data.informacoes.length == 1)
                            setDadosRematricula(retorno.data.informacoes[0]);
                    }
                }
            }
        }, (erro) => {
            setCarregando(false);
        });
    }

    function salvarMatricula() {
        let dadosObrigatorios = "";

        if (!cepValue) dadosObrigatorios += "* CEP\n";
        if (!bairroValue) dadosObrigatorios += "* Bairro\n";
        if (!enderecoValue) dadosObrigatorios += "* Endereço\n";
        if (!celularValue) dadosObrigatorios += "* Celular\n";
        if (codOrgLogado == 4387 && !racaSelecionada) dadosObrigatorios += "* Cor/Raça\n";

        if (dadosObrigatorios != "") {
            exibirAlerta('Dados Obrigatórios', dadosObrigatorios);
        } else {
            let dadosInvalidos = "";

            if (cepValue.length < 10) dadosInvalidos += "* CEP\n";
            if (bairroValue.length < 3) dadosInvalidos += "* Bairro\n";
            if (enderecoValue.length < 3) dadosInvalidos += "* Endereço\n";

            if (complementoValue.length > 0)
                if (complementoValue.length < 3) dadosInvalidos += "* Complemento\n";

            if (celularValue.length < 15) dadosInvalidos += "* Celular\n";

            if (telefoneValue.length > 0)
                if (telefoneValue.length < 14) dadosInvalidos += "* Telefone\n";

            if (dadosInvalidos != "") {
                exibirAlerta('Dados inválidos, informe corretamente:', dadosInvalidos);
            } else {
                setSalvandoMatricula(true);

                let formData = new FormData();
                formData.append('codigoMatricula', estudante.matricula);
                formData.append('codigoSerieAno', dadosRematricula.proximaSerieAno);
                formData.append('codigoPeriodo', dadosRematricula.codigoPeriodo);
                formData.append('codigoEscola', dadosRematricula.codigoEscola);
                formData.append('codigoTurno', dadosRematricula.codigoTurno);
                formData.append('ano', dadosRematricula.ano);

                const endereco = {
                    cep: cepValue,
                    bairro: bairroValue,
                    endereco: enderecoValue,
                    numero: numeroValue,
                    complemento: complementoValue,
                    ibge: ibgeValue
                };
                formData.append('endereco', JSON.stringify(endereco));

                const contato = {
                    celular: celularValue,
                    telefone: telefoneValue
                };
                formData.append('contato', JSON.stringify(contato));

                let dadosPessoais = null;

                if (codOrgLogado == 4387) {
                    dadosPessoais = {
                        rac_codigo: racaSelecionada
                    };
                }
                formData.append('dadosPessoais', JSON.stringify(dadosPessoais));


                let api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
                api.defaults.headers["Content-Type"] = "multipart/form-data";
                api.post('ApiMobileMatricula/salvarMatricula', formData).then((retorno) => {
                    setSalvandoMatricula(false);

                    if (retorno.status === 200) {
                        if (retorno.data.success === true) {
                            if (retorno.data.iderr === 'vaga_turno_inverso') {

                                exibirConfirmacao("Informação!", `As vagas para o turno da ${retorno.data.informacoes.turnoAnterior} esgotaram, porém você pode tentar para o turno da ${retorno.data.informacoes.nomeTurno}. \n Deseja prosseguir?`, "Sim", "Não", () => {
                                    dadosRematricula.codigoTurno = retorno.data.informacoes.codigoTurno;
                                    dadosRematricula.nomeTurno = retorno.data.informacoes.nomeTurno;
                                    salvarMatricula();
                                });
                            } else {
                                setModalConfirmarRematricula(false);
                                buscarDadosRematricula();
                                exibirAlerta("Informação", "As informações foram incluídas com sucesso.");
                            }
                        }
                        else {
                            if (retorno.data.iderr === 'vaga_indisponivel') {
                                exibirAlerta("Atenção:", "Não há mais vagas disponíveis!");
                            } else {
                                exibirAlerta("ERRO", retorno.data.mensagem);
                            }
                        }
                    }

                    if (retorno.status !== 200) {
                        setSalvandoMatricula(false);
                        exibirAlerta("ERRO", "Não foi possível incluir a rematrícula no momento. Tente novamente mais tarde...");
                    }
                }, (erro) => {
                    setSalvandoMatricula(false);
                    exibirAlerta("ERRO!", "Erro ao incluir a rematrícula");
                    console.log('erro', erro.response);
                });
            }
        }
    }

    useEffect(() => {
        if (cepValue.length == 10) {
            buscaEnderecoApartirDoCep();
        } else {
            setBairroApi(true);
            setEnderecoApi(true);
            setComplementoApi(true);
        }
    }, [cepValue]);


    function buscarBairros() {
        if (!!ibgeValue) {
            setCarregandoModalBairro(true);
            let api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
            api.post('ApiMobileMatricula/listarBairros?_ibge=' + ibgeValue).then((retorno) => {
                console.log(retorno.data);
                let dados = retorno.data.dados;

                setListaBairros(dados);

                setModalSelecionarBairro(true);
                setCarregandoModalBairro(false);
            }, (erro) => {
                setCarregandoModalBairro(false);
                console.log('erro', erro.response);
            });
        } else {
            exibirAlerta('Atenção', 'Informe o CEP corretamente, para então buscar os bairros!');
        }
    }

    function buscaEnderecoApartirDoCep() {

        let config = {
            baseURL: `https://viacep.com.br/ws/${cepValue.replace(/\D/g, "")}/json`,
            timeout: 30000
        }
        setBuscandoDadosCep(true);
        let apiExterno = axios.create(config);
        apiExterno.get().then((retorno) => {
            setBuscandoDadosCep(false);

            if (!retorno.data.erro) {
                setIbgeValue(retorno.data.ibge);
                setBairroValue(retorno.data.bairro);
                setEnderecoValue(retorno.data.logradouro);
                setComplementoValue(retorno.data.complemento);

                if (!!retorno.data.bairro) setBairroApi(true);
                else setBairroApi(false);

                if (!!retorno.data.logradouro) setEnderecoApi(true);
                else setEnderecoApi(false);

                if (!!retorno.data.complemento) setComplementoApi(true);
                else setComplementoApi(false);
            } else {
                console.log('não encontrou o cep');
                //setCepValue("");
                setBairroApi(false);
                setEnderecoApi(false);
                setComplementoApi(false);

                setIbgeValue(4216602);


                //setDadosAlerta({ exibir: true, texto: 'Você informou um CEP inválido.' });
            }

        }, (erro) => {
            setBuscandoDadosCep(false);
        });
    }

    function buscarRacas() {
        api.get('ApiMobileMatricula/listarRacas')
            .then(response => {
                setListaRacas(response.data.lista);
            })
            .catch(error => {
                console.error("Erro ao buscar dados para o combo: ", error);
            });
    }

    return (
        <React.Fragment>
            <View style={[styles.container, { alignItems: "center", flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>

                <View style={styles.viewEstudante}>
                    <Text style={{ margin: 5, alignItems: "flex-start" }}>Dados da matrícula atual:</Text>
                    <View style={styles.viewDadosEstudante}>
                        <Text style={styles.textoNomeAluno}>{estudante.nome}</Text>
                        <Text>Escola: <Text style={styles.textoEscolaTurma}>{estudante.escola}</Text></Text>
                        <Text>Turma: <Text style={styles.textoEscolaTurma}>{estudante.turma}</Text></Text>

                        <View style={{ flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                            <Text>Ano: <Text style={styles.textoEscolaTurma}>{estudante.ano}</Text></Text>
                            <Text style={{ marginLeft: 10 }}>Turno: <Text style={styles.textoEscolaTurma}>{estudante.turno}</Text></Text>
                        </View>
                    </View>
                </View>

                {!possuiMatricula && periodoRematriculaAberto &&
                    <>
                        <Button loading={salvandoMatricula} disabled={salvandoMatricula} style={styles.botaoRematricular} mode="contained" icon="arrow-downward" color={Colors.blue500} size={25} onPress={() => {
                            if (!!dadosRematricula.proximaSerieAno) {
                                setModalConfirmarRematricula(true)
                            } else {
                                exibirAlerta('Atenção', 'Selecione uma das opções de escola!');
                            }
                        }
                        }>Efetivar Rematricular Para...</Button>
                        {listaEscolasRematricula.length > 1 &&
                            <Text style={{ marginTop: 5, color: ThemeContext._currentValue.theme?.textWhite }} >Selecione uma escola e depois clique em efetivar a rematricula...</Text>
                        }
                    </>
                }

                {possuiMatricula && periodoRematriculaAberto &&
                    <View style={styles.viewMatriculaJaRealizada}>
                        <Text style={styles.textoMatriculaJaRealizada}>Matrícula já realizada!</Text>
                        <Text>Protocolo: <Text style={styles.textoEscolaTurma}>{dadosRematricula.codigoMatricula}</Text></Text>
                        <Text>Escola: <Text style={styles.textoEscolaTurma}>{dadosRematricula.nomeEscola}</Text></Text>
                        <Text>Série/Etapa: <Text style={styles.textoEscolaTurma}>{dadosRematricula.serieDescricao}</Text></Text>

                        <View style={{ flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                            <Text>Ano: <Text style={styles.textoEscolaTurma}>{dadosRematricula.ano}</Text></Text>
                            <Text style={{ marginLeft: 10 }}>Turno: <Text style={styles.textoEscolaTurma}>{dadosRematricula.nomeTurno}</Text></Text>
                        </View>
                        {codOrgLogado == 4661 &&
                        <View>
                            <Text>Para validar a matrícula, por favor, dirija-se à unidade selecionada até o dia 29/10/2024 e apresente a documentação necessária. 
                            Certifique-se de levar todos os documentos exigidos</Text>
                        </View>
                        }
                    </View>
                }

                {periodoRematriculaAberto && !possuiMatricula &&
                    <ScrollView style={{ marginTop: 10, borderColor: Colors.grey500, borderTopWidth: 2 }}>
                        {listaEscolasRematricula.map((item, indice) => (
                            <React.Fragment key={indice}>
                                <TouchableOpacity
                                    style={[styles.viewEstudante,
                                    {
                                        backgroundColor: (dadosRematricula.proximaSerieAno == item.proximaSerieAno && dadosRematricula.codigoTurno == item.codigoTurno) ? Colors.green100 : '#fefefe',
                                        borderColor: (dadosRematricula.proximaSerieAno == item.proximaSerieAno && dadosRematricula.codigoTurno == item.codigoTurno) ? Colors.green500 : Colors.blue500
                                    }
                                    ]}
                                    onPress={() => {
                                        setDadosRematricula(item);
                                    }}
                                >
                                    <Text style={{ margin: 5, alignItems: "flex-start" }}>{!dadosRematricula.jaMatriculado ? `Dados para rematrícula:` : `Dados da matrícula - ${item.ano}:`}</Text>
                                    <View style={styles.viewDadosEstudante}>
                                        <Text>Escola: <Text style={styles.textoEscolaTurma}>{item.nomeEscola}</Text></Text>
                                        <Text>Série/Etapa: <Text style={styles.textoEscolaTurma}>{item.serieDescricao}</Text></Text>

                                        <View style={{ flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                                            <Text>Ano: <Text style={styles.textoEscolaTurma}>{item.ano}</Text></Text>
                                            <div>
                                                {codOrgLogado != 4387 && (
                                                    <Text style={{ marginLeft: 10 }}>
                                                        Turno: <Text style={styles.textoEscolaTurma}>{item.nomeTurno}</Text>
                                                    </Text>
                                                )}
                                            </div>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            </React.Fragment>
                        ))
                        }
                    </ScrollView>
                }

                {periodoRematriculaAberto && !possuiMatricula && codOrgLogado == 4387 &&
                    <View style={[styles.mensagemAviso, { flexGrow: 0 }]}>
                        <Text><div dangerouslySetInnerHTML={{ __html: mensagemRematricula }}></div></Text>
                    </View>
                }

                {!periodoRematriculaAberto && !carregando &&
                    <View style={styles.viewPeriodoRematriculaFechado}>
                        <Text style={styles.textoPeriodoRematriculaFechado}>Período para rematrículas não está disponível!</Text>
                    </View>
                }
                <Modal visible={carregando}>
                    <ActivityIndicator color="#FFF" size="large" />
                </Modal>
            </View>


            <Modal contentContainerStyle={[styles.containerModalConfirmarRematricula, { padding: 5, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? Dimensions.get('window').width >= 1900 ? 620 : 380 : 10), height: Dimensions.get('window').height - 80, backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]} visible={modalConfirmarRematricula}
                onDismiss={() => {
                    setAceitoTermosValue(false)
                    setModalConfirmarRematricula(false)
                }
                }>
                <Title>CONFIRME OS DADOS:</Title>
                <ScrollView>
                    <Paragraph>Informe o CEP para que possamos identificar seu endereço:</Paragraph>

                    <View style={{ justifyContent: "center", alignItems: "center" }}>

                        <TextInput label="CEP" value={cepValue} maxLength={10} style={styles.input} onChangeText={setCepValue}
                            render={props =>
                                <TextInputMask
                                    {...props}
                                    type={'custom'}
                                    options={{
                                        mask: '99.999-999'
                                    }}
                                    keyboardType="decimal-pad"
                                />
                            }
                        />

                        <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: "center", marginBottom: 8, width: (Dimensions.get('screen').width >= 1024 ? 380 : '100%') }}>
                            <TextInput label="Bairro" value={bairroValue} disabled={true}
                                style={{
                                    flex: 1,
                                    flexDirection: 'row'
                                }}
                                onChangeText={setBairroValue} />
                            {!bairroApi &&
                                <IconButton size={35} color={Colors.blue500} accessibilityLabel="Buscar Bairro" icon="search" onPress={() => buscarBairros()} />
                            }
                        </View>

                        <TextInput label="Endereço" value={enderecoValue} disabled={enderecoApi} style={styles.input} onChangeText={setEnderecoValue} />
                        <TextInput label="Número" value={numeroValue} style={styles.input} onChangeText={setNumeroValue}
                            render={props =>
                                <TextInputMask
                                    {...props}
                                    type={'custom'}
                                    options={{
                                        mask: '9999999999'
                                    }}
                                    keyboardType="decimal-pad"
                                />
                            }
                        />
                        <TextInput label="Complemento" value={complementoValue} disabled={complementoApi} style={styles.input} onChangeText={setComplementoValue} />
                    </View>
                    <Divider style={{ margin: 5 }} />
                    <View style={{ justifyContent: "center", alignItems: "center" }}>
                        <TextInput label="Celular" value={celularValue} style={styles.input} onChangeText={setCelularValue}
                            render={props =>
                                <TextInputMask
                                    {...props}
                                    type={'custom'}
                                    options={{
                                        mask: '(99) 99999-9999'
                                    }}
                                    keyboardType="decimal-pad"
                                />
                            }
                        />
                        <TextInput label="Telefone" value={telefoneValue} style={styles.input} onChangeText={setTelefoneValue}
                            render={props =>
                                <TextInputMask
                                    {...props}
                                    type={'custom'}
                                    options={{
                                        mask: '(99) 9999-9999'
                                    }}
                                    keyboardType="decimal-pad"
                                />
                            }
                        />
                    </View>
                    {codOrgLogado == 4387 && (
                        <>
                            <Divider style={{ margin: 5 }} />
                            <Text style={{ marginLeft: 35, alignItems: "flex-start" }}>Cor/Raça:</Text>
                            <View style={[styles.conteudo, { alignSelf: 'center', alignItems: 'center' }]}>
                                <Picker
                                    style={styles.componentePicker}
                                    selectedValue={racaSelecionada}
                                    onValueChange={(value) => {
                                        const raca = listaRacas.find(r => r.rac_codigo == value);
                                        if (raca) {
                                            setRacaSelecionada(raca.rac_codigo);
                                        }
                                    }}>
                                    {!racaSelecionada && <Picker.Item label="Selecione uma Cor/Raça" value={null} />}
                                    {listaRacas.map((raca) => (
                                        <Picker.Item key={raca.rac_codigo} label={raca.rac_descricao} value={raca.rac_codigo} />
                                    ))}
                                </Picker>
                            </View>
                        </>
                    )}
                    <Divider style={{ margin: 5 }} />
                    <View style={{ flexDirection: 'row' }}>
                        <Checkbox
                            status={aceitoTermosValue ? 'checked' : 'unchecked'}
                            onPress={() => setAceitoTermosValue(!aceitoTermosValue)}
                        />
                        <Text style={{ marginTop: 10, color: ThemeContext._currentValue.theme?.textWhite }}>Estou ciente das regras que regem o edital de rematrícula.</Text>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: "flex-end", marginVertical: 5, marginBottom: 5 }}>
                        <Button loading={salvandoMatricula} disabled={!aceitoTermosValue || salvandoMatricula} style={{ marginRight: 15 }} mode="contained" onPress={() => salvarMatricula()}>Confirmar</Button>
                        <Button style={{ marginRight: 15 }} mode="outlined" onPress={() => {
                            setAceitoTermosValue(false)
                            setModalConfirmarRematricula(false)
                        }
                        }>Cancelar</Button>

                    </View>


                    <Modal visible={buscandoDadosCep}>
                        <ActivityIndicator color="#FFF" size="large" />
                    </Modal>
                    <Snackbar
                        visible={dadosAlerta.exibir}
                        style={{ backgroundColor: '#B00020' }}
                        onDismiss={() => setDadosAlerta({ exibir: false, texto: '' })}>
                        {dadosAlerta.texto}
                    </Snackbar>
                </ScrollView>
            </Modal>

            <Modal contentContainerStyle={[styles.containerModalBairro, { padding: 15, height: Dimensions.get('window').height - 80, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? (Dimensions.get('window').width >= 1900 ? 620 : 380) : 30), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]} visible={modalSelecionarBairro} onDismiss={() => {
                setModalSelecionarBairro(false);
                setCarregandoModalBairro(false);
            }}>
                <View style={styles.containerTituloBairro}>
                    <Text style={[styles.titulo, { color: ThemeContext._currentValue.theme?.textWhite }]}>Selecione o seu Bairro</Text>
                </View>
                <Searchbar style={styles.searchBar} placeholder="Localizar Bairro" autoCapitalize="none" autoCorrect={false} returnKeyLabel="search" value={filtroBairro} onChangeText={setFiltroBairro} />
                <ScrollView>
                    <FlatList data={_.filter(listaBairros, function (o) {
                        return Util.retira_acentos(o.bai_nome).toLowerCase().replace(/ /g, "-").indexOf(Util.retira_acentos(filtroBairro).toLowerCase().replace(/ /g, "-")) > -1;
                    })} renderItem={({ item, index }) => (
                        <React.Fragment key={index}>
                            <List.Item
                                style={styles.searchBar}
                                title={item.bai_nome}
                                right={props => <List.Icon {...props} icon="arrow-forward" />}
                                onPress={() => {
                                    setBairroValue(item.bai_nome);
                                    setModalSelecionarBairro(false);
                                }}
                            />
                            <Divider />
                        </React.Fragment>
                    )} keyExtractor={item => item.id} />
                </ScrollView>

                <Modal visible={carregandoModalBairro}>
                    <ActivityIndicator color="#FFF" size="large" />
                </Modal>
            </Modal>
        </React.Fragment>
    );
}


const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        flex: 1
    },
    linhaCentralizadoHorizontal: {
        justifyContent: "center",
        margin: 3
    },
    viewDadosEstudante: {
        padding: 5,
        alignItems: "center",
        width: (Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? Dimensions.get('window').width / 2 : Dimensions.get('window').width - 20),
    },
    viewEstudante: {
        flexDirection: 'column',
        borderColor: Colors.blue500,
        borderWidth: 1,
        backgroundColor: '#fefefe',
        margin: 5,
        borderRadius: 5,
    },
    mensagemAviso: {
        borderColor: Colors.blue500,
        borderWidth: 1,
        backgroundColor: '#fefefe',
        borderRadius: 5,
        width: (Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? Dimensions.get('window').width / 2 : Dimensions.get('window').width - 20),
        textAlign: 'justify',
        padding: 5,
        marginBottom: 10,
    },
    textoNomeAluno: {
        fontSize: (Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? 20 : 18),
        color: '#212020',
        fontWeight: "bold",
        alignItems: "center",
    },
    textoEscolaTurma: {
        fontSize: (Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? 18 : 16),
        color: '#212020',
        fontWeight: "bold",
        alignItems: "center",
    },
    botaoRematricular: {
        maxWidth: (Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? Dimensions.get("window").width / 2 : Dimensions.get("window").width) - 100,
    },
    viewPeriodoRematriculaFechado: {
        flexDirection: 'column',
        borderColor: Colors.red500,
        borderWidth: 1,
        backgroundColor: Colors.red50,
        margin: 10,
        borderRadius: 5,
        padding: 16,
        alignItems: "center",
        width: (Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? Dimensions.get('window').width / 2 : Dimensions.get('window').width - 20)
    },
    textoPeriodoRematriculaFechado: {
        fontSize: (Platform.OS === 'web' ? 20 : 16),
        color: Colors.red500,
        fontWeight: "bold",
        alignItems: "center",
    },
    viewMatriculaJaRealizada: {
        flexDirection: 'column',
        borderColor: Colors.green500,
        borderWidth: 1,
        backgroundColor: Colors.green50,
        margin: 10,
        borderRadius: 5,
        padding: 16,
        alignItems: "center",
        width: (Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? Dimensions.get('window').width / 2 : Dimensions.get('window').width - 20)
    },
    textoMatriculaJaRealizada: {
        fontSize: (Platform.OS === 'web' ? 20 : 18),
        color: Colors.green500,
        fontWeight: "bold",
        alignItems: "center",
    },
    containerModalConfirmarRematricula: {
        padding: 5,
        margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? Dimensions.get('window').width >= 1900 ? 620 : 380 : 10),
        backgroundColor: "#FFF",
        height: Dimensions.get('window').height - 80,
        //width: (Dimensions.get('screen').width >= 1024 ? 380 : '100%')
    },
    input: {
        marginBottom: 8,
        width: (Dimensions.get('screen').width >= 1024 ? 380 : '100%')
    },
    containerModalBairro: {
        padding: 15,
        height: Dimensions.get('window').height - 80,
        margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? (Dimensions.get('window').width >= 1900 ? 620 : 380) : 30),
        backgroundColor: "#FFF",
    },
    containerTituloBairro: {
        alignSelf: "center",
    },
    componentePicker: {
        marginVertical: 5,
        height: 45,
        marginLeft: 10,
        width: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 380 : 250),
    },
    conteudo: {
        flex: 1
    },
});
