import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, TextInput, Platform, Dimensions, TouchableOpacity } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Button, Modal, ActivityIndicator, Card, Title, Paragraph, Divider, HelperText } from 'react-native-paper';
import { useStateValue } from '../../state';
import { Api } from '../../services';
import { NavigationActions } from 'react-navigation';
import Carousel from '../../screens/Carousel';
import { roundToNearestMinutesWithOptions } from 'date-fns/fp';
import { ThemeContext } from '../../../theme-context';
//import LocalAuthentication from 'expo-local-authentication'

const screenWidth = Dimensions.get("screen").width;

export default function ItemListaLivros(props) {

  const carouselRef = useRef(null);
  const numeroRegistros = 15; //(Platform.OS === "web" ? parseInt(Math.round(Dimensions.get("window").height / 100)) : 10);
  const [listaLivros, setListaLivros] = useState([]);
  const [search, setSearch] = useState('');
  const [nRegistros, setNRegistros] = useState(0);
  const [{ usuario }, dispatch] = useStateValue();
  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
  const [loadingLivros, setLoadingLivros] = useState({ pagina: 0, loading: false, })
  const [searchTimer, setSearchTimer] = useState(null);

  useEffect(() => {
    CarregarLivros(true);
  }, [])

  async function CarregarLivros(paginainicial) {
    if (loadingLivros.loading) return;
    
    let pagina = (paginainicial) ? 0 : loadingLivros.pagina + 1;
    setLoadingLivros({ pagina: loadingLivros.pagina, loading: true })
    let pesquisa = (paginainicial) ? "" : search;
    await api.get("/ApiMobileBiblioteca/ListaLivros?pes_codigo=" + usuario.pes_codigo + "&pagina=" + pagina + "&numero_registros=" + numeroRegistros+"&pesquisa=" +  pesquisa).then((retorno) => {

      if (retorno.status === 200) {
        setListaLivros((paginainicial) ? retorno.data.livros : [...listaLivros, ...retorno.data.livros]);
        setLoadingLivros({ pagina: pagina, loading: false })
        setNRegistros((retorno.data.livros).length ? retorno.data.totalRegistros : 0);
      }
    })
  }

  function navigateToScreen(route) {
    const navigateAction = NavigationActions.navigate({
      routeName: route
    });
    props.props.navigation.dispatch(navigateAction);
  }

  function CarregarPagina() {
    if (!loadingLivros.loading) return null;
    return (
      <View>
        <ActivityIndicator size="large" color="#0a83bf" />
      </View>
    )
  }

  async function Filtrar(filtro) {
    setSearch(filtro);
    setListaLivros([])
    if (filtro.length > 3) {
      setLoadingLivros({ pagina: 0, loading: true })
      await api.get("/ApiMobileBiblioteca/ListaLivros?pes_codigo=" + usuario.pes_codigo + "&numero_registros=" + numeroRegistros + "&pesquisa=" + filtro).then((retorno) => {
        if (retorno.status === 200) {
          setListaLivros(retorno.data.livros);
          setLoadingLivros({ pagina: 0, loading: false })
          setNRegistros(retorno.data.livros.length ? retorno.data.totalRegistros : 0);
        }
      })
    } else if (filtro.length == 0) {
      setLoadingLivros({ pagina: 0, loading: false })
      CarregarLivros(true)
    }
    else {
      setLoadingLivros({ pagina: 0, loading: false })
    }
  }
 
  function ListaLivrosWeb() {
    return (
      <View style={[styles.container, { backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            style={styles.textInputStyle}
            value={search}
            placeholder="Pesquisar um livro"
            underlineColorAndroid="transparent"
            maxLength={100}

            onChangeText={(text) => {
              if (searchTimer)
                clearTimeout(searchTimer);
              setSearch(text)
              setSearchTimer(
                setTimeout(() => {
                  Filtrar(text);
                }, 2000),
              );
            }}
          >

          </TextInput>

          <Button icon="people" style={styles.botaoWeb} mode="contained" onPress={() => navigateToScreen('MinhasReservas')} >Meus empréstimos</Button>
        </View>
            {search.length <= 3  &&
        <Text style={{padding: 10, fontSize: 10}}>
        {search.split(search.length < 3).map(( c) => c && 'Digite no mínimo 3 caracteres').join(' ')}
      </Text>}
   
      
        <View style={{ flexDirection: 'row', height: 40, marginTop: 15 }}>
          <Text style={[styles.bibliotecaCabecalho, { color: ThemeContext._currentValue.theme?.textWhite }]}>Biblioteca</Text>
          <Text style={[styles.qtdeCabecalho, { color: ThemeContext._currentValue.theme?.textWhite }]}>Disponível</Text>
          <Text style={[styles.tituloCabecalho, { color: ThemeContext._currentValue.theme?.textWhite }]}>Título</Text>
          <Text style={[styles.autoresCabecalho, { color: ThemeContext._currentValue.theme?.textWhite }]}>Autores</Text>
        </View>
        <Divider style={styles.divider} />
        <FlatList
          data={listaLivros}
          keyExtractor={(props, index) => index.toString()}
          onEndReached={() => nRegistros > 15 ? CarregarLivros() : null}
          onEndReachedThreshold={0.5}
          ListFooterComponent={CarregarPagina}
          renderItem={({ item }) => (
            <View>
              <View style={{ flexDirection: 'row', height: 40, marginTop: 15, }}>
                <Text numberOfLines={3} style={[styles.biblioteca, { color: ThemeContext._currentValue.theme?.textWhite }]}>{item.bib_descricao}</Text>
                <Text numberOfLines={2} style={[styles.qtde, { color: ThemeContext._currentValue.theme?.textWhite }]}>{item.qtde}</Text>
                <Text numberOfLines={3} style={[styles.titulo, { color: ThemeContext._currentValue.theme?.textWhite }]}>{item.obr_titulo}</Text>
                <Text numberOfLines={3} style={[styles.autores, { color: ThemeContext._currentValue.theme?.textWhite }]}>{item.autores}</Text>
              </View>
              <Divider />
            </View>
          )}
        />
        <Modal visible={loadingLivros.loading && Platform.OS !== "web"}>
          <ActivityIndicator color="#FFF" size="large" />
        </Modal>
      </View>
    );
  }

  function ListaLivrosMobile() {
    return (
      <View style={[styles.container]}>
      
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            style={styles.textInputStyle}
            value={search}
            placeholder="Pesquisar um livro"
            underlineColorAndroid="transparent"
            maxLength={100}
            onChangeText={(text) => Filtrar(text)} >
        
          </TextInput>
         
        </View>
        {search.length <= 3  &&
        <Text style={{padding: 5, fontSize: 10}}>
        {search.split(search.length < 3).map(( c) => c && 'Digite no mínimo 3 caracteres').join(' ')}
      </Text>}
      
        <Button icon="people" style={styles.botaoMobile} mode="contained" onPress={() => navigateToScreen('MinhasReservas')}> Meus empréstimos</Button>
        <FlatList
          data={listaLivros}
          keyExtractor={(props, index) => index.toString()}
          onEndReached={() => nRegistros > 15 ? CarregarLivros() : null}
          onEndReachedThreshold={0.5}
          ListFooterComponent={CarregarPagina}
          renderItem={({ item }) => (
            <View>
              <Card>
                <Card.Content>
                  <Carousel
                    sliderWidth={screenWidth}
                    sliderHeight={screenWidth}
                    itemWidth={screenWidth}
                    data={[item, item]}
                    renderItem={renderItem}
                    layout={'default'}
                  />
                </Card.Content>
              </Card>
              <View>
                <Divider />
              </View>
            </View>
          )}
        />
      </View>
    )
  }

  const renderItem = ({ item, index }) => {
    if (index === 0) {
      return (
        <View style={{ flexDirection: "row" }}>
          <View style={[styles.item, { flex: 1, justifyContent: 'center' }]}>
            <Title numeroRegistros={3} style={{ fontSize: 15 }}>{item.obr_titulo}</Title>
            <Paragraph style={{ flexDirection: 'row' }}>
              {item.bib_descricao}
            </Paragraph>
          </View>
          <View style={{ alignSelf: 'flex-end' }}>
            <Button icon='keyboard-arrow-left' size={25} />
          </View>
        </View>
      )
    }
    else if (item.autores == null || item.autores == 0 && index === 1) {
      return (
        <View style={[styles.item, { flex: 1, justifyContent: 'center' }]}>
          <Title style={{ fontSize: 15, color: 'black' }}>
            Disponível: {item.qtde}
          </Title>
        </View>
      )
    }
    else {
      return (
        <View style={[styles.item, { flex: 1, justifyContent: 'center' }]}>
     
          <Title style={{ fontSize: 15, color: 'black' }}>Autor(es): {item.autores}</Title>
          <Title style={{ fontSize: 15, color: 'black' }}>Disponível: {item.qtde}</Title>
        </View>
      );
    }
  }
  return (
    <View style={styles.container}>
      {(Platform.OS === "web" && Dimensions.get("screen").width >= 1024) ? ListaLivrosWeb() : ListaLivrosMobile()}
    </View>
  )
};

const styles = StyleSheet.create({
  item: {
    width: screenWidth,
    marginRight: 10
  },
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  itemStyle: {
    padding: 15
  },
  textInputStyle: {
    flex: 1,
    height: 50,
    borderWidth: 1,
    paddingLeft: 20,
    margin: 5,
    borderColor: '#438eb9',
    backgroundColor: 'white',
    fontSize: 15,
    fontWeight: "bold",
    color: "black",
    width: "80%"
  },
  centralizar: {
    alignItems: "center",
    textAlign: "center",
    padding: 10
  },
  botaoMobile: {
    height: 50,
    borderWidth: 1,
    //marginLeft: 5, 
    margin: 5,
    backgroundColor: '#438eb9',
    color: 'blue',
    borderColor: '#438eb9',
    fontSize: 10,
    fontWeight: "bold",
    width: "97%",
    justifyContent: "center"
  },
  botaoWeb: {
    height: 50,
    borderWidth: 1,
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: '#438eb9',
    color: 'blue',
    borderColor: '#438eb9',
    fontSize: 10,
    fontWeight: "bold",
    width: "20%",
    justifyContent: "center"
  },
  biblioteca: {
    width: 380,
    marginLeft: 10,
  },
  qtde: {
    width: 120
  },
  titulo: {
    width: 380,
    marginLeft: 5,
  },
  autores: {
    width: 380,
    marginLeft: 5,
    marginRight: 5,
  },
  bibliotecaCabecalho: {
    width: 370,
    marginLeft: 10,
    fontWeight: 'bold'
  },
  qtdeCabecalho: {
    width: 115,
    fontWeight: 'bold',
    marginLeft: 7,
  },
  tituloCabecalho: {
    width: 370,
    marginLeft: 10,
    //marginLeft: 5,
    fontWeight: 'bold'
  },
  autoresCabecalho: {
    width: 380,
    marginLeft: 15,
    //marginLeft: 5,
    marginRight: 5,
    fontWeight: 'bold'
  },
  divider: {
    height: 3
  }
});

