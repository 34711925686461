import React, { useState, useEffect, useCallback } from 'react';
import { View, ScrollView, Text, TouchableOpacity, RefreshControl, Dimensions, Platform, StyleSheet, Linking, Alert } from 'react-native';
import { NavigationActions } from 'react-navigation';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import * as Network from 'expo-network';
import { IconButton, Button, Modal, FAB, ActivityIndicator, RadioButton, TextInput, Divider, Title, Snackbar } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { Calendar } from 'react-native-calendars';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { ListaHorariosAula, DataCardHeader, ListaFrequencia, ListaEventos, ModalSelecionaFilho, ModalInformacoesFrequencia, ModalAviso, ListaCardapio, MenuApp } from '../components';
import { Api, Util } from '../services';
import { useStateValue } from '../state';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { themes, ThemeContext } from '../../theme-context';

const flexDir = Platform.OS === 'web' ? 'row' : 'column';
let cpfField = null;
export default function Home(props) {

    const [{ usuario, filhos, estudante, menuApp }, dispatch] = useStateValue();

    const [dataFiltro, setDataFiltro] = useState(new Date());
    const [fabOpen, setFabOpen] = useState(false);
    const [modalFiltroData, setModalFiltroData] = useState(false);
    const [modalDataSelecionada, setModalDataSelecionada] = useState({});
    const [modalSelecionaFilho, setModalSelecionaFilho] = useState(false);
    const [modalInformacoesFrequencia, setModalInformacoesFrequencia] = useState(false);
    const [dadosModalFrequencia, setDadosModalFrequencia] = useState({});
    const [refreshing, setRefreshing] = React.useState(false);

    const [horarios, setHorarios] = useState([]);
    const [carregandoHorarios, setCarregandoHorarios] = useState(false);
    const [chamadas, setChamadas] = useState([]);
    const [carregandoChamadas, setCarregandoChamadas] = useState(false);
    const [eventos, setEventos] = useState([]);
    const [acoes, setAcoes] = useState([]);
    const [cardapio, setCardapio] = useState([]);
    const [carregandoEventos, setCarregandoEventos] = useState(false);
    const [carregandoCardapio, setCarregandoCardapio] = useState(false)
    const [notificacao, setNotificacao] = useState(null);

    const [modalTeste, setModalTeste] = useState(false);
    const [salvandoDeclaracaoFrequencia, setSalvandoDeclaracaoFrequencia] = useState(false);

    const [dadosAlerta, setDadosAlerta] = useState({ exibir: false, texto: '' });
    const [dadosRematricula, setDadosRematricula] = useState({});
    const [aceitoEnsinoReligioso, setAceitoEnsinoReligioso] = useState('');
    const [grauParentesco, setGrauParentesco] = useState('');
    const [cpfFamiliar, setCpfFamiliar] = useState('');
    const [nomeFamiliar, setNomeFamiliar] = useState();

    const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

    const optionalConfigObject = {
        title: 'Touch-Id', // Android
        imageColor: '#e00606', // Android
        imageErrorColor: '#ff0000', // Android
        sensorDescription: 'Sensor de touch', // Android
        sensorErrorDescription: 'Falhou', // Android
        fallbackLabel: 'Show Passcode', // iOS (if empty, then label is hidden)
        unifiedErrors: false, // use unified error messages (default false)
        passcodeFallback: false, // iOS - allows the device to fall back to using the passcode, if faceid/touch is not available. this does not mean that if touchid/faceid fails the first few times it will revert to passcode, rather that if the former are not enrolled, then it will use the passcode.
    };

    function _handleNotification(notification) {
        if (notification.origin === 'selected') {
            if (!!notification.data?.codigo) {
                setNotificacao(notification.data);
            }
        }
    }

    async function carregarDados() {
        setModalDataSelecionada(JSON.parse(`{"${format(dataFiltro, "yyyy-MM-dd")}": {"selected": true, "selectedColor": "#af453d"}}`));
        if (usuario.tipo === 2) setAcoes([{ icon: 'email', label: 'Enviar uma Mensagem', onPress: () => props.navigation.navigate('EnviarMensagem') }]);
        else setAcoes([{ icon: 'today', label: 'Criar um Evento', onPress: () => props.navigation.navigate('AdicionarEvento') }]);
    }

    async function carregarHorarios() {
        setCarregandoHorarios(true);
        api.get("/ApiMobileHorarios/listarHorarios?_pes_codigo=" + usuario.pes_codigo + "&_data=" + format(dataFiltro, "dd/MM/yyyy")).then((retorno) => {
            if (retorno.status === 200) setHorarios(retorno.data);
            setCarregandoHorarios(false);
        }, (erro) => {
            console.error(erro);
            setCarregandoHorarios(false);
        });
    }
    async function carregarCardapio() {

        setCarregandoCardapio(true);
        api.get("/ApiMobileCardapio/ListaCardapio?pes_codigo=" + usuario.pes_codigo + "&data_inicial=" + format(dataFiltro, "dd/MM/yyyy") + "&data= " + format(dataFiltro, "dd/MM/yyyy")).then((retorno) => {

            if (retorno.status === 200)
                setCardapio(retorno.data);
            setCarregandoCardapio(false);
        }, (erro) => {
            console.error(erro);
            setCarregandoCardapio(false);
        });
    }

    async function carregarChamada() {
        setCarregandoChamadas(true);
        api.get("/ApiMobileHorarios/listarChamadaEstudante?_pes_codigo=" + usuario.pes_codigo + "&_data=" + format(dataFiltro, "dd/MM/yyyy")).then((retorno) => {
            if (retorno.status === 200) setChamadas(retorno.data.RegistrosChamadas);
            setCarregandoChamadas(false);
        }, (erro) => {
            console.error(erro);
            setCarregandoChamadas(false);
        });
    }

    async function carregarEventos() {
        if (!!usuario.tipo) {
            setCarregandoEventos(true);
            api.get("/ApiMobileEventos/listarEventosData?_pes_codigo=" + usuario.pes_codigo + "&_tipo=" + usuario.tipo + "&_dataAvaliacao=" + format(dataFiltro, "dd/MM/yyyy")).then((retorno) => {
                if (retorno.status === 200) setEventos(retorno.data.eventos);
                setCarregandoEventos(false);
            }, (erro) => {
                console.error(erro);
                setCarregandoEventos(false);
            });
        }
    }

    async function carregarDadosDeclaracaoFrequencia() {
        if (estudante.matricula) {
            api.get("/ApiMobileMatricula/buscarDadosMatriculaSeguinte?_mtr_atual=" + estudante.matricula).then((retorno) => {
                if (retorno.status === 200) {
                    if (!!retorno.data.dados) {
                        if (retorno.data.dados.declaracaoConfirmada == false) {
                            setDadosRematricula(retorno.data.dados);
                            setModalTeste(true);
                        }
                    }
                }
            }, (erro) => {
                //console.error(erro);
            });
        }
    }

    function confirmarDeclaracaoFrequencia() {
        let dadosObrigatorios = "";

        if (!nomeFamiliar) {
            dadosObrigatorios += "* Nome\n";
        } else if (!!nomeFamiliar && (nomeFamiliar.split(" ").length < 2)) {
            dadosObrigatorios += "* Deve ser informado o Nome e Sobrenome\n";
        } else if (nomeFamiliar.split(" ")[0].length < 3 || nomeFamiliar.split(" ")[1].length < 3) {
            dadosObrigatorios += "* Deve ser informado o Nome e Sobrenome correto\n";
        }

        if (!cpfFamiliar) dadosObrigatorios += "* CPF\n";
        if (!!cpfFamiliar && !cpfField.isValid()) dadosObrigatorios += "* CPF inválido\n";
        if (!grauParentesco) dadosObrigatorios += "* Grau de Parentesco\n";
        if (!aceitoEnsinoReligioso) dadosObrigatorios += "* Frequência\n";

        if (dadosObrigatorios != "") {
            exibirAlerta('Dados Obrigatórios', dadosObrigatorios);
        } else {
            setSalvandoDeclaracaoFrequencia(true);

            let data = {
                mtrCodigo: dadosRematricula.matricula,
                nomeResponsavel: nomeFamiliar,
                cpfReponsavel: Util.retornaApenasNumeros(cpfFamiliar),
                tipoResponsavel: grauParentesco,
                matricularEnsReligioso: (aceitoEnsinoReligioso === 'N' ? false : true)
            };

            api.post('/ApiMobileMatricula/salvarDeclaracao?data=' + JSON.stringify(data)).then((retorno) => {
                setSalvandoDeclaracaoFrequencia(false);

                if (retorno.status === 200) {
                    if (retorno.data.success === true) {
                        setModalTeste(false);
                        setDadosAlerta({ exibir: true, texto: 'Declaração recebida com sucesso.' });
                    } else {
                        exibirAlerta('Erro:', 'Ocorreu um erro ao salvar a declaração!');
                    }

                }
            }, (erro) => {
                setSalvandoDeclaracaoFrequencia(false);
                exibirAlerta('Erro:', 'Ocorreu um erro ao salvar a declaração!');
                console.log('Ocorreu um erro ao salvar a declaração.', erro)
            });
        }
    }

    async function carregarTodosDados() {
        carregarHorarios();
        //carregarChamada();
        carregarEventos();
        carregarCardapio();

        if (usuario.ApiEducar == "https://saojose.educarweb.net.br" )
            //usuario.ApiEducar == "http://localhost:59473" ||
            //usuario.ApiEducar == "http://localhost:49699" ||
            //usuario.ApiEducar == "http://localhost:50601" ||
            //usuario.ApiEducar == "http://localhost:64719")            
            carregarDadosDeclaracaoFrequencia();
    }

    function logAcesso() {
        Network.getNetworkStateAsync().then((rede) => {
            Device.getDeviceTypeAsync().then((tipoDispositivo) => {
                const especificacaoDispositivo = ['N/A', 'SMARTPHONE', 'TABLET', 'COMPUTADOR']
                let infoLog = {
                    pes_codigo: usuario.pes_codigoLogin,
                    oru_codigo: 0,
                    tpp_codigo: usuario.tipo,
                    acl_tipodispositivo: especificacaoDispositivo[tipoDispositivo],// 1=SMARTPHONE   2=TABLET    3=DESKTOP/PC
                    acl_sodispositivo: Device.osName,
                    acl_acessoweb: Platform.OS === "web",
                    atp_descricao: rede.type,
                    afd_descricao: Device.brand,
                    mtr_codigo: estudante.matricula
                };
                global.logAcesso = true;
                api.post('/ApiMobile/AtualizaEstatisticaAcesso?data=' + JSON.stringify(infoLog)).then(() => { }, (erro) => { console.log('erro ao registrar estatistica de acesso', erro) });
            });
        })
    }

    useEffect(() => {

        if (usuario.tipo === 2) setAcoes([{ icon: 'email', label: 'Enviar uma Mensagem', onPress: () => props.navigation.navigate('EnviarMensagem') }]);
        else setAcoes([{ icon: 'today', label: 'Criar um Evento', onPress: () => props.navigation.navigate('AdicionarEvento') }]);
    }, [usuario])

    useEffect(() => {
        //const switchOn = await AsyncStorage.getItem('EducarWebEstudanteApp:SwitchOn')
        if (usuario.tipo !== null || !!estudante.matricula) {


            if (global.logAcesso == false && !!estudante.matricula) {
                logAcesso();
            }
        }
        carregarTodosDados();
    }, [dataFiltro, estudante]);

    useEffect(() => {
        Notifications.addNotificationReceivedListener(_handleNotification);
        carregarTodosDados();
    }, []);

    const onRefresh = useCallback(() => {
        if (usuario.tipo !== null) {
            setRefreshing(true);
            carregarTodosDados().then(() => setRefreshing(false));
        }
    }, []);

    function abrirModalFrequencia(frequencia) {
        setDadosModalFrequencia(frequencia);
        setModalInformacoesFrequencia(true);
    }

    function abrePaginaURL(link) {
        if (Platform.OS == "web") {
            window.open(link, "_blank");
        } else {
            Linking.openURL(link);
        }
    }

    function exibirAlerta(titulo, texto) {
        if (Platform.OS === "web") {
            alert(titulo + '\n\n' + texto);
        }
        else {
            Alert.alert(
                titulo,
                texto
            );
        }
    }

    function navigateToScreen(route) {
        const navigateAction = NavigationActions.navigate({
            routeName: route
        });
        props.navigation.dispatch(navigateAction);
    }

    return (
        <View style={[styles.container, { flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
            <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
                <View style={{ margin: 10, flexDirection: 'row', justifyContent: "center" }}>
                    <TouchableOpacity style={styles.btnCalendario} onPress={() => setModalFiltroData(true)}>
                        <View style={{ position: "absolute", alignSelf: "flex-start", paddingStart: 10 }}>
                            <Ionicons name="md-calendar" size={18} color="#FFF" />
                        </View>
                        <Text style={[styles.dia, { color: "#FFF", fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSizeDia }]}>{dataFiltro.getDate()}</Text>
                        <Text style={[styles.mesAno, { color: "#FFF", fontSize: ThemeContext._currentValue.theme?.fontSizeMes }]}>{format(dataFiltro, "MMM", { locale: ptBR })}</Text>
                        <Text style={[styles.mesAno, { color: "#FFF", fontSize: ThemeContext._currentValue.theme?.fontSizeMes }]}>{format(dataFiltro, "uuu", { locale: ptBR })}</Text>
                        <Text style={[styles.diaSemana, { color: "#FFF", fontSize: ThemeContext._currentValue.theme?.fontSizeDiaSemana }]}>{format(dataFiltro, "iiii", { locale: ptBR })}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.dadosEstudante} onPress={() => { if (filhos.length > 0) /*if (usuario.tipo === 2)*/ setModalSelecionaFilho(true) }}>
                        <View style={{ flexDirection: "row" }}>
                            <Text ellipsizeMode="clip" style={[styles.textoNomeAluno, { fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textBlack }]} numberOfLines={2}>{estudante.nome}</Text>
                            {usuario.tipo === 2 && <MaterialCommunityIcons name="account-switch" size={20} color="#212020" />}
                        </View>
                        <View>
                            {(!!estudante?.codPessoa || !!estudante?.pes_codigo) && <Text style={{ fontSize: ThemeContext._currentValue.theme?.fontSize10 }}>Cod.: {estudante?.codPessoa || estudante?.pes_codigo}</Text>}
                            <Text style={{ fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>{estudante.turma}</Text>
                            <Text style={{ fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>{estudante.escola}</Text>
                            {(estudante.nperiodo != 0) && <Text style={{ fontSize: ThemeContext._currentValue.theme?.fontSize10 }}>{estudante.periodo}</Text>}
                        </View>

                    </TouchableOpacity>
                    {Platform.OS === "web" && Dimensions.get("screen").width >= 1024 &&
                        <React.Fragment>
                            <TouchableOpacity style={styles.btnAcessoRapido} onPress={() => props.navigation.navigate('Documentos')}>
                                <MaterialCommunityIcons name="attachment" size={36} color="#212020" />
                                <Text style={[styles.textoBtnAcessoRapido, { fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textBlack }]}>Documentos</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnAcessoRapido} onPress={() => props.navigation.navigate('Horarios')}>
                                <MaterialCommunityIcons name="timer" size={36} color="#212020" />
                                <Text style={[styles.textoBtnAcessoRapido, { fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textBlack }]}>Horários</Text>
                            </TouchableOpacity>

                            {/*<TouchableOpacity style={styles.btnAcessoRapido} onPress={() => props.navigation.navigate('Notas')}>
                <MaterialCommunityIcons name="chart-line-variant" size={36} color="#212020" />
                <Text style={styles.textoBtnAcessoRapido}>Notas</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.btnAcessoRapido} onPress={() => props.navigation.navigate('Frequencia')}>
                <MaterialIcons name="assignment" size={36} color="#212020" />
                <Text style={styles.textoBtnAcessoRapido}>Frequência</Text>
              </TouchableOpacity>*/}

                            {/*menuApp.length > 0 &&
                <React.Fragment>
                  {_.orderBy(menuApp, 'men_codigo').map((menu, indice) => (
                    <TouchableOpacity style={styles.btnAcessoRapido} onPress={() => props.navigation.navigate(menu.men_link)}>
                    <IconButton icon={menu.men_icone} size={36} color="#212020" />
                    <Text style={styles.textoBtnAcessoRapido}>{menu.men_descricao}</Text>
                    </TouchableOpacity>
                  ))}

                </React.Fragment>*/
                            }

                        </React.Fragment>
                    }
                </View>

                {usuario.ApiEducar == "https://restingaseca.educarweb.net.br" &&
                    <TouchableOpacity style={styles.saojose} onPress={() => abrePaginaURL('https://forms.gle/gVssaHY6MUrka6ZL6')}>
                        <Text style={styles.saojosetexto}>Formulário de interesse para recebimento de kits de alimentos <Text style={{ fontSize: ThemeContext._currentValue.theme?.fontSize10 }}>(toque para responder)</Text></Text>
                    </TouchableOpacity>
                }

                {(/* estudante.nperiodo != '0' && */(usuario.ApiEducar == "https://saojose.educarweb.net.br" ||
                    //usuario.ApiEducar == "https://palhoca.educarweb.net.br" ||
                    usuario.ApiEducar == "http://localhost:8854" || //pc da claudia
                    usuario.ApiEducar == "http://localhost:34108" || //pc da michele
                    usuario.ApiEducar == " http://localhost:34289" || //pc da vinicius
                    usuario.ApiEducar == "http://localhost:61527"))
                    &&
                    <TouchableOpacity style={styles.saojose} onPress={() => navigateToScreen('Rematricula')}>
                        <Text style={styles.saojosetexto}>REMATRÍCULA <Text style={{ fontSize: (Platform.OS === 'web' ? 14 : 10), }}>(toque para acessar)</Text></Text>
                    </TouchableOpacity>
                }

                <View style={styles.gridArea}>
                    <View style={styles.gridItem}>
                        <DataCardHeader titulo="Horários de Aula" icone="refresh" onPress={carregarHorarios} />
                        {carregandoHorarios ? <ActivityIndicator style={{ margin: 50 }} /> : <ListaHorariosAula dados={horarios} />}
                    </View>
                    {/*<View style={styles.gridItem}>
            <DataCardHeader titulo="Frequência" icone="refresh" onPress={carregarChamada} />
            {carregandoChamadas ? <ActivityIndicator style={{ margin: 50 }} /> : <ListaFrequencia dados={chamadas} detalhes={abrirModalFrequencia} />}
        </View>*/}
                    <View style={styles.gridItem}>
                        <DataCardHeader titulo="Eventos" icone="refresh" onPress={carregarEventos} />
                        {carregandoEventos ? <ActivityIndicator style={{ margin: 50 }} /> : <ListaEventos dados={eventos} />}
                    </View>
                    <View style={styles.gridItem}>
                        <DataCardHeader titulo="Cardápio" icone="refresh" onPress={carregarCardapio} />
                        {carregandoCardapio ? <ActivityIndicator style={{ margin: 50 }} /> : <ListaCardapio dados={cardapio} />}
                        <Button onPress={() => props.navigation.navigate('Cardapio')}>Cardápio da semana</Button>

                    </View>

                </View>
            </ScrollView>

            <FAB.Group
                open={fabOpen}
                icon={fabOpen ? 'close' : 'add'}
                onPress={() => {
                    dispatch({
                        type: 'alterarQtdeNotificacoes',
                        newQtdeNotificacoes: 99
                    });
                }}
                actions={acoes}
                onStateChange={({ open }) => setFabOpen(open)}
            />

            <Modal visible={modalFiltroData} onDismiss={() => setModalFiltroData(false)}>
                <View style={[styles.containerModalData, { padding: 10, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 325 : 5), alignItems: "center", backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
                    <Text style={{ margin: 10, fontSize: ThemeContext._currentValue.theme?.fontSize16, fontWeight: "bold", color: ThemeContext._currentValue.theme?.textWhite }}>Informe a data desejada</Text>
                    <Calendar
                        style={{
                            borderWidth: 1,
                            borderColor: 'gray',
                            height: 350,
                            width: (Platform.OS === 'web' ? 450 : Dimensions.get("window").width - 60),
                        }}
                        renderArrow={(direction) => <IconButton icon={`keyboard-arrow-${direction}`} size={22} />}
                        markedDates={modalDataSelecionada}
                        current={format(dataFiltro, "yyyy-MM-dd")}
                        onDayPress={(day) => {
                            setModalDataSelecionada(JSON.parse(`{"${day.dateString}": {"selected": true, "selectedColor": "#af453d"}}`));
                            setDataFiltro((new Date(day.year, (day.month - 1), day.day)));
                            setModalFiltroData(false);
                        }} />

                    <Button mode="outlined" style={{ margin: 15, alignSelf: 'flex-end' }} onPress={() => setModalFiltroData(false)}>OK</Button>
                </View>
            </Modal>

            <ModalSelecionaFilho visible={modalSelecionaFilho} onDismiss={() => setModalSelecionaFilho(false)} />
            <ModalInformacoesFrequencia visible={modalInformacoesFrequencia} dados={dadosModalFrequencia} onDismiss={() => setModalInformacoesFrequencia(false)} />
            <ModalAviso visible={!!notificacao} dados={notificacao} onDismiss={() => setNotificacao(null)} />

            <Modal visible={modalTeste} contentContainerStyle={styles.containerModalDeclaracao}>
                <View style={{ alignItems: 'center' }}>
                    <Text style={{ fontSize: 18, fontWeight: "bold" }}>Declaração de frequência na disciplina de Ensino Religioso</Text>
                </View>
                <Divider />

                <ScrollView>
                    <Text style={{ marginTop: 10 }}>Eu </Text>
                    <TextInput style={{ flex: 1, marginBottom: 5 }} placeholder="Informe o nome e sobrenome" label="" value={nomeFamiliar} onChangeText={setNomeFamiliar} />

                    <Text>CPF nº </Text>
                    <TextInput style={{ flex: 1, marginBottom: 5 }} label=""
                        value={cpfFamiliar}
                        onChangeText={setCpfFamiliar}
                        render={props =>
                            <TextInputMask
                                {...props}
                                type={'cpf'}
                                keyboardType="decimal-pad"
                                ref={(ref) => cpfField = ref}
                            />
                        }
                    />

                    <View style={{ flexDirection: 'row', justifyContent: "center" }}>
                        <RadioButton.Group
                            onValueChange={value => setGrauParentesco(value)}
                            value={grauParentesco}
                        >
                            <View style={{ flexDirection: 'row', marginRight: 5 }}>
                                <RadioButton value="P" />
                                <Text style={{ marginTop: 10 }} onPress={() => setGrauParentesco('P')}>Pai</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginRight: 5 }}>
                                <RadioButton value="M" />
                                <Text style={{ marginTop: 10 }} onPress={() => setGrauParentesco('M')}>Mãe</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <RadioButton value="R" />
                                <Text style={{ marginTop: 10 }} onPress={() => setGrauParentesco('R')}>Responsável</Text>
                            </View>
                        </RadioButton.Group>
                    </View>

                    <Text>pelo/a estudante {estudante.nome}, matrículado na Unidade Escolar {dadosRematricula.nomeEscola} no ano de {dadosRematricula.ano} período {dadosRematricula.periodo}, estou ciente da oferta da disciplina de Ensino Religioso na Rede Municipal de Ensino de São José
                        no contraturno e opto pela:</Text>

                    <View style={{ flexDirection: 'row', justifyContent: "center", margin: 10 }}>
                        <RadioButton.Group
                            onValueChange={value => setAceitoEnsinoReligioso(value)}
                            value={aceitoEnsinoReligioso}
                        >
                            <View style={{ flexDirection: 'row' }}>
                                <RadioButton value="S" />
                                <Text style={{ marginTop: 10 }} onPress={() => setAceitoEnsinoReligioso('S')}>Frequência</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <RadioButton value="N" />
                                <Text style={{ marginTop: 10 }} onPress={() => setAceitoEnsinoReligioso('N')}>Infrequência</Text>
                            </View>
                        </RadioButton.Group>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: 10, marginBottom: 5 }}>
                        <Button loading={salvandoDeclaracaoFrequencia} disabled={salvandoDeclaracaoFrequencia} mode="contained" style={{ marginRight: 15 }} onPress={() => confirmarDeclaracaoFrequencia()}>Confirmar</Button>
                    </View>


                </ScrollView>
            </Modal>

            <Snackbar
                visible={dadosAlerta.exibir}
                style={{ backgroundColor: '#4caf50' }}
                onDismiss={() => setDadosAlerta({ exibir: false, texto: '' })}>
                {dadosAlerta.texto}
            </Snackbar>

        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    btnCalendario: {
        backgroundColor: '#af453d',
        height: 130,
        width: 120,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center"
    },
    dadosEstudante: {
        padding: 15,
        marginLeft: 10,
        borderColor: '#af453d',
        borderWidth: 1,
        backgroundColor: '#fefefe',
        maxWidth: (Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? Dimensions.get("window").width / 2 : Dimensions.get("window").width) - 150,
        height: 130,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center"
    },
    dia: {
        color: "#FFF",
        fontSize: 38,
        fontWeight: "bold"
    },
    mesAno: {
        color: '#FFF',
        fontSize: 18
    },
    diaSemana: {
        fontSize: 10,
        color: '#FFF'
    },
    btnAcessoRapido: {
        marginLeft: 10,
        backgroundColor: '#cecece',
        height: 130,
        width: 100,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
        borderColor: '#212020',
        borderWidth: 1
    },
    textoBtnAcessoRapido: {
        fontSize: 14,
        color: '#212020'
    },
    textoNomeAluno: {
        fontSize: 14,
        color: '#212020',
        fontWeight: "bold",
        marginRight: 20,
    },
    gridArea: {
        flexDirection: flexDir,
        flex: 1,
        flexWrap: 'wrap',
    },
    gridItem: {
        flex: 1,
        marginVertical: 5,
        marginHorizontal: (Platform.OS === "web" ? 10 : 0),
        backgroundColor: 'white',
        minWidth: (Platform.OS === "web" ? 300 : '100%'),
        minHeight: 120,
        elevation: 2,
    },
    containerModalData: {
        padding: 10,
        margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 5),
        backgroundColor: "#FFF",
        alignItems: "flex-start"
    },
    saojose: {
        height: 60,
        borderRadius: 5,
        justifyContent: "center",
        borderColor: '#7159c1',
        borderWidth: 1,
        backgroundColor: '#7159c1',
        marginVertical: 5,
        marginHorizontal: 10,
        elevation: 2,
    },
    saojosetexto: {
        color: '#FFF',
        fontSize: (Platform.OS === 'web' ? 18 : 16),
        textAlign: "center",
        fontWeight: "bold",
    },

    containerModalDeclaracao: {
        padding: 10,
        //margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 420 : 5),
        margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? Dimensions.get('window').width >= 1900 ? 620 : 380 : 10),
        backgroundColor: "#FFF",
        justifyContent: "flex-start"
    }
});