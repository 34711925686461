import React, { useEffect, useState, useRef } from 'react';
import { View, Text, Dimensions, StyleSheet, Platform, ScrollView } from 'react-native';
import { Menu, IconButton, Card, Avatar, ActivityIndicator, Colors } from 'react-native-paper';
import { WebView } from 'react-native-webview';

import { useStateValue } from '../../state';
import { Api } from '../../services';

export default function MensagemChat({ mensagem, visualizarAnexo }) {
    
  const [{ usuario, estudante }, dispatch] = useStateValue();
  const [ menuAcoes, setMenuAcoes ] = useState(false);

  const [ urlImagem, setUrlImagem ] = useState('');
  const [ loadingImagem, setLoadingImagem ] = useState(false);

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
  
  let webViewRef = useRef();
  let iframeEl = useRef(null);

  function apagarMensagem(codigoMensagem){
      api.get('/ApiMobileMensagens/apagarMensagem?chn_codigo=' + codigoMensagem).then(res => {
          if (res.data?.success === true){
          }
          else exibirAlerta("Erro", res.data.mensagem);
      }, err => {
          console.error("Erro ao apagar a mensagem", err);
      });
      
      mensagem.mensagemapagada = true;
      mensagem.chn_mensagem = '';
  }

  useEffect(() => {
   
    if(!!mensagem.arquivo) {
      mensagem.arquivo.tipoArquivo = 0;
      
      previewAnexo(mensagem.arquivo);
/*
      if (mensagem.arq_mimetype.match(`image\/.*`)|| mensagem.extencao.toLowerCase().match('(.*\.png)|(.*\.jpeg)|(.*\.jpg)|(.*\.gif)')) {//frame de imagens
        mensagem.icon = 'image';
        mensagem.tipoArquivo = 1;
      } else if (mensagem.arq_mimetype.match(`video\/.*`)  || mensagem.extencao.match('(.*\.mp4)')) {//player de video
        mensagem.icon = 'ondemand-video';
        mensagem.tipoArquivo = 2;
      } else if (mensagem.arq_mimetype.match(`audio\/.*`)) {//player de audio
        mensagem.icon = 'audiotrack';
      } else if (mensagem.arq_mimetype.match(`.*\/(msword.*)|(octet-stream.*)|(vnd.*)`) && !(mensagem.arq_mimetype.match(`(.*\/zip.*)|(.*\/x-zip.*)|(.*\/rar.*)`) || mensagem.arq_nome.match(`(.*\.rar)|(.*\.zip)|(.*\.exe)`)) || (mensagem.arq_nome.match(`(.*\.rar)|(.*\.zip)`) && arquivoZip)) {
        mensagem.icon = 'insert-drive-file';
      } else if (mensagem.arq_mimetype.match(`.*\/html`)) {
        mensagem.icon = 'find-in-page';
      } else if ((!(mensagem.arq_mimetype.match(`(.*\/zip.*)|(.*\/x-zip.*)|(.*\/rar.*)`) || mensagem.arq_nome.match(`(.*\.rar)|(.*\.zip)|(.*\.exe)`)) || (mensagem.arq_nome.match(`(.*\.rar)|(.*\.zip)`)))) {//google docs pra abrir pdf
        mensagem.icon = 'folder';
      } else {
        mensagem.icon = '';
      }*/

    }

  }, [mensagem]);

  function previewAnexo(arquivo){
    setLoadingImagem(true);
    
    let formatos_nao_suportados = [".dot", ".mht", ".mhtml", ".xml", ".wps", ".wpd", ".xltx", ".xltm", ".xlam", ".xlm", ".xla", ".xlt", ".xll", ".xlw", ".prn", ".csv",
    ".mdb", ".mde", ".accdb", ".accde", ".dbc", ".igy", ".dqy", ".rqy", ".oqy", ".cub", ".uxdc", ".dbf", ".slk", ".dif", ".xlk", ".bak", ".xlb", ".pot", ".ppa", ".odp", ".thmx"];
    let mimetype = arquivo.mimetype;
    let src = '';
    let height = (Dimensions.get('window').height - (Dimensions.get('window').height * 0.30));
    let arquivoZip = (!!arquivo.zip) ? arquivo.zip : false;
    let nomeArquivo = arquivo.name;
    let extencao = `.${arquivo.extencao || nomeArquivo.split('.')[1]}`;
    let suportado = (formatos_nao_suportados.indexOf(extencao) == -1);
    arquivo.height = height;

    if (mimetype.match(`image\/.*`)|| extencao.toLowerCase().match('(.*\.png)|(.*\.jpeg)|(.*\.jpg)|(.*\.gif)')) {//frame de imagens
      src = `data:text/html;charset=utf-8,` +
          encodeURI(`<div style="text-align: center; position: absolute; top: 50%; left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);">
                  <img src="${arquivo.url}" style="justify-content: center;align-items: center;height: auto; max-height: 100vh;width:auto; max-width:100vw;"></div>`);

      arquivo.tipoArquivo = 1;
      setUrlImagem(arquivo.url);
    } else if (mimetype.match(`video\/.*`)  || extencao.match('(.*\.mp4)')) {//player de video
      src = `data:text/html;charset=utf-8,` +
              encodeURI(`<div style="text-align: center; position: absolute;  left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);">
                  <video style="justify-content: center;align-items: center;height:400px; max-height: 100vh;width:652px; max-width:100vw;" controls ><source src="${arquivo.url}" type="${(extencao.match('(.*\.mp4)') ? 'video/mp4':mimetype)}"></video></div>`);

      arquivo.tipoArquivo = 2;
      //arquivo.height = 396;
      arquivo.height = 400;
      setUrlImagem(src);
    } else if (mimetype.match(`audio\/.*`)) {//player de audio
      src = `data:text/html;charset=utf-8,` +
              encodeURI(`<div style="text-align: center; position: absolute; top: 50%; left: 50%; -moz-transform: translateX(-50%) translateY(-50%);-webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%);height: 50;width: 600;">
                  <audio style="justify-content: center;align-items: center;height: 100%; max-height: 100%;width:100%; max-width:100%;" controls><source src="${arquivo.url}" type="${mimetype}"></audio></div>`);

      arquivo.icon = 'audiotrack';
      arquivo.height = 75;
    } else if (mimetype.match(`.*\/(msword.*)|(octet-stream.*)|(vnd.*)`) && suportado && !(mimetype.match(`(.*\/zip.*)|(.*\/x-zip.*)|(.*\/rar.*)`) || nomeArquivo.match(`(.*\.rar)|(.*\.zip)|(.*\.exe)`)) || (nomeArquivo.match(`(.*\.rar)|(.*\.zip)`) && arquivoZip)) {
      var url = encodeURIComponent(arquivo.url);//arquivos do pacote office
      src = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;

      arquivo.icon = 'insert-drive-file';
    } else if (mimetype.match(`.*\/html`)) {
      src = (arquivo.url);

      arquivo.icon = 'find-in-page';
    } else if ((!(mimetype.match(`(.*\/zip.*)|(.*\/x-zip.*)|(.*\/rar.*)`) || nomeArquivo.match(`(.*\.rar)|(.*\.zip)|(.*\.exe)`)) || (nomeArquivo.match(`(.*\.rar)|(.*\.zip)`) && arquivoZip)) && suportado) {//google docs pra abrir pdf
      var url = encodeURIComponent(arquivo.url);
      src = `https://docs.google.com/viewer?url=${url}&embedded=true`;

      arquivo.icon = 'folder';
    } else {
      src = null;
    }
    
    setLoadingImagem(false);
  }

  function downloadDocumento(arq_codigo) {
    if (Platform.OS === 'web') window.open(`${usuario.ApiEducar}/abs_arquivos/downloadArquivoPorId?_arq_codigo=${arq_codigo}`, '_blank');
    else Linking.openURL(`${usuario.ApiEducar}/abs_arquivos/downloadArquivoPorId?_arq_codigo=${arq_codigo}`);
  }

  return (
    <>
    <View style={{ marginBottom: 6, flexDirection: 'row', justifyContent: (mensagem.codigoremetente === usuario.pes_codigoLogin ? 'flex-end' : 'flex-start')}}>
      <View style={{borderColor: '#ccc', borderWidth: 1, minWidth: 230, maxWidth: (Dimensions.get("window").width - (Dimensions.get("window").width * 0.26)), backgroundColor: (mensagem.codigoremetente === usuario.pes_codigoLogin ? '#8bd17d' : '#fefefe'), marginBottom: 10, marginRight: 10, borderRadius: 6, padding: 4}}>
        
        {mensagem.codigoremetente !== usuario.pes_codigoLogin &&
          <Text style={{fontSize: 10, fontWeight: '500', color: mensagem.cor}}>{mensagem.nomeremetente} {!!mensagem.gru_descricao ? `(${mensagem.gru_descricao.toLowerCase()} do(a) ${mensagem.nomefamiliarremetente.split(' ')[0]})`:''}</Text>
        }

        {mensagem.mensagemapagada && // mensagem apagada
            <Text style={{flex: 1, fontStyle: 'italic'}} numberOfLines={30}>
                <IconButton icon="block" size={14} style={{margin: 0, padding: 0, right: 0, bottom: 0, top: 2}}/>
                {mensagem.codigoremetente !== usuario.pes_codigoLogin ? 'Essa mensagem foi apagada.':'Você apagou essa mensagem.'}
            </Text>
        }
        
        {!mensagem.mensagemapagada && !!mensagem.chn_mensagem && // mensagem texto
          <Text style={{ marginLeft: 8, marginTop: 5}} numberOfLines={30}>{mensagem.chn_mensagem}</Text>
        }


        {!mensagem.mensagemapagada && !!mensagem.arquivo && mensagem.arquivo.tipoArquivo == 0 && // documento qualquer
          <>
          <View style={{flexDirection: "row", flex: 1, justifyContent: 'flex-end', marginTop: 5}}>
            <IconButton icon={mensagem.arquivo.icon} size={24} style={{margin: 0, padding: 0, right: 0, bottom: 0, top: 2}}/>
            <Text style={{flex: 1, marginLeft: 8}}>{mensagem.arquivo.arq_nome}</Text>
          </View>
          <View style={{flexDirection: "row", flex: 1, justifyContent: 'flex-end', marginBottom: 15}}>
            <IconButton icon="pageview" color="#4186c3" size={28} onPress={() => {visualizarAnexo(mensagem.arquivo);}}> Visualizar</IconButton>
            <IconButton icon="cloud-download" color="#4186c3" size={28} onPress={() => {downloadDocumento(mensagem.arquivo.arq_codigo);}} > Download</IconButton>
          </View>
          </>
        }

        {!mensagem.mensagemapagada && !!mensagem.arquivo && mensagem.arquivo.tipoArquivo == 2  && Platform.OS == "web" && // video
          <Card style={{ height: 200, marginLeft: 8, marginTop: 5}}>
            <iframe ref={iframeEl}
                id="idPreVisualizarAnexo"
                onLoad={() => {
                  
                }}
                frameBorder="0"
                title={mensagem.arquivo.arq_nome}
                height={400}
                allowFullScreen
                src={urlImagem}>
            </iframe>
          </Card>
        }

        {!mensagem.mensagemapagada && !!mensagem.arquivo && mensagem.arquivo.tipoArquivo == 2 && Platform.OS != "web" && // video
           <Card style={{flex: 1, marginLeft: 8, marginTop: 5}}>
              <WebView
                originWhitelist={['*']}
                height={mensagem.arquivo.height}
                source={{uri: urlImagem}}
                onLoadStart={() => {
                  
                }}
                onLoad={() => {
                  
                }}
                onLoadEnd={(e) => {
                  //setCarregandoPreVisualizacaoErro(true);
                }}
                startInLoadingState={true}
                ref={(ref) => (webViewRef.current = ref)}
                saveFormDataDisabled={true}
                />

                  {/*carregandoPreVisualizacao && (
                    <ActivityIndicator size="small" color="#0a83bf"
                      style={{ position: "absolute",
                              top: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.70)),
                              left:(Dimensions.get('window').width / 2.5)
                            }} />
                  )*/}
              </Card>
          }

        {!mensagem.mensagemapagada && !!mensagem.arquivo && mensagem.arquivo.tipoArquivo == 1 && // imagem
          <Card style={{flex: 1, }} onPress={() => {visualizarAnexo(mensagem.arquivo);}}>
            {loadingImagem && <ActivityIndicator animating={true} color={Colors.blue500} style={{marginBottom: 20, marginLeft: 8, marginTop: 5}} />}
            {!loadingImagem && <Card.Cover  source={{ uri: urlImagem }}  style={{marginBottom: 0, marginLeft: 0, marginTop: 0}}/>  }          
          </Card>
        }

        <View style={{height: 20, marginTop: 10}}>
          <Text style={{ position: 'absolute', fontSize: 11, color: '#3b484f', right: (mensagem.codigoremetente === usuario.pes_codigoLogin)? 60: 5, bottom: 0 }}>{`${mensagem.datahoraenvio}`}</Text>
        </View>

        {mensagem.codigoremetente === usuario.pes_codigoLogin &&
          <React.Fragment>
            
            {/*<IconButton icon="check" color={mensagem.msg_lido ? "#003b99" : "#cccccc" } size={18} style={{margin: 0, padding: 0, position: 'absolute', right: 25, bottom: 0}} />
            <IconButton icon="check" color={mensagem.msg_lido ? "#003b99" : "#cccccc" } size={18} style={{margin: 0, padding: 0, position: 'absolute', right: 30, bottom: 0}} /> 
            */}
            {!mensagem.mensagemapagada &&
                <View style={{margin: 0, padding: 0, position: 'absolute', right: -10, bottom: 0, height: 40}}>
                    <Menu 
                        visible={menuAcoes}
                        onDismiss={() => setMenuAcoes(false)}
                        anchor={
                            <React.Fragment>
                                <IconButton icon="more-vert" size={18} style={{bottom: 0}} onPress={() => setMenuAcoes(true)} />
                            </React.Fragment>}>
                        
                            <Menu.Item icon="" onPress={() => {
                                setMenuAcoes(false);
                                apagarMensagem(mensagem.chn_codigo);
                            }} title="Apagar mensagem" />
                            
                    </Menu>
                </View>
            }
          </React.Fragment>
        }
      </View>
    </View>
  
  </>
  );
}

const styles = StyleSheet.create({
    conteudoAcoes: { 
      flexDirection: "row", 
      flex: Dimensions.get('window').width >= 1024 ? 0.7 : 0.2, 
      justifyContent: 'flex-end'
    },
    containerModalVisualizar: {
      padding: 5,
      margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 10),
      backgroundColor: "#FFF"
    }
  })