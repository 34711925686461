import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Platform, Dimensions, ScrollView } from 'react-native';
import { ActivityIndicator, Modal, Divider, Colors } from 'react-native-paper';
import { SelecionaDiaSemana } from '../components';
import { useStateValue } from '../state';
import { Api } from '../services';
import { ThemeContext } from '../../theme-context';

const flexDir = Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? 'row' : 'column';
const justify = Platform.OS === 'web' && Dimensions.get("screen").width >= 1024 ? 'center' : 'flex-start';

export default function Cardapio() {

  const diasSemana = ["DOMINGO", "SEGUNDA-FEIRA", "TERÇA-FEIRA", "QUARTA-FEIRA", "QUINTA-FEIRA", "SEXTA-FEIRA", "SÁBADO"];

  const [{ usuario }, dispatch] = useStateValue();
  const [carregando, setCarregando] = useState(true);
  const [diaSemana, setDiaSemana] = useState(null);
  const [listaCardapio, setListaCardapio] = useState([]);
  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  useEffect(() => {
    api.get("/ApiMobileCardapio/listarCardapioSemana?pes_codigo=" + usuario.pes_codigo).then((retorno) => {
      if (retorno.status === 200) {
        setListaCardapio(retorno.data.lista);
      
        setCarregando(false);
      }
    }, (erro) => {
      console.error(erro);
      setCarregando(false);
    });
  }, [usuario.pes_codigo]);

  let filtrarPorTipo = () => {
  
    return listaCardapio.filter((item) => {
      return item.listacardapiosemana.some(d => d.diasemanaextenco === diasSemana[diaSemana])
    }).map((item) => {
      let listacardapio = item.listacardapiosemana.filter((c) => { return c.diasemanaextenco === diasSemana[diaSemana] });
      return {
        tpo_descricao: item.tpo_descricao,
        listacardapiosemana: listacardapio
      };
      
    }
    )

  };

  function CardapioSemanaMobile(lista) {
    return (
        <View style={{flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }}>
           <ScrollView>
          {lista?.length > 0 && lista.map((cardapios, indice) => (
            <React.Fragment key={indice} >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ maxWidth: (Dimensions.get("screen").width - 10) }}>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ height: 30, width: 5, backgroundColor: (!!cardapios.listacardapiosemana[0].tpo_cor ? '#' + cardapios.listacardapiosemana[0].tpo_cor.trim() : '#FFF') }} />
                      <Text style={[styles.descricaoMobile,{ color: ThemeContext._currentValue.theme?.textWhite}]}>{cardapios.tpo_descricao} {"\n"}
                      </Text>
                    </View>
                    {cardapios.listacardapiosemana.map((cardapio, indice) => (
                      <View key={indice}> 
                        <Text style={[styles.refeicaoMobile, { color: ThemeContext._currentValue.theme?.textWhite}]}>
                          - {cardapio.descricao_produto}  {"\n"}
                        </Text>
                      </View>
                    ))}
                    <Divider style={styles.dividerMobile} />
                </View>
              </View>
            </React.Fragment>
          ))}
          
          {lista.length === 0 && carregando === false && (
            <View style={{flex: 1, justifyContent: 'center'}}>
              <Text style={styles.textoSemDadosMobile}>Você não possui cardápio neste dia</Text>
            </View>
          )}
          </ScrollView>
        </View>
        
    )
  }

  function CardapioSemanaWeb(lista) {
    return (
      <ScrollView>
      <View style={{flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }}>
        {lista?.length > 0 && lista.map((cardapios, indice) => (
          <React.Fragment key={indice} >
              
              <View style={{ flexDirection: "row" }}>
             
                <View style={{ maxWidth: (Dimensions.get("screen").width - 10) }}>
                  <View style={{ flexDirection: "row" }}>
                
                    <View style={{ height: 30, width: 5, backgroundColor: (!!cardapios.listacardapiosemana[0].tpo_cor ? '#' + cardapios.listacardapiosemana[0].tpo_cor.trim() : '#FFF') }} />
                    <Text style={[styles.descricaoWeb, {color: ThemeContext._currentValue.theme?.textWhite}]}>{cardapios.tpo_descricao} {"\n"}
                    </Text>
                  </View>
                  {cardapios.listacardapiosemana.map((cardapio, indice) => (
                    <View key={indice}>
                      <Text style={[styles.refeicaoWeb, {color: ThemeContext._currentValue.theme?.textWhite}]}>
                        - {cardapio.descricao_produto}  {"\n"}
                      </Text>
                    </View>
                  ))}
                  <Divider style={styles.dividerWeb} />
              </View>
            </View>
          </React.Fragment>
        ))}
        {lista.length === 0 && carregando === false && (
        <View style={{flex: 1, justifyContent: 'center'}}>
          <View style={{ alignItems: "center"}}>
            <Text style={[styles.textoSemDados, { fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }]}>Você não possui cardápio neste dia</Text>
          </View>
          </View>
        )}
    </View>
    </ScrollView>
    )
  }


  return (
    <View style={styles.container}>
      <SelecionaDiaSemana onChange={(dia) => setDiaSemana(dia)} />
      {(Platform.OS === "web" && Dimensions.get("screen").width >= 1024) ? CardapioSemanaWeb(filtrarPorTipo()) : CardapioSemanaMobile(filtrarPorTipo())}
      <Modal visible={carregando}>
        <ActivityIndicator color={(!!!ThemeContext._currentValue.theme.dark) ? Colors.blue700 : Colors.white} size="large" />
      </Modal>
    </View>
  );

}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerConteudo: {
    flex: 1,
    marginTop: 5,
    flexWrap: 'wrap',
    flexDirection: flexDir,
    justifyContent: justify,
    alignContent: justify
  },
  materia: {
    fontSize: 14,
    fontWeight: "bold",
  },
  containerSemDados: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    
  },
  textoSemDados: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: 'center',
  },
  containerSemDadosMobile: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignContent: 'center'
  },
  textoSemDadosMobile: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: 'center',
  },
  textoSemDados: {
    fontSize: 16,
    fontWeight: "bold",
  },
  
  descricaoMobile: {
    fontWeight: "bold",
    fontSize: 17,
    margin: 5,
  },
  refeicaoMobile: {
    marginLeft: 10,
    fontSize: 15,
    marginBottom: 5,
  },
  dividerMobile: {
    width: 500
  },
  descricaoWeb: {
    fontWeight: "bold",
    fontSize: 17,
    margin: 5,
  },
  refeicaoWeb: {
    marginLeft: 15,
    fontSize: 14,
    marginBottom: 3,
  },
  dividerWeb: {
    width: 1500
  }
});




