import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView, Text, TouchableOpacity, StyleSheet, Dimensions, Platform, FlatList, Alert } from 'react-native';
import { Card, IconButton, Divider, List, ActivityIndicator, Modal, Searchbar, Colors, Title, Button } from 'react-native-paper';
import { WebView } from 'react-native-webview';

import { AlterarAluno, ModalSelecionaFilho } from '../components';
import { format } from 'date-fns';
import { useStateValue } from '../state';
import { Api, Util } from '../services';
import {ThemeContext} from '../../theme-context';

import _ from 'lodash';

export default function Notas() {

  const [{ usuario, estudante }, dispatch] = useStateValue();
  const [ carregando, setCarregando ] = useState(true);
  const [ alterarAluno, setAlterarAluno ] = useState(false);
  const [ dadosNotas, setDadosNotas ] = useState([]);
  const [ indiceNota, setIndiceNota ] = useState(0);

  const [ modalVisualizarParcial, setModalVisualizarParcial ] = useState(false);
  const [ carregandoModalParcial, setCarregandoModalParcial ] = useState(false);
  const [ listaParciais, setListaParciais ] = useState([]);
  const [ filtroParcial, setFiltroParcial ] = useState("");
  const [ componenteSelecionado, setComponenteSelecionado ] = useState("");
  const [ periodoSelecionado, setPeriodoSelecionado ] = useState("");

  const [ modalVisualizarParecer, setModalVisualizarParecer ] = useState(false);
  const [ parecerVisualizando, setParecerVisualizando ] = useState('');

  const [ webViewHeight, setWebViewHeight] = useState(0);

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
  
  let webview = useRef(null);
  let iframeEl = useRef(null);

  useEffect(() => {
    api.get("/ApiMobileNotas/buscarNotasPeriodo?_pes_codigo=" + usuario.pes_codigo+"&_mtr_codigo="+estudante.matricula).then((retorno) => {
      setCarregando(false);
      if (retorno.status === 200) {
        setDadosNotas(retorno.data.listaPeriodos);
      }
    }, (erro) => {
      setCarregando(false);
    });
  }, [usuario.pes_codigo]);

  useEffect(() => {
    let indiceAtual = dadosNotas.findIndex(el => el.atual === true);
    if (indiceAtual >= 0) setIndiceNota(indiceAtual);
  }, [dadosNotas]);

  function periodoAnterior() {
    if (indiceNota === 0) setIndiceNota(dadosNotas.length-1);
    else setIndiceNota(indiceNota-1);
  }

  function proximoPeriodo() {
    if (indiceNota === (dadosNotas.length-1)) setIndiceNota(0);
    else setIndiceNota(indiceNota+1);
  }

  function renderBoxNota(componente) {
    let corBoxNota = "";
    if (componente.media && componente.valor != 'Sem Nota' && componente.valor != 'Menção' &&  componente.valor != 'Parecer') corBoxNota = "#33cd5f";
    else if (!componente.media && componente.valor != 'Sem Nota' && componente.valor != 'Sem nota' && componente.valor != 'Menção' &&  componente.valor != 'Parecer') corBoxNota = "#ef473a";
    else if (componente.valor == 'Sem Nota' || componente.valor == 'Sem nota' || componente.valor == 'Dispensado' || componente.valor == 'Não compareceu') corBoxNota = "#f8f8f8";
    else if (componente.valor == 'Parecer' || componente.valor == 'Menção') corBoxNota = "#33cd5f";
    
    return (
      <>
        {componente.parecer != '' && componente.parecer != null &&
          <IconButton icon="description" size={28} onPress={() => visualizarParecer(componente)}/>
        }

        <TouchableOpacity style={[styles.boxNota, { backgroundColor: corBoxNota }]}>
          <Text>{componente.valor}</Text>
        </TouchableOpacity>

        <IconButton icon="keyboard-arrow-right" size={28} onPress={() => buscarParcial(componente)}/>
      </> 
    )
  }

  function exibirAlerta(titulo, texto) {
    if (Platform.OS === "web") {
      alert(titulo+'\n\n'+texto);
    }
    else {
      Alert.alert(
        titulo,
        texto
      );
    }
  }

  function buscarParcial(componente){
      setCarregandoModalParcial(true);
      let prl = dadosNotas[indiceNota];
      setComponenteSelecionado(componente.descricao);
      setPeriodoSelecionado(prl.descricao);
      let api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
      api.post('ApiMobileNotas/buscarNotasParciais?pesCodigo=' + estudante.codPessoa+'&cctCodigo='+componente.codigo+'&prlCodigo='+prl.codigo).then((retorno) => {
        setCarregandoModalParcial(false);

        if (retorno.status === 200) {
          let dados = retorno.data.parciais;
          setListaParciais(dados); 
          setModalVisualizarParcial(true);
        }else{
          exibirAlerta('Alerta', 'Não foram encontradas avaliações para serem exibidas!');
        }
      }, (erro) => {
        setCarregandoModalParcial(false);
        console.log('erro', erro.response);
      });
  }

  function visualizarParecer(componente){
    let prl = dadosNotas[indiceNota];
    setComponenteSelecionado(componente.descricao);
    setPeriodoSelecionado(prl.descricao);
    setParecerVisualizando(componente.parecer);
    setModalVisualizarParecer(true);
}

function onWebViewMessage(event) {
  setWebViewHeight(Number(event.nativeEvent.data));
  /*this.setState({webViewHeight: Number(event.nativeEvent.data)})*/
}

  return (
    <>
    <View style={[styles.container,{flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
      <AlterarAluno onPress={() => setAlterarAluno(true)}/>
      {dadosNotas.length > 0  &&
        <React.Fragment>
          <View style={styles.containerSeletor}>
            <IconButton icon="keyboard-arrow-left" size={28} onPress={() => periodoAnterior()}/>
            <View style={styles.containerTextoSeletor}>
              <Text style={styles.textoSeletor}>{dadosNotas[indiceNota].descricao}</Text>
            </View>
            <IconButton icon="keyboard-arrow-right" size={28} onPress={() => proximoPeriodo()}/>
          </View>

          <ScrollView>
          <View style={{margin: 5}}>
            {dadosNotas[indiceNota].tipo === 'N' && <List.Item title={<Text style={styles.texto}>Média: <Text style={styles.textoNegrito}>{dadosNotas[indiceNota].media}</Text></Text>} right={props => <Text style={styles.texto}>Nota Máxima: <Text style={styles.textoNegrito}>{dadosNotas[indiceNota].valor}</Text></Text>} /> }
            {dadosNotas[indiceNota].tipo === 'C' && <List.Item title={<Text style={styles.texto}>{dadosNotas[indiceNota].conceitos}</Text>} />}
            <Divider />
            
            {dadosNotas[indiceNota].componentes.length === 0 &&
              <View style={styles.containerCentralizado}>
                <Text style={styles.texto}>Você não possui notas neste período letivo.</Text>
              </View>
            }

            {dadosNotas[indiceNota].componentes.length > 0 && (dadosNotas[indiceNota].tipo !== 'P' && dadosNotas[indiceNota].tipo !== 'M') &&
              <ScrollView>
                {dadosNotas[indiceNota].componentes.map((componente, indice) => (
                  <React.Fragment key={indice}>
                    <List.Item title={<Text style={styles.texto}>{componente.descricao}</Text>} right={props => renderBoxNota(componente)} />
                    <Divider />
                  </React.Fragment>
                ))}
              </ScrollView>
            }

            {dadosNotas[indiceNota].componentes.length > 0 && (dadosNotas[indiceNota].tipo === 'P' || dadosNotas[indiceNota].tipo === 'M') &&
              <ScrollView>
                {dadosNotas[indiceNota].componentes.map((componente, indice) => (
                
                <React.Fragment key={indice}>

                    <Card style={{marginBottom: 10}}>
                      <Card.Title 
                      title={componente.descricao} 
                      right={(props) => 
                        componente.parecer != null && 
                        <IconButton {...props} icon="open-in-new" onPress={() => {
                          visualizarParecer(componente);
                        }} />
                        
                      }/>
                      <Divider />
                      <Card.Content>
                        {componente.parecer == null &&
                          <View style={styles.containerCentralizado}>
                            <Text>Você não possui parecer neste componente.</Text>
                          </View>
                        }

                        {Platform.OS == "web" && componente.parecer != null &&
                          <iframe ref={iframeEl}
                            id="idVisualizarParecer"
                            onLoad={() => {
                              
                            }}
                            frameBorder="0"
                            height={250}
                            allowFullScreen
                            srcDoc={componente.parecer}>
                          </iframe>
                        }
                        
                        {Platform.OS != "web" && componente.parecer != null &&
                        
                        <WebView
                          originWhitelist={['*']}
                          //height={150}
                          style={{ height: webViewHeight }}
                          automaticallyAdjustContentInsets={true}
                          //source={{html: `<div style="font-size: 30;">${componente.parecer}</div>`}}
                          source={{html: `
                          <!DOCTYPE html>
                          <html>
                            <head>
                              <meta http-equiv="content-type" content="text/html; charset=utf-8">
                              <meta name="viewport" content="width=320, user-scalable=no">
                            </head>
                            <body>
                            <div id="teste" >
                            ${componente.parecer}
                            </div>
                            </body>
                          </html>
                          `}}
                          startInLoadingState={true}
                          ref={(ref) => (webview.current = ref)}
                          saveFormDataDisabled={true}
                          scrollEnabled={false}
                          onMessage={onWebViewMessage}
                          injectedJavaScript='window.ReactNativeWebView.postMessage(document.getElementById("teste").clientHeight)'
                        />
                        }
                      </Card.Content>
                    </Card>
                    
                    {/*<Text style={styles.texto}>{componente.parecer}</Text>*/}
                  </React.Fragment>
                ))}
              </ScrollView>
            }

            {dadosNotas[indiceNota].componentes.length === 1 && dadosNotas[indiceNota].tipo === 'M' && dadosNotas[indiceNota].componentes[0].mencoes.length > 0 &&
              <View style={styles.linhaCentralizadoHorizontal}>
                <Text style={styles.texto}>Menções</Text>
              </View>
            }
            {dadosNotas[indiceNota].componentes.length === 1 && dadosNotas[indiceNota].tipo === 'M' && !!dadosNotas[indiceNota].componentes[0].mencoes.length === false &&
              <View style={styles.linhaCentralizadoHorizontal}>
                <Text style={styles.texto}>Avaliação ainda não foi concluída</Text>
              </View>
            }
            {dadosNotas[indiceNota].componentes.length === 1 && dadosNotas[indiceNota].tipo === 'M' &&
              dadosNotas[indiceNota].componentes[0].mencoes.map((mencao, indice) => (
                <React.Fragment key={indice}>
                  <List.Item title={<Text style={{fontSize: 12}}>{mencao.descricao}</Text>} right={props => (
                    <TouchableOpacity style={[styles.boxNota, { backgroundColor: "#F8F8F8" }]}>
                      <Text>{mencao.nota}</Text>
                    </TouchableOpacity>
                  )} />
                  <Divider />
                </React.Fragment>
              ))
            }
            {dadosNotas[indiceNota].componentes.length === 1 && dadosNotas[indiceNota].tipo === 'M' && dadosNotas[indiceNota].componentes[0].mencoes.length > 0 &&
              <React.Fragment>
                <View style={styles.linhaCentralizadoHorizontal}>
                  <Text style={styles.texto}>Legenda</Text>
                </View>
                {dadosNotas[indiceNota].componentes[0].conceitosMencoes.map((conceito, indice) => (
                  <List.Item key={indice} title={<Text style={styles.texto}>{conceito}</Text>} />
                ))}
              </React.Fragment>
            }
       
            {dadosNotas[indiceNota].recomendacoes.length > 0 &&
              <React.Fragment>
                <View style={styles.linhaCentralizadoHorizontal}>
                  <Text style={styles.texto}>Recomendações</Text>
                </View>
                {dadosNotas[indiceNota].recomendacoes.map((rec, indice) => (
                  <List.Item key={indice} title={<Text style={styles.texto}>{rec.descricao}</Text>} />
                ))}
              </React.Fragment>
            }
            {!!dadosNotas[indiceNota].resultado === true &&
              <View style={styles.linhaCentralizadoHorizontal}>
                <Text style={{fontSize: 20}}>{dadosNotas[indiceNota].resultado.descricao}</Text>
              </View>
            }
          </View>
          </ScrollView>
        </React.Fragment>
      }
      <Modal visible={carregando}>
        <ActivityIndicator color={(!!!ThemeContext._currentValue.theme.dark) ? Colors.blue700 : Colors.white} size="large" />
      </Modal>
      <ModalSelecionaFilho visible={alterarAluno} onDismiss={() => setAlterarAluno(false)} />
    </View>


    <Modal contentContainerStyle={[styles.containerModalParcial, { padding: 10, height: Dimensions.get('window').height - 100, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? (Dimensions.get('window').width >= 1900 ? 620: 280) : 20), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal } ]} visible={modalVisualizarParcial} onDismiss={() => {
        setModalVisualizarParcial(false);
        setCarregandoModalParcial(false);
      }}>
      <View style={styles.containerTituloParcial}>
        <Title style={{fontSize: Dimensions.get('window').width >= 1024 ? 20 : 16, color: ThemeContext._currentValue.theme?.textWhite }}>Lista de Avaliações de {componenteSelecionado} no {periodoSelecionado}</Title>
      </View>
      <Searchbar style={[styles.searchBar, {marginBottom: 10}]} placeholder="Localizar Avaliação" autoCapitalize="none" autoCorrect={false} returnKeyLabel="search" value={filtroParcial} onChangeText={setFiltroParcial} />
      
      <ScrollView>

      {listaParciais.length == 0 &&
          <View style={styles.linhaCentralizadoHorizontal}>
            <Text style={[styles.texto, {color: ThemeContext._currentValue.theme?.textWhite}]}>Não foram encontradas avaliações para serem exibidas!</Text>
          </View>
        }

        <FlatList data={_.filter(listaParciais, function(o) {
            return Util.retira_acentos(o.avaliacao).toLowerCase().replace(/ /g, "-").indexOf(Util.retira_acentos(filtroParcial).toLowerCase().replace(/ /g, "-")) > -1;
          })} renderItem={({item, index}) => (
            <React.Fragment key={index}>
               <View style={styles.containerAvaliacao}>

                <View style={styles.conteudoAvaliacoes}>
                  <View>
                    <Text ellipsizeMode="clip" numberOfLines={5} style={[styles.avaliacaoDescricao, {color: ThemeContext._currentValue.theme?.textWhite}]}>{item.avaliacao}</Text>
                  </View>
                  <View>
                    <Text style={{fontSize: Dimensions.get('window').width >= 1024 ? 14 : 12, color: ThemeContext._currentValue.theme?.textWhite}}>Realizado em: {format(new Date(item.dataAvaliacao), 'dd/LL/yyyy')}</Text>  
                  </View>

                  {item.parecer !== '' &&
                  <>
                  <Divider />
                  <View>
                    <Text style={{fontSize: 12, color: ThemeContext._currentValue.theme?.textWhite}} ellipsizeMode="clip" numberOfLines={5}>Parecer: {item.parecer}</Text>  
                  </View>
                  </>
                  }
                </View>

                <View style={styles.conteudoAvaliacaoNota}>
                  
                  <View style={styles.circuloNota}>
                    <Text style={[styles.nota, {textAlign: "center", fontSize: Dimensions.get('window').width >= 1024 ? 17 : 15,  fontWeight: "bold", paddingTop: Dimensions.get('window').width >= 1024 ? 20 : 10, color: ThemeContext._currentValue.theme?.textWhite}]}>{item.nota}</Text>
                    <Text style={[styles.peso, {textAlign: "center", fontSize: Dimensions.get('window').width >= 1024 ? 12 : 10,  borderTopWidth: 0.3, color: ThemeContext._currentValue.theme?.textWhite}]}>Peso: {item.peso}</Text>
                  </View>

                </View>
              </View>
              <Divider />
            </React.Fragment>
        )} keyExtractor={item => item.codigo}/>
      </ScrollView>
      
      <View style={{flexDirection: 'row', justifyContent: "flex-end", marginVertical: 5, marginBottom: 5}}>
        <Button style={{marginRight: 15}} mode="outlined" onPress={() => setModalVisualizarParcial(false)}>Cancelar</Button>

      </View>

      <Modal visible={carregandoModalParcial} style={styles.spinnerView}>
        <ActivityIndicator color={(!!!ThemeContext._currentValue.theme.dark) ? Colors.blue700 : Colors.white} size="large" />
      </Modal>
                
    </Modal>
    
    <Modal contentContainerStyle={styles.containerModal} visible={modalVisualizarParecer} onDismiss={() => {
        setModalVisualizarParecer(false);
        setParecerVisualizando('');
      }}>
      <View style={styles.containerTitulo}>
        <Title style={{fontSize: Dimensions.get('window').width >= 1024 ? 20 : 16}}>{componenteSelecionado} no {periodoSelecionado}</Title>
      </View>
      <Divider />
      <ScrollView>
        {Platform.OS == "web" &&
          <iframe ref={iframeEl}
            id="idVisualizarParecer"
            onLoad={() => {
              
            }}
            frameBorder="0"
            height={Dimensions.get('window').height - 200}
            allowFullScreen
            srcDoc={parecerVisualizando}>
          </iframe>
        }
                        
        {Platform.OS != "web" &&
          <WebView
            originWhitelist={['*']}
            height={Dimensions.get('window').height - 200}
            style={{flex: 1}}
            source={{html: `
                <!DOCTYPE html>
                <html>
                  <head>
                    <meta http-equiv="content-type" content="text/html; charset=utf-8">
                    <meta name="viewport" content="width=320, user-scalable=no">
                  </head>
                  <body>
                  <div id="teste" >
                  ${parecerVisualizando}
                  </div>
                  </body>
                </html>
            `}}
            startInLoadingState={true}
            ref={(ref) => (webview.current = ref)}
            saveFormDataDisabled={true}
            scrollEnabled={true}
          />
        }
      </ScrollView>
      
      <Divider />

      <View style={{flexDirection: 'row', justifyContent: "flex-end", marginVertical: 5, marginBottom: 5}}>
        <Button style={{marginRight: 15}} mode="outlined" onPress={() => setModalVisualizarParecer(false)}>Cancelar</Button>
      </View>
    </Modal>
      </>
  );
}

const styles = StyleSheet.create({
  spinnerView: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F5FCFF88",
  },
  container: {
    flex: 1
  },
  containerCentralizado: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
  },
  linhaCentralizadoHorizontal: {
    justifyContent: "center",
    margin: 3,
  },
  containerSeletor: {
    flexDirection: 'row',
    height: 50,
    backgroundColor: "#CECECE",
    alignItems: "center",
    justifyContent: "center",
  },
  containerTextoSeletor: {
    flex: 1,
    flexDirection: 'row',
    width: 220,
    alignItems: "center",
    justifyContent: "center",
  },
  textoSeletor: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 15
  },
  texto: {
    fontSize: 15,
  },
  textoNegrito: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  boxNota: {
    borderColor: "#202021",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    minWidth: 82
  },
  
  
  containerModal: {
    padding: 10,
    height: Dimensions.get('window').height - 50,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? (Dimensions.get('window').width >= 1900 ? 620: 280) : 20),
    backgroundColor: "#FFF",
  },
  containerTitulo: {
    alignSelf: "center",
  },
  searchBar: {
    fontSize: 9,
    flexDirection: 'row',
    color: Colors.red500
  },
  containerAvaliacao: {
    flexDirection: "row",
    flex: 1,
    minHeight: 92,
    alignItems: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  conteudoAvaliacoes: { 
    flexDirection: "column", 
    justifyContent: 'center',
    flex: 0.8
  },
  avaliacaoDescricao: {
    fontSize: Dimensions.get('window').width >= 1024 ? 14 : 12,
    fontWeight: "bold",
  },
  conteudoAvaliacaoNota: { 
    flexDirection: "row", 
    flex: 0.2, 
    justifyContent: 'flex-start', 
    alignItems: 'center',
    marginRight: Dimensions.get('window').width >= 1024 ? 0 : 10,
  },
  circuloNota:{
    backgroundColor: Colors.blue200, 
    borderRadius: 50, 
    minWidth: Dimensions.get('window').width >= 1024 ? 85 : 60, 
    minHeight: Dimensions.get('window').width >= 1024 ? 85 : 60
  },
  nota:{
    textAlign: "center",
    fontSize: Dimensions.get('window').width >= 1024 ? 17 : 15, 
    fontWeight: "bold", 
    paddingTop: Dimensions.get('window').width >= 1024 ? 20 : 10
  },
  peso:{
    textAlign: "center",
    fontSize: Dimensions.get('window').width >= 1024 ? 12 : 10, 
    borderTopWidth: 0.3
  }
});