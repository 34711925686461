import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Platform, Alert } from 'react-native';
import { Text, Button, TextInput, Snackbar, Chip } from 'react-native-paper';
import { format, parse } from 'date-fns';
import { TextInputMask } from 'react-native-masked-text';
import DateTimePicker from "react-native-modal-datetime-picker";

import { useStateValue } from '../state';
import { Api, Util } from '../services';
import {ThemeContext} from '../../theme-context';

export default function AdicionarEvento(props) {

  const [{ usuario }, dispatch] = useStateValue();
  const [ dataEvento, setDataEvento ] = useState(null);
  const [ nomeEvento, setNomeEvento ] = useState(null);
  const [ descricao, setDescricao ] = useState(null);
  const [ local, setLocal ] = useState(null);
  const [ horaInicial, setHoraInicial ] = useState(null);
  const [ horaFinal, setHoraFinal ] = useState(null);
  const [ participantes, setParticipantes ] = useState([]);
  const [ modalDataEvento, setModalDataEvento ] = useState(false);
  const [ carregando, setCarregando ] = useState(false);
  const [dadosAlerta, setDadosAlerta] = useState({ exibir: false, texto: '' });

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  let codigo = 0;

  function carregarListaParticipantes() {
    api.get("/ApiMobileEventos/buscarColegas?_pes_codigo=" + usuario.pes_codigo).then((retorno) => setParticipantes(retorno.data.colegas), (error) => console.error(error));
  }

  useEffect(() => {
    carregarListaParticipantes();
  }, []);

  function renderInputData() {
    if (Platform.OS === "web") {
      return (
        <React.Fragment>
          <TextInput
            label="Data do Evento"
            value={dataEvento}
            render={props =>
              <TextInputMask
                {...props}
                type={'custom'}
                options={{
                  mask: '99/99/9999'
                }}
                onChangeText={text => setDataEvento(text)}
                onBlur={(text) => {
                  if (text.target.value && !Util.dataValida(text.target.value, 'dd/LL/yyyy')) {
                    setDataEvento(null);
                    setDadosAlerta({ exibir: true, texto: 'A data informada para o envento é inválida!' });
                  }
                }}
              />
            }
          />
        </React.Fragment>
      )
    }
    else {
      return (
        <Button mode="outlined" icon="date-range" uppercase={false} onPress={()=>setModalDataEvento(true)}>{!!dataEvento === false ? 'Selecione a Data do Evento' : dataEvento}</Button>
      )
    }
  }

  function exibirAlerta(titulo, descricao) {
    if (Platform.OS === "web") alert(titulo + '\n\n' +descricao);
    else Alert.alert(titulo, descricao);
  }

  function salvarEvento() {
    let dadosObrigatorios = '';

    if (!!dataEvento === false) dadosObrigatorios += "* Data do Evento\n";
    if (!!dataEvento && Util.dataInferior(dataEvento, null, 'dd/LL/yyyy')) dadosObrigatorios += " - Data do Evento inválida\n";
    if (!!nomeEvento === false) dadosObrigatorios += "* Nome do Evento\n";
    if (!!local === false) dadosObrigatorios += "* Local do Evento\n";
    if (!!horaInicial === false) dadosObrigatorios += "* Hora inicial do Evento\n";
    if (!!horaFinal === false) dadosObrigatorios += "* Hora final do Evento\n";

    if (dadosObrigatorios !== '') {
      exibirAlerta(
        'Informe os seguintes dados:',
        dadosObrigatorios
      );
    }
    else {
      setCarregando(true);
      let listaParticipantes = participantes.reduce(function(valorAnterior, item, indice){
        if (item.selecionado) valorAnterior.push({pes_codigo: item.pes_codigo});
        return valorAnterior;
      }, []);
      
      let url = "/ApiMobileEventos/salvarEvento?_participantes=" + JSON.stringify(listaParticipantes);
      url += "&_local=" + local + "&_nome=" + nomeEvento +"&_descricao=" + descricao + "&_pes_codigo=" + usuario.pes_codigoLogin;
      url += "&_codigo=" + codigo + "&_tipo=" + usuario.tipo + "&_data=" + dataEvento + "&_horainicial=" + horaInicial + "&_horafinal=" + horaFinal;

      api.get(url).then((retorno) => {
        setCarregando(false);
        if (retorno.status === 200 && retorno.data.success){
          props.navigation.navigate('Home');
        }
        else {
          console.error(retorno.data.mensagem);
          exibirAlerta("Erro", retorno.data.mensagem);
        }
      }, (error) => {
        setCarregando(false);
        console.error(JSON.stringify(error));
        exibirAlerta("Erro", "Não foi possível incluir o evento, verifique sua conexão com a internet e tente novamente.");
      });
    }
  }

  return (
    <View style={[styles.container, {flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
      <Text style={styles.titulo}>Adicionar um Evento</Text>
      <ScrollView>
        <View style={styles.containerFormulario}>
          <View style={styles.itemFormulario}>
            {renderInputData()}
          </View>

          <View style={styles.itemFormulario}>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <TextInput
                label="Das"
                value={horaInicial}
                style={styles.inputHorario}
                keyboardType="decimal-pad"
                render={props =>
                  <TextInputMask
                    {...props}
                    type={'custom'}
                    options={{
                      mask: '99:99'
                    }}
                    onChangeText={text => {
                      if (text) setHoraInicial(text);
                    }}
                    onEndEditing={(text) => {
                      if (text.nativeEvent.text && !Util.horaValida(text.nativeEvent.text)) {
                        setHoraInicial(null);
                        setDadosAlerta({ exibir: true, texto: 'A hora inicial informada para o envento é inválida!' });
                      }
                    }}
                    onBlur={(text) => {
                      if (text.target.value && !Util.horaValida(text.target.value)) {
                        setHoraInicial(null);
                        setDadosAlerta({ exibir: true, texto: 'A hora inicial informada para o envento é inválida!' });
                      }
                    }}
                  />
                }
              />
              <TextInput
                label="Até às"
                value={horaFinal}
                style={styles.inputHorario}
                keyboardType="decimal-pad"
                render={props =>
                  <TextInputMask
                    {...props}
                    type={'custom'}
                    options={{
                      mask: '99:99'
                    }}
                    onChangeText={text => {
                      if (text) setHoraFinal(text);
                    }}
                    onEndEditing={(text) => {
                      if (text.nativeEvent.text && !Util.horaValida(text.nativeEvent.text)) {
                        setHoraFinal(null);
                        setDadosAlerta({ exibir: true, texto: 'A hora final informada para o envento é inválida!' });
                      }
                    }}
                    onBlur={(text) => {
                      if (text.target.value && !Util.horaValida(text.target.value)) {
                        setHoraFinal(null);
                        setDadosAlerta({ exibir: true, texto: 'A hora final informada para o envento é inválida!' });
                      }
                    }}
                  />
                }
              />
            </View>
          </View>

          <View style={styles.itemFormulario}>
            <TextInput label='Nome do Evento' style={styles.input} value={nomeEvento} onChangeText={setNomeEvento} autoCapitalize="none" autoCorrect={false} />
          </View>
          <View style={styles.itemFormulario}>
            <TextInput label='Descrição' style={styles.input} value={descricao} onChangeText={setDescricao} autoCapitalize="none" autoCorrect={false} />
          </View>
          <View style={styles.itemFormulario}>
            <TextInput label='Local' style={styles.input} value={local} onChangeText={setLocal} autoCapitalize="none" autoCorrect={false} />
          </View>
          {participantes.length > 0 &&
            <View style={styles.itemFormulario}>
              <Text style={styles.titulo}>Selecione os participantes:</Text>
              <View style={styles.containerParticipantes}>
                {participantes.map((colega, indice) => (
                  <Chip key={colega.pes_codigo} selected={!!colega.selecionado} style={styles.chip} icon={!!colega.selecionado ? "check" : "account-circle"} onPress={() => {
                    let colegas = [...participantes];
                    colegas[indice].selecionado = !(!!colegas[indice].selecionado);
                    setParticipantes(colegas);
                    console.log('colegas', colegas)
                  }}>{colega.pes_nome}</Chip>
                ))}
              </View>
            </View>
          }
          <View style={{margin: 15, justifyContent: 'center', alignItems: 'center'}}>
            <Button mode="contained" icon="check" loading={carregando} disabled={carregando} onPress={()=>salvarEvento()}>
              Salvar
            </Button>
          </View>
        </View>
        
      </ScrollView>
      {Platform.OS !== "web" &&
        <DateTimePicker
          isVisible={modalDataEvento}
          onConfirm={(date) => {
            setDataEvento(format(date,"dd/LL/yyyy"));
            setModalDataEvento(false);
          }}
          onCancel={() => setModalDataEvento(false)}
        />
      }
      <Snackbar
        duration={4000}
        visible={dadosAlerta.exibir}
        style={{backgroundColor: '#B00020'}}
        onDismiss={() => setDadosAlerta({ exibir: false, texto: '' })}>
        {dadosAlerta.texto}
      </Snackbar>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerFormulario: {
    flex: 1,
    margin: 10
  },
  containerParticipantes: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  itemFormulario: {
    marginBottom: 10
  },
  titulo: {
    fontSize: 16,
    fontWeight: "bold",
    margin: 10,
  },
  label: {
    alignSelf: 'center',
    marginRight: 10,
  },
  webTextInput: {
    height: 36,
    borderColor: '#202021',
    borderWidth: 1,
    fontSize: 14,
    fontWeight: 'bold',
  },
  inputHorario: {
    marginHorizontal: 5,
    minWidth: 120,
  },
  chip: {
    margin: 3,
  },
});