import React from 'react';
import { View, Text, StyleSheet, } from 'react-native';
import {ThemeContext} from '../../../theme-context';

export default function ItemListaCardapio(props) {

  return (
    <View style={styles.container}>
    
          <Text ellipsizeMode="clip" numberOfLines={2} style={{fontSize: ThemeContext._currentValue.theme?.fontSize14, marginLeft:10,}}>
              - {props.dados.descricao_produto}
          </Text>
        </View>
   
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    minHeight: 35,
    alignItems: "center",
  },
  materia: {
    fontSize: 14,
    fontWeight: "bold",
  }
})