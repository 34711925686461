import React, { useState, useEffect } from 'react';
import { View, ScrollView, StyleSheet, Platform, Dimensions, TouchableOpacity, Image } from 'react-native';
import { Text, TextInput, Appbar, Menu, Button, Divider, Modal, ActivityIndicator } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';
import * as ImagePicker from 'expo-image-picker';
import { MaterialIcons } from '@expo/vector-icons';
import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';

import { Api, Util } from '../services';

export default function FormularioSolicitacaoAcesso(props) {

  const TIPO_ACESSO = parseInt(props.navigation.getParam('tipoAcesso', '2'));
  const CIDADE = props.navigation.getParam('cidade', null);
  const DADOS_USUARIO = props.navigation.getParam('dados', null);

  const [ nome, setNome ] = useState('');
  const [ CPF, setCPF ] = useState('');
  const [ RG, setRG ] = useState('');
  const [ dataNascimento, setDataNascimento ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ anexo, setAnexo ] = useState(null);

  const [ menu, setMenu ] = useState(false);
  const [ carregando, setCarregando ] = useState(false);

  let cpfField = null;

  useEffect(() => {
    async function verificarPermissoes() {
      if (Platform.OS !== "web") {
        const { status: existingStatus } = await Permissions.getAsync(
          Permissions.NOTIFICATIONS
        );
        let finalStatus = existingStatus;
        // only ask if permissions have not already been determined, because
        // iOS won't necessarily prompt the user a second time.
        if (existingStatus !== 'granted') {
          // Android remote notification permissions are granted during the app
          // install, so this will only ask on iOS
          const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
          finalStatus = status;
        }
        // Stop here if the user did not grant permissions
        if (finalStatus !== 'granted') {
          return;
        }
        // Get the token that uniquely identifies this device
      }
    }
    verificarPermissoes();
  }, []);

  async function enviarSolicitacao() {
    if (!!nome === false) alert('Você deve informar seu nome!');
    else if (!!CPF === false) alert('Você deve informar seu CPF!');
    else if (!cpfField.isValid) alert('O CPF informado é inválido');
    else if (!!RG === false) alert('Você deve informar seu RG!');
    else if (!!dataNascimento === false) alert('Você deve informar sua data de nascimento!');
    else if (!Util.dataValida(dataNascimento, "dd/LL/yyyy")) alert('A data de nacimento informada não é uma data válida');
    else if (!!email === false || Util.emailValido(email) === false) alert('Você deve informar um endereço de email válido!');
    else if (!!anexo === false) alert('Você deve adicionar a foto de seu RG como anexo à solicitação.');
    else {
      setCarregando(true);
      let token = "0";
      if (Platform.OS !== "web") {
        // Get the token that uniquely identifies this device
        token = (await Notifications.getExpoPushTokenAsync()).data;
      }

      let formData = new FormData(); 
      formData.append('_nome', nome);
      formData.append('_email', email);
      formData.append('_datanascimento', dataNascimento);
      formData.append('_cpf', Util.retornaApenasNumeros(CPF));
      formData.append('_rg', RG);
      formData.append('_campoanexo', 'documento');
      formData.append('_extensao', 'jpg');
      formData.append('_soacessoapp', (Platform.OS === "web" ? 3 : Platform.OS === "android" ? 1 : 2 ));
      formData.append('_tipo', TIPO_ACESSO);
      formData.append('_nomefilho', (!!DADOS_USUARIO && !!DADOS_USUARIO.filhos.length) ? DADOS_USUARIO.filhos.reduce(( prevVal, elem ) => prevVal + ',' + elem.nome, 0) : '');
      formData.append('_token', token);

      if (Platform.OS === "web") {
        Util.b64toBlob("data:image/jpg;base64," + anexo, (file) => {
          formData.append('documento', file, 'documento');
          let api = Api.create(CIDADE.url);//'http://localhost:49999/');
          api.defaults.headers["Content-Type"] = "multipart/form-data";
          api.post('ApiMobileAcesso/solicitarAcesso', formData).then((retorno) => {
            processarRetornoSolicitacao(retorno);
          }, (erro) => {
            console.error('erro', erro);
          });
        }, (erro) => {console.error(erro)});
      }
      else {
          formData.append('documento', { uri: anexo, name: 'documento', type: 'image/jpg'});
          let api = Api.create(CIDADE.url);//'http://192.168.11.97:4321/');
          api.defaults.headers["Content-Type"] = "multipart/form-data";
          api.post('ApiMobileAcesso/solicitarAcesso', formData).then((retorno) => {
            processarRetornoSolicitacao(retorno);
          }, (erro) => {
            console.error('erro', erro);
          });
      }
    }
  }

  function processarRetornoSolicitacao(dados) {
    if (dados.data.success) alert(dados.data.mensagem);
    else alert(dados.data.erro);
    props.navigation.navigate('Login');
  }

  function anexarDocumento() {
    if (Platform.OS === "web") {
      ImagePicker.launchImageLibraryAsync({
        mediaTypes : "Images",
        allowsEditing: true,
        quality: 1
      }).then((dados) => {
        let arquivoAnexo = dados.uri;
        setAnexo(arquivoAnexo.split(',')[1]);
      }, (erro) => {
        console.error('erro', erro);
      });
    }
    else {
      setMenu(!menu);
    }
  }

  async function selecionarImagemGaleria() {
    setMenu(false);
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: false,
      allowsEditing: true,
      aspect: [16, 9],
      quality: 1
    });
    if (!result.cancelled) {
      setAnexo(result.uri);
    }
  }

  async function selecionarImagemCamera() {
    setMenu(false);
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: false,
      allowsEditing: true,
      aspect: [16, 9],
      quality: 1
    });
    if (!result.cancelled) {
      setAnexo(result.uri);
    }
  }

  function renderButton() {
    return (
      <TouchableOpacity style={styles.botaoAnexo} onPress={() => anexarDocumento()}>
        <MaterialIcons name="camera-alt" size={32} color="#212020" />
        <Text>Anexar imagem</Text>
      </TouchableOpacity>
    );
  }

  return (
    <React.Fragment>
      <Appbar.Header>
        <Appbar.Content title="Solicitação de acesso" />
      </Appbar.Header>
      <ScrollView contentContainerStyle={styles.container}>
        <Text style={styles.titulo}>Não foi possível encontrar seu cadastro em nosso sistema. Utilize o formulário abaixo para enviar uma solicitação de acesso à secretaria de educação.</Text>
        <View style={styles.linhaFormulario}>
          <TextInput label="Informe seu Nome" value={nome} onChangeText={setNome} autoCompleteType="name" style={styles.input} />
        </View>
        <View style={styles.linhaFormulario}>
          <TextInput
          label="Informe seu CPF"
          value={CPF}
          onChangeText={text => {
            setCPF(text);
          }}
          style={styles.input}
          render={props =>
            <TextInputMask
              {...props}
              type={'cpf'}
              keyboardType="decimal-pad"
              ref={(ref) => cpfField = ref}
            />    
          }
          />
          <TextInput
          label="Informe seu RG"
          value={RG}
          onChangeText={text => {
            setRG(text);
          }}
          style={styles.input}
          render={props =>
            <TextInputMask
              {...props}
              type={'custom'}
              options={{
                mask: '9999999999'
              }}
              keyboardType="decimal-pad"
            />    
          }
          />
        </View>
        <View style={styles.linhaFormulario}>
          <TextInput
          label="Data de Nascimento"
          value={dataNascimento}
          onChangeText={text => {
            setDataNascimento(text);
          }}
          style={{marginHorizontal: 5, width: Platform.OS === "web" ? 210 : Dimensions.get("window").width - 30}}
          render={props =>
            <TextInputMask
              {...props}
              type={'custom'}
              options={{
                mask: '99/99/9999'
              }}
              keyboardType="decimal-pad"
            />    
          } />
        </View>
        <View style={styles.linhaFormulario}>
          <TextInput label="Informe seu Email" value={email} onChangeText={setEmail} autoCompleteType="email" keyboardType="email-address" style={styles.input} />
        </View>
        <View style={styles.linhaFormulario}>
          {Platform.OS === "web" && renderButton()}
          {Platform.OS !== "web" &&
            <Menu
              visible={menu}
              onDismiss={() => setMenu(false)}
              anchor={ renderButton()} >
              <Menu.Item onPress={() => selecionarImagemCamera()} title="Utilizar a Camera" />
              <Divider />
              <Menu.Item onPress={() => selecionarImagemGaleria()} title="Selecionar da Galeria" />
            </Menu>
          }
          <View style={styles.containerImagemAnexo}>
            {!!anexo === false && <Text>Adicione uma foto de sua carteira de identidade. Abra o documento e faça a captura frente e verso do mesmo.</Text>}
            {!!anexo === true && 
              <Image
                style={{ width: 160, height: 90 }}
                source={{
                  uri: Platform.OS === "web" ? `data:image/png;base64,${anexo}` : anexo,
                }}
              />
            }
          </View>
        </View>
        <View style={[styles.linhaFormulario, {justifyContent: "center", marginTop: 15, marginBottom: 15}]}>
          <Button mode="contained" icon="send" onPress={() => enviarSolicitacao()}>Enviar Solicitação</Button>
        </View>
      </ScrollView>
      <Modal visible={carregando}>
        <ActivityIndicator color="#FFF" size="large" />
      </Modal>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  linhaFormulario: {
    flexDirection: 'row',
    flexWrap: "wrap",
    margin: 5,
    marginTop: 10,
    alignSelf: "stretch",
    paddingLeft: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 250 : 5,
    paddingRight: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 250 : 5
  },
  titulo: {
    fontSize: 22,
    marginVertical: 15,
    marginHorizontal: 10,
  },
  input: {
    flex: 1,
    marginHorizontal: 5
  },
  botaoAnexo: {
    borderColor: "#212020",
    borderWidth: 1,
    backgroundColor: "#e7e7e7",
    alignItems: "center",
    justifyContent: "center",
    height: 92,
    width: 140,
    margin: 5
  },
  containerImagemAnexo: {
    flex: 1,
    borderColor: "#212020",
    borderWidth: 1,
    borderStyle: "dashed",
    margin: 5,
    alignItems: "center",
    justifyContent: "center",
  },
});