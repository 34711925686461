import React from 'react';
import { Platform } from 'react-native';
import { Button } from 'react-native-paper';
import {ThemeContext} from './theme-context';

class ThemedButton extends React.Component {
  render() {
    ThemeContext._currentValue.theme = this.props.theme;
    let props = this.props;
    if (Platform.OS != 'web') return (
      <Button
        style={{marginTop: 0, flexDirection: 'row', justifyContent: 'flex-end'}}
        {...props}
      />
    );

    if (Platform.OS === 'web') return (
      <button
        style={{marginTop: 0, flexDirection: 'row', justifyContent: 'flex-end'}}
        {...props}
      />
    );
  }
}
ThemedButton.contextType = ThemeContext;

export default ThemedButton;