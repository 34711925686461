import React, { useState, useEffect } from 'react';
import { TextInput, TouchableOpacity, View, ScrollView, StyleSheet } from 'react-native';
import { Text, IconButton } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';

import { useStateValue } from '../state';
import { Api } from '../services';

export default function VisualizarMensagem(props) {

  const Mensagem = props.navigation.getParam('Mensagem', []);

  let conversa = Mensagem.mensagensAnteriores.map((msg) => {
    return { texto: msg.texto, data: msg.data, hora: msg.hora, tipo: msg.tipo };
  });

  conversa.push({ texto: Mensagem.texto, data: Mensagem.data, hora: Mensagem.hora, tipo: Mensagem.tipo });

  const [{ usuario }, dispatch] = useStateValue();
  const [ mensagens, setMensagens ] = useState([]);
  const [ mensagem, setMensagem ] = useState('');

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  function mensagemLida() {
    api.get("/ApiMobileMensagens/lerMensagem?_msg_codigo=" + Mensagem.codigo + "&_pes_codigo=" + usuario.pes_codigoLogin).then((retorno) => {
      console.log(retorno.data.mensagem);
    }, (error) => {
      console.error(error);
    });
  }

  function enviarMensagem() {
    if (!!mensagem) {
      let titulo = Mensagem.assunto.indexOf("Re:") == -1 ? "Re: " + Mensagem.assunto : Mensagem.assunto;
      api.get("/ApiMobileMensagens/enviarMensagem?_pes_codigo=" + usuario.pes_codigoLogin + "&_pes_destinatario=" + Mensagem.contatoCodigo + "&_mensagem=" + mensagem + "&_assunto=" + titulo + "&_msg_anterior=" + Mensagem.codigo).then((retorno) => {
        if (retorno.status === 200 && retorno.data.success) {
          let data = new Date();
          setMensagens([...mensagens, { texto: mensagem, data: format(data,"dd/LL/yyyy"), hora: format(data,"hh:mm"), tipo: "E" }]);
          setMensagem('');
        }
        else {
          alert('Não foi possível enviar sua mensagem');
        }
      }, (erro) => {
        console.error(erro);
      });
    }
  }

  useEffect(() => {
    if (!Mensagem.lido) mensagemLida();
    setMensagens(conversa);
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.containerTitulo}>
        <IconButton icon="arrow-back" size={28} color="#FFF" onPress={() => props.navigation.navigate('Mensagens')} style={{marginLeft: 10}} /><Text style={styles.textoTitulo}>{Mensagem.contato} - {Mensagem.assunto}</Text>
      </View>
      <ScrollView contentContainerStyle={{flex:1}}>
        <View style={styles.containerMensagens}>
          {mensagens.map((msg, indice) => (
            <View style={msg.tipo === 'E' ? styles.containerMensagemEnviada : styles.containerMensagemRecebida} key={indice}>
              <View style={[styles.mensagem, { backgroundColor: (msg.tipo === 'E' ? '#007AFF' : '#C7C7C7') }]}>
                <Text style={{color: (msg.tipo === 'E' ? '#FFF' : '#000')}}>{msg.texto}</Text>
              </View>
              <Text style={styles.dataHora}>{`${msg.hora}h, em ${msg.data}`}</Text>
            </View>
          ))}
        </View>
      </ScrollView>
      <View style={styles.containerEnvio}>
        <View style={styles.containerEnvioInput}>
          <TextInput multiline={true} autoCapitalize="sentences" autoCompleteType="off" style={styles.input} value={mensagem} onChangeText={setMensagem} />
        </View>
        <TouchableOpacity style={styles.botao} onPress={() => enviarMensagem()}>
          <MaterialIcons name="send" size={28} color="#FFF" />
          <Text style={styles.textoBotao}>Enviar</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerTitulo: {
    flexDirection: "row",
    backgroundColor: '#438eb9',
    height: 36,
    alignItems: 'center',
    borderTopColor: '#CCC',
    borderTopWidth: 1
  },
  textoTitulo: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#FFF',
    marginLeft: 20,
  },
  containerEnvio: {
    height: 120,
    flexDirection: 'row',
  },
  mensagem: {
    borderRadius: 25,
    padding: 15,
  },
  containerMensagens: {
    margin: 15,
  },
  containerMensagemEnviada: {
    alignItems: "flex-end",
    margin: 5,
  },
  containerMensagemRecebida: {
    alignItems: "flex-start",
    margin: 5,
  },
  dataHora: {
    fontSize: 11,
    color: '#6e6e6e',
    marginTop: 1,
  },
  containerEnvioInput: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 30,
    borderColor: '#6e6e6e',
    borderWidth: 1,
    backgroundColor: '#FFF',
    margin: 10,
  },
  input: {
    flex: 1,
    padding: 10
  },
  botao: {
    height: 100,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    borderRadius: 10,
    backgroundColor: '#438eb9',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 76
  },
  textoBotao: {
    color: '#FFF',
    fontWeight: 'bold',
    marginTop: 5,
  },
});