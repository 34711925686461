import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Util } from '../../services'

export default function ListaFrequencia(props) {

  function renderLista() {
    return (
      <React.Fragment>
        {props.dados.map((chamada, indice) => (
          <TouchableOpacity key={indice} style={[styles.item, {backgroundColor: Util.retornaCorStatus(chamada.status)}]} onPress={() => props.detalhes(chamada)}>
            <Text style={styles.textoStatus}>{chamada.status}</Text>
          </TouchableOpacity>
        ))}
      </React.Fragment>
    )
  }

  function renderSemDados(){
    return <Text>Você não possui chamadas para esta data</Text>
  }

  return (
    <View style={styles.container}>
      {!!props.dados && props.dados.length > 0 ? renderLista() : renderSemDados()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  item: {
    margin: 5,
    padding: 8,
    borderColor: "#2d2d2d",
    borderWidth: 1,
    minWidth: 36,
    alignItems: "center",
  },
  textoStatus: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#202021",
  },
});