import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Divider } from 'react-native-paper';

import ItemListaHoraiosAula from './ItemListaHoraiosAula';

import {ThemeContext} from '../../../theme-context';

export default function ListaHorariosAula(props) {

  function renderLista(){
    return (
      <View>
        {props.dados.horarios.map((dadosHorario, indice) => (
          <View key={indice}>
            <ItemListaHoraiosAula dados={dadosHorario}/>
            <Divider />
          </View>
        ))}
      </View>
    )
  }

  function renderSemDados(){
    return (
      <View style={styles.center}>
        <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14}}>Você não possui horários para esta data</Text>
      </View>
    )
  }

  return (
      <View style={styles.content}>
        {!!props.dados.horarios && props.dados.horarios.length > 0 ? renderLista() : renderSemDados()}
      </View>
  );
}

const styles = StyleSheet.create({
  
  content: {
    backgroundColor: "#FFF",
    flex: 1,
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },
});