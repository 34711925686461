import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';

import { useStateValue } from '../../state';
import {themes, ThemeContext} from '../../../theme-context';

export default function AlterarAluno(props) {

  const [{ usuario, estudante }, dispatch] = useStateValue();

  if (usuario.tipo === 2) {
    return (
      <React.Fragment>
        <View style={[styles.container,{ backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
          <Button mode="text" icon="people" onPress={() => props.onPress()}>{estudante.nome}</Button>
        </View>
      </React.Fragment>
    );
  }
  else {
    return (
      <React.Fragment />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    borderColor: "#202021",
    borderBottomWidth: 1,
    borderTopWidth: 1,
  }
});