import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, IconButton } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';

import {ThemeContext} from '../../../theme-context';
import { format } from 'date-fns';
export default function SelecionaDiaSemana(props) {

  const diasSemana = [ "Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado" ];
  let diaInicial = props.diaInicial || new Date().getDay();
  const [ dia, setDia ] = useState(parseInt(diaInicial) === 0 ? 1 : diaInicial);
  const [data, setData] = useState(new Date())

  function proximoDia() {
    if (dia < 6)
    { 
      setDia(dia+1);
      setData(new Date(data.setDate(data.getDate() + 1 )))
    }  
    else {
      setDia(1);
      setData(new Date())
    }    
  }

  function diaAnterior() {
    if (dia > 1)
    { setDia(dia-1);
      setData(new Date(data.setDate(data.getDate() - 1)))
    } 
    else {
      setDia(6);
     
      setData(new Date(data.setDate(data.getDate() + 5)))
    } 
  }

  useEffect(() => {
    if (!!props.onChange) props.onChange(dia);
  }, [dia]);

  return (
    <View style={styles.container}>
      <IconButton icon="keyboard-arrow-left" size={28} onPress={() => diaAnterior()}/>
      <View style={styles.containerTextoDiaSemana}>
        <Ionicons name="md-calendar" size={22}/><Text style={[styles.textoDiaSemana, {fontWeight: "bold", marginLeft: 15, fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{diasSemana[dia]} ({(format(data, "dd/MM"))})</Text>
      </View>
      <IconButton icon="keyboard-arrow-right" size={28} onPress={() => proximoDia()}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 50,
    backgroundColor: "#CECECE",
    alignItems: "center",
    justifyContent: "center",
  },
  containerTextoDiaSemana: {
    flex: 1,
    flexDirection: 'row',
    width: 220,
    alignItems: "center",
    justifyContent: "center",
  },
  textoDiaSemana: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 15
  },
});