import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import ItemListaReservas from './ItemListaReservas';
import { ThemeContext } from '../../../theme-context';
import { IconButton, Divider } from 'react-native-paper';

export default function ListaReservas(props) {

  function renderLista() {
    return (
      
      <View>
        <View>
          <View style={{ flexDirection: 'row', height: 50, }}>
            <Text style={styles.bibliotecaCabecalho}>Biblioteca</Text>
            <Text style={styles.tituloCabecalho}>Título</Text>
            <Text style={styles.retiradaCabecalho}>Data da retirada </Text>
            <Text style={styles.devolucaoCabecalho}>Data da devolução </Text>
            <Text style={styles.statusCabecalho}>Status</Text>
          </View>
          <Divider />
        </View>
        {props.dados.map((dadosReserva, indice) => (
          <View key={indice}>
            <View style={{ marginRight: 5 }}>
              <ItemListaReservas dados={dadosReserva}/>
            </View>
          </View>
        ))}
      </View>
    )
  }

  function renderSemDados() {
    return (
      <View style={styles.center}>
        <Text style={{ fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite, fontWeight: "bold"}}>Você não possui livros emprestados</Text>
      </View>
    )
  }

  return (
    <View style={styles.content}>

   {!!props.dados && props.dados.length > 0 ? renderLista() : renderSemDados()}   
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  center: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  }, 
  bibliotecaCabecalho: {
    marginTop: 12,
    width: 380,
    marginLeft: 10,
    fontWeight: 'bold' 
  },

  tituloCabecalho: {
    marginTop: 12,
    width: 385,
    marginLeft: 5,
    fontWeight: 'bold' 
  },
  retiradaCabecalho: {
    marginTop: 8,
    width: 100,
    fontWeight: 'bold' 
  },
  devolucaoCabecalho: {
    marginTop:8,
    width: 100,
    fontWeight: 'bold' 
  },
  statusCabecalho: {
    width: 100,
    marginTop:12,
    fontWeight: 'bold' 
  },
});