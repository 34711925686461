import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform, Dimensions } from 'react-native';
import { Modal, Text, Button, IconButton } from 'react-native-paper';
import { Calendar } from 'react-native-calendars';
import { format } from 'date-fns';

export default function DatePickerModal(props) {

  const [ dataSelecionada, setDataSelecionada ] = useState(props.dataSelecionada ? props.dataSelecionada : null);
  const [ modalDataSelecionada, setModalDataSelecionada ] = useState({});
  const [ selecionaData, setSelecionaData ] = useState(false);

  useEffect(() => {
    if (dataSelecionada !== null) setModalDataSelecionada(JSON.parse(`{"${format(dataSelecionada, "yyyy-MM-dd")}": {"selected": true, "selectedColor": "#af453d"}}`));
  }, []);

  return (
    <React.Fragment>
      <View style={styles.linha}>
        <Text style={{marginRight: 10}}>{props.label || 'Selecione a data '}</Text><Button mode="outlined" uppercase={false} icon="date-range" onPress={() => setSelecionaData(true)}>{ dataSelecionada !== null ? format(dataSelecionada, "dd/MM/yyyy") : 'Selecionar Data' }</Button>
      </View>
      <Modal visible={selecionaData}>
        <View style={styles.containerModalData}>
          <Text style={{margin: 10, fontSize: 16, fontWeight: "bold"}}>{props.label || 'Selecione a data'}</Text>
          <Calendar
          style={{
            borderWidth: 1,
            borderColor: 'gray',
            height: 350,
            width: (Platform.OS === 'web' ? 450 : Dimensions.get("window").width - 60),
          }}
          renderArrow={(direction) => <IconButton icon={`keyboard-arrow-${direction}`} size={22} /> } 
          markedDates={modalDataSelecionada}
          current={format(new Date(), "yyyy-MM-dd")} onDayPress={(day) => {
            setDataSelecionada(new Date(day.year, (day.month-1), day.day));
            setSelecionaData(false);
            props.onChange(new Date(day.year, (day.month-1), day.day));
          }}/>
          <Button mode="outlined" style={{margin: 15}} onPress={() => setSelecionaData(false)}>OK</Button>
        </View>
      </Modal>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  linha: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 2,
  },
  containerModalData: {
    padding: 10,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 5),
    backgroundColor: "#FFF",
    alignItems: "center",
    borderColor: '#202021',
    borderWidth: 1,
  },
});