import React, { useState, useEffect } from 'react';
import { View, ScrollView, Image, StyleSheet, Platform, Dimensions, FlatList, Linking, NativeModules, TouchableHighlight } from 'react-native';
import { Paragraph, Button, Snackbar, TextInput, Text, List, Divider, Modal, Searchbar, } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';
import NetInfo from "@react-native-community/netinfo";
import MD5 from 'md5';
import _, { reduce } from 'lodash';
import { Api, Util } from '../services';
import { useStateValue } from '../state';
import { ThemeContext } from '../../theme-context';
import { ro } from 'date-fns/locale';

const SistemaOperacionalAcessoApp = ["N/A", "android", "ios", "web"];

export default function Login(props) {
    const [{ usuario, estudante }, dispatch] = useStateValue();
    const [irPaginaDuvidas, setIrPaginaDuvidas] = useState(false);
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalSelecionarMunicipio, setModalSelecionarMunicipio] = useState(false);
    const [filtroCidade, setFiltroCidade] = useState('');
    const [listaCidades, setListaCidades] = useState([]);
    const [dadosAlerta, setDadosAlerta] = useState({ exibir: false, texto: '' });
    const [expoPushToken, setExpoPushToken] = useState(null);
    const [estaOnline, setEstaOnline] = useState(false);
    const [dadosLoginMunicipio, setDadosLoginMunicipio] = useState({});

    
    const api = Api.create('https://adm.educarweb.net.br/');//Api.create('https://localhost:44395');

    //const api = Api.create('http://localhost:57898');

    const listaPessoas = [];
    let idCidadeSelecionada = null;

    async function verificarPermissaoNotificacoes() {
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);

        if (status !== 'granted') {
            setDadosAlerta({ exibir: true, texto: 'Você não permitiu o recebimento de notificações do aplicativo neste dispositivo, desta forma você não receberá notificações.' });
        }
        else {
            push_token = (await Notifications.getExpoPushTokenAsync()).data;
            setExpoPushToken(push_token);
        }
    }

    async function atualizaPushToken(pes_codigo, pushToken, tokenAuth, urlApi) {
        const apiMobile = Api.create(urlApi, tokenAuth);
        return await apiMobile.get(`/ApiMobile/AtualizaTokenApp?pes_codigo=${pes_codigo}&token=${pushToken}`);
    }

    async function efetuarLogin() {
        idCidadeSelecionada = await AsyncStorage.getItem('EducarWebEstudanteApp:idcidadeselecionada');

        if (!!email === false) setDadosAlerta({ exibir: true, texto: "Você deve informar o seu email, CPF ou login do Educar..." });
        else if (!!senha === false) setDadosAlerta({ exibir: true, texto: "Você deve informar sua senha..." });
        else {

            setLoading(true);
            setIrPaginaDuvidas(false);

            if (Util.emailValido(email) || Util.verificaCPF(email) !== null) {
                api.post("AbsUsuario/Login", { User: email, Password: senha }).then(async (response) => {

                    if (response.data.success) {
                        let dadosUsuario = {
                            tokenAuth: response.data.result.token,
                            pes_codigo: response.data.result.identity.pes_codigo,
                            pes_codigoLogin: response.data.result.identity.pes_codigo,
                            nome: response.data.result.identity.nome,
                            tipo: null,
                            ApiEducar: response.data.result.identity.url
                        };

                        await AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(dadosUsuario));

                        if (Platform.OS === "android" || Platform.OS === "ios") {
                            await AsyncStorage.setItem('EducarWebEstudanteApp:email', email);
                            await AsyncStorage.setItem('EducarWebEstudanteApp:senha', senha);
                        }

                        AsyncStorage.removeItem('EducarWebEstudanteApp:municipio');

                        if (!!expoPushToken === true) {
                            let atualizacaoToken = await atualizaPushToken(response.data.result.identity.pes_codigo, expoPushToken, response.data.result.token, response.data.result.identity.url);
                        }
                        dispatch({
                            type: 'alterarUsuario',
                            newUsuario: dadosUsuario
                        });

                        dispatch({
                            type: 'alterarMainNavigator',
                            newMainNavigator: 'AppHome'
                        });

                        await carregarFilhos(dadosUsuario);
                        await carregaDadosPessoa(dadosUsuario);

                        props.navigation.navigate('AppHome');
                    }
                    else {
                        preparaLoginEducar();

                    }
                }, (erro) => {
                    preparaLoginEducar();
                });
            }
            else {
                preparaLoginEducar();

            }
        }
    }

    NetInfo.fetch().then(state => {
        setEstaOnline(state.isConnected);
    });

    function adicionarCidadesComDadosPatrocinados() {
        let dados = [{ id: '19fb8441-6a23-4e3a-9ad7-a9dc01182f1d', nome: 'Bento Gonçalves - RS', url: 'https://bentogoncalves.educarweb.net.br' }];
        setListaCidades(dados);
        setModalSelecionarMunicipio(true);
    }

    function preparaLoginEducar() {
        debugger
        setLoading(false);
        api.get("AdmOrgaoGovernamental/ListarDisponibilizamAplicativo").then((retorno) => {
            let dados = retorno.data.result; //DESCOMENTAR PARA PRODUÇÃO

            //COMENTAR PARA PRODUÇÃO
            // let dados = [
            //    { id: 'e347a66c-c8d5-4d5e-b485-a97400c40f27', nome: 'local', url: 'http://localhost:57898' },
            //    { id: '19fb8441-6a23-4e3a-9ad7-a9dc01182f13', nome: 'localPablo', url: 'http://localhost:59473' },
            //    { id: '19fb8441-6a23-4e3a-9ad7-a9dc01182f15', nome: 'localKatia', url: 'http://localhost:49699' },
            //    { id: 'e35243f3-ca54-4ca8-aa54-89dfe6906266', nome: 'localRaianeMai', url: 'http://localhost:50601' },                
            //    { id: '19fb8441-6a23-4e3a-9ad7-a9dc01182f21', nome: 'localAlesandro', url: 'http://localhost:64719' },
            //    { id: '19fb8441-6a23-4e3a-9ad7-a9dc01182f19', nome: 'Demo22', url: 'https://demo22.educarweb.net.br/' },
            //    { id: '19fb8441-6a23-4e3a-9ad7-a9dc01182f20', nome: 'Demo23', url: 'https://demo23.educarweb.net.br/' }
            // ];

            if (!!idCidadeSelecionada) {
                let cidade = dados.find(c => c.id == idCidadeSelecionada);

                if (!!cidade)
                    setFiltroCidade(cidade.nome);
            }

            setListaCidades(dados);
            setModalSelecionarMunicipio(true);
        }, (erro) => {
            console.log(erro);
        });
    }

    async function carregaDadosPessoa(usuario) {
        let dadosUsuario = JSON.parse(await AsyncStorage.getItem('EducarWebEstudanteApp:user'));
        let apiEducar = Api.create(usuario.ApiEducar, usuario.tokenAuth);
        let alterouEstudante = false;
        apiEducar.get("/ApiMobileMatricula/buscarInformacoesPessoa?_pes_codigo=" + usuario.pes_codigo).then((retorno) => {

            if (retorno.status === 200 && retorno.data.informacoes.length > 0) {
                if (retorno.data.informacoes.length === 1) {
                    let dadosMatriculaFilho = listaPessoas.find((m) => m.matricula == retorno.data.informacoes[0].matricula);
                    retorno.data.informacoes[0].parentesco = dadosMatriculaFilho?.parentesco || null;

                    dispatch({
                        type: 'alterarEstudante',
                        newEstudante: retorno.data.informacoes[0]
                    });
                }
                else {
                    let lista = [];
                    for (let i = 0; i < retorno.data.informacoes.length; i++) {
                        let dadosMatriculaFilho = listaPessoas.find((m) => m.matricula == retorno.data.informacoes[i].matricula);
                        retorno.data.informacoes[i].parentesco = dadosMatriculaFilho?.parentesco || null;

                        if (parseInt(retorno.data.informacoes[i].matricula) === dadosUsuario.matricula) {
                            dispatch({
                                type: 'alterarEstudante',
                                newEstudante: retorno.data.informacoes[i]
                            });
                            alterouEstudante = true;
                        }
                        else {
                            if (!!!listaPessoas.find((m) => m.matricula == retorno.data.informacoes[i].matricula))
                                lista.push({
                                    pes_nome: retorno.data.informacoes[i].nome,
                                    parentesco: retorno.data.informacoes[i].parentesco,
                                    turma: retorno.data.informacoes[i].turma,
                                    escola: retorno.data.informacoes[i].escola,
                                    matricula: retorno.data.informacoes[i].matricula,
                                    pes_codigo: retorno.data.informacoes[i].codPessoa,
                                    codturma: retorno.data.informacoes[i].codTurma,
                                    periodo: retorno.data.informacoes[i].periodo,
                                    nperiodo: retorno.data.informacoes[i].nperiodo,
                                    ano: retorno.data.informacoes[i].ano,
                                    turno: retorno.data.informacoes[i].turno,
                                    turnocodigo: retorno.data.informacoes[i].turnocodigo
                                })
                        }
                    }

                    if (!!!alterouEstudante)
                        dispatch({
                            type: 'alterarEstudante',
                            newEstudante: retorno.data.informacoes[0]
                        });

                    listaPessoas.push(...lista);
                    dispatch({
                        type: 'alterarFilhos',
                        newFilhos: listaPessoas
                    });
                }
            }
        }, (erro) => {
            console.log(erro);
        });
    }

    async function carregarFilhos(usuario) {
        let apiEducar = Api.create(usuario.ApiEducar, usuario.tokenAuth);
        let pesLogado = usuario.pes_codigoLogin;
        apiEducar.get("/ApiMobileResponsaveis/listarFilhos?_pes_codigo=" + usuario.pes_codigoLogin).then((retorno) => {
            if (retorno.status === 200 && !!retorno.data.success) {

                for (let i = 0; i < retorno.data.filhos.length; i++) {
                    if (!!!listaPessoas.find((m) => m.matricula == retorno.data.filhos[i].matricula))
                        listaPessoas.push({
                            pes_nome: retorno.data.filhos[i].pes_nome,
                            parentesco: retorno.data.filhos[i].pgp_codigo,
                            turma: retorno.data.filhos[i].turma,
                            escola: retorno.data.filhos[i].escola,
                            matricula: retorno.data.filhos[i].matricula,
                            pes_codigo: retorno.data.filhos[i].pes_codigo,
                            pgp_codigo: retorno.data.filhos[i].pgp_codigo,
                            codturma: retorno.data.filhos[i].codTurma,
                            periodo: retorno.data.filhos[i].periodo,
                            nperiodo: retorno.data.filhos[i].nperiodo
                        })
                }

                dispatch({
                    type: 'alterarFilhos',
                    newFilhos: retorno.data.filhos
                });

                let infoUsuario = usuario;
                if (!!retorno.data.filhos && !!retorno.data.filhos.length && retorno.data.filhos.length > 0) {
                    infoUsuario.tipo = (retorno.data.filhos.length > 0 ? 2 : 1);
                    infoUsuario.pes_codigo = retorno.data.filhos[0].pes_codigo;
                    infoUsuario.mtr_codigo = retorno.data.filhos[0].matricula;

                    dispatch({
                        type: 'alterarEstudante',
                        newEstudante: {
                            nome: retorno.data.filhos[0].pes_nome,
                            parentesco: retorno.data.filhos[0].pgp_codigo,
                            turma: retorno.data.filhos[0].turma,
                            escola: retorno.data.filhos[0].escola,
                            periodo: retorno.data.filhos[0].periodo,
                            nperiodo: retorno.data.filhos[0].nperiodo,
                            matricula: retorno.data.filhos[0].matricula,
                            ano: retorno.data.filhos[0].ano,
                            turnocodigo: retorno.data.filhos[0].turnocodigo,
                            turno: retorno.data.filhos[0].turno
                        }
                    });
                    pesLogado = retorno.data.filhos[0].pes_codigo;

                }
                else infoUsuario.tipo = 1;

                infoUsuario.pes_codigo = pesLogado;

                AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(infoUsuario));

                dispatch({
                    type: 'alterarUsuario',
                    newUsuario: infoUsuario
                });
            } else {
                let infoUsuario = usuario;
                infoUsuario.tipo = 1;
                dispatch({
                    type: 'alterarUsuario',
                    newUsuario: infoUsuario
                });
                AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(infoUsuario));
            }
        }, (erro) => {
            console.log(erro);
        });
    }

    async function cadastrarUsuarioAdmEducar(dadosUsuario) {
        try {
            return await api.post('AbsUsuario/Cadastrar', dadosUsuario);
        }
        catch (err) {
            return err;
        }
    }

    function getTokenPayload(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(Util.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    function acessarPaginaDuvidas() {
        setIrPaginaDuvidas(true);
        preparaLoginEducar();
    }

    async function verificaSSO() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let sso = params.get('sso');
        let origem = params.get('origem');

        if (!!sso && !!origem) {
            await processarLoginEducar(sso, origem);
        }
    }

    async function loginEducar(municipio) {
        setLoading(true);
        const apiLoginEducar = Api.create(municipio.url);

        if (Platform.OS != 'web') {
            if (municipio.url != "https://bentogoncalves.educarweb.net.br") {
                console.log('não tem patrocinio de dados!', municipio.url);
                NativeModules.SmiSdkReactModule.stopSponsoredData();
            }
        }

        apiLoginEducar.post('/ApiMobileAcesso/LoginApp', {
            _usuario: email,
            _senha: MD5(senha).toUpperCase(),
            _soacessoapp: 1,
            _token: expoPushToken,
            tipo: Util.emailValido(email) ? 1 : (Util.verificaCPF(email) !== null ? 2 : 3)
        }).then(
            async (retorno) => {
                setModalSelecionarMunicipio(false);

                if (retorno.status === 200 && retorno.data.success === true) {
                    await AsyncStorage.setItem('EducarWebEstudanteApp:municipio', municipio.url);
                    await AsyncStorage.setItem('EducarWebEstudanteApp:email', email);
                    await AsyncStorage.setItem('EducarWebEstudanteApp:senha', MD5(senha).toUpperCase());
                    await AsyncStorage.setItem('EducarWebEstudanteApp:expopushtoken', (!!expoPushToken) ? expoPushToken : '');

                    await processarLoginEducar(retorno.data.auth.token, municipio.url);
                }
                else {
                    setLoading(false);
                    setDadosAlerta({ exibir: true, texto: "Não foi possível realizar o login de usuário." });
                }
            }, (erro) => {
                setLoading(false);
                console.log("ERRO AO EFETUAR LOGIN", erro);
            });
    }

    async function processarLoginEducar(token, urlEducar) {
        let userTokenPayload = getTokenPayload(token);
        let dadosUsuario = {
            tokenAuth: token,
            pes_codigo: userTokenPayload.pes_codigo,
            pes_codigoLogin: userTokenPayload.pes_codigo,
            nome: userTokenPayload.nome,
            tipo: null,
            ApiEducar: urlEducar
        };

        await AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(dadosUsuario));

        let municipios = listaCidades;
        if (municipios.length === 0) {
            municipios = (await api.get("AdmOrgaoGovernamental/ListarDisponibilizamAplicativo")).data.result;
        }

        let cidadeSelecionada = municipios.find(x => x.url === urlEducar);
        await AsyncStorage.setItem('EducarWebEstudanteApp:idcidadeselecionada', cidadeSelecionada.id);

        let usuarioAdm = {
            Nome: userTokenPayload.nome, Cpf: userTokenPayload.cpf, Senha: MD5(senha).toUpperCase(), Email: userTokenPayload.email,
            DataNascimento: userTokenPayload.datanascimento, Ativo: true, IdOrgaoGovernamental: cidadeSelecionada.id, pes_codigo: userTokenPayload.pes_codigo
        };

        if (!!usuarioAdm.Cpf && !!usuarioAdm.Email) {
            let cadastroUsuario = await cadastrarUsuarioAdmEducar(usuarioAdm);
        }

        dispatch({
            type: 'alterarUsuario',
            newUsuario: dadosUsuario
        });

        dispatch({
            type: 'alterarMainNavigator',
            newMainNavigator: 'AppHome'
        });

        await carregarFilhos(dadosUsuario);
        await carregaDadosPessoa(dadosUsuario);

        if (Platform.OS === "web") window.location = '/';
        else props.navigation.navigate('AppHome');
    }

    useEffect(() => {
        (async function inicializar() {

            dispatch({

                type: 'alterarEstudante',
                newEstudante: { nome: '', parentesco: '', turma: '', escola: '', codturma: '', matricula: '', pes_codigo: '', periodo: '', nperiodo: '', ano: '', turno: '', turnocodigo: '' }
            });

            verificarPermissaoNotificacoes();
            verificaSSO();
        })();
    }, []);

    return (
        <View style={[styles.container, { flex: 1, justifyContent: "center", backgroundColor: ThemeContext._currentValue.theme?.backgroundColorLogin }]}>
            <View style={Platform.OS === 'web' ? { maxWidth: 500, alignSelf: "center" } : null}>
                <View style={[styles.loginContainer, { margin: 15, padding: 10, minWidth: 300, backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
                    <Image style={styles.logo} source={require('../../assets/educar_cor.png')} />
                    <Paragraph style={[styles.texto, { alignSelf: "center", fontSize: ThemeContext._currentValue.theme?.fontSize14 }]}>Para acessar o app, faça seu login.</Paragraph>
                    <View style={styles.inputContainer}>
                        <TextInput label='Email, CPF ou Login do Educar' style={styles.input} value={email} onChangeText={setEmail} autoCapitalize="none" autoCompleteType="email" returnKeyType="next" autoCorrect={false} keyboardType="email-address" />
                        <TextInput label='Senha' style={styles.input} value={senha} onChangeText={setSenha} autoCompleteType="password" returnKeyType="done" onSubmitEditing={() => efetuarLogin()} secureTextEntry={true} />
                    </View>
                    <Button accessibilityLabel="Botão Efetuar Login" icon="check" mode="contained" loading={loading} disabled={loading} onPress={() => efetuarLogin()}>
                        Acessar
                    </Button>
                    <View style={styles.buttonCantainer}>
                        <Button accessibilityLabel="Botão para acesso à página de envio de dúvidas" icon="help-outline" mode="outlined" style={styles.inputContainer} compact onPress={() => acessarPaginaDuvidas()}>Dúvidas</Button>
                        <Button accessibilityLabel="Botão Esqueci Minha Senha" icon="sentiment-dissatisfied" mode="outlined" style={styles.inputContainer} compact={true} onPress={() => props.navigation.navigate('RecuperarSenha')}>
                            Esqueci Minha Senha
                        </Button>

                        {/* <Button accessibilityLabel="Botão Solicitar Acesso" icon="supervisor-account" mode="contained" style={styles.inputContainer} compact={true} onPress={ () => props.navigation.navigate('SolicitarAcesso') }>
              Solicitar Acesso
            </Button> */}


                    </View>
                </View>
            </View>
            <Modal contentContainerStyle={[styles.containerModal, { padding: 15, height: Dimensions.get('window').height - 80, margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 160 : 30), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]} visible={modalSelecionarMunicipio} onDismiss={() => {
                setModalSelecionarMunicipio(false);
                setLoading(false);
            }}>
                <View style={[styles.containerTitulo, { alignSelf: "center", backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
                    <Text style={[styles.titulo, { fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize18 }]}>Selecione o seu Município</Text>
                </View>
                <Searchbar style={styles.searchBar} placeholder="Localizar Cidade" autoCapitalize="none" autoCorrect={false} returnKeyLabel="search" value={filtroCidade} onChangeText={setFiltroCidade} />
                <ScrollView>
                    <FlatList data={_.filter(listaCidades, function (o) {
                        return Util.retira_acentos(o.nome).toLowerCase().replace(/ /g, "-").indexOf(Util.retira_acentos(filtroCidade).toLowerCase().replace(/ /g, "-")) > -1;
                    })} renderItem={({ item, index }) => (
                        <React.Fragment key={index}>
                            <List.Item
                                style={[styles.searchBar, { fontSize: ThemeContext._currentValue.theme?.fontSize16 }]}
                                title={item.nome}
                                right={props => <List.Icon {...props} icon="arrow-forward" />}
                                onPress={() => {
                                    if (irPaginaDuvidas) Linking.openURL(`${item.url}/servicoexterno/duvida`);
                                    else loginEducar(item);
                                }}
                            />
                            <Divider />
                        </React.Fragment>
                    )} keyExtractor={item => item.id} />
                </ScrollView>
            </Modal>
            <Snackbar
                visible={dadosAlerta.exibir}
                style={{ backgroundColor: '#B00020' }}
                onDismiss={() => setDadosAlerta({ exibir: false, texto: '' })}>
                {dadosAlerta.texto}
            </Snackbar>
            <Snackbar
                visible={!estaOnline}
                style={{ backgroundColor: '#B00020' }}>
                {'Você está offline!'}
            </Snackbar>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#438eb9',
        justifyContent: "center",
    },
    containerModal: {
        padding: 15,
        height: Dimensions.get('window').height - 80,
        margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 160 : 30),
        backgroundColor: "#FFF",
    },
    containerTitulo: {
        alignSelf: "center",
    },
    titulo: {
        fontSize: 18,
        fontWeight: "bold",
    },
    texto: {
        alignSelf: "center"
    },
    logo: {
        height: 38,
        width: 110,
        margin: 10,
        alignSelf: "center"
    },
    loginContainer: {
        margin: 15,
        padding: 10,
        minWidth: 350,
        backgroundColor: '#FFF'
    },
    inputContainer: {
        margin: 10
    },
    buttonCantainer: {
        margin: 10,
        justifyContent: "center"
    },
    input: {
        marginBottom: 10,
    }
});