import React, { useEffect, useState, useRef } from 'react';
import { View, ScrollView, Text, StyleSheet, Platform, Alert } from 'react-native';
import { Modal, IconButton, TextInput, Title, ActivityIndicator } from 'react-native-paper';
import ItemMensagensDocumento from './ItemMensagensDocumento';

import { useInterval } from '../../hooks'
import { useStateValue } from '../../state';
import { Api } from '../../services';
import {ThemeContext} from '../../../theme-context';

export default function ModalMensagensDocumento({ mensagensDocumento, fecharModal }) {

  const [{ usuario }, dispatch] = useStateValue();

  const inputMsg = useRef(null);
  const areaMsgs = useRef(null);
  const [ carregando, setCarregando ] = useState(false);
  const [ enviando, setEnviando ] = useState(false);
  const [ mensagens, setMensagens ] = useState([]);
  const [ mensagem, setMensagem ] = useState('');
  const [ tempoAtualizacaoMensagens, setTempoAtualizacaoMensagens ] = useState(5000);
  const [ coresRemetente, setCoresRemetente ] = useState([]);
  
  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);

  useEffect(() => {
    setCoresRemetente(['#0000FF', '#FF0000', '#008000', '#FFA500', '#000000', '#FFFF00', '#00FFFF', '#8B008B', '#B87333', '#2F4F4F', '#FF007F', '#738678', '#008080']);
    if (!!mensagensDocumento) {
      setMensagens([]);
      carregarMensagens();
      setTempoAtualizacaoMensagens(5000);
    }
    else setTempoAtualizacaoMensagens(null);
  }, [mensagensDocumento]);

  useInterval(()=>carregarMensagens(), tempoAtualizacaoMensagens);

  function exibirAlerta(titulo, texto) {
    if (Platform.OS === "web") {
      alert(titulo+'\n\n'+texto);
    }
    else {
      Alert.alert(
        titulo,
        texto
      );
    }
  }

  function carregarMensagens() {
    if (!!mensagensDocumento?.dpf_codigo) {
      if (mensagens.length === 0) setCarregando(true);
      let url = `/ApiMobileMensagens/listarMensagensDocumento?_pes_codigo=${usuario.pes_codigo}&_dpf_codigo=${mensagensDocumento.dpf_codigo}`;
      if (mensagens.length > 0) {
        url += `&_msg_ultimavisualizada=${mensagens[mensagens.length-1].msg_codigo}`;
      }
      else url += '&_msg_ultimavisualizada=0';
      api.get(url).then(res => {
        if (res.status === 200 && res.data.success === true) {
          if (res.data.mensagens.length > 0){
            let msgsId = [];
            res.data.mensagens.map(msg => {
              
              if(msg.pes_remetente !== usuario.pes_codigo){
                let cor = res.data.mensagens.find((m) => {
                  return m.pes_remetente === msg.pes_remetente && m.cor !== undefined;
                });

                if(cor === undefined){
                  cor = mensagens.find((m) => {
                    return m.pes_remetente === msg.pes_remetente;
                  });
                }

                if(cor === undefined){
                  msg.cor = coresRemetente[0]; 
                  coresRemetente.splice(0,1); 
                  setCoresRemetente(coresRemetente);
                }else{
                  msg.cor = cor.cor;
                }

              }

              if (msg.msg_lido === false && msg.pes_destinatario === usuario.pes_codigo) msgsId.push(msg.msg_codigo);
            });
            api.post(`/ApiMobileMensagens/lerMensagens`, {
              _msg_codigolista: msgsId
            });
            setMensagens([...mensagens, ...res.data.mensagens]);
          }
        }
      }, erro => {
        console.log('ERRO ao carregar mensagens', erro);
      }).finally(() => {
        setCarregando(false);
      });
    }
  }

  function enviarMensagem() {
    if (!!mensagem) {
      setEnviando(true);
      let msgAnterior = 0
      if (mensagens.length > 0) msgAnterior = mensagens[mensagens.length-1].msg_codigo;
      let dadosEnviarMensagem = {
        _pes_codigo: usuario.pes_codigo,
        _pes_destinatario: null,
        _mensagem: mensagem,
        _assunto: mensagensDocumento.dpf_descricao,
        _msg_anterior: msgAnterior,
        _dpf_codigo: mensagensDocumento.dpf_codigo
      }
      api.post('/ApiMobileMensagens/enviarMensagem', dadosEnviarMensagem).then(res => {
        setMensagem('');
        if (!res.data.success) exibirAlerta("Erro", res.data.mensagem);
        else carregarMensagens();
      }, erro => {
        exibirAlerta('ERRO', 'Não foi possível realizar o envio da mensagem, tente novamente mais tarde.');
        console.error('ERRO ao enviar a mensagem', erro);
      }).finally(() => {
        setEnviando(false);
        inputMsg.current.focus();
      });
    }
  }

  return (
    <Modal contentContainerStyle={[styles.containerModalMensagens, {padding: 20, margin: 20, flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]} visible={!!mensagensDocumento} onDismiss={fecharModal}>
      <View style={{height: 42, flexDirection: 'row'}}>
        <View style={{flex: 1}}>
          <Title style={{fontSize: ThemeContext._currentValue.theme?.fontSize20}}>Conversa com o professor</Title>
        </View>
        <View style={{width: 36, alignItems: 'flex-end'}}>
          <IconButton style={{margin: 0, padding: 0, backgroundColor: '#ccc', borderRadius: 20}} icon="close" size={18} onPress={fecharModal} />
        </View>
      </View>
      <ScrollView ref={areaMsgs} onContentSizeChange={(contentWidth, contentHeight)=>{        
        areaMsgs.current.scrollToEnd({animated: true});
      }}>
        {carregando === true &&
          <View style={{marginTop: 20, alignItems: 'center', justifyContent: 'center'}}>
            <ActivityIndicator size="large" color="#0a83bf" />
          </View>
        }
        {mensagens.map((mensagem, indice) => <ItemMensagensDocumento key={indice.toString()} mensagem={mensagem} usuario={usuario.pes_codigo} />)}
        {carregando === false && mensagens.length === 0 &&
          <View style={{marginTop: 20, alignItems: 'center', justifyContent: 'center'}}>
            <View style={{borderColor: '#ccc', borderWidth: 1, minWidth: 120, backgroundColor: '#fefefe', padding: 10, borderRadius: 6}}>
              <Text style={{fontWeight: '500', fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>Inicie uma conversa com o professor aqui sobre: {mensagensDocumento?.dpf_descricao}</Text>
            </View>
          </View>
        }
      </ScrollView>
      <View style={{height: 56, flexDirection: 'row', marginTop: 6}}>
        <View style={{flex:1}}>
          <TextInput ref={inputMsg} value={mensagem} autoFocus autoCorrect returnKeyType="send" onSubmitEditing={() => enviarMensagem()} mode="outlined" placeholder="Escreva sua mensagem aqui..." onChangeText={setMensagem} />
        </View>
        <View style={{width: 38}}>
          <IconButton style={{marginTop: 20}} disabled={enviando} icon="send" size={32} onPress={() => enviarMensagem()} />
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  containerModalMensagens: {
    padding: 20,
    margin: 20,
    flex: 1,
    backgroundColor: "#FFF",
  }
});