import React, { useState, useEffect } from 'react';
import { View, ScrollView, KeyboardAvoidingView, Text, TouchableOpacity, StyleSheet, Dimensions, Platform, FlatList, Alert } from 'react-native';
import { Appbar, Searchbar, Button, IconButton, Divider, List, Modal, ActivityIndicator, TextInput } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';
import { SimpleLineIcons } from '@expo/vector-icons';
import _ from 'lodash';
import { parse, format } from 'date-fns';

import { Api, Util } from '../services';
import { BotaoModalPicker } from '../components'

export default function SolicitarAcesso(props) {

  const ETAPAS = { SELECIONAR_PERFIL: 1, CIDADE: 2, INFORMACOES_PESSOAIS: 3, DADOS_MATIRCULA: 4, INFORMACOES_ALUNOS: 5, INFORMACOES_RESPONSAVEL: 6, FORMULARIO_SOLICITACAO: 7, ACESSO_LIBERADO: 8 };
  const PERFIL_USUARIO = { ALUNO: 1, PAIS_RESPONSAVEL: 2 };

  const [ etapa, setEtapa ] = useState(ETAPAS.SELECIONAR_PERFIL);
  const [ perfilUsuario, setPerfilUsuario ] = useState(null);
  const [ carregando, setCarregando ] = useState(false);
  const [ modalEscola, setModalEscola ] = useState(false);
  const [ modalSerie, setModalSerie ] = useState(false);
  const [ modalTurma, setModalTurma ] = useState(false);
  const [ modalCurso, setModalCurso ] = useState(false);
  const [ modalAddFilho, setModalAddFilho ] = useState(false);
  
  const [ filtroCidade, setFiltroCidade ] = useState('');
  const [ filtroEscola, setFiltroEscola ] = useState('');

  const [ textoEscolaSelecionada, setTextoEscolaSelecionada ] = useState(null);
  const [ textoSerieSelecionada, setTextoSerieSelecionada ] = useState(null);
  const [ textoTurmaSelecionada, setTextoTurmaSelecionada ] = useState(null);
  const [ cursoSelecionado, setCursoSelecionado ] = useState({crs_codigo: null, crs_nome: ''});
  
  const [ listaCidades, setListaCidades ] = useState([]);
  const [ listaEscolas, setListaEscolas ] = useState([]);
  const [ listaSerie, setListaSerie ] = useState([]);
  const [ listaTurma, setListaTurma ] = useState([]);
  const [ listaCursos, setListaCursos ] = useState([]);

  const [ cidade, setCidade ] = useState({});
  const [ CPF, setCPF ] = useState('');
  const [ nome, setNome ] = useState('');
  const [ dataNascimento, setDataNascimento ] = useState('');
  const [ email, setEmail ] = useState('');

  const [ filhos, setFilhos ] = useState([]);
  const [ filho, setFilho ] = useState({});

  const [ responsaveis, setResponsaveis ] = useState([]);
  const [ responsavel, setResponsavel ] = useState({});

  const [ informacoesMatricula, setInformacoesMatricula ] = useState({ano: new Date().getFullYear()})

  let cpfField = null;
  let cpfFilhoField = null;

  const apiAdminEducar = Api.create(Api.urlAdminEducar);

  useEffect(() => {
    if (!modalAddFilho) { 
      setFilho({});
      setInformacoesMatricula({ano: new Date().getFullYear()});
      setTextoEscolaSelecionada(null);
      setTextoSerieSelecionada(null);
      setTextoTurmaSelecionada(null);
      setCursoSelecionado({crs_codigo: null, crs_nome: ''});
    }
  }, [modalAddFilho]);

  function exibirAlerta(titulo, texto) {
    if (Platform.OS === "web") {
      alert(titulo+'\n\n'+texto);
    }
    else {
      Alert.alert(
        titulo,
        texto
      );
    }
  }

  function exibirConfirmacao(titulo, texto, descricaoBotaoOK = 'OK', descricaoBotaoCancela = 'Cancelar', funcaoOK = ()=>{}, funcaoCancela = ()=>{}) {
    if (Platform.OS === "web") {
      if (confirm(titulo+'\n\n'+texto)) funcaoOK();
    }
    else {
      Alert.alert(
        titulo,
        texto,
        [
          {
            text: descricaoBotaoCancela,
            onPress: funcaoCancela,
            style: 'cancel',
          },
          {text: descricaoBotaoOK, onPress: funcaoOK},
        ],
        {cancelable: true},
      );
    }
  }

  function definirPerfilUsuario(perfil){
    setPerfilUsuario(perfil);
    setEtapa(ETAPAS.CIDADE);
    setCarregando(true);
    apiAdminEducar.get("AdmOrgaoGovernamental/ListarDisponibilizamAplicativo").then((retorno) => {
      setListaCidades(retorno.data.result);
      setCarregando(false);
    }, (erro) => {
      setCarregando(false);
      console.error(erro);
    })
  }

  function definirCidade(cidade) {
    setCidade(cidade);
    setEtapa(ETAPAS.INFORMACOES_PESSOAIS);
  }

  function criarSenhaUsuario(dados) {
    setCarregando(true);
    
    let dadosUsuario = {
      Id: null,
      Nome: nome,
      Cpf: CPF,
      Email: email,
      DataNascimento: format(parse(dataNascimento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
      Foto: null,
      IdOrgaoGovernamental: cidade.id,
      pes_codigo: dados.pes_codigo
    };

    apiAdminEducar.post('AbsUsuario/Cadastrar', dadosUsuario).then((retorno) => {
      setCarregando(false);
      if (retorno.status === 200) {
        if (retorno.data.success === false) {
          exibirAlerta("ERRO!", retorno.data.message);
        }
        else setEtapa(ETAPAS.ACESSO_LIBERADO);
      }
    }, (erro) => {
      console.error(erro);
      setCarregando(false);
    })
  }

  function verificarCadastroPessoa(dados) {
    setCarregando(true);
    let apiEducar = Api.create(cidade.url);
    apiEducar.post('servicoexterno/BuscarPessoaSolicitacaoAcesso', {...dados, perfil: perfilUsuario}).then((retorno) => {
      setCarregando(false);
      if (retorno.status === 200) {
        if (retorno.data.abs_pessoas.length === 1) {
          criarSenhaUsuario(retorno.data.abs_pessoas[0]);
        }
        else {
          if (etapa === ETAPAS.INFORMACOES_PESSOAIS) {
            switch (perfilUsuario){
              case PERFIL_USUARIO.ALUNO:
                setEtapa(ETAPAS.DADOS_MATIRCULA);
                break;
              case PERFIL_USUARIO.PAIS_RESPONSAVEL:
                setEtapa(ETAPAS.INFORMACOES_ALUNOS);
                break;
            }
          }
          else {
            switch (etapa){
              case ETAPAS.DADOS_MATIRCULA:
                setEtapa(ETAPAS.INFORMACOES_RESPONSAVEL);
                break;
              case ETAPAS.INFORMACOES_ALUNOS:
              case ETAPAS.INFORMACOES_RESPONSAVEL:
                let dadosPessoa = { cpf: CPF, nome: nome, dataNascimento: dataNascimento, email: email, filhos: filhos, dadosMatriculaAluno: informacoesMatricula, responsaveis: responsaveis };
                props.navigation.navigate('FormularioSolicitacaoAcesso', {tipoAcesso: perfilUsuario, cidade: cidade, dados: dadosPessoa});
                break;
            }
          }
        }
      }
    }, (erro) => {
      setCarregando(false);
      console.log(erro);
    });
  }

  function definirDadosPessoais() {
    let dadosObrigatorios = '';

    if (!!CPF === false) dadosObrigatorios+= '* CPF\n'
    if (!!CPF && !cpfField.isValid()) dadosObrigatorios += '- O CPF informado é inválido\n'
    if (!!nome === false) dadosObrigatorios+= '* Nome\n'
    if (!!dataNascimento === false) dadosObrigatorios+= '* Data de Nascimento\n'
    if (!!dataNascimento && Util.dataPosterior(dataNascimento, null, 'dd/LL/yyyy')) dadosObrigatorios += '- A Data de nascimento informada não é válida\n'
    if (!!email === false) dadosObrigatorios+= '* E-mail\n'
    
    if(dadosObrigatorios !== ''){
      exibirAlerta('Informe os seguintes dados:', dadosObrigatorios);
    }
    else verificarCadastroPessoa({ cpf: CPF, nome: nome, dataNascimento: dataNascimento, email: email });
  }

  function definirDadosFilhos() {
    verificarCadastroPessoa({ cpf: CPF, nome: nome, dataNascimento: dataNascimento, email: email, filhos: filhos });
  }

  function definirDadosMatricula() {
    let validacaoFormulario = '';

    if (!!informacoesMatricula.oru_codigo === false) validacaoFormulario+= '- Você deve selecionar a escola onde você está matriculado.\n'
    if (!!informacoesMatricula.ano === false) validacaoFormulario+= '- Você deve informar o ano de sua matricula.\n'
    if (!!informacoesMatricula.ser_codigo === false) validacaoFormulario+= '- Você deve selecionar a série na qual você estuda.\n'
    if (!!informacoesMatricula.trm_codigo === false) validacaoFormulario+= '- Você deve selecionar a turma na qual você estuda.\n'
    
    if(validacaoFormulario !== ''){
      exibirAlerta(
        'Atenção!',
        validacaoFormulario
      );
    }
    else verificarCadastroPessoa({ cpf: CPF, nome: nome, dataNascimento: dataNascimento, email: email, dadosMatriculaAluno: informacoesMatricula });
  }

  function definirDadosResponsaveis() {
    verificarCadastroPessoa({ cpf: CPF, nome: nome, dataNascimento: dataNascimento, email: email, dadosMatriculaAluno: informacoesMatricula, responsaveis: responsaveis });
  }

  function adicionarFilho() {
    let dadosObrigatorios = '';

    if (!cpfFilhoField.isValid) dadosObrigatorios+= '* Um CPF Válido\n'
    if (!!filho.nome === false) dadosObrigatorios+= '* Nome\n'
    if (!!filho.dataNascimento === false) dadosObrigatorios+= '* Data de Nascimento\n'
    if (!!filho.dataNascimento && (!Util.dataValida(filho.dataNascimento, "dd/LL/yyyy") || !Util.dataPosterior(filho.dataNascimento, null, "dd/LL/yyyy"))) dadosObrigatorios+= '- Data de Nascimento inválida\n'
    if (!!filho.nroCertidaoNascimento === false) dadosObrigatorios+= '* Certidão de Nascimento\n'
    if (!!filho.nroCertidaoNascimento && filho.nroCertidaoNascimento.length < 30 && !!filho.livroCertidaoNascimento === false) dadosObrigatorios+= '* Nro Livro da Certidão Nascimento\n'
    if (!!filho.nroCertidaoNascimento && filho.nroCertidaoNascimento.length < 30 && !!filho.folhaCertidaoNascimento === false) dadosObrigatorios+= '* Folha do Livro da Certidão Nascimento\n'
    if (!!informacoesMatricula.oru_codigo === false) dadosObrigatorios+= '* Escola onde o aluno está matriculado\n'
    if (!!informacoesMatricula.ano === false) dadosObrigatorios+= '* Ano em que o aluno está matriculado\n'
    if (!!informacoesMatricula.ser_codigo === false) dadosObrigatorios+= '* Série em que o aluno está matriculado\n'
    if (!!informacoesMatricula.trm_codigo === false) dadosObrigatorios+= '* Turma em que o aluno está matriculado\n'

    if(dadosObrigatorios !== ''){
      exibirAlerta(
        'Informe os seguintes dados:',
        dadosObrigatorios
      );
    }
    else {
      let listaFilhos = [...filhos];
      let dadosFilho = {...filho};
      dadosFilho.informacoesMatricula = informacoesMatricula;
      if (!!dadosFilho.cpf === false) dadosFilho.cpf = '';
      
      listaFilhos.push({...dadosFilho});
      setFilhos(listaFilhos);
      setFilho({});
      setModalAddFilho(false);
    }
  }

  function adicionarResponsavel(){
    let dadosObrigatorios = '';
    if (!!responsavel.nome === false) dadosObrigatorios += '* Nome do responsável\n'
    if (!!responsavel.dataNascimento === false) dadosObrigatorios += '* Data de nascimento do responsável\n'
    if (!Util.dataValida(responsavel.dataNascimento, "dd/LL/yyyy")) dadosObrigatorios += '* Uma data de nascimento do responsável válida\n'
    
    if(dadosObrigatorios !== ''){
      exibirAlerta(
        'Informe os seguintes dados:',
        dadosObrigatorios
      );
    }
    else {
      let listaResponsaveis = [...responsaveis];
      listaResponsaveis.push(responsavel);
      setResponsaveis(listaResponsaveis);
      setResponsavel({nome:'', dataNascimento: ''});
    }
  }

  function renderAcessoLiberado(){
    return (
      <React.Fragment>
        <View style={styles.cardTitulo}>
          <Text style={styles.titulo}>Acesso ao aplicativo liberado!</Text>
        </View>
        <View style={styles.botaoPerfil}>
          <Text style={styles.textoInfo}>Seu acesso ao aplicativo foi liberado. Enviamos para você um e-mail no endereço cadastrado ({email}) com as instruções para definição de sua senha de acesso!</Text>
          <Button icon="check" style={{marginTop: 50}} mode="contained" onPress={() => {
            props.navigation.navigate('Login');
          }}>ENTENDI</Button>
        </View>
      </React.Fragment>
    );  
  }

  function renderSelecionarPerfil() {
    return (
      <React.Fragment>
        <View style={styles.cardTitulo}>
          <Text style={styles.titulo}>Selecione seu Perfil de Usuário</Text>
        </View>
        <View style={styles.areaBotaoPerfil}>
          <TouchableOpacity style={styles.botaoPerfil} onPress={() => definirPerfilUsuario(PERFIL_USUARIO.ALUNO)}>
            <SimpleLineIcons name="graduation" size={48} color="#202021" />
            <Text style={styles.textoBotaoPerfil}>Aluno</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.areaBotaoPerfil}>
          <TouchableOpacity style={styles.botaoPerfil} onPress={() => definirPerfilUsuario(PERFIL_USUARIO.PAIS_RESPONSAVEL)}>
            <SimpleLineIcons name="people" size={48} color="#202021" />
            <Text style={styles.textoBotaoPerfil}>Pai, Mãe ou Responsável Legal</Text>
          </TouchableOpacity>
        </View>
      </React.Fragment>
    );
  }

  function renderSelecionarCidade() {
    return (
      <React.Fragment>
        <View style={styles.cardTitulo}>
          <Text style={styles.titulo}>{perfilUsuario === PERFIL_USUARIO.ALUNO ? 'Informe a Cidade onde você estuda' : 'Informe a Cidade onde seu(s) filho(os) estuda(m)'}</Text>
        </View>
        <View style={styles.botaoPerfil}>
          <Searchbar style={styles.searchBar} placeholder="Localizar Cidade" autoCapitalize="none" autoCorrect={false} returnKeyLabel="search" value={filtroCidade} onChangeText={setFiltroCidade} />
          <View style={{flex:1}}>
            <FlatList data={_.filter(listaCidades, function(o) {
              return o.nome.toLowerCase().indexOf(filtroCidade.toLowerCase()) > -1;
            })} renderItem={({item, index}) => (
              <React.Fragment>
                <List.Item
                  style={styles.searchBar}
                  title={item.nome}
                  right={props => <List.Icon {...props} icon="arrow-forward" />}
                  onPress={() => definirCidade(item)}
                />
                <Divider />
              </React.Fragment>
            )} keyExtractor={item => item.id}/>
          </View>
        </View>
      </React.Fragment>
    );
  }

  function renderDadosPessoais() {
    return (
      <KeyboardAvoidingView behavior="padding" enabled>
        <View style={styles.cardTitulo}>
          <Text style={styles.titulo}>Informe seus dados pessoais</Text>
        </View>
        <View style={styles.botaoPerfil}>
          <View style={styles.linhaFormulario}>
            <TextInput
            label={"Informe seu CPF"}
            value={CPF}
            onChangeText={text => {
              setCPF(text);
            }}
            style={{width: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 210 : Dimensions.get("screen").width - (Dimensions.get("screen").width*0.194)}}
            render={props =>
              <TextInputMask
                {...props}
                type={'cpf'}
                keyboardType="decimal-pad"
                ref={(ref) => cpfField = ref}
              />    
            }
            />
          </View>
          <View style={styles.linhaFormulario}>
            <TextInput label="Informe seu Nome" value={nome} onChangeText={setNome} autoCompleteType="name"
              style={{width: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? Dimensions.get("window").width - 600 : Dimensions.get("window").width - (Dimensions.get("screen").width*0.194)}} />
          </View>
          <View style={styles.linhaFormulario}>
            <TextInput
            label="Data de Nascimento"
            value={dataNascimento}
            onChangeText={text => {
              setDataNascimento(text);
            }}
            style={{width: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 210 : Dimensions.get("window").width - (Dimensions.get("screen").width*0.194)}}
            render={props =>
              <TextInputMask
                {...props}
                type={'custom'}
                options={{
                  mask: '99/99/9999'
                }}
                keyboardType="decimal-pad"
              />    
            }
            />
          </View>
          <View style={styles.linhaFormulario}>
            <TextInput label="Informe seu E-mail" value={email} onChangeText={setEmail} keyboardType="email-address" autoCompleteType="email"
              style={{width: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? Dimensions.get("window").width - 600 : Dimensions.get("window").width - (Dimensions.get("screen").width*0.194)}}
            />
          </View>
          <View style={{marginTop: 40, marginBottom: 10, justifyContent: "center"}}>
            <Button icon="arrow-forward" mode="contained" onPress={() => definirDadosPessoais()}>Avançar</Button>
          </View>
        </View>
      </KeyboardAvoidingView>
    );
  }

  function renderDadosMatricula(){
    return (
      <React.Fragment>
        <View style={styles.cardTitulo}>
          <Text style={styles.titulo}>Informe os dados de sua matricula na escola</Text>
        </View>
        <View style={styles.botaoPerfil}>
          {renderFormularioInformacoesMatricula()}
          <View style={{marginVertical: 15, justifyContent: "center"}}>
            <Button icon="arrow-forward" mode="contained" onPress={() => definirDadosMatricula()}>Avançar</Button>
          </View>
        </View>
      </React.Fragment>
    );
  }

  function renderDadosResponsavel(){
    return (
      <React.Fragment>
        <View style={styles.cardTitulo}>
          <Text style={styles.titulo}>Informe os dados de seus pais ou responsáveis legais</Text>
        </View>
        <View style={styles.botaoPerfil}>
        
          {Platform.OS === "web" &&
            <View style={styles.linhaFormulario}>
              <TextInput style={{marginRight: 5, flex: 1}} label="Informe o Nome do Responsável" value={responsavel.nome} onChangeText={text => setResponsavel({...responsavel, nome: text})} autoCompleteType="name" autoCorrect={false} />
              <TextInput style={{marginLeft: 5, flex: 1}}
              label="Data de Nascimento"
              value={responsavel.dataNascimento}
              onChangeText={text => {
                setResponsavel({...responsavel, dataNascimento: text });
              }}
              
              render={props =>
                <TextInputMask
                  {...props}
                  type={'custom'}
                  options={{
                    mask: '99/99/9999'
                  }}
                  keyboardType="decimal-pad"
                />
              } />
              <View style={{alignContent: "center", justifyContent: "center"}}>
                <Button style={{marginLeft: 15}} icon="add" mode="contained" onPress={() => adicionarResponsavel()}>Adicionar</Button>
              </View>
            </View>
          }

          {Platform.OS !== "web" &&
            <React.Fragment>
              <View style={styles.linhaFormulario}>
                <TextInput style={{marginRight: 5, flex: 1}} label="Informe o Nome do Responsável" value={responsavel.nome} onChangeText={text => setResponsavel({...responsavel, nome: text})} autoCompleteType="name" autoCorrect={false} />
              </View>
              <View style={styles.linhaFormulario}>
                <TextInput style={{marginLeft: 5, flex: 1}}
                label="Data de Nascimento"
                value={responsavel.dataNascimento}
                onChangeText={text => {
                  setResponsavel({...responsavel, dataNascimento: text });
                }}
                
                render={props =>
                  <TextInputMask
                    {...props}
                    type={'custom'}
                    options={{
                      mask: '99/99/9999'
                    }}
                    keyboardType="decimal-pad"
                  />
                } />
                <View style={{alignContent: "center", justifyContent: "center"}}>
                  <Button style={{marginLeft: 15}} icon="add" mode="contained" onPress={() => adicionarResponsavel()}>Adicionar</Button>
                </View>
              </View>
            </React.Fragment>
          }

          {responsaveis.length > 0 && (
            <ScrollView contentContainerStyle={{flex:1, marginTop: 10}}>
              <FlatList style={{width: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 600 : Dimensions.get("window").width - (Dimensions.get("screen").width*0.194)}} data={responsaveis} renderItem={({item, index}) => (
                <React.Fragment>
                  <List.Item
                    title={item.nome}
                    description={`Data Nascimento: ${item.dataNascimento}`}
                    right={props => (
                      <IconButton icon="remove" size={22} onPress={() => {
                        exibirConfirmacao('Remover Responsável?', 'Deseja remover o registro?', 'SIM', 'Cancelar', () => {
                          let dadosResponsaveis = [...responsaveis];
                          dadosResponsaveis.splice(index, 1);
                          setResponsaveis(dadosResponsaveis);
                        });
                      }} />
                    )}
                  />
                  <Divider />
                </React.Fragment>
              )} keyExtractor={(item, index) => index.toString()} />
            </ScrollView>
          )}
          {responsaveis.length === 0 && (
            <Text style={{marginHorizontal: 10}}>Adicione as informações de seus responsáveis para que possamos analisar os dados e encontrar seu cadastro no sistema.</Text>
          )}

          {responsaveis.length > 0 &&
            <View style={{marginVertical: 15, justifyContent: "center"}}>
              <Button icon="arrow-forward" mode="contained" onPress={() => definirDadosResponsaveis()}>Avançar</Button>
            </View>
          }
        </View>
      </React.Fragment>
    );
  }

  function renderInformacoesFilhos(){
    return (
      <React.Fragment>
        <View style={styles.cardTitulo}>
          <Text style={styles.subTitulo}>Não identificamos seu cadastro no sistema, precisamos que nos informe mais alguns dados.</Text>
          <Text style={styles.subTitulo}>Informações sobre seu(s) filho(s)</Text>
        </View>
        <View style={styles.botaoPerfil}>
          {filhos.length > 0 && (
            <ScrollView contentContainerStyle={{flex:1, marginTop: 10}}>
              <FlatList style={{width: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 600 : Dimensions.get("window").width - (Dimensions.get("screen").width*0.194)}} data={filhos} renderItem={({item, index}) => (
                <React.Fragment>
                  <List.Item key={index}
                    title={item.nome}
                    description={`CPF: ${!!item.cpf ? item.cpf : 'Não Informado'} - Data Nascimento: ${item.dataNascimento}`}
                    right={props => (
                      <IconButton icon="remove" size={22} onPress={() => {
                        exibirConfirmacao('Remover?', 'Deseja remover o registro?', 'SIM', 'Cancelar', () => {
                          let dadosFilhos = [...filhos];
                          dadosFilhos.splice(index, 1);
                          setFilhos(dadosFilhos);
                        });
                      }} />
                    )}
                  />
                  <Divider />
                </React.Fragment>
              )} keyExtractor={item => item.cpf} />
            </ScrollView>
          )}
          {filhos.length === 0 && (
            <Text style={{marginHorizontal: 10}}>Adicione as informações de seus filhos para que possamos analisar os dados e encontrar seu cadastro no sistema.</Text>
          )}
          <View style={{alignItems: "flex-end", marginTop: 10}}>
             <Button mode="contained" onPress={() => setModalAddFilho(true)}>Adicionar Filho</Button>
          </View>
          {filhos.length > 0 && (
            <View style={{marginVertical: 15, justifyContent: "center"}}>
              <Button icon="arrow-forward" mode="contained" onPress={() => definirDadosFilhos()}>Avançar</Button>
            </View>
          )}
        </View>
      </React.Fragment>
    );
  }

  function renderFormularioInformacoesMatricula(){
    return (
      <React.Fragment>
        <View style={styles.linhaFormulario}>
          <BotaoModalPicker label="Selecione a Escola" texto={textoEscolaSelecionada} onPress={() => abrirModalUnidade()} />
          
        </View>

        <View style={styles.linhaFormulario}>
          <TextInput label="Ano" style={{width: 100}} value={informacoesMatricula.ano.toString()} onChangeText={text => setInformacoesMatricula({...informacoesMatricula, ano: text})} autoCompleteType="off" keyboardType="decimal-pad" />
        </View>

        <View style={styles.linhaFormulario}>
          <BotaoModalPicker label="Selecione o Curso" texto={cursoSelecionado.crs_nome} onPress={() => abrirModalCurso()} />
        </View>

        <View style={styles.linhaFormulario}>
          <BotaoModalPicker label="Selecione a Série" texto={textoSerieSelecionada} onPress={() => abrirModalSerie()} />
        </View>

        <View style={styles.linhaFormulario}>
          <BotaoModalPicker label="Selecione a Turma" texto={textoTurmaSelecionada} onPress={() => abrirModalTurma()} />
        </View>
      </React.Fragment>
    );
  }

  function abrirModalUnidade() {
    let apiEducar = Api.create(cidade.url);//'http://localhost:49999/');
    setCarregando(true);
    apiEducar.get(`servicoexterno/listarUnidadesApp`).then((dados) => {
      setListaEscolas(dados.data.lista);
      setModalEscola(true);
      setCarregando(false);
    }, (erro) => {
      console.error(erro);
      setCarregando(false);
      exibirAlerta('Erro', 'Não foi possível carregar a lista de unidades de ensino. Tente novamente mais tarde.');
    });
  }

  function abrirModalCurso() {
    if (!!informacoesMatricula.oru_codigo) {
      let apiEducar = Api.create(cidade.url);//'http://localhost:49999/');
      setCarregando(true);
      apiEducar.get(`servicoexterno/listarCursos?oru_codigo=${informacoesMatricula.oru_codigo}&ano=${informacoesMatricula.ano}`).then((dados) => {
        setListaCursos(dados.data.lista);
        setModalCurso(true);
        setCarregando(false);
      }, (erro) => {
        console.error(erro);
        setCarregando(false);
        exibirAlerta('Erro', 'Não foi possível carregar a lista de cursos. Tente novamente mais tarde.');
      });
    }
    else exibirAlerta('Atenção!', 'Antes de selecionar o curso você deve selecionar a Escola!');
  }

  function abrirModalSerie() {
    if (!!cursoSelecionado.crs_codigo) {
      let apiEducar = Api.create(cidade.url);//'http://localhost:49999/');
      setCarregando(true);
      apiEducar.get(`servicoexterno/listarSeries?oru_codigo=${informacoesMatricula.oru_codigo}&ano=${informacoesMatricula.ano}&crs_codigo=${cursoSelecionado.crs_codigo}`).then((dados) => {
        setListaSerie(dados.data.lista);
        setModalSerie(true);
        setCarregando(false);
      }, (erro) => {
        console.error(erro);
        setCarregando(false);
        exibirAlerta('Erro', 'Não foi possível carregar a lista de séries. Tente novamente mais tarde.');
      });
    }
    else exibirAlerta('Atenção!', 'Antes de selecionar a Série, você precisa selecionar um Curso!');
  }

  function abrirModalTurma() {
    if (!!informacoesMatricula.ser_codigo) {
      let apiEducar = Api.create(cidade.url);//'http://localhost:49999/');
      setCarregando(true);
      apiEducar.get(`servicoexterno/listarTurmas?oru_codigo=${informacoesMatricula.oru_codigo}&ser_codigo=${informacoesMatricula.ser_codigo}&ano=${informacoesMatricula.ano}`).then((dados) => {
        setListaTurma(dados.data.lista);
        setModalTurma(true);
        setCarregando(false);
      }, (erro) => {
        console.error(erro);
        setCarregando(false);
        exibirAlerta('Erro', 'Não foi possível carregar a lista de turmas. Tente novamente mais tarde.');
      });
    }
    else exibirAlerta('Atenção!', 'Antes de selecionar a Turma, você precisa selecionar uma Série!');
  }

  return (
    <React.Fragment>
      <Appbar.Header>
        <Appbar.BackAction
          onPress={ () => {
            props.navigation.navigate('Login');
          } }
        />
        <Appbar.Content
          title={"Solicitação de acesso" + (!!perfilUsuario === true ? (perfilUsuario === PERFIL_USUARIO.ALUNO ? ' para aluno' : ' para responsáveis') : '')}
        />
      </Appbar.Header>
      <View style={styles.container}>
        
        {etapa === ETAPAS.SELECIONAR_PERFIL && renderSelecionarPerfil()}
        {etapa === ETAPAS.CIDADE && renderSelecionarCidade()}
        {etapa === ETAPAS.INFORMACOES_PESSOAIS && renderDadosPessoais()}
        {etapa === ETAPAS.DADOS_MATIRCULA && renderDadosMatricula()}
        {etapa === ETAPAS.INFORMACOES_RESPONSAVEL && renderDadosResponsavel()}
        {etapa === ETAPAS.INFORMACOES_ALUNOS && renderInformacoesFilhos()}
        {etapa === ETAPAS.ACESSO_LIBERADO && renderAcessoLiberado()}
        
      </View>

      <Modal visible={carregando}>
        <ActivityIndicator color="#FFF" size="large" />
      </Modal>

      <Modal visible={modalAddFilho} contentContainerStyle={styles.modalFiltro}>
      <ScrollView>
        <View style={styles.linhaFormulario}>
          <TextInput
          label="Informe o CPF"
          value={filho.cpf||''}
          onChangeText={text => {
            setFilho({...filho, cpf: text});
          }}
          style={Platform.OS === "web" ? {width: 210} : {flex: 1}}
          render={props =>
            <TextInputMask
              {...props}
              type={'cpf'}
              keyboardType="decimal-pad"
              ref={(ref) => cpfFilhoField = ref}
            />    
          }
          />
        </View>
        <View style={styles.linhaFormulario}>
          <TextInput label="Informe o Nome do aluno" value={filho.nome} onChangeText={text => setFilho({...filho, nome: text})} autoCompleteType="name"
          style={{flex: 1}} />
        </View>
        <View style={styles.linhaFormulario}>
          <TextInput
          label="Data de Nascimento"
          value={filho.dataNascimento}
          onChangeText={text => {
            setFilho({...filho, dataNascimento: text });
          }}
          style={Platform.OS === "web" ? {width: 210} : {flex: 1}}
          render={props =>
            <TextInputMask
              {...props}
              type={'custom'}
              options={{
                mask: '99/99/9999'
              }}
              keyboardType="decimal-pad"
            />
          }
          />
        </View>
        <View style={styles.linhaFormulario}>
          <TextInput label="Número da Certidão de Nascimento" value={filho.nroCertidaoNascimento} onChangeText={text => setFilho({...filho, nroCertidaoNascimento: text})} autoCompleteType="off"
          style={{flex: 1}} />
        </View>
        
        {!!filho.nroCertidaoNascimento && filho.nroCertidaoNascimento.length < 30 &&
          <View style={styles.linhaFormulario}>
            <TextInput label="Livro Certidão de Nascimento" style={Platform.OS === "web" ? {width: 250} : {flex: 1}} value={filho.livroCertidaoNascimento} onChangeText={text => setFilho({...filho, livroCertidaoNascimento: text})} autoCompleteType="off"/>
            <TextInput label="Folha Certidão de Nascimento" style={Platform.OS === "web" ? {marginLeft: 10, width: 250} : {flex: 1}} value={filho.folhaCertidaoNascimento} onChangeText={text => setFilho({...filho, folhaCertidaoNascimento: text})} autoCompleteType="off"/>
          </View>
        }

        {renderFormularioInformacoesMatricula()}

        <View style={{flexDirection: "row", alignSelf: "flex-end", margin: 5}}>
            <Button mode="contained" icon="check" onPress={() => {
              adicionarFilho();
            }}>Adicionar</Button>
            <Button style={{marginLeft: 10}} mode="outlined" icon="close" onPress={() => {
              setModalAddFilho(false);
            }}>Cancelar</Button>
          </View>
        </ScrollView>
      </Modal>

      <Modal visible={modalEscola} onDismiss={()=>setModalEscola(false)} contentContainerStyle={styles.modalFiltro}>
        <View style={styles.modalConteudo}>
          <Searchbar placeholder="Localizar Unidade de Ensino" autoCapitalize="none" autoCorrect={false} returnKeyLabel="search" value={filtroEscola} onChangeText={setFiltroEscola} />
          <ScrollView contentContainerStyle={{flex:1}}>
            <FlatList data={_.filter(listaEscolas, function(o) {
              return o.und_nome.toLowerCase().indexOf(filtroEscola.toLowerCase()) > -1;
            })} renderItem={({item, index}) => (
              <React.Fragment>
                <List.Item
                  title={item.und_nome}
                  right={props => <List.Icon {...props} icon="arrow-forward" />}
                  onPress={() => {
                    setInformacoesMatricula({...informacoesMatricula, oru_codigo: item.oru_codigo})
                    setTextoEscolaSelecionada(item.und_nome);
                    setFiltroEscola('');
                    setModalEscola(false);
                  }}
                />
                <Divider />
              </React.Fragment>
            )} keyExtractor={item => item.oru_codigo.toString()} />
          </ScrollView>
          <View style={{alignItems: "flex-end", marginTop: 10}}>
            <Button mode="contained" icon="close" onPress={() => {
              setModalEscola(false);
              setFiltroEscola('');
            }}>Cancelar</Button>
          </View>
        </View>
      </Modal>

      <Modal visible={modalSerie} onDismiss={()=>setModalSerie(false)} contentContainerStyle={styles.modalFiltro}>
        <View style={styles.modalConteudo}>
          <ScrollView contentContainerStyle={{flex:1}}>
            <FlatList data={listaSerie} renderItem={({item, index}) => (
              <React.Fragment>
                <List.Item
                  title={item.ser_descricao}
                  right={props => <List.Icon {...props} icon="arrow-forward" />}
                  onPress={() => {
                    setInformacoesMatricula({...informacoesMatricula, ser_codigo: item.ser_codigo});
                    setTextoSerieSelecionada(item.ser_descricao);
                    setModalSerie(false);
                  }}
                />
                <Divider />
              </React.Fragment>
            )} keyExtractor={item => item.ser_codigo.toString()} />
          </ScrollView>
          <View style={{alignItems: "flex-end", marginTop: 10}}>
            <Button mode="contained" icon="close" onPress={() => setModalSerie(false)}>Cancelar</Button>
          </View>
        </View>
      </Modal>

      <Modal visible={modalTurma} onDismiss={()=>setModalTurma(false)} contentContainerStyle={styles.modalFiltro}>
        <View style={styles.modalConteudo}>
          <ScrollView contentContainerStyle={{flex:1}}>
            <FlatList data={listaTurma} renderItem={({item, index}) => (
              <React.Fragment>
                <List.Item
                  title={item.trm_nometurma}
                  right={props => <List.Icon {...props} icon="arrow-forward" />}
                  onPress={() => {
                    setInformacoesMatricula({...informacoesMatricula, trm_codigo: item.trm_codigo});
                    setTextoTurmaSelecionada(item.trm_nometurma);
                    setModalTurma(false);
                  }}
                />
                <Divider />
              </React.Fragment>
            )} keyExtractor={item => item.trm_codigo.toString()} />
          </ScrollView>
          <View style={{alignItems: "flex-end", marginTop: 10}}>
            <Button mode="contained" icon="close" onPress={() => setModalTurma(false)}>Cancelar</Button>
          </View>
        </View>
      </Modal>

      <Modal visible={modalCurso} onDismiss={()=>setModalCurso(false)} contentContainerStyle={styles.modalFiltro}>
        <View style={styles.modalConteudo}>
          <ScrollView contentContainerStyle={{flex:1}}>
            <FlatList data={listaCursos} renderItem={({item, index}) => (
              <React.Fragment>
                <List.Item
                  title={item.crs_nome}
                  right={props => <List.Icon {...props} icon="arrow-forward" />}
                  onPress={() => {
                    setCursoSelecionado({crs_codigo: item.crs_codigo, crs_nome: item.crs_nome});
                    setModalCurso(false);
                  }}
                />
                <Divider />
              </React.Fragment>
            )} keyExtractor={item => item.crs_codigo.toString()} />
          </ScrollView>
          <View style={{alignItems: "flex-end", marginTop: 10}}>
            <Button mode="contained" icon="close" onPress={() => setModalCurso(false)}>Cancelar</Button>
          </View>
        </View>
      </Modal>
      
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: '#438eb9',
  },
  modalFiltro: {
    backgroundColor: "#FFF",
    padding: 10,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30),
  },
  modalContainerTitulo: {
    alignItems: "center",
  },
  modalTitulo: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#202021"
  },
  modalConteudo: {
    marginTop: 20,
    marginHorizontal: (Platform.OS === "web" ? 10 : 5),
    height: Dimensions.get('window').height - 140
  },
  cardTitulo: {
    alignContent: "center",
    justifyContent: "center",
    height: 50,
  },
  titulo: {
    fontSize: 23,
    fontWeight: "bold",
    color: "#FFF",
    flexWrap: "wrap",
    textAlign: "center",
    marginHorizontal: 10
  },
  subTitulo: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#FFF",
    flexWrap: "wrap",
    textAlign: "center"
  },
  textoInfo: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#000",
    flexWrap: "wrap",
    textAlign: "center"
  },
  areaBotaoPerfil: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  botaoPerfil: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    minWidth: Dimensions.get("window").width-50,
    maxWidth: Dimensions.get("window").width-50,
    margin: 10,
  },
  textoBotaoPerfil: {
    fontSize: 22,
    marginTop: 15,
  },
  searchBar: {
    minWidth: Dimensions.get("window").width-50,
    maxWidth: Dimensions.get("window").width-50,
    marginBottom: 5
  },
  linhaFormulario: {
    flexDirection: 'row',
    flexWrap: "wrap",
    margin: 5,
    alignSelf: "stretch",
    paddingLeft: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 250 : 5,
    paddingRight: Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 250 : 5
  }
})