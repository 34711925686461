import React from 'react';
import { View, Platform, StyleSheet, Dimensions, ScrollView } from 'react-native';
import { Modal, Text, List, Button } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useStateValue } from '../../state';

import {themes, ThemeContext} from '../../../theme-context';

export default function ModalSelecionaFilho(props) {

  const [{ usuario, filhos }, dispatch] = useStateValue();

  async function selecionarFilho(filho) {
    let infoUsuario = usuario;
    infoUsuario.pes_codigo = filho.pes_codigo;
    infoUsuario.matricula = filho.matricula;
    dispatch({
      type: 'alterarUsuario',
      newUsuario: infoUsuario
    });

    dispatch({
      type: 'alterarEstudante',
      newEstudante: { 
          nome: filho.pes_nome, 
          turma: filho.turma, 
          escola: filho.escola,
          matricula: filho.matricula,
          pes_codigo: (!!filho.codPessoa) ? parseInt(filho.codPessoa) : parseInt(filho.pes_codigo),
          codturma: filho.codTurma,
          periodo: filho.periodo,
          nperiodo: filho.nperiodo
      }
    });
    let dadosUsuario = JSON.parse(await AsyncStorage.getItem('EducarWebEstudanteApp:user'));
    dadosUsuario.pes_codigo = (!!filho.codPessoa) ? parseInt(filho.codPessoa) : parseInt(filho.pes_codigo);
    dadosUsuario.matricula = parseInt(filho.matricula);
    await AsyncStorage.setItem('EducarWebEstudanteApp:user', JSON.stringify(dadosUsuario));
    props.onDismiss();
  }

  return (
    <Modal visible={props.visible} onDismiss={props.onDismiss} contentContainerStyle={[styles.container, {padding: 20,
      margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30),backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal}]} propagateSwipe={true}>
      <View style={styles.containerTitulo}>
        <Text style={[styles.titulo, {fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }]}>Selecione o Estudante</Text>
      </View>
      <View style={[styles.conteudo, { backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
        <ScrollView style={{maxHeight: (Dimensions.get('window').height - (Dimensions.get('window').height * 0.55))}}>
          {!!filhos && filhos.map((filho, indice) => (
              <List.Item
                onPress={() => selecionarFilho(filho)}
                title={filho.pes_codigo+' - '+filho.pes_nome}
                description={props =>  <Text style={{fontSize:ThemeContext._currentValue.theme?.fontSize14}} {...props} numberOfLines={5}> {filho.turma} {(!!filho.nperiodo) ? '('+ filho?.periodo?.substring(0,6) +')': ''} - {filho.escola} </Text>}
                left={props => <List.Icon {...props} icon="account-box" />}
                key={indice}
              />
          ))}
        </ScrollView>
      </View>
      <View style={[styles.conteudo, { backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
        <Button mode="outlined" onPress={() => props.onDismiss()}>Cancelar</Button>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    margin: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 220 : 30),
    backgroundColor: "#FFF"
  },
  containerTitulo: {
    alignSelf: "center",
  },
  titulo: {
    fontSize: 16,
    fontWeight: "bold"
  },
  conteudo: {
    marginTop: 20,
    marginHorizontal: (Platform.OS === "web" && Dimensions.get('window').width >= 1024 ? 40 : 5),
    alignContent: "center"
  },
});