import React, { useState } from 'react';
import { View, Text, StyleSheet, Dimensions, Platform } from 'react-native';
import { Menu, IconButton, Button, Badge } from 'react-native-paper';
import { format } from 'date-fns';

import { useStateValue } from '../../state';
import {ThemeContext} from '../../../theme-context';

export default function ItemListaDocumentos({dados, responderDocumento, visualizarDocumento, visualizarPaginaDocumento, downloadDocumento, abrirMensagensDocumento, abrirChatGrupo, visualizarAnexo, cor}) {

  const [{ usuario }, dispatch] = useStateValue();
  const [ menuAcoes, setMenuAcoes ] = useState(false);

  return (
    <View style={[styles.container, {flexDirection: "row", flex: 1, minHeight: 92, alignItems: "center", borderWidth: ThemeContext._currentValue.theme?.borderWidthDoc, borderLeftColor: ThemeContext._currentValue.theme?.borderLeftColorDoc }]}>
      <View style={{ height: 92, width: 5, backgroundColor: (!!cor ? cor : "#4186c3"), marginRight: 5 }} />
      
      
        <View style={styles.conteudoLista}>
          <View>
            <Text ellipsizeMode="clip" numberOfLines={5} style={[styles.descricao, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite }]}>{!!dados.novoDocumento && <Badge style={{backgroundColor:'#2c9648', padding: 4}} size={24}> * Novo * </Badge>} {dados.dpf_descricao}</Text>
          </View>
          <View>
            <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite}}>Publicado em: {format(new Date(dados.dpf_data), 'dd/LL/yyyy')}</Text>
            {!!dados.dpf_datalimite && <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite}}>Entregar até: {format(new Date(dados.dpf_datalimite), 'dd/LL/yyyy')}</Text> }
            {dados.qtde_respostas > 0 && <Text style={{fontWeight: 'bold', color: (!!ThemeContext._currentValue.theme.dark) ? ThemeContext._currentValue.theme?.textPink : '#0900b0', fontSize: ThemeContext._currentValue.theme?.fontSize14}}>Resposta já enviada!</Text> }
            {!!dados.correcao && 
                <Text style={{fontWeight: 'bold', color: '#2c9648', fontSize: ThemeContext._currentValue.theme?.fontSize14}} onPress={() => responderDocumento(dados)}>Há correção! 
                  { Platform.OS === "web" && <Text style={{color: '#2c9648', fontSize: ThemeContext._currentValue.theme?.fontSize9}}> (clique para ver) </Text> }
                  { Platform.OS != "web" && <Text style={{color: '#2c9648', fontSize: ThemeContext._currentValue.theme?.fontSize9}}> (toque para ver) </Text> }
                </Text> 
            }
          </View>
        </View>
        
        {Dimensions.get('window').width >= 1024 &&
          <View style={styles.conteudoAcoes}>
              {!!dados.dpf_link &&
                <Button style={styles.botaoAcao} icon="link" compact mode="contained" onPress={() => visualizarDocumento(dados)}>Links</Button>
              }
              {!!dados.possuianexo &&
                <Button style={styles.botaoAcao} icon="pageview" compact mode="contained" onPress={() => {
                  dados.atualizaLog = true;
                  dados.tipolog = 0;
                  dados.tituloModalVisualizar = `Visualizando a atividade: ${dados?.dpf_descricao}`;
                  dados.codigoVisualizar = dados.arq_codigo;
                  visualizarAnexo(dados);
                }}>Visualizar</Button>
              }
              
              {dados.possuipagina &&
                <Button style={styles.botaoAcao} icon="pageview" compact mode="contained" onPress={() => {
                  dados.atualizaLog = true;
                  dados.tipolog = 2;
                  dados.tituloModalVisualizar = `Visualizando a atividade: ${dados?.dpf_descricao}`;
                  dados.codigoVisualizar = 0;
                  visualizarPaginaDocumento(dados);
                }}>Conteúdo</Button>
              }

              {!!dados.possuianexo &&
                <Button style={styles.botaoAcao} icon="cloud-download" compact mode="contained" onPress={() => downloadDocumento(dados)}>Download</Button>
              }
              {!!dados.dpf_datalimite && dados.dpf_tiporesposta > 0 &&
                <Button style={styles.botaoAcao} icon="send" compact mode="contained" onPress={() => responderDocumento(dados)}>Responder Exercício</Button>
              }
              <Button style={styles.botaoAcao} icon="mail" compact mode="contained" onPress={() => abrirMensagensDocumento(dados)}>Tirar Dúvidas</Button>
              {!!dados.quantidadeMensagensNaoLidas && <Badge style={{position: "relative", right: 8, top: -20}}>{dados.quantidadeMensagensNaoLidas}</Badge>}

              {/*<Button style={styles.botaoAcao} icon="chat" compact mode="contained" onPress={() => abrirChatGrupo(dados)}>Chat em Grupo</Button>*/}
          </View>
        }
        {Dimensions.get('window').width < 1024 &&
          <View style={styles.conteudoAcoes}>
            <Menu
              visible={menuAcoes}
              onDismiss={() => setMenuAcoes(false)}
              anchor={<React.Fragment>
                  <IconButton icon="arrow-drop-down-circle" color="black" size={28} onPress={() => setMenuAcoes(true)} />
                  {!!dados.quantidadeMensagensNaoLidas && <Badge style={{position: "relative", right: 5, top: -18}} size={12} />}
                </React.Fragment>}>
              <Menu.Item title={dados.dpf_descricao} titleStyle={{color: '#black', fontSize: 14}} />
              <Menu.Item disabled={!!!dados.dpf_link} icon="link" onPress={() => {
                setMenuAcoes(false);
                visualizarDocumento(dados);
              }} title="Links" />
              {!!dados.possuianexo &&
                <Menu.Item icon="pageview" onPress={() => {
                  setMenuAcoes(false);
                  dados.atualizaLog = true;
                  dados.tipolog = 0;
                  dados.tituloModalVisualizar = `Visualizando a atividade: ${dados?.dpf_descricao}`;
                  dados.codigoVisualizar = dados.arq_codigo;
                  visualizarAnexo(dados); 
                }} title="Visualizar" />
              }
              {dados.possuipagina &&
                <Menu.Item icon="pageview" onPress={() => {
                  setMenuAcoes(false);
                  dados.atualizaLog = true;
                  dados.tipolog = 2;
                  dados.tituloModalVisualizar = `Visualizando a atividade: ${dados?.dpf_descricao}`;
                  dados.codigoVisualizar = 0;
                  visualizarPaginaDocumento(dados); 
                }} title="Conteúdo" />
              }
              {!!dados.possuianexo &&
                <Menu.Item icon="cloud-download" onPress={() => {
                  setMenuAcoes(false);
                  downloadDocumento(dados);
                }} title="Download" />
              }
              {!!dados.dpf_datalimite && dados.dpf_tiporesposta > 0 &&
              <Menu.Item icon="send" onPress={() => {
                setMenuAcoes(false);
                responderDocumento(dados);
              }} title="Responder Exercício" />
              }
              <Menu.Item icon="mail" onPress={() => {
                setMenuAcoes(false);
                abrirMensagensDocumento(dados);
              }} title={<React.Fragment>
                  <Text>Tirar Dúvidas </Text>
                  {!!dados.quantidadeMensagensNaoLidas && <Badge size={24} style={{position: "relative"}}>{dados.quantidadeMensagensNaoLidas}</Badge>}
                </React.Fragment>} />

                {/*<Menu.Item icon="chat" onPress={() => {
                setMenuAcoes(false);
                abrirChatGrupo(dados);
              }} title={<React.Fragment>
                  <Text>Chat em Grupo</Text>
              </React.Fragment>} />*/}
                
            </Menu>
          </View>
        }
      
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
    minHeight: 92,
    alignItems: "center",
  },
  descricao: {
    fontSize: 14,
    fontWeight: "bold",
  },
  conteudoLista: { 
    flexDirection: "column", 
    justifyContent: 'center',
    flex: Dimensions.get('window').width >= 1024 ? 0.3 : 0.8
  },
  conteudoAcoes: { 
    flexDirection: "row", 
    flex: Dimensions.get('window').width >= 1024 ? 0.7 : 0.2, 
    justifyContent: 'flex-start', 
    alignItems: 'center'
  },
  botaoAcao: {
    marginLeft: 10
  }
})