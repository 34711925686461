import React, { useState, useEffect } from 'react';
import { View, FlatList, Text, StyleSheet, Platform, Dimensions, TouchableOpacity } from 'react-native';
import { Divider, Button, ActivityIndicator, Modal, Title, Badge, Switch } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';

import { ModalAviso } from '../components';
import { Api } from '../services';
import { useStateValue } from '../state';
import { ThemeContext } from '../../theme-context';

export default function Notificacoes(props) {

  const NOTIFICACOES_POR_PAGINA = (Platform.OS === "web" ? parseInt(Math.round(Dimensions.get("window").height / 100)) : 10);

  const [{ usuario, qtdeNotificacoes, estudante }, dispatch] = useStateValue();
  const [carregando, setCarregando] = useState(false);
  const [numeroPagina, setNumeroPagina] = useState(0);
  const [notificacoes, setNotificacoes] = useState([]);
  const [aviso, setAviso] = useState(null);
  const [filtroLido, setFiltroLido] = useState(false);

  const api = Api.create(usuario.ApiEducar, usuario.tokenAuth);
 
  function carregarDadosNotificacoes(zeraPaginacao) {
    setCarregando(true);
    api.get("/ApiMobileNotificacoes/listarNotificacoes?_pes_codigo=" + usuario.pes_codigoLogin + "&_mtr_codigo=" + estudante.matricula + "&_ativo=true&_lido=" + filtroLido + "&datainformacao=null&_limit=" + NOTIFICACOES_POR_PAGINA + "&_offset=" + (zeraPaginacao ? 0 : (NOTIFICACOES_POR_PAGINA * numeroPagina))).then((retorno) => {
      setCarregando(false);
      if (retorno.status === 200) {
        if (zeraPaginacao)
          setNotificacoes(retorno.data.notificacoes);
        else {
          var ids = new Set(notificacoes.map(d => d.codigo));
          var merged = [...notificacoes, ...retorno.data.notificacoes.filter(d => !ids.has(d.codigo))];
          merged = merged.sort((a, b) => a.codigo > b.codigo ? -1 : 1);

          setNotificacoes(merged);
        }

        if (retorno.data.notificacoes.length > 0)
          setNumeroPagina((zeraPaginacao ? 0 : numeroPagina + 1));
      }
    }, (erro) => {
      setCarregando(false);
      console.error(erro);
    });
  }

  function atualizaQtdeNotificacoesPendentes(statusNotificacao) {
    if (statusNotificacao === null) return null;
    let qtdeAtualizada = global.QtdeNotificacoes;
    if (statusNotificacao === false) qtdeAtualizada++;
    if (statusNotificacao === true) qtdeAtualizada--;

    global.QtdeNotificacoes = qtdeAtualizada;

    props.navigation.closeDrawer();
  }

  function exluirNotificacao(notificacao, indice) {
    api.get("/ApiMobileNotificacoes/ocultarNotificacao?_codigo=" + notificacao.codigo).then((retorno) => {
      if (retorno.status === 200) {
        let listaNotificacoes = [...notificacoes];
        listaNotificacoes.splice(indice, 1);
        setNotificacoes(listaNotificacoes);
        atualizaQtdeNotificacoesPendentes(notificacao.lido ? null : true);
      }
    }, (erro) => {
      console.error(erro);
    });
  }

  function alterarStatusNotificacao(notificacao, indice) {
    let url = "/ApiMobileNotificacoes/marcarLidaNotificacao?_codigo=" + notificacao.codigo;
    if (notificacao.lido) url += "&_lido=false";
    api.get(url).then((retorno) => {
      if (retorno.status === 200) {
        let listaNotificacoes = [...notificacoes];
        listaNotificacoes[indice].lido = !notificacao.lido;
        atualizaQtdeNotificacoesPendentes(listaNotificacoes[indice].lido);
        setNotificacoes(listaNotificacoes);
      }
    }, (erro) => {
      console.error(erro);
    });
  }

  useEffect(() => {
    carregarDadosNotificacoes(true);
  }, [filtroLido]);

  function abrirAviso(aviso) {
    aviso.descricaoHTML = aviso.descricao;
    var regexp = /(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,}/gi;
    var listaLinks = (aviso.descricao).toString().match(regexp);
    if (!!listaLinks)
      for (var i = 0; i < listaLinks.length; i++) {
        aviso.descricaoHTML = (aviso.descricao).toString().replace(listaLinks[i], '<a target="_blank" href="' + listaLinks[i] + '" title="' + listaLinks[i] + '">' + listaLinks[i] + '</a>');
      }

    if (aviso.tipo === 1) setAviso(aviso);
  }

  return (
    <View style={[styles.conteiner, { flex: 1, backgroundColor: ThemeContext._currentValue.theme?.backgroundColor }]}>
      <View style={styles.containerFilter}>
        <Text style={{ fontSize: ThemeContext._currentValue.theme?.fontSize14 }}>Notificações Lidas:</Text>
        <Switch style={{ marginLeft: 5 }}
          value={filtroLido}
          onValueChange={() => { setFiltroLido(!filtroLido); }
          }
        />
      </View>

      {notificacoes.length === 0 &&
        <View style={styles.containerSemDados}>
          <Text style={[styles.textoSemDados, { fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16, color: ThemeContext._currentValue.theme?.textWhite }]}>Nenhuma notificação ou aviso disponível no momento</Text>
        </View>

      }

      <FlatList data={notificacoes}
        renderItem={({ item, index }) => (
          <TouchableOpacity onPress={() => abrirAviso(item)}>
            <View style={[(!item.lido ? styles.itemNaoLido : null), { backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
              <View style={styles.itemNotificacao}>
                <Badge style={{ fontSize: ThemeContext._currentValue.theme?.fontSize10, backgroundColor: item.tipo === 0 ? "#7159c1" : item.tipo === 1 ? "#ff5300" : item.tipo === 2 ? '#2c9648' : '#2196F3', float: "right", position: "absolute" }} size={18}> {item.tipo === 0 ? "Automática" : item.tipo === 1 ? "Aviso" : item.tipo === 2 ? "Mensagem" : "Atividade"} </Badge>
                <Text style={[[styles.titulo, (!item.lido ? { fontWeight: 'bold' } : {})], { fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite }]}>
                  {item.titulo}
                </Text>

                <Text style={[styles.descricao, { fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite }]}>{item.descricao}</Text>
                <View style={{ flexDirection: "row" }}>
                  <MaterialIcons name="access-time" size={16} color="#828181" />
                  <Text style={[styles.texto, { fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite }]}>{` Enviado em ${item.data} ${item.tipo !== 1 && item.tipo !== 3 ? 'às ' + item.horario : ''}`}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <MaterialIcons name={item.tipo !== 3 ? "mode-comment" : "person"} size={16} color="#828181" />
                  <Text style={[styles.texto, { fontSize: ThemeContext._currentValue.theme?.fontSize14, color: ThemeContext._currentValue.theme?.textWhite }]}>{item.tipo !== 3 ? ` Por ${item.enviadoPor}` : `Postado por ${item.enviadoPor}`}</Text>
                </View>
                {item.tipo === 0 &&
                  <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                    <Button style={{ marginRight: 10 }} icon={!item.lido ? "check" : "add-alert"} mode={!item.lido ? "text" : "text"} compact={true} uppercase={false} labelStyle={{ fontSize: ThemeContext._currentValue.theme?.fontSize10 }} onPress={() => alterarStatusNotificacao(item, index)}>
                      {!item.lido ? "Marcar como lido" : "Marcar como não lido"}
                    </Button>
                    <Button color="red" icon="remove" mode="text" compact={true} uppercase={false} labelStyle={{ fontSize: ThemeContext._currentValue.theme?.fontSize10 }} onPress={() => exluirNotificacao(item, index)}>
                      Excluir
                    </Button>
                  </View>
                }
              </View>
              <Divider />
            </View>
          </TouchableOpacity>
        )}
        keyExtractor={item => item.codigo.toString()}
        onEndReachedThreshold={0.5}
        onEndReached={() => carregarDadosNotificacoes(false)} />

      <ModalAviso visible={!!aviso} dados={aviso} onDismiss={() => setAviso(null)} />
      <Modal visible={carregando && Platform.OS !== "web"}>
        <ActivityIndicator color="#FFF" size="large" />
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  conteiner: {
    flex: 1,
    backgroundColor: "#ddd"
  },
  containerFilter: {
    flexDirection: 'row',
    height: 50,
    backgroundColor: "#CECECE",
    alignItems: "center",
    justifyContent: "center",
  },
  itemNaoLido: {
    backgroundColor: "#fff"
  },
  itemNotificacao: {
    minHeight: 50,
    margin: 10,
    justifyContent: 'flex-start',
  },
  titulo: {
    color: "#000000"
  },
  texto: {
    color: "#828181"
  },
  descricao: {
    color: "#202021",
    fontSize: 14
  },
  containerSemDados: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    
  },
  textoSemDados: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: 'center',
  },
});