import React from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { Modal, Text, Button } from 'react-native-paper';

import { Util } from '../../services';
import {themes, ThemeContext} from '../../../theme-context';

export default function ModalLegendaFrequencia(props) {

  const dadosLegenda = [
    {
      status: 'P',
      descricao: 'Presença',
    },
    {
      status: 'F',
      descricao: 'Falta',
    },
    {
      status: 'FJ',
      descricao: 'Falta Justificada',
    },
    {
      status: '-',
      descricao: 'Não Informado',
    },
  ];

  return (
    <Modal visible={props.visible} onDismiss={props.onDismiss} contentContainerStyle={[styles.container, {padding: 20, margin: (Platform.OS === "web" ? 400 : 30), backgroundColor: ThemeContext._currentValue.theme?.backgroundColorCal }]}>
      <View style={styles.containerTitulo}>
        <Text style={[styles.titulo, {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>Legenda de Frequências</Text>
        <View style={styles.conteudo}>
          {dadosLegenda.map((legenda, indice) => (
            <View key={indice} style={styles.linha}>
              <View style={[styles.item, {backgroundColor: Util.retornaCorStatus(legenda.status)}]}>
                <Text style={[styles.textoStatus, {fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{legenda.status}</Text>
              </View>
              <Text style={[styles.titulo,  {fontWeight: "bold", fontSize: ThemeContext._currentValue.theme?.fontSize16}]}>{legenda.descricao}</Text>
            </View>
          ))}
        </View>
        <View style={styles.conteudo}>
          <Button mode="outlined" onPress={() => props.onDismiss()}>Fechar</Button>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    margin: (Platform.OS === "web" ? 400 : 30),
    backgroundColor: "#FFF",
  },
  linha: {
    flexDirection: 'row',
    alignItems: "center",
  },
  containerTitulo: {
    alignSelf: "center",
  },
  titulo: {
    fontSize: 16,
    fontWeight: "bold",
  },
  conteudo: {
    marginTop: 20,
    marginHorizontal: (Platform.OS === "web" ? 40 : 5),
  },
  item: {
    margin: 5,
    padding: 8,
    borderColor: "#2d2d2d",
    borderWidth: 1,
    minWidth: 36,
    alignItems: "center",
  },
  texto: {
    marginTop: 8,
    fontSize: 15,
  },
});