import React from 'react';
import { View, Text, Dimensions } from 'react-native';
import { IconButton } from 'react-native-paper';
import {ThemeContext} from '../../../theme-context';

export default function ItemMensagensDocumento({ mensagem, usuario }) {
  return (
    <View style={{ marginBottom: 6, flexDirection: 'row', justifyContent: (mensagem.pes_remetente === usuario ? 'flex-end' : 'flex-start')}}>
      <View style={{borderColor: '#ccc', borderWidth: 1, minWidth: 120, maxWidth: (Dimensions.get("window").width - (Dimensions.get("window").width * 0.26)), backgroundColor: (mensagem.pes_remetente === usuario ? '#8bd17d' : '#fefefe'), marginBottom: 25, borderRadius: 6, padding: 4}}>
        {mensagem.pes_remetente !== usuario &&
          <Text style={{fontSize: 10, fontWeight: '500', color: mensagem.cor, fontSize: ThemeContext._currentValue.theme?.fontSize10}}>{mensagem.pes_nomeremetente}</Text>
        }
        <Text style={{flex: 1, fontWeight: '500', marginBottom: 20, fontSize: ThemeContext._currentValue.theme?.fontSize14 }} numberOfLines={30}>{mensagem.msg_descricao}</Text>
        <Text style={{fontSize: ThemeContext._currentValue.theme?.fontSize9, color: '#3b484f', position: 'absolute', left: 4, bottom: 4}}>{`${mensagem.msg_data} ${mensagem.msg_hora}`}</Text>
        {mensagem.pes_remetente === usuario &&
          <React.Fragment>
            <IconButton icon="check" color={mensagem.msg_lido ? "#003b99" : "#cccccc" } size={18} style={{margin: 0, padding: 0, position: 'absolute', right: 0, bottom: 0}} />
            <IconButton icon="check" color={mensagem.msg_lido ? "#003b99" : "#cccccc" } size={18} style={{margin: 0, padding: 0, position: 'absolute', right: 5, bottom: 0}} /> 
          </React.Fragment>
        }
      </View>
    </View>
  );
}
